<div class="project-overview-wrapper">
  <div class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto">
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>👋 Client Query List</h2>
            </div>
            <!-- <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <page-button [config]="{ rank: 'neutral' }" [icon]="'bi bi-people-fill'" [loading]="false"
                  (clicked)="openShareToWebDialog()">Share</page-button>
                <page-button [config]="{ rank: 'primary' }" [icon]="'bi bi-plus-square-dotted'" [loading]="false"
                  (clicked)="openAddEditSideNav()">Add</page-button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </header>
    <div class="container-fluid mt-5">
      <div class="card">
        <main class="bg-surface-secondary">
          <!-- <app-share-to-web-popup></app-share-to-web-popup> -->
          <div class="cmn-table-wrapper table-container">
            <table mat-table matSort [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Type
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ element.type }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Client Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ element.name }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="services">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Services
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ element.services }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Email
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ element.email }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="location">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Location
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ element.location }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="phone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Phone
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ element.phone }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="desc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Description
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ element.desc }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="file">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  File Download
                </th>
                <td mat-cell *matCellDef="let element">
                  <a [href]="link + element.file" target="_blank">
                    {{ element.file }}
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef></th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <button mat-icon-button class="cus-round-btn" (click)="openAddEditSideNav(element.id)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button class="cus-round-btn" color="warn" (click)="deleteClientQuery(element.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator [length]="paginatorData?.itemCount" [pageSize]="paginatorData?.perPage"
            [pageSizeOptions]="[10, 20, 50]" aria-label="Select page" (page)="pageChange($event)"
            class="mat-paginator-sticky">,
          </mat-paginator>
        </main>
      </div>
    </div>
  </div>
</div>
