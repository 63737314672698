import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
export interface ButtonConfig {
  label?: string;
  arrow?: 'left' | 'right' | false;
  rank?:
    | 'primary'
    | 'secondary'
    | 'secondary-grey'
    | 'ternary'
    | 'orange'
    | 'neutral';
}

@Component({
  selector: 'page-button',
  templateUrl: './page-button.component.html',
  styleUrls: ['./page-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageButtonComponent {
  @Input() config?: ButtonConfig;
  @Input() loading: boolean | null = false;
  @Input() disabled = false;
  @Output() clicked = new EventEmitter();
  @Input() icon?: string = '';
  @Input() type = 'submit';

  @ViewChild('btn', { static: true }) button!: ElementRef<HTMLButtonElement>;

  onClick() {
    this.clicked.emit();
  }

  focus() {
    this.button.nativeElement.focus();
  }

  isLeft(arrow: ButtonConfig['arrow']) {
    return arrow === 'left';
  }

  isRight(arrow: ButtonConfig['arrow']) {
    return arrow === 'right';
  }
}
