import { SalaryListComponent } from './salary/salary-list/salary-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SalaryDispatchedListComponent } from './salary-dispatched/salary-dispatched-list/salary-dispatched-list.component';
import { RoleGuard } from 'src/app/core/guard/role.guars';

const routes: Routes = [
  { path: 'salary', component: SalaryListComponent, canActivate: [RoleGuard] },
  {
    path: 'salary-dispatched',
    component: SalaryDispatchedListComponent,
    canActivate: [RoleGuard],
  },
  { path: '', redirectTo: 'salary', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalaryRoutingModule {}
