<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} salary Dispatcheds detail
    </h5>

    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="px-0 container-fluid">
      <form [formGroup]="salaryDispatchedForm" class="row">
        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>User Name</mat-label>
            <mat-select placeholder="Select your user" formControlName="userId">
              <mat-option
                *ngFor="let user of userList | roleFilter : [1, 2, 4]"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="salaryDispatchedForm.controls['userId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["userId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Salary ID</mat-label>
            <input type="number"    matInput formControlName="salaryDispatchedId" />
            <mat-error
              *ngIf="salaryDispatchedForm.controls['salaryDispatchedId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["salaryDispatchedId"] }}
            </mat-error>
          </mat-form-field>
        </div> -->

        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Amount</mat-label>
            <input type="number" matInput formControlName="amount" />
            <mat-error
              *ngIf="salaryDispatchedForm.controls['amount'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["amount"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Leaves</mat-label>
            <input type="number" matInput formControlName="leaves" />
            <mat-error
              *ngIf="salaryDispatchedForm.controls['leaves'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["leaves"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Total Amount</mat-label>
            <input type="number" matInput formControlName="totalAmount" />
            <mat-error
              *ngIf="salaryDispatchedForm.controls['totalAmount'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["totalAmount"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
