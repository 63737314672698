import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CmnAddEditSidenavService {
  addEditSideNav = new BehaviorSubject<boolean>(false);
  updatePortfolioList = new BehaviorSubject<boolean>(false);
  updateProjectList = new BehaviorSubject<boolean>(false);
  updateRoleList = new BehaviorSubject<boolean>(false);
  updateUserDocumentList = new BehaviorSubject<boolean>(false);
  updateAttendanceList = new BehaviorSubject<boolean>(false);
  updateLeaveList = new BehaviorSubject<boolean>(false);
  updateSalaryList = new BehaviorSubject<boolean>(false);
  updateSalaryDispatchedList = new BehaviorSubject<boolean>(false);
  updateOrganizationList = new BehaviorSubject<boolean>(false);
  updateClientInfoList = new BehaviorSubject<boolean>(false);
  updateClientQueryList = new BehaviorSubject<boolean>(false);
  updateTeamsList = new BehaviorSubject<boolean>(false); //new team service created
  updatePostList = new BehaviorSubject<boolean>(false); //new post service created
  updateSiteCredsList = new BehaviorSubject<boolean>(false); //new SiteCreds service created
  updateUsersList = new BehaviorSubject<boolean>(false); //new Users service created
  updateTaskList = new BehaviorSubject<boolean>(false); //new Task service created
  updateTeamMembersList = new BehaviorSubject<boolean>(false); //new team Member service created
  openingComponentSubject = new BehaviorSubject<any>(null);

  openAddEditSideNav(componentName: string, id?: string | number) {
    if (componentName == 'ProjectComponent') {
      this.updateProjectList.next(false);
    } else if (componentName == 'PortfolioComponent') {
      this.updatePortfolioList.next(false);
    } else if (componentName == 'RoleListComponent') {
      this.updateRoleList.next(false);
    } else if (componentName == 'UserDocumentListComponent') {
      this.updateUserDocumentList.next(false);
    } else if (componentName == 'AttendanceListComponent') {
      this.updateAttendanceList.next(false);
    } else if (componentName == 'LeaveComponent') {
      this.updateLeaveList.next(false);
    } else if (componentName == 'SalaryListComponent') {
      this.updateSalaryList.next(false);
    } else if (componentName == 'OrganizationListComponent') {
      this.updateOrganizationList.next(false);
    } else if (componentName == 'ClientInfoListComponent') {
      this.updateClientInfoList.next(false);
    } else if (componentName == 'ClientQueryListComponent') {
      this.updateClientQueryList.next(false);
    } else if (componentName == 'SalaryDispatchedListComponent') {
      this.updateSalaryDispatchedList.next(false);
    } else if (componentName == 'TeamsComponent') { //new team service created
      this.updateTeamsList.next(false);
    } else if (componentName == 'PostListComponent') { //new post service created
      this.updatePostList.next(false);
    } else if (componentName == 'SiteCredsListComponent') { //new site Creds service created
      this.updateSiteCredsList.next(false);
    } else if (componentName == 'UsersListComponent') { //new Users service created
      this.updateUsersList.next(false);
    } else if (componentName == 'TaskListComponent') { //new Task service created
      this.updateTaskList.next(false);
    } else if (componentName == 'TeamMembersListComponent') { //new Team Member service created
      this.updateTeamMembersList.next(false);
    }
    const obj = { componentName };

    if (id) {
      obj['id'] = id;
    }

    this.addEditSideNav.next(true);
    this.openingComponentSubject.next(obj);
  }

  closeAddEditSideNav() {
    this.openingComponentSubject.next(null);
    this.addEditSideNav.next(false);
    this.updatePortfolioList.next(false);
    this.updateProjectList.next(false);
    this.updateRoleList.next(false);
    this.updateUserDocumentList.next(false);
    this.updateAttendanceList.next(false);
    this.updateLeaveList.next(false);
    this.updateSalaryList.next(false);
    this.updateSalaryDispatchedList.next(false);
    this.updateOrganizationList.next(false);
    this.updateClientInfoList.next(false);
    this.updateClientQueryList.next(false);
    this.updateTeamsList.next(false); //new team service for close
    this.updatePostList.next(false); //new team service for close
    this.updateSiteCredsList.next(false); //new team service for close
    this.updateUsersList.next(false); //new user service for close
    this.updateTaskList.next(false); //new user service for close
    this.updateTeamMembersList.next(false); //new team member service for close
  }

  getAddEditSideNav() {
    return this.addEditSideNav.asObservable();
  }

  getUpdatedDataList(componentName: string) {
    if (componentName == 'ProjectComponent') {
      this.updateProjectList.next(true);
    } else if (componentName == 'PortfolioComponent') {
      this.updatePortfolioList.next(true);
    } else if (componentName == 'RoleListComponent') {
      this.updateRoleList.next(true);
    } else if (componentName == 'UserDocumentListComponent') {
      this.updateUserDocumentList.next(true);
    } else if (componentName == 'AttendanceListComponent') {
      this.updateAttendanceList.next(true);
    } else if (componentName == 'LeaveComponent') {
      this.updateLeaveList.next(true);
    } else if (componentName == 'SalaryListComponent') {
      this.updateSalaryList.next(true);
    } else if (componentName == 'OrganizationListComponent') {
      this.updateOrganizationList.next(true);
    } else if (componentName == 'ClientInfoListComponent') {
      this.updateClientInfoList.next(true);
    } else if (componentName == 'ClientQueryListComponent') {
      this.updateClientQueryList.next(true);
    } else if (componentName == 'SalaryDispatchedListComponent') {
      this.updateSalaryDispatchedList.next(true);
    } else if (componentName == 'TeamsComponent') { // new team service
      this.updateTeamsList.next(true);
    } else if (componentName == 'PostListComponent') { // new post service
      this.updatePostList.next(true);
    } else if (componentName == 'SiteCredsListComponent') { // new site creds service
      this.updateSiteCredsList.next(true);
    } else if (componentName == 'UsersListComponent') { // new users service
      this.updateUsersList.next(true);
    } else if (componentName == 'TaskListComponent') { // new task service
      this.updateTaskList.next(true);
    } else if (componentName == 'TeamMembersListComponent') { // new team member service
      this.updateTeamMembersList.next(true);
    }
  }
}
