import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
  name: 'dataValidation',
})
export class DataValidationPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === 0) {
      return 0;
    } else if (value == null || value == undefined || Number.isNaN(value)) {
      return '-';
    } else if (value === '') {
      return '-';
    } else {
      return value;
    }
  }
}
