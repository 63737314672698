<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} Role
    </h5>
    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="container-fluid px-0">
      <form [formGroup]="roleForm" class="row">
        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" />
            <mat-error
              *ngIf="roleForm.controls['name'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["name"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Code</mat-label>
            <input matInput formControlName="code" />
            <mat-error
              *ngIf="roleForm.controls['code'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["code"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Weight</mat-label>
            <input matInput type="number" formControlName="weight" />
            <mat-error
              *ngIf="roleForm.controls['weight'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["weight"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>

  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
