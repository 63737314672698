<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} leave
    </h5>

    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="px-0 container-fluid">
      <form [formGroup]="leaveForm" class="row">
        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Approved By</mat-label>
            <mat-select
              placeholder="Select your user"
              formControlName="approverId"
            >
              <mat-option
                *ngFor="let user of userList | roleFilter : [1, 2, 4]"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="leaveForm.controls['approverId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["approverId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Leave Status</mat-label>
            <mat-select formControlName="status">
              <mat-option
                *ngFor="let option of leaveOptions"
                [value]="option.value"
              >
                {{ option.text }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              formControlName="startDate"
              [matDatepicker]="startDate"
              [min]="currentDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="startDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
            <mat-error
              *ngIf="leaveForm.controls['startDate'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["startDate"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input
              matInput
              formControlName="endDate"
              [matDatepicker]="endDate"
              [min]="leaveForm.get('startDate').value"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="endDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
            <mat-error
              *ngIf="leaveForm.controls['endDate'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["endDate"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 cmn-form-field">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="desc" rows="3" matInput></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
