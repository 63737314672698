<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} user document
    </h5>
    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="container-fluid px-0">
      <form [formGroup]="roleDocumentForm" class="row">
        <div class="col-12 mx-auto">
          <div
            class="file-selector"
            [ngClass]="{ 'border-none': selectedImage }"
          >
            <div class="w-100" (click)="fileInput.click()">
              <div
                class="m-auto d-flex h-100 d-flex justify-content-center align-items-center"
                *ngIf="!selectedImage"
              >
                <!-- <mat-icon class="text-gray-100 w-[26px] h-[26px]"
                  >add_circle_outline</mat-icon
                > -->
                <div class="text-center">
                  <div class="text-2xl text-muted">
                    <i class="bi bi-upload"></i>
                  </div>
                  <div class="d-flex text-sm mt-3">
                    <p class="font-semibold">Upload a file or drag and drop</p>
                  </div>
                  <p class="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                </div>
              </div>
              <div
                class="preview-container w-full h-full rounded-[12px]"
                *ngIf="selectedImage"
              >
                <img
                  [src]="selectedImage"
                  alt="Selected Image"
                  class="preview-image"
                />
                <mat-icon
                  style="color: black; z-index: 3"
                  class="position-absolute w-[26px] h-[26px]"
                  >add_circle_outline</mat-icon
                >
              </div>
            </div>

            <input
              type="file"
              #fileInput
              hidden
              (change)="onFileSelected($event)"
              accept="image/*"
            />
          </div>
          <!-- <ng-container>
            <mat-error
              *ngIf="roleDocumentForm.controls['docUrl'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["docUrl"] }}
            </mat-error>
          </ng-container> -->
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>User Name</mat-label>
            <mat-select
              placeholder="Select your employee name"
              formControlName="userId"
            >
              <mat-option *ngFor="let user of userList" [value]="user.id">{{
                user.name
              }}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="roleDocumentForm.controls['userId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["userId"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <!-- 
        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Document Url</mat-label>
            <input matInput formControlName="docUrl" />
          </mat-form-field>
        </div> -->

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Document No</mat-label>
            <input type="number" matInput formControlName="documentNo" />
            <mat-error
              *ngIf="roleDocumentForm.controls['documentNo'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["documentNo"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Document Type</mat-label>
            <input
              matInput
              placeholder="Aadharcard Pancard etc."
              formControlName="type"
            />
            <mat-error
              *ngIf="roleDocumentForm.controls['type'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["type"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>

  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
