<div class="cmn-dialog-box">
  <div mat-dialog-content>
    <div class="modal-content shadow-3">
      <div class="modal-header justify-content-start">
        <div
          class="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4"
        >
          <i class="bi bi-people"></i>
        </div>
        <div>
          <h5 class="mb-1">Add members</h5>
          <small class="d-block text-xs text-muted"
            >Publish and share link with anyone</small
          >
        </div>
      </div>
      <div class="modal-body py-0 scrollable-y" style="max-height: 425px">
        <div class="list-group list-group-flush add-member-wrapper">
          <div class="list-group-item d-flex align-items-center px-0 py-3">
            <div class="add-member-name-icon-wrapper">
              <div>
                <a class="avatar avatar-sm rounded-circle"
                  ><img
                    alt="..."
                    src="../../../../assets/images/thumbnail/people-1.jpg"
                /></a>
              </div>
              <div class="flex-fill">
                <a class="d-block h6 mb-1">Darlene Robertson</a>
              </div>
            </div>
            <div>
              <a class="btn btn-sm btn-neutral px-4 py-2 text-xs">Add</a>
            </div>
          </div>
          <div class="list-group-item d-flex align-items-center px-0 py-3">
            <div class="add-member-name-icon-wrapper">
              <div>
                <a class="avatar avatar-sm rounded-circle"
                  ><img
                    alt="..."
                    src="../../../../assets/images/thumbnail/people-1.jpg"
                /></a>
              </div>
              <div class="flex-fill">
                <a class="d-block h6 mb-1">Cody Fisher</a>
              </div>
            </div>
            <div>
              <a class="btn btn-sm btn-neutral px-4 py-2 text-xs">Add</a>
            </div>
          </div>
          <div class="list-group-item d-flex align-items-center px-0 py-3">
            <div class="add-member-name-icon-wrapper">
              <div>
                <a class="avatar avatar-sm rounded-circle"
                  ><img
                    alt="..."
                    src="../../../../assets/images/thumbnail/people-1.jpg"
                /></a>
              </div>
              <div class="flex-fill">
                <a class="d-block h6 mb-1">Kristin Watson</a>
              </div>
            </div>
            <div>
              <a class="btn btn-sm btn-neutral px-4 py-2 text-xs">Add</a>
            </div>
          </div>
          <div class="list-group-item d-flex align-items-center px-0 py-3">
            <div class="add-member-name-icon-wrapper">
              <div>
                <a class="avatar avatar-sm rounded-circle"
                  ><img
                    alt="..."
                    src="../../../../assets/images/thumbnail/people-1.jpg"
                /></a>
              </div>
              <div class="flex-fill">
                <a class="d-block h6 mb-1">Cody Fisher</a>
              </div>
            </div>
            <div>
              <a class="btn btn-sm btn-neutral px-4 py-2 text-xs">Add</a>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="me-auto">
          <a class="text-sm font-semibold"
            ><i class="bi bi-clipboard me-2"></i>
            <span class="">Copy link</span></a
          >
        </div>
        <div mat-dialog-actions>
          <button mat-button mat-dialog-close class="btn btn-sm btn-neutral">
            Close
          </button>
        </div>

        <button type="button" class="btn btn-sm btn-primary">
          Invite 3 users
        </button>
      </div>
    </div>
  </div>
</div>
