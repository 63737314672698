import { Component, OnInit } from '@angular/core';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit {

  isEdit:boolean = true;

  constructor(
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
  ) { }

  ngOnInit(): void {
  }

  
  // You can also close the add/edit sidenav if needed
  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }
}
