<div
  class="file-selector"
  [ngClass]="{ 'border-none': uploadedImage.length > 0 }"
>
  <div class="w-100" (click)="fileInput.click()">
    <div
      class="m-auto d-flex h-100 d-flex justify-content-center align-items-center"
      *ngIf="uploadedImage.length == 0"
    >
      <div class="text-center">
        <div class="text-2xl text-muted">
          <i class="bi bi-upload"></i>
        </div>
        <div class="d-flex text-sm mt-3">
          <p class="font-semibold">Upload a file or drag and drop</p>
        </div>
        <p class="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
      </div>
    </div>
    <div
      class="preview-container w-full h-full rounded-[12px]"
      *ngIf="uploadedImage.length > 0"
    >
      <img
        [src]="enviromentUrl + uploadedImage[0]"
        alt="Selected Image"
        class="preview-image"
      />
      <mat-icon
        style="color: black; z-index: 3"
        class="position-absolute w-[26px] h-[26px]"
        >add_circle_outline</mat-icon
      >
    </div>
  </div>

  <input
    id="fileUpload"
    type="file"
    #fileInput
    hidden
    (change)="onFileSelected($event)"
    accept="image/*"
    [multiple]="isMulti"
  />
</div>
<div class="image-preview" *ngIf="uploadedImage.length > 0 && showPreview">
  <div class="sinle-image-box" *ngFor="let img of uploadedImage; let i = index">
    <img [src]="enviromentUrl + img" alt="" />
    <span class="delete-icon">
      <mat-icon (click)="removeImage(i)">delete</mat-icon>
    </span>
  </div>
</div>
