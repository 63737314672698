import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiStatus, leaves, RolesEnum } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { leaveApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { User } from 'src/app/core/model/user';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-add-edit-leave',
  templateUrl: './add-edit-leave.component.html',
  styleUrls: ['./add-edit-leave.component.scss'],
})
export class AddEditLeaveComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  userList: User[] = [];
  leaveForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    approverId: {
      required: 'Approved By is required',
    },
    desc: {
      required: 'Description is required',
    },
    startDate: {
      required: 'Start Date is required',
    },
    endDate: {
      required: 'End Date is required',
    },
  };
  currentDate = new Date();

  isEdit: boolean = false;
  leaveId: number;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  leaveOptions = [
    { text: 'applied', value: leaves.applied },
    { text: 'accepted', value: leaves.accepted },
    { text: 'rejected', value: leaves.rejected },
  ];
  userId: number;
  patchedLeaveData;

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.leaveId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createLeaveForm();
    this.getUserList();
    if (this.isEdit) {
      this.patchVal();
    }
    let user = this.localStorageService.getUser();
    this.userId = user.id;
  }

  // You can also close the add/edit sidenav if needed
  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createLeaveForm() {
    this.leaveForm = this.fb.group({
      approverId: ['', Validators.required],
      status: [null],
      desc: [null],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }
  getUserList() {
    let userListUrl = userApi.list;
    (this.apiQuery.options.select = ['name', 'id', 'role']),
      (this.apiQuery.query = {
        isDeleted: false,
      });

    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService
      .getList(userListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.userList = res.data.data;
        }
      });
  }

  onSubmit() {
    this.leaveForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.leaveForm.controls,
      this.validationMessages
    );

    if (this.leaveForm.valid) {
      let leaveApiUrl = this.isEdit ? leaveApi.update : leaveApi.create;
      let model = this.leaveForm.value;
      model.userId = +this.userId;
      if (this.isEdit) {
        model['id'] = +this.leaveId;
      } else {
        model['status'] = leaves.applied;
      }

      this.cmnApiCallService.addUpdateData(leaveApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `Leave created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'Leave has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList('LeaveComponent');
            this.leaveForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let leaveGetByIdUrl = leaveApi.byId;
    this.cmnApiCallService
      .getById(leaveGetByIdUrl, this.leaveId)
      .subscribe((res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.patchedLeaveData = res.data;
            this.leaveForm.patchValue({
              approverId: res.data.approverId,
              status: +res.data.status,
              startDate: res.data.startDate,
              endDate: res.data.endDate,
              desc: res.data.desc,
            });
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
