import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AttendanceRoutingModule } from './attendance-routing.module';
import { LeaveComponent } from './leave/leave.component';
import { InOutComponent } from './in-out/in-out.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AttendanceListComponent } from './attendance-list/attendance-list.component';
import { AddEditAttendanceComponent } from './add-edit-attendance/add-edit-attendance.component';
import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddEditLeaveComponent } from './add-edit-leave/add-edit-leave.component';

@NgModule({
  declarations: [
    LeaveComponent,
    InOutComponent,
    AttendanceListComponent,
    AddEditAttendanceComponent,
    AddEditLeaveComponent,
  ],
  imports: [
    CommonModule,
    AttendanceRoutingModule,
    SharedModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [AddEditAttendanceComponent, AddEditLeaveComponent],
})
export class AttendanceModule {}
