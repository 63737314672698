<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} project
    </h5>

    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="px-0 container-fluid">
      <form [formGroup]="projectForm" class="row">
        <div class="col-12 mx-auto">
          <div
            class="file-selector"
            [ngClass]="{ 'border-none': selectedImage }"
          >
            <div class="w-100" (click)="fileInput.click()">
              <div
                class="m-auto d-flex h-100 d-flex justify-content-center align-items-center"
                *ngIf="!selectedImage"
              >
                <!-- <mat-icon class="text-gray-100 w-[26px] h-[26px]"
                  >add_circle_outline</mat-icon
                > -->
                <div class="text-center">
                  <div class="text-2xl text-muted">
                    <i class="bi bi-upload"></i>
                  </div>
                  <div class="d-flex text-sm mt-3">
                    <p class="font-semibold">Upload a file or drag and drop</p>
                  </div>
                  <p class="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                </div>
              </div>
              <div
                class="preview-container w-full h-full rounded-[12px]"
                *ngIf="selectedImage"
              >
                <img
                  [src]="selectedImage"
                  alt="Selected Image"
                  class="preview-image"
                />
                <mat-icon
                  style="color: black; z-index: 3"
                  class="position-absolute w-[26px] h-[26px]"
                  >add_circle_outline</mat-icon
                >
              </div>
            </div>

            <input
              type="file"
              #fileInput
              hidden
              (change)="onFileSelected($event)"
              accept="image/*"
            />
          </div>
        </div>
        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Organization</mat-label>
            <mat-select
              placeholder="Select your Organization"
              formControlName="orgId"
            >
              <mat-option
                *ngFor="let user of OrganizationList"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="projectForm.controls['orgId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["orgId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" required />
            <mat-error
              *ngIf="projectForm.controls['name'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["name"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="cmn-form-field col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>Image URL</mat-label>
            <input matInput formControlName="imageUrl" />
          </mat-form-field>
        </div> -->

        <div class="cmn-form-field col-md-6">
          <div class="cmn-date-picker">
            <mat-form-field appearance="outline">
              <mat-label>Start date</mat-label>
              <input
                matInput
                [matDatepicker]="picker2"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="cmn-form-field col-md-6 col-12">
          <div class="cmn-date-picker">
            <mat-form-field appearance="outline">
              <mat-label>Due date</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="dueDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                style="width: 1em"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select
              placeholder="Select your Organization"
              formControlName="type"
            >
              <mat-option
                *ngFor="let type of ProjectypeList"
                [value]="type.id"
                >{{ type.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="projectForm.controls['type'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["type"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select
              placeholder="Select your Organization"
              formControlName="status"
            >
              <mat-option
                *ngFor="let status of ProjectStatusList"
                [value]="status.id"
                >{{ status.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="projectForm.controls['status'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["status"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-md-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Budget</mat-label>
            <input matInput type="number" formControlName="budget" />
            <mat-error
              *ngIf="projectForm.controls['budget'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["budget"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="vstack gap-3 ps-4">
              <h6 class="border-start border-secondary mb-4 ps-4">Project Status</h6>
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                @for (status of projectStatus; track status) {
                  <div class="example-box" cdkDrag>
                    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    {{status.tag}}
                    <div>
                      <button class="border rounded rounded-circle p-0" (click)="deleteItem(status.id)">
                        <mat-icon class="h-100 w-100 d-flex fs-5">clear</mat-icon>
                      </button>
                    </div>
                  </div>
                }
              </div>
              <!-- <div *ngIf = "isEdit">
                <div class="d-flex" *ngFor="let status of projectStatus">
                  <div>
                    <input matInput value={{status.tag}}>
                  </div>
                  <div>
                    <button class="border rounded rounded-circle p-0" (click)="deleteItem(status.id)">
                      <mat-icon class="h-100 w-100 d-flex fs-5">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="d-flex justify-content-evenly">
              <div class="cmn-form-field mt-4 col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Add status</mat-label>
                  <input matInput placeholder="status" formControlName="projectStatus"/>
                </mat-form-field>
              </div>
              <div class="col-4 mt-4">
                <page-button [config]="{ rank: 'primary' }" (clicked)="onAddItem(projectForm.value['projectStatus'])">
                  Add status
                </page-button>
              </div>
            </div>
        <!-- <div class="col-12">
          <div>
            <label class="form-label">Select view</label>
            <div class="hstack gap-3 flex-wrap">
              <div class="form-item-checkable">
                <input
                  class="form-item-check"
                  type="radio"
                  name="project-view"
                  id="project-view-kanban"
                  checked="checked"
                  value="0"
                />
                <label class="form-item" for="project-view-kanban">
                  <span
                    class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
                  >
                    <i class="bi bi-kanban" style="font-size: 2rem"></i>
                  </span>
                </label>
              </div>
              <div class="form-item-checkable">
                <input
                  class="form-item-check"
                  type="radio"
                  name="project-view"
                  id="project-view-list"
                  value="1"
                />
                <label class="form-item" for="project-view-list"
                  ><span
                    class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
                    ><i class="bi bi-view-list" style="font-size: 2rem"></i
                  ></span>
                </label>
              </div>
              <div class="form-item-checkable">
                <input
                  class="form-item-check"
                  type="radio"
                  name="project-view"
                  id="project-view-table"
                  value="3"
                />
                <label class="form-item" for="project-view-table">
                  <span
                    class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
                  >
                    <i class="bi bi-table" style="font-size: 2rem"></i>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-12 select-view-box">
          <label class="form-label d-block mb-2">Select view</label>
          <mat-radio-group name="project-view" formControlName="view">
            <mat-radio-button value="0" id="project-view-kanban">
              <span
                class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
              >
                <i class="bi bi-kanban" style="font-size: 2rem"></i>
              </span>
            </mat-radio-button>
            <mat-radio-button value="1" id="project-view-list">
              <span
                class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
              >
                <i class="bi bi-view-list" style="font-size: 2rem"></i>
              </span>
            </mat-radio-button>
            <mat-radio-button value="3" id="project-view-table">
              <span
                class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
              >
                <i class="bi bi-table" style="font-size: 2rem"></i>
              </span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="cmn-form-field col-12 mt-4">
          <!-- <mat-form-field class="w-100" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="desc" matInput required></textarea>
            <mat-error
              *ngIf="projectForm.controls['desc'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["desc"] }}
            </mat-error>
          </mat-form-field> -->
          <ckeditor
            [editor]="editor"
            [data]="editorData"
            (ready)="onReady($event)"
            [config]="editorConfig"
          ></ckeditor>
        </div>
      </form>
    </div>
  </div>
  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
