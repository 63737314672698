import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserAccessRoutingModule } from './user-access-routing.module';
import { UserAccessComponent } from './user-access/user-access.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddEditUserAccessComponent } from './add-edit-user-access/add-edit-user-access.component';

@NgModule({
  declarations: [UserAccessComponent, AddEditUserAccessComponent],
  imports: [CommonModule, UserAccessRoutingModule, SharedModule],
  exports: [AddEditUserAccessComponent],
})
export class UserAccessModule {}
