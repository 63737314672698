<!-- <div class="create-project-form-wrapper">
  <form>
    <div
      class="offcanvas offcanvas-center show max-w-screen-md position-relative mx-auto mt-6 bg-transparent"
      data-bs-scroll="false"
      data-bs-backdrop="false"
      tabindex="-1"
      id="offcanvasCreate"
      aria-labelledby="offcanvasCreateLabel"
      style="visibility: visible"
      aria-hidden="true"
    >
      <div
        class="offcanvas-header border-bottom cmn-header-box py-5 bg-surface-secondary"
      >
        <h5 class="offcanvas-title" id="offcanvasCreateLabel">
          Create a new project
        </h5>
      
      </div>
      <div class="offcanvas-body vstack gap-5">
        <div class="cmn-form-field white-field">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Project name" />
          </mat-form-field>
          <span class="d-block mt-2 text-sm text-muted">Make it unique.</span>
        </div>
        <div class="cmn-form-field white-field">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Project description ..."></textarea>
          </mat-form-field>
          <span class="d-block mt-2 text-sm text-muted">Make it unique.</span>
        </div>
        <hr class="my-0" />
        <div>
          <label class="form-label">Select view</label>
          <div class="hstack gap-3 flex-wrap">
            <div class="form-item-checkable">
              <input
                class="form-item-check"
                type="radio"
                name="project-view"
                id="project-view-kanban"
                checked="checked"
              />
              <label class="form-item" for="project-view-kanban">
                <span
                  class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
                >
                  <i class="bi bi-kanban" style="font-size: 2rem"></i>
                </span>
              </label>
            </div>
            <div class="form-item-checkable">
              <input
                class="form-item-check"
                type="radio"
                name="project-view"
                id="project-view-list"
              />
              <label class="form-item" for="project-view-list"
                ><span
                  class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
                  ><i class="bi bi-view-list" style="font-size: 2rem"></i
                ></span>
              </label>
            </div>
            <div class="form-item-checkable">
              <input
                class="form-item-check"
                type="radio"
                name="project-view"
                id="project-view-table"
              />
              <label class="form-item" for="project-view-table">
                <span
                  class="form-item-click d-inline-flex align-items-center justify-content-center form-control w-24 h-24 text-center text-muted"
                >
                  <i class="bi bi-table" style="font-size: 2rem"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
        <hr class="my-0" />
        <div class="vstack gap-4">
          <div class="d-flex gap-3">
            <input
              class="form-check-input flex-shrink-0 text-lg"
              type="radio"
              name="projecy-privacy"
              checked="checked"
            />
            <div class="pt-1 form-checked-content">
              <h6 class="mb-1 lh-relaxed">Private</h6>
              <span class="d-block text-muted text-sm">
                <i class="bi bi-lock-fill me-1"></i>
                Only you will be able to see this project
              </span>
            </div>
          </div>
          <div class="d-flex gap-3">
            <input
              class="form-check-input flex-shrink-0 text-lg"
              type="radio"
              name="projecy-privacy"
            />
            <div class="pt-1 form-checked-content">
              <h6 class="mb-1 lh-relaxed">Make it public</h6>
              <span class="d-block text-muted text-sm">
                <i class="bi bi-people-fill me-1"></i> Everyone in this
                workspace will be able to see this project</span
              >
            </div>
          </div>
        </div>
        <hr class="my-0" />
        <div class="row gy-1 align-items-center">
          <div class="col-md">
            <div class="cmn-form-field white-field">
              <mat-form-field appearance="outline">
                <mat-label>Client</mat-label>
                <mat-select>
                  <mat-option>Apple</mat-option>
                  <mat-option>Webpixels</mat-option>
                  <mat-option>Google</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="col-auto py-sm-0">
            <span class="d-inline-block font-semibold text-muted">or</span>
          </div>
          <div class="col-md-auto">
            <button type="button" class="btn btn-neutral">
              <i class="bi bi-plus-lg me-2"></i>New client
            </button>
          </div>
        </div>
        <div class="row gy-4">
          <div class="col-md-6">
            <div class="cmn-date-picker">
              <mat-form-field appearance="fill">
                <mat-label>Start date</mat-label>
                <input matInput [matDatepicker]="picker2" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="cmn-date-picker">
              <mat-form-field appearance="fill">
                <mat-label>Due date</mat-label>
                <input matInput [matDatepicker]="picker" />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                  style="width: 1em"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer py-2 bg-surface-secondary">
       
        <button type="button" class="btn btn-sm btn-primary">Save</button>
      </div>
    </div>
  </form>
</div> -->
<div class="create-project">
  <app-add-edit-project></app-add-edit-project>
</div>
