<h1 mat-dialog-title>Confirmation</h1>

<mat-dialog-content>
  Are you sure you want to delete this record?
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    class="mat-dialog-button cancel-button"
    [mat-dialog-close]="false"
  >
    Cancel
  </button>
  <button
    mat-button
    class="mat-dialog-button delete-button"
    [mat-dialog-close]="true"
    color="warn"
  >
    Delete
  </button>
</mat-dialog-actions>
