import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { projectApi, teamApi, userApi } from 'src/app/core/model/api-url';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';

@Component({
  selector: 'app-add-edit-teams',
  templateUrl: './add-edit-teams.component.html',
  styleUrls: ['./add-edit-teams.component.scss'],
})
export class AddEditTeamsComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  userList: User[] = [];
  projectList = [];
  teamForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    userId: {
      required: 'User ID is required',
      pattern: 'User ID must be a numeric value',
    },
    projectId: {
      required: 'Project ID is required',
      pattern: 'Project ID must be a numeric value',
    },
    name: {
      required: 'Team Name is required',
    },
  };

  isEdit: boolean = false;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userId: number;
  teamId: number;

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.teamId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createTeamForm();
    this.getUserList();
    this.getProjectList();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createTeamForm() {
    this.teamForm = this.fb.group({
      userId: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      projectId: ['', [Validators.required]],
      name: ['', [Validators.required]],
      desc: [''],
    });
  }

  getUserList() {
    let roleUserUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;
            this.userList.push(this.localStorageService.getUser());
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  getProjectList() {
    let projectListUrl = projectApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService.getList(projectListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.projectList = res.data.data;
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onSubmit() {
    this.teamForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.teamForm.controls,
      this.validationMessages
    );

    if (this.teamForm.valid) {
      let teamApiUrl = this.isEdit ? teamApi.update : teamApi.create;
      let model = this.teamForm.value;
      // model.userId = +this.userId;
      if (this.isEdit) {
        model['id'] = +this.teamId;
      }

      this.cmnApiCallService.addUpdateData(teamApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `Team created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'Team has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList('TeamsComponent');
            this.teamForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let leaveGetByIdUrl = teamApi.byId;
    this.cmnApiCallService.getById(leaveGetByIdUrl, this.teamId).subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.teamForm.patchValue({
              userId: res.data.userId,
              projectId: res.data.projectId,
              name: res.data.name,
              desc: res.data.desc,
            });
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
