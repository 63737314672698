<div
  class="offcanvas offcanvas-end w-full w-lg-1/3"
  data-bs-scroll="true"
  data-bs-backdrop="true"
  tabindex="-1"
  id="offcanvasCreate"
  aria-labelledby="offcanvasCreateLabel"
  style="visibility: visible"
  aria-modal="true"
  role="dialog"
>
  <div class="offcanvas-header border-bottom py-4 bg-surface-secondary">
    <h5 class="offcanvas-title" id="offcanvasCreateLabel">Create a new user</h5>
    <button
      type="button"
      class="btn-close text-reset text-xs"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body vstack gap-5">
    <div class="row g-5">
      <div class="col-md-6">
        <div class="cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="" />
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Last name</mat-label>
            <input matInput placeholder="" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <div class="cmn-form-field">
        <mat-form-field appearance="outline">
          <mat-label>Bio</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div>
      <label class="form-label" for="formInputExample">Photo</label>
      <div class="d-flex align-items-center">
        <a

          class="avatar avatar-lg bg-warning rounded-circle text-white"
          ><img
            alt="..."
            src="../../../../assets/images/thumbnail/people-1.jpg"
        /></a>
        <div class="ms-5">
          <label for="file-upload" class="btn btn-sm btn-neutral"
            ><span>Upload</span>
            <input
              type="file"
              name="file-upload"
              id="file-upload"
              class="visually-hidden"
          /></label>
          <a

            class="btn d-inline-flex btn-sm btn-neutral ms-2 text-danger"
            ><span class="pe-2"><i class="bi bi-trash"></i> </span
            ><span>Remove</span></a
          >
        </div>
      </div>
    </div>
    <hr />
    <div class="row gy-md-0 gy-5">
      <div class="col-md-6">
        <div class="cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Email address</mat-label>
            <input matInput placeholder="" />
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Phone number</mat-label>
            <input matInput placeholder="" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          name="switchMakeAdmin"
          id="switchMakeAdmin"
        />
        <label class="form-check-label ms-2" for="switchMakeAdmin"
          >Make admin</label
        >
      </div>
    </div>
  </div>
  <div class="modal-footer py-2 bg-surface-secondary">
    <button
      type="button"
      class="btn btn-sm btn-neutral"
      data-bs-dismiss="offcanvas"
    >
      Close
    </button>
    <button type="button" class="btn btn-sm btn-primary">Save</button>
  </div>
</div>
