<div
  class="offcanvas offcanvas-end w-full w-lg-1/3"
  data-bs-scroll="true"
  data-bs-backdrop="true"
  tabindex="-1"
  id="offcanvasCreate"
  aria-labelledby="offcanvasCreateLabel"
>
  <div class="offcanvas-header border-bottom py-5 bg-surface-secondary">
    <h5 class="offcanvas-title" id="offcanvasCreateLabel">Add a new role</h5>
    <button
      type="button"
      class="btn-close text-reset text-xs"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body vstack gap-5">
    <div class="row g-5">
      <div class="col-md-12">
        <div class="cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Role name</mat-label>
            <input matInput placeholder="Admin" />
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-12">
        <div class="cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
              rows="4"
              cols="30"
              placeholder="Role description ..."
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <label class="form-label">Select permissions</label>
      <div class="list-group list-group-flush gap-3">
        <div
          class="list-group-item list-group-item-setting p-6 bg-surface-secondary border-0 rounded-2"
        >
          <div class="d-flex">
            <div class="w-10 me-2 me-sm-0">
              <i class="bi bi-folder-plus text-lg text-muted"></i>
            </div>
            <div class="flex-1 me-10">
              <a  class="d-block h5">Projects</a>
              <p class="mt-1 text-sm text-muted">
                Select the permissions you want to add to all the projects in
                this account.
              </p>
            </div>
          </div>
          <div class="vstack gap-3 mt-4 ms-10">
            <div class="d-flex align-items-center">
              <h6 class="text-sm font-semibold">Can view</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch1"
                  id="switchView1"
                  checked="checked"
                />
              </div>
            </div>
            <hr class="my-0" />
            <div class="d-flex align-items-center">
              <h6 class="font-semibold">Can edit</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch1"
                  id="switchEdit1"
                  checked="checked"
                />
              </div>
            </div>
            <hr class="my-0" />
            <div class="d-flex align-items-center">
              <h6 class="font-semibold">Can publish</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch1"
                  id="switchPublish1"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="list-group-item list-group-item-setting p-6 bg-surface-secondary border-0 rounded-2"
        >
          <div class="d-flex">
            <div class="w-10 me-2 me-sm-0">
              <i class="bi bi-folder-plus text-lg text-muted"></i>
            </div>
            <div class="flex-1 me-10">
              <a  class="d-block h5">Users</a>
              <p class="mt-1 text-sm text-muted">
                Select the permissions you want to add to all the users in this
                account.
              </p>
            </div>
          </div>
          <div class="vstack gap-3 mt-4 ms-10">
            <div class="d-flex align-items-center">
              <h6 class="text-sm font-semibold">Can view</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch2"
                  id="switchView2"
                />
              </div>
            </div>
            <hr class="my-0" />
            <div class="d-flex align-items-center">
              <h6 class="font-semibold">Can edit</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch2"
                  id="switchEdit2"
                  checked="checked"
                />
              </div>
            </div>
            <hr class="my-0" />
            <div class="d-flex align-items-center">
              <h6 class="font-semibold">Can publish</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch2"
                  id="switchPublish2"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="list-group-item list-group-item-setting p-6 bg-surface-secondary border-0 rounded-2"
        >
          <div class="d-flex">
            <div class="w-10 me-2 me-sm-0">
              <i class="bi bi-folder-plus text-lg text-muted"></i>
            </div>
            <div class="flex-1 me-10">
              <a  class="d-block h5">Payments</a>
              <p class="mt-1 text-sm text-muted">
                Select the permissions you want to add to all the projects in
                this account.
              </p>
            </div>
          </div>
          <div class="vstack gap-3 mt-4 ms-10">
            <div class="d-flex align-items-center">
              <h6 class="text-sm font-semibold">Can view</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch3"
                  id="switchView3"
                  checked="checked"
                />
              </div>
            </div>
            <hr class="my-0" />
            <div class="d-flex align-items-center">
              <h6 class="font-semibold">Can edit</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch3"
                  id="switchEdit3"
                  checked="checked"
                />
              </div>
            </div>
            <hr class="my-0" />
            <div class="d-flex align-items-center">
              <h6 class="font-semibold">Can publish</h6>
              <div class="form-check form-switch ms-auto">
                <input
                  class="form-check-input me-n2"
                  type="checkbox"
                  name="switch3"
                  id="switchPublish3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer py-2 bg-surface-secondary">
    <button
      type="button"
      class="btn btn-sm btn-neutral"
      data-bs-dismiss="offcanvas"
    >
      Close
    </button>
    <button type="button" class="btn btn-sm btn-primary">Save</button>
  </div>
</div>
