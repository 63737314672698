import { Observable } from 'rxjs';
import {
  HostListener,
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { CmnAddEditSidenavService } from '../core/services/cmn-add-edit-sidenav.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Output() updatePortFolioList = new EventEmitter<void>();
  @ViewChild('addEditSideNav') addEditSideNav: MatSidenav;

  title = 'NexDevDesign';
  addEditSideNav$ = this.sidenavService.getAddEditSideNav();

  sideNaveMode: any = 'side';
  screenWidth: any;
  getResizeWidth: any;
  opened: boolean = true;
  isHeaderSlide: boolean = false;

  @ViewChild('sidenav')
  test!: MatSidenav;
  openingComponentSubject;

  constructor(private sidenavService: CmnAddEditSidenavService) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.getResizeWidth = window.innerWidth;
    if (this.screenWidth < 991) {
      this.sideNaveMode = 'over';
      this.opened = false;
      this.isHeaderSlide = true;
    } else {
      this.sideNaveMode = 'side';
      this.isHeaderSlide = false;
    }
    this.sidenavService.openingComponentSubject.subscribe((res) => {
      this.openingComponentSubject = res;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.getResizeWidth = event.target.innerWidth;
    if (event.target.innerWidth < 991) {
      this.sideNaveMode = 'over';
      this.opened = false;
      this.isHeaderSlide = true;
    } else {
      this.sideNaveMode = 'side';
      this.opened = true;
      this.isHeaderSlide = false;
    }
  }

  openSidebar() {
    this.test.toggle();
    if (this.getResizeWidth > 991) {
      this.isHeaderSlide = !this.isHeaderSlide;
    }
  }

  openAddEditSideNav() {
    this.sidenavService.openAddEditSideNav('HomeComponent');
  }

  // You can also close the add/edit sidenav if needed
  closeAddEditSideNav() {
    this.sidenavService.closeAddEditSideNav();
  }

  ngOnDestroy(): void {
    // this.sidenavService.openingComponentSubject.unsubscribe();
  }
}
