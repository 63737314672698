<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>Leave Applied</h2>
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <page-button
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  >Add</page-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="container-fluid mt-5">
      <div class="card">
        <main class="bg-surface-secondary">
          <div class="cmn-table-wrapper">
            <table
              mat-table
              matSort
              [dataSource]="dataSourceApplied"
              class="w-100"
            >
              <ng-container matColumnDef="image">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class="avatar avatar-sm bg-warning rounded-circle text-white"
                  >
                    <img
                      class="img-fluid cus-image"
                      [src]="element.imageUrls"
                    />
                  </span>
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="userId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  User
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ getUserNameById(element.userId) | dataValidation }}
                  </span>
                </td>
              </ng-container> -->

              <ng-container matColumnDef="userId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Approver
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getUserNameById(element.approverId) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getStatusFromEnum(element.status) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="startDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Start Date
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.startDate) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  End Date
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.endDate) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="desc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Description
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.desc | dataValidation }}
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="updatedBy">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Updated By
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getUserNameById(element.updatedBy) | dataValidation }}
                </td>
              </ng-container> -->

              <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <!-- <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="deleteLeave(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button> -->
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      color="warn"
                      (click)="deleteLeave(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngClass]="getStatusColorClass(row.status)"
              ></tr>
            </table>
            <!-- <mat-paginator
              [length]="paginatorAppliedData?.itemCount"
              [pageSize]="paginatorAppliedData?.perPage"
              [pageSizeOptions]="[1, 6, 10, 100]"
              aria-label="Select page"
              (page)="pageChange($event)"
              >,
            </mat-paginator> -->
          </div>
        </main>
      </div>
    </div>

    <!-- test -->
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>Requests</h2>
            </div>
            <!-- <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <page-button
                  [config]="{ rank: 'neutral' }"
                  [icon]="'bi bi-people-fill'"
                  [loading]="false"
                  (clicked)="openShareToWebDialog()"
                  >Share</page-button
                >
                <page-button
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  >Add</page-button
                >
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </header>

    <div class="container-fluid mt-5">
      <div class="card">
        <main class="bg-surface-secondary">
          <div class="cmn-table-wrapper">
            <table
              mat-table
              matSort
              [dataSource]="dataSourceRequest"
              class="w-100"
            >
              <ng-container matColumnDef="image">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class="avatar avatar-sm bg-warning rounded-circle text-white"
                  >
                    <img
                      class="img-fluid cus-image"
                      [src]="element.imageUrls"
                    />
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="userId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  User Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ getUserNameById(element.userId) | dataValidation }}
                  </span>
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="approverId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Approved By
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getUserNameById(element.approverId) | dataValidation }}
                </td>
              </ng-container> -->

              <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Status
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getStatusFromEnum(element.status) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="startDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Start Date
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.startDate) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  End Date
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.endDate) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="desc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Description
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.desc | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="updatedBy">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Updated By
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getUserNameById(element.updatedBy) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <!-- <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="deleteLeave(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button> -->
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="changeStatus(LeaveStatusEnum.accepted, element)"
                    >
                      <mat-icon>check</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      color="warn"
                      (click)="changeStatus(LeaveStatusEnum.rejected, element)"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <!-- <mat-paginator
              [length]="paginatorData?.itemCount"
              [pageSize]="paginatorData?.perPage"
              [pageSizeOptions]="[1, 6, 10, 100]"
              aria-label="Select page"
              (page)="pageChange($event)"
              >,
            </mat-paginator> -->
          </div>
        </main>
      </div>
    </div>
  </div>
</div>
