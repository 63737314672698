<nav
  class="navbar show navbar-vertical h-lg-screen navbar-expand-lg p-0 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg scrollbar nav-mega-wrapper"
>
  <div class="div w-100">
    <div class="logo-wrapper">
      <a class="navbar-brand d-block text-center nexotips-logo p-0">
        <img src="../../../assets/images/thumbnail/nexotips logo.png" alt="" />
      </a>
    </div>
    <div class="nav-bar-wrapper">

      <ul class="navbar-nav" *ngFor="let data of sidebar">
        <li class="nav-item">
          <a class="nav-link" href="{{'#' + data.groupName}}" routerLinkActive="active-tab" data-bs-toggle="collapse" *ngIf="data.links.length > 1">
            <span class="side-navlink-icon">
              <i class="bi bi-briefcase"></i>
            </span>
            <span>{{data.groupName}}</span>
          </a>
          <a
            class="nav-link"
            routerLink="{{data.links[0].url}}"
            routerLinkActive="active-tab"
            data-bs-toggle="collapse"
            *ngIf="(data.links.length == 1)"
          >

            <span class="side-navlink-icon">
              <i class="bi bi-briefcase"></i>
            </span>
            <span>{{data.groupName}}</span>
          </a>

          <div class="collapse" id={{data.groupName}} *ngIf="data.links.length > 1">
            <ul class="nav nav-sm flex-column" *ngFor="let d of data.links">
              <li class="nav-item">
                <a
                  routerLinkActive="active-tab"
                  routerLink="{{d.url}}"
                  class="nav-link sublink"
                >
                  {{d.api[0].charAt(0).toUpperCase() + d.api.slice(1)}}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <hr>
    </div>
  </div>
</nav>
