import { SalaryService } from './../../../../core/services/salary.service';
import { CmnApiCallService } from '../../../../core/services/cmn-api-call.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiStatus, leaves, RolesEnum } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { salaryApi, userApi } from 'src/app/core/model/api-url';
import { Salary } from 'src/app/core/model/salary';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/core/model/user';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserListService } from 'src/app/core/services/user-list.service';

@Component({
  selector: 'app-add-edit-salary',
  templateUrl: './add-edit-salary.component.html',
  styleUrls: ['./add-edit-salary.component.scss'],
})
export class AddEditSalaryComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  userList: User[] = [];
  salaryForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    userId: {
      required: 'User name is required',
      pattern: 'User ID must be a numeric value',
    },
    salary: {
      required: 'Salary is required',
      min: 'Salary must be a non-negative number',
    },
    accName: {
      required: 'Account Name is required',
      maxLength: 'Account Name cannot exceed 50 characters',
    },
    bankAcc: {
      required: 'Bank Account is required',
      pattern: 'Invalid Bank Account format (should be 9-18 digits)',
    },
    bankName: {
      required: 'Bank Name is required',
    },
    ifsc: {
      required: 'IFSC Code is required',
      pattern: 'Invalid IFSC Code format (e.g., ABCD1234567)',
    },
    upi: {
      required: 'UPI Id is required',
      pattern: 'Invalid UPI ID format (e.g., example@upi.com)',
    },
    incrementDate: {
      required: 'Increment Date is required',
    },
    incrementAmount: {
      required: 'Increment Amount required',
    },
  };

  isEdit: boolean = false;
  salaryId: number;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  leaveOptions = [
    { text: 'applied', value: leaves.applied },
    { text: 'accepted', value: leaves.accepted },
    { text: 'rejected', value: leaves.rejected },
  ];
  userId: number;
  haveToIncrementInSalary = false;
  onDestroy: any = new Subject();

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private salaryService: SalaryService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.salaryId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createSalaryForm();
    this.getUserList();
    if (this.isEdit) {
      this.patchVal();
    }

    this.salaryService.isIncrement
      .pipe(takeUntil(this.onDestroy))
      .subscribe((result) => {
        if (result) {
          this.haveToIncrementInSalary = result;
        } else {
          this.haveToIncrementInSalary = result;
        }
      });
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
    this.salaryService.haveToIncrementInSalary(false);
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  createSalaryForm() {
    this.salaryForm = this.fb.group({
      userId: ['', [Validators.required]],
      salary: [null, [Validators.required, Validators.min(0)]],
      accName: [null], // [Validators.required, Validators.maxLength(50)]
      bankAcc: [null], // [Validators.required, Validators.pattern(/^\d{9,18}$/)]
      bankName: [null],
      ifsc: [''], // [Validators.required, Validators.pattern(/^[A-Z]{4}[0-9]{7}$/)]
      upi: [''], // [Validators.required, Validators.pattern(/^[\w.-]+@[\w.-]+$/)]
      desc: [''],
      incrementDate: [],
      incrementAmount: [],
    });
  }

  getUserList() {
    let roleUserUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;
            // this.userList.push(this.localStorageService.getUser());
            const currentUser = this.localStorageService.getUser();
            if (
              currentUser.role === RolesEnum.Developer ||
              currentUser.role === RolesEnum.Creator ||
              currentUser.role === RolesEnum.Admin
            ) {
              this.userList.push(currentUser);
            }
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onSubmit() {
    this.salaryForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.salaryForm.controls,
      this.validationMessages
    );

    if (this.salaryForm.valid) {
      let salaryApiUrl =
        this.isEdit && !this.haveToIncrementInSalary
          ? salaryApi.update
          : salaryApi.create;
      const {
        userId,
        salary,
        accName,
        bankAcc,
        bankName,
        ifsc,
        upi,
        desc,
        incrementDate,
        incrementAmount,
      } = this.salaryForm.value;

      let model: Salary;
      if (this.haveToIncrementInSalary) {
        model = {
          userId: userId,
          salary: +salary,
          accName: accName,
          bankAcc: bankAcc.toString(),
          bankName: bankName,
          ifsc: ifsc,
          upi: upi,
          desc: desc,
          incrementDate: incrementDate,
          incrementAmount: incrementAmount,
        };
      } else {
        model = {
          userId: userId,
          salary: salary,
          accName: accName,
          bankAcc: bankAcc.toString(),
          bankName: bankName,
          ifsc: ifsc,
          upi: upi,
          desc: desc,
        };
      }
      // model.userId = +this.userId;
      if (this.isEdit && !this.haveToIncrementInSalary) {
        model['id'] = +this.salaryId;
      }

      this.cmnApiCallService.addUpdateData(salaryApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `Salary created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'Salary has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'SalaryListComponent'
            );
            this.salaryForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let salaryGetByIdUrl = salaryApi.byId;
    this.cmnApiCallService.getById(salaryGetByIdUrl, this.salaryId).subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.salaryForm.patchValue({
              userId: res.data.userId,
              salary: +res.data.salary,
              accName: res.data.accName,
              bankAcc: +res.data.bankAcc,
              bankName: res.data.bankName,
              ifsc: res.data.ifsc,
              upi: res.data.upi,
              desc: res.data.desc,
            });
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
