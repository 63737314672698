import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import { roleApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import { gender } from 'src/app/core/enums/roles.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss'],
})
export class AddEditUsersComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();

  roleList = [];
  gender = gender;
  genderList = ['Male', 'Female'];
  selectedImage: any; // Variable to hold the selected image preview
  isEdit: boolean = false;
  loadingImage: boolean = false;
  userId: number;
  userForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    username: {
      required: 'User Name is required',
      pattern: 'User ID must be a numeric value',
    },
    password: {
      required: 'Password is required',
      pattern: 'length 8,one lower and one upper and one digit',
    },
    name: {
      required: 'Name is required',
    },
    role: {
      required: 'Role is required',
    },
    email: {
      required: 'Email is required',
      pattern: 'Invalid email address',
    },
    mobileNo: {
      required: 'Mobile No. is required',
      pattern: 'Invalid Mobile Number',
    },
  };

  apiQuery: ApiQuery = new ApiQuery();

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private formValidationService: FormValidationService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.userId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.getRoleList();
    this.createUserForm();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  createUserForm() {
    const currentDate: Date = new Date();
    this.userForm = this.fb.group({
      imageUrl: [''],
      gender: [''],
      username: ['', Validators.required],
      name: ['', Validators.required],
      password: ['', [Validators.required]],
      // password: ['', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/)]],
      role: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/),
        ],
      ],
      mobileNo: ['', [Validators.required]],
      joinDate: [currentDate],
    });
  }

  getRoleList() {
    let roleListUrl = roleApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService
      .getList(roleListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.roleList = res.data.data;
          }
        }
      });
  }

  patchVal() {
    let usersGetByIdUrl = userApi.byId;
    this.cmnApiCallService.getById(usersGetByIdUrl, this.userId).subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userForm.patchValue({
              imageUrl: res.data.imageUrl,
              gender: +res.data.gender,
              username: res.data.username,
              name: res.data.name,
              role: res.data.role,
              email: res.data.email,
              mobileNo: res.data.mobileNo,
              joinDate: res.data.joinDate,
            });
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onFileSelected(event: Event) {
    this.loadingImage = true;
    const fileInput = event.target as HTMLInputElement;
    const selectedFile = fileInput.files?.[0];

    if (selectedFile) {
      this.cmnApiCallService.uploadImage(selectedFile).subscribe(
        (response: ApiResponse) => {
          if (response.status === ApiStatus.SUCCESS) {
            const path: string = response.data.uploadSuccess[0].path;
            this.userForm.controls['imageUrl'].patchValue(path);
            this.selectedImage = environment.mainUrl + path;
          }
          if (response.status === ApiStatus.FAILURE) {
            this.toastService.uploadFailure(
              response.data.uploadFailed[0].error
            );
          }
          this.loadingImage = false;
        },
        (error) => {
          console.error('Error uploading image', error);
          this.toastService.errorMsg(error);
          this.loadingImage = false;
        }
      );
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  onSubmit() {
    if (this.isEdit) {
      this.userForm.removeControl('password');
    }
    this.userForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.userForm.controls,
      this.validationMessages
    );

    if (this.userForm.valid) {
      let usersApiUrl = this.isEdit ? userApi.update : userApi.create;
      let model = this.userForm.value;
      // model.userId = +this.userId;
      if (this.isEdit) {
        model['id'] = +this.userId;
      }

      this.cmnApiCallService.addUpdateData(usersApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `User created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'User has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'UsersListComponent'
            );
            this.userForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
