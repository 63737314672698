<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>👋 Salary Dispatched List</h2>
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <!-- <a class="btn btn-sm btn-neutral border-base" (click)="openShareToWebDialog()">
                                        <span class="pe-2">
                                            <i class="bi bi-people-fill"></i>
                                        </span>
                                        <span> Share</span>
                                    </a> -->
                <!-- <page-button
                  [config]="{ rank: 'neutral' }"
                  [icon]="'bi bi-people-fill'"
                  [loading]="false"
                  (clicked)="openShareToWebDialog()"
                  >Share</page-button
                > -->
                <page-button
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  >Add</page-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container-fluid mt-5">
      <div class="card">
        <main class="bg-surface-secondary">
          <!-- <app-share-to-web-popup></app-share-to-web-popup> -->
          <div class="cmn-table-wrapper table-container">
            <table mat-table matSort [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="userId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  User Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ getUserNameById(element.userId) | dataValidation }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="salaryId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Salary Id
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.salaryId | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="amount">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Amount
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.amount | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="leaves">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Leaves
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.leaves | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalAmount">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  total Amount
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalAmount | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="empty">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <!-- <a
                        (click)="openAddEditSideNav(element.id)"
                        class="btn btn-sm btn-neutral"
                        >Edit</a
                      > -->
                  <div class="d-flex">
                    <!-- <button
                        type="button"
                        class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                        (click)="openAddEditSideNav(element.id)"
                      >
                        <mat-icon>edit</mat-icon>
                      </button> -->
                    <!-- <button
                        type="button"
                        class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                        (click)="deleteSalary(element.id)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button> -->
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      color="warn"
                      (click)="deleteDispatchedSalary(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            [length]="paginatorData?.itemCount"
            [pageSize]="paginatorData?.perPage"
            [pageSizeOptions]="[10, 20, 50]"
            aria-label="Select page"
            (page)="pageChange($event)"
            class="mat-paginator-sticky"
            >,
          </mat-paginator>
        </main>
      </div>
    </div>
  </div>
</div>
