import { AddEditTaskComponent } from './add-edit-task/add-edit-task.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TaskBoardviewComponent } from './task-boardview/task-boardview.component';
import { TaskListviewDetailsComponent } from './task-listview-details/task-listview-details.component';
import { TaskListviewComponent } from './task-listview/task-listview.component';
import { TaskOverviewComponent } from './task-overview/task-overview.component';
import { RoleGuard } from 'src/app/core/guard/role.guars';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'task',
    pathMatch: 'full',
  },
  {
    path: 'task',
    component: TaskOverviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'task-overview',
    component: TaskOverviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'task-listview',
    component: TaskListviewComponent,
  },
  {
    path: 'task-listview-details',
    component: TaskListviewDetailsComponent,
  },
  {
    path: 'task-boardview',
    component: TaskBoardviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'create-task',
    component: AddEditTaskComponent,
    canActivate: [RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TasksRoutingModule {}
