<div
  class="flex-lg-1 h-screen-header overflow-y-lg-auto task-overview-wrapper bg-surface-secondary"
>
  <header>
    <div class="container-fluid">
      <div class="pt-6 pb-3 border-bottom cmn-header-box">
        <div class="row align-item-center">
          <div class="col-sm col-12">
            <h2>👋 Task Listview</h2>
          </div>
          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <page-button
                [config]="{ rank: 'primary' }"
                [icon]="'bi bi-plus-square-dotted'"
                [loading]="false"
                (clicked)="openAddEditSideNav()"
                >Add</page-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <main class="pb-6">
    <div class="container-fluid mt-5">
      <form [formGroup]="filterTaskForm" class="row">
        <div class="cmn-form-field col-12 col-sm-5">
          <mat-form-field appearance="outline">
            <mat-select
              placeholder="Select your project"
              formControlName="projectId"
            >
              <mat-option
                *ngFor="let project of projectList"
                [value]="project.id"
                >{{ project.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12 col-sm-5">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select
              placeholder="Current task status"
              formControlName="statusId"
            >
              <mat-option value="all"> All </mat-option>
              <mat-option *ngFor="let sts of statusList" [value]="sts[0]">
                <span>{{ sts[1] }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="container-fluid mt-5">
      <div class="card">
        <main class="bg-surface-secondary">
          <!-- <app-share-to-web-popup></app-share-to-web-popup> -->
          <div class="cmn-table-wrapper">
            <table mat-table matSort [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="projectId">
                <th mat-header-cell *matHeaderCellDef>Project Name</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.projectId }}
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.statusId }}
                </td>
              </ng-container>
              <ng-container matColumnDef="taskType">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element">
                  <span class="{{ taskTypeColorClassEnum[+element.type] }}"
                    >{{ taskTypeEnum[+element.type] | uppercase }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="task-name-summary">
                <th mat-header-cell *matHeaderCellDef>Task</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="priority">
                <th mat-header-cell *matHeaderCellDef>Priority</th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class="{{ taskPriorityColorClassEnum[+element.priority] }}"
                    >{{ taskPriorityEnum[+element.priority] | uppercase }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="assignee">
                <th mat-header-cell *matHeaderCellDef>Assignee</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.assignTo }}
                </td>
              </ng-container>

              <ng-container matColumnDef="project-manager">
                <th mat-header-cell *matHeaderCellDef>PM</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.projectManagerId }}
                </td>
              </ng-container>
              <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef>CCD</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dueDate | date : "dd/MMM/yy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef>Created</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.createdAt | date : "dd/MMM/yy" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="creator">
                <th mat-header-cell *matHeaderCellDef>Creator</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.addedBy }}
                </td>
              </ng-container>
              <ng-container matColumnDef="empty">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Actions
                </th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button class="cus-round-btn" color="warn">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            [length]="paginatorData?.itemCount"
            [pageSize]="paginatorData?.perPage"
            [pageSizeOptions]="[1, 6, 10, 50, 100]"
            aria-label="Select page"
            (page)="pageChange($event)"
            >,
          </mat-paginator>
        </main>
      </div>
    </div>
  </main>
</div>
