import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { projectApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-project-table-view',
  templateUrl: './project-table-view.component.html',
  styleUrls: ['./project-table-view.component.scss'],
})
export class ProjectTableViewComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'date',
    'status',
    'team',
    'completion',
    'empty',
  ];
  ELEMENT_DATA: any[] = [
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Hydrogen',
      date: '25-02-2027',
      status: 'In progress',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Helium',
      date: '25-02-2027',
      status: 'Done',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Lithium',
      date: '25-02-2027',
      status: 'Project at risk',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Beryllium',
      date: '25-02-2027',
      status: 'Not started',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Boron',
      date: '25-02-2027',
      status: 'In progress',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Carbon',
      date: '25-02-2027',
      status: 'In progress',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Nitrogen',
      date: '25-02-2027',
      status: 'In progress',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Oxygen',
      date: '25-02-2027',
      status: 'In progress',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Fluorine',
      date: '25-02-2027',
      status: 'In progress',
      team: 'John,Anup',
      completion: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Neon',
      date: '25-02-2027',
      status: 'In progress',
      team: 'John,Anup',
      completion: '25',
    },
  ];
  projectList = [];
  dataSource = new MatTableDataSource(this.projectList);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  constructor(
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private toastr: ToastService
  ) {}

  ngOnInit(): void {
    // this.getProjectList();
    // this.cmnAddEditSidenavService.updateProjectList.subscribe((res) => {
    //   if (res) {
    //     this.getProjectList();
    //   }
    // });
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav('ProjectComponent', id);
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('ProjectComponent');
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getProjectList() {
    let projectListUrl = projectApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService
      .getList(projectListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.projectList = res.data.data.map((item) => ({
            name: item.title,
            date: item.date,
            status: item.status,
            team: item.teamsize,
            completion: item.duration,
            empty: '', // You can leave this empty if it's not needed
          }));
          this.dataSource = new MatTableDataSource(this.projectList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }

  deleteProject(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteportfolioUrl = projectApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteportfolioUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastr.successMsg(
                  'Deleted',
                  `Project has been successfully deleted`
                );
                this.getProjectList();
              }
            },
            (err) => {
              this.toastr.errorMsg(err);
            }
          );
      }
    });
  }
}
