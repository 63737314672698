<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <div *ngIf="userDetails">
                <h2>👋 Hello {{ userDetails.name }}!</h2>
              </div>
            </div>
            <!-- <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                Last punch out at
                {{ lastPunchInOutHistory }}
              
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </header>
    <div class="container-fluid mt-5">
      <div class="row g-5 justify-content-center">
        <div class="col-lg-2 col-md-4">
          <div class="punch-in-out-card">
            <span>{{ lastPunchInOutHistory }}</span>

            <page-button
              *ngIf="breakInOutText"
              class="punch-in-out-button"
              [config]="{
                rank: breakInOutText == 'Break In' ? 'primary' : 'orange'
              }"
              (clicked)="updateAttendance(breakInOutText)"
              [loading]="false"
            >
              {{ breakInOutText }}
            </page-button>
            <page-button
              *ngIf="punchInOutText && breakInOutText !== 'Break Out'"
              class="punch-in-out-button"
              [config]="{
                rank: punchInOutText == 'Punch In' ? 'primary' : 'orange'
              }"
              (clicked)="
                punchInOutText == 'Punch In'
                  ? punchIn()
                  : updateAttendance(punchInOutText)
              "
              [loading]="false"
            >
              {{ punchInOutText }}
            </page-button>
          </div>
        </div>
        <div class="col-lg-6 col-md-8">
          <!-- <page-button
            class="punch-in-out-button mb-3"
            [config]="{
              rank: 'primary'
            }"
            (clicked)="punchIn()"
            [loading]="false"
          >
            PunchIn
          </page-button> -->
          <ckeditor
            [editor]="editor"
            [data]="editorData"
            [config]="editorConfig"
            (ready)="onReady($event)"
          ></ckeditor>
        </div>
      </div>
    </div>
  </div>
</div>
