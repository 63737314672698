import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { portfolioApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

//newly Created
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'image',
    'title',
    'technology',
    'libraryFrameworks',
    'role',
    'duration',
    'country',
    'teamsize',
    'links',
    // 'description',
    'empty',
  ];
  portfolioList = [];
  dataSource = new MatTableDataSource(this.portfolioList);
  updatePortfolioList: boolean = false;
  private updatePortfolioListSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();

  //newly Created
  portfolio:object = {};

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastr: ToastService,
    private localStorageService: LocalStorageService //newly created
  ) { }

  async ngOnInit() {
    await this.getPortFolioList();
    this.subscribeToUpdateProjectList();

    //newly Created

    let data: any = this.localStorageService.getUser();
    // this.userData = data.roleAccess;
    this.portfolio = data.roleAccess[0];
    console.log(this.portfolio);
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'PortfolioComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('PortfolioComponent');
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getPortFolioList();
  }

  async getPortFolioList() {
    let portfolioListUrl = portfolioApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService.getList(portfolioListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.portfolioList = res.data.data.map((item) => ({
            title: item.title,
            description: item.desc,
            technology: item.tech,
            libraryFrameworks: item.libsFramework,
            role: item.devRole,
            duration: item.duration,
            country: item.originCountry,
            teamsize: item.teamSize,
            links: item.links,
            imageUrls: environment.mainUrl + item.imageUrls,
            id: item.id,
          }));
          this.dataSource = new MatTableDataSource(this.portfolioList);
          this.paginatorData = res.data.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      (err) => {
        this.toastr.errorMsg(err);
      }
    );
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  deletePortfolio(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteportfolioUrl = portfolioApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteportfolioUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastr.successMsg(
                  'Deleted',
                  `Portfolio has been successfully deleted`
                );
                this.getPortFolioList();
              }
            },
            (err) => {
              this.toastr.errorMsg(err);
            }
          );
      }
    });
    // ngAfterViewInit() {
    //   this.cmnAddEditSidenavService.updatePortfolioList.subscribe((res) => {
    //     debugger;
    //   });
    // }
  }

  private subscribeToUpdateProjectList(): void {
    this.updatePortfolioListSubscription =
      this.cmnAddEditSidenavService.updatePortfolioList.subscribe(
        async (res) => {
          if (res) {
            await this.getPortFolioList();
          }
        }
      );
  }

  ngOnDestroy(): void {
    if (this.updatePortfolioListSubscription) {
      this.updatePortfolioListSubscription.unsubscribe();
    }
  }
}
