import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { ShareToWebPopupComponent } from 'src/app/pages/share-to-web-popup/share-to-web-popup.component';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent {

  constructor (
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
  ) 
    {}

  async ngOnInit() {

  }


  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'PostListComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('PostListComponent');
    }
  }

  
  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }



}
