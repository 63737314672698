<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>👋 Attendance</h2>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="container-fluid mt-5">
      <div class="card">
        <main class="bg-surface-secondary">
          <div class="cmn-table-wrapper table-container">
            <table mat-table matSort [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="image">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class="avatar avatar-sm bg-warning rounded-circle text-white"
                  >
                    <img
                      class="img-fluid cus-image"
                      [src]="element.imageUrls"
                    />
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="userId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  User Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ getUserNameById(element.userId) | dataValidation }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="createdAt">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  created At
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.createdAt) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="start">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Punch-In
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.start) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="end">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Puch-Out
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.end) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="updatedAt">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Updated At
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ formatCustomDate(element.updatedAt) | dataValidation }}
                </td>
              </ng-container>

              <ng-container matColumnDef="links">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Links</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex flex-column">
                    <a [href]="element.links" target="_blank">{{
                      element.links | dataValidation
                    }}</a>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <!-- <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="deleteAttendance(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button> -->

                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      color="warn"
                      (click)="deleteAttendance(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            [length]="paginatorData?.itemCount"
            [pageSize]="paginatorData?.perPage"
            [pageSizeOptions]="[10, 20, 50]"
            aria-label="Select page"
            (page)="pageChange($event)"
            class="mat-paginator-sticky"
            >,
          </mat-paginator>
        </main>
      </div>
    </div>
  </div>
</div>
