<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} Client Information
    </h5>

    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="px-0 container-fluid">
      <form [formGroup]="clientInfoForm" class="row">
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Client Name</mat-label>
            <mat-select placeholder="Select your user" formControlName="userId">
              <mat-option
                *ngFor="let user of userList | roleFilter : [3]"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="clientInfoForm.controls['userId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["userId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Number</mat-label>
            <input type="number" matInput formControlName="number" />
            <mat-error
              *ngIf="clientInfoForm.controls['number'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["number"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Line 1</mat-label>
            <input matInput formControlName="line1" />
            <mat-error
              *ngIf="clientInfoForm.controls['line1'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["line1"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Line 2</mat-label>
            <input matInput formControlName="line2" />
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Area</mat-label>
            <input matInput formControlName="area" />
            <mat-error
              *ngIf="clientInfoForm.controls['area'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["area"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" />
            <mat-error
              *ngIf="clientInfoForm.controls['city'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["city"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>State</mat-label>
            <input matInput formControlName="state" />
            <mat-error
              *ngIf="clientInfoForm.controls['state'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["state"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Country</mat-label>
            <input matInput formControlName="country" />
            <mat-error
              *ngIf="clientInfoForm.controls['country'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["country"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Pincode</mat-label>
            <input type="number" matInput formControlName="pincode" />
            <mat-error
              *ngIf="clientInfoForm.controls['pincode'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["pincode"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
