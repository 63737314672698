import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { ClientInfoListComponent } from './client-info-list/client-info-list.component';
import { AddEditClientInfoComponent } from './add-edit-client-info/add-edit-client-info.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClientQueryListComponent } from './client-query-list/client-query-list.component';

@NgModule({
  declarations: [
    ClientInfoListComponent,
    AddEditClientInfoComponent,
    ClientQueryListComponent,
  ],
  imports: [CommonModule, ClientRoutingModule, SharedModule],
  exports: [AddEditClientInfoComponent],
})
export class ClientModule {}
