import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';

import { MatSidenavModule } from '@angular/material/sidenav';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { HomeComponent } from './home/home.component';
import { ShareToWebPopupComponent } from './pages/share-to-web-popup/share-to-web-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './core/interceptor/Interceptors';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SpinnerOverlayComponent } from './shared/spinner/spinner/spinner-overlay.component';
import { PortfolioModule } from './pages/portfolio/portfolio.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAccessModule } from './pages/user-access/user-access.module';
import { ProjectsModule } from './pages/projects/projects.module';
import { UserModule } from './pages/user/user.module';
import { AttendanceModule } from './pages/attendance/attendance.module';
import { SalaryModule } from './pages/salary/salary.module';
import { OrganizationModule } from './pages/organization/organization.module';
import { TeamsModule } from './pages/teams/teams.module';
import { CreatorModule } from './pages/creator/creator.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TasksModule } from './pages/tasks/tasks.module';
import { ClientModule } from './pages/client/client.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    HomeComponent,
    ShareToWebPopupComponent,
    SpinnerOverlayComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressBarModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    PortfolioModule,
    UserAccessModule,
    ProjectsModule,
    UserModule,
    AttendanceModule,
    SalaryModule,
    OrganizationModule,
    TeamsModule,
    CreatorModule,
    TasksModule,
    ClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
