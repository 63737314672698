import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { forgorPassword, register } from '../model/api-url';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  requestedUrl = '/authentication/login';

  public authTokenStale: string = 'stale_auth_token';
  public authTokenNew: string = 'new_auth_token';
  public currentToken: string;

  private newToken = ' ';
  private current_token: any;
  private refresh_token: string = localStorage.getItem('environment.dataKey');

  constructor(private http: HttpClient, private route: Router) {
    this.currentToken = this.authTokenStale;
  }

  getAuthToken() {
    let data: any = localStorage.getItem(environment.dataKey);
    if (data) {
      data = JSON.parse(atob(data));
      this.currentToken = data;
    }
    return this.currentToken;
  }

  login(model: any) {
    return this.http.post(`${environment.baseUrl}auth/login`, model);
  }

  // createUser(model: any) {
  //   return this.http.post(`${environment.baseUrl}/auth/api/v1/user/authenticate`, model);
  // }
  createUser(model) {
    return this.http.post(`${environment.baseUrl}` + register.create, model);
  }

  forgotPassword(model) {
    return this.http.post(
      `${environment.baseUrl}` + forgorPassword.create,
      model
    );
  }

  refreshToken() {
    return this.http.get(`${environment.baseUrl}auth/api/v1/user/refreshtoken`);
  }

  logout(model = {}) {
    return this.http.post(`${environment.baseUrl}auth/logout`, model);
  }

  getDecodedAccessToken(data: string): any {
    try {
      return data;
    } catch (Error) {
      return null;
    }
  }
}
