<div class="h-100 overflow-auto cmn-add-edit-sidenav">
    <div class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary">
        <h5 class="add-edit-sidenav-title">
            {{ isEdit ? "Update" : "Create a new" }} user detail
        </h5>

        <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="add-edit-sidenav-body px-4 pt-4">
        <div class="px-0 container-fluid">

            <form [formGroup]="userForm" class="row">

                <div class="cmn-form-field col-12">
                    <div class="d-flex align-items-center">
                        <div class="file-selector rounded rounded-circle"
                            [ngClass]="{ 'border-secondary': selectedImage }">
                            <div class="w-100" (click)="fileInput.click()">
                                <div class="m-auto d-flex h-100 d-flex justify-content-center align-items-center"
                                    *ngIf="!selectedImage">
                                    <div class="text-center">
                                        <div class="text-2xl text-muted">
                                            <mat-icon>insert_photo</mat-icon>
                                        </div>
                                        <mat-label><b>Profile</b></mat-label>
                                    </div>
                                </div>
                                <div class="preview-container w-full h-full rounded-[12px] border border-1"
                                    *ngIf="selectedImage">
                                    <img [src]="selectedImage" alt="Selected Image" class="preview-image" />
                                    <mat-icon style="color: black; z-index: 3"
                                        class="position-absolute w-[26px] h-[26px]">add_circle_outline</mat-icon>
                                </div>
                            </div>
                            <input type="file" #fileInput hidden (change)="onFileSelected($event)" accept="image/*" />
                        </div>
                    </div>
                    <hr />
                </div>

                <div class="cmn-form-field col-lg-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>User Name</mat-label>
                        <input matInput formControlName="username" />
                        <mat-error *ngIf="userForm.controls['username'].invalid" class="error-msg text-danger">
                            {{formErrors["username"]}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-lg-6 col-12" *ngIf="!isEdit">
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password">
                        <mat-error *ngIf="userForm.controls['password'].invalid" class="error-msg text-danger">
                            {{formErrors['password']}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Gender</mat-label>
                        <mat-select placeholder="Select your gender" formControlName="gender">
                            <mat-option *ngFor="let gen of genderList" [value]="gender[gen]">{{
                                gen
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" />
                        <mat-error *ngIf="userForm.controls['name'].invalid" class="error-msg text-danger">
                            {{formErrors['name']}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Role</mat-label>
                        <mat-select placeholder="Select your role" formControlName="role">
                            <mat-option *ngFor="let role of roleList" [value]="(role.id).toString()">{{
                                role.name
                            }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="userForm.controls['role'].invalid" class="error-msg text-danger">
                            {{formErrors['role']}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-lg-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email"/>
                        <mat-error *ngIf="userForm.controls['email'].invalid" class="error-msg text-danger">
                            {{formErrors['email']}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-lg-6 col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Mobile No.</mat-label>
                        <input matInput formControlName="mobileNo"/>
                        <mat-error *ngIf="userForm.controls['mobileNo'].invalid" class="error-msg text-danger">
                            {{formErrors['mobileNo']}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- <div class="cmn-form-field col-12">
            <mat-form-field appearance="outline">
              <mat-label>Project Name</mat-label>
              <mat-select placeholder="Select your project" formControlName="projectId">
                <mat-option *ngFor="let project of projectList" [value]="project.id">{{
                  project.name
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="teamForm.controls['projectId'].invalid"
                class="error-msg text-danger"
              >
                {{ formErrors["projectId"] }}
              </mat-error>
            </mat-form-field>
          </div> -->

                <!-- <div class="col-12 cmn-form-field">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea formControlName="desc" rows="3" matInput></textarea>
            </mat-form-field>
          </div> -->
            </form>
        </div>
    </div>
    <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
        <page-button data-bs-dismiss="add-edit-sidenav" [config]="{ rank: 'neutral' }" [loading]="false"
            (clicked)="closeAddEditSideNav()">Close</page-button>
        <page-button
        [config]="{ rank: 'primary' }"
        [loading]="false"
        (clicked)="onSubmit()"
        >{{ isEdit ? "Update" : "Add" }}
      </page-button>
    </div>
</div>