import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ApiStatus, leaves } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { clientInfoApi, userApi } from 'src/app/core/model/api-url';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { RolesEnum } from 'src/app/core/enums/roles.enum';

@Component({
  selector: 'app-add-edit-client-info',
  templateUrl: './add-edit-client-info.component.html',
  styleUrls: ['./add-edit-client-info.component.scss'],
})
export class AddEditClientInfoComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  userList: User[] = [];
  clientInfoForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    userId: {
      required: 'CLient Name is required',
      pattern: 'CLient Name must be a numeric value',
    },
    number: {
      required: 'Number is required',
    },
    line1: {
      required: 'Line 1 is required',
    },
    area: {
      required: 'Area is required',
    },
    city: {
      required: 'City is required',
    },
    state: {
      required: 'State is required',
    },
    country: {
      required: 'Country is required',
    },
    pincode: {
      required: 'Pincode is required',
      pattern: 'Pincode must be a 6-digit number',
    },
  };

  isEdit: boolean = false;
  clientInfoId: number;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userId: number;

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.clientInfoId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createClientInfoForm();
    this.getUserList();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createClientInfoForm() {
    this.clientInfoForm = this.fb.group({
      userId: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      number: [null, [Validators.required]],
      line1: [null, [Validators.required]],
      line2: [null],
      area: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]], // Add the pattern validator for 6 digits
    });
  }
  getUserList() {
    let roleUserUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;
            const currentUser = this.localStorageService.getUser();
            if (currentUser.role === RolesEnum.Client) {
              this.userList.push(currentUser);
            }
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onSubmit() {
    this.clientInfoForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.clientInfoForm.controls,
      this.validationMessages
    );

    if (this.clientInfoForm.valid) {
      let clientInfoApiUrl = this.isEdit
        ? clientInfoApi.update
        : clientInfoApi.create;
      let model = this.clientInfoForm.value;
      // model.userId = +this.userId;
      if (this.isEdit) {
        model['id'] = +this.clientInfoId;
      }

      this.cmnApiCallService.addUpdateData(clientInfoApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `ClientInfo created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'ClientInfo has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'ClientInfoListComponent'
            );
            this.clientInfoForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let clientInfoGetByIdUrl = clientInfoApi.byId;
    this.cmnApiCallService
      .getById(clientInfoGetByIdUrl, this.clientInfoId)
      .subscribe((res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.clientInfoForm.patchValue({
              userId: res.data.userId,
              number: +res.data.number,
              line1: res.data.line1,
              line2: res.data.line2,
              area: res.data.area,
              city: res.data.city,
              state: res.data.state,
              country: res.data.country,
              pincode: res.data.pincode,
            });
          }
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
