import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { RoleListComponent } from './role/role-list/role-list.component';
import { UserDocumentListComponent } from './user-documents/user-document-list/user-document-list.component';
import { UserAccessComponent } from './user-access/user-access.component';
import { RoleGuard } from 'src/app/core/guard/role.guars';

const routes: Routes = [
  {
    path: 'users-access',
    component: UserAccessComponent,
  },
  {
    path: 'users-profile',
    component: UserProfileComponent,
  },
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'users-permissions',
    component: UserPermissionsComponent,
  },
  {
    path: 'users-role',
    component: RoleListComponent,
  },
  {
    path: 'users-documents',
    component: UserDocumentListComponent,
    canActivate: [RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
