import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import { Component, ChangeDetectorRef } from '@angular/core';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { attendanceApi } from 'src/app/core/model/api-url';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastService } from 'src/app/core/services/toast.service';
import * as moment from 'moment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-in-out',
  templateUrl: './in-out.component.html',
  styleUrls: ['./in-out.component.scss'],
})
export class InOutComponent {
  lastPunchInOutHistory: string = '';
  attendanceHistory: any;
  punchInOutText: string = '';
  breakInOutText: string = '';
  userDetails: any;
  attendanceApiList = attendanceApi;
  apiQuery: ApiQuery = new ApiQuery();
  public editor = ClassicEditor;
  public editorData = '';
  private editorInstance: any;
  editorConfig = {
    placeholder: 'Add description...',
  };

  constructor(
    private cmnApiCallService: CmnApiCallService,
    private localStorageService: LocalStorageService,
    public toast: ToastService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.userDetails = this.localStorageService.getUser();
    if (this.userDetails.id) {
      this.getAttendanceDetails(this.userDetails.id);
    }
  }

  getCurrentDateWithZeroMilliseconds(): string {
    const todayWithMidnightTime = moment().startOf('day');
    return todayWithMidnightTime.toISOString();
  }

  getAttendanceDetails(id: number) {
    const currentDate = this.getCurrentDateWithZeroMilliseconds();
    this.apiQuery.query = {
      userId: id,
      start: {
        $gte: currentDate,
      },
    };
    this.cmnApiCallService
      .getList(this.attendanceApiList.list, this.apiQuery)
      .subscribe({
        next: (res: ApiResponse) => {
          if (res.status == ApiStatus.SUCCESS) {
            const response = res.data.data[0];
            if (res.data.data.length > 0) {
              this.attendanceHistory = { ...response };
              const { start, end } = response;
              if (start && end) {
                this.punchInOutText == '';
                this.punchInOutText = '';
                const localTime = moment.utc(end).local();
                this.lastPunchInOutHistory =
                  'Last punch out at ' + localTime.format('YYYY-MM-DD hh:mm A');
              } else if (start && !end) {
                const localTime = moment.utc(start).local();
                this.lastPunchInOutHistory =
                  localTime.format('YYYY-MM-DD hh:mm A');
                this.breakInOutText = 'Break In';
                this.punchInOutText = 'Punch Out';
                if (response.break) {
                  let breakHistory = response.break;
                  breakHistory = JSON.parse(breakHistory);
                  this.changeBreakInOutText(breakHistory);
                } else {
                  this.breakInOutText = 'Break In';
                }
              }
            } else {
              this.punchInOutText = 'Punch In';
            }
          }
        },
        error: (error) => {
          this.toast.errorMsg(error);
        },
      });
  }

  punchIn() {
    const payload = {
      userId: this.userDetails.id,
      start: this.generateUTCDate(),
    };
    this.cmnApiCallService
      .addUpdateData(this.attendanceApiList.create, payload)
      .subscribe({
        next: (res: ApiResponse) => {
          if (res.status == ApiStatus.SUCCESS) {
            this.attendanceHistory = res.data;
            const localTime = moment.utc(res.data.start).local();
            this.lastPunchInOutHistory = localTime.format('YYYY-MM-DD hh:mm A');
            this.punchInOutText = 'Punch Out';
            this.breakInOutText = 'Break In';
          }
        },
        error: (error) => {
          this.punchInOutText = '';
          this.toast.errorMsg(error);
        },
      });
  }

  updateAttendance(type: string) {
    let payload: any = {
      userId: this.userDetails.id,
      id: this.attendanceHistory.id,
    };
    if (type == 'Punch Out') {
      payload = {
        ...payload,
        end: this.generateUTCDate(),
      };
    } else if (type == 'Break In' || type == 'Break Out') {
      let breakHistory = this.attendanceHistory.break;
      if (breakHistory && type == 'Break Out') {
        breakHistory = JSON.parse(breakHistory);
        breakHistory.forEach((element) => {
          if (element.break_in && !element.break_out) {
            element['break_out'] = this.generateUTCDate();
          }
        });
        breakHistory = JSON.stringify(breakHistory);
        payload = {
          ...payload,
          break: breakHistory,
        };
        this.breakInOutText = '';
        this.punchInOutText = 'Punch Out';
      } else {
        if (breakHistory) {
          breakHistory = JSON.parse(breakHistory);
          let updateValue = [...breakHistory];
          updateValue.push({
            break_in: this.generateUTCDate(),
          });
          const updateStringyValue = JSON.stringify(updateValue);
          payload = {
            ...payload,
            break: updateStringyValue,
          };
        } else {
          let breakTimeObject = [
            {
              break_in: this.generateUTCDate(),
            },
          ];
          const breakTimeStringifyValue = JSON.stringify(breakTimeObject);
          payload = {
            ...payload,
            break: breakTimeStringifyValue,
          };

          this.breakInOutText = 'Break Out';
        }
      }
    }

    this.cmnApiCallService
      .addUpdateData(this.attendanceApiList.update, payload)
      .subscribe({
        next: (res: ApiResponse) => {
          if (res.status == ApiStatus.SUCCESS) {
            this.attendanceHistory = { ...res.data[0] };
            // this.getAttendanceDetails(this.userDetails.id);

            if (this.attendanceHistory.end) {
              const localTime = moment.utc(this.attendanceHistory.end).local();
              this.lastPunchInOutHistory =
                localTime.format('YYYY-MM-DD hh:mm A');
              this.punchInOutText = '';
              this.breakInOutText = '';
            } else {
              let breakHistory = this.attendanceHistory.break;
              if (breakHistory) {
                breakHistory = JSON.parse(breakHistory);
                this.changeBreakInOutText(breakHistory);
                breakHistory = JSON.stringify(breakHistory);
              }
              this.punchInOutText = 'Punch Out';
            }
            if (this.attendanceHistory.start && this.attendanceHistory.end) {
              this.punchInOutText == '';
              const localTime = moment.utc(this.attendanceHistory.end).local();
              this.lastPunchInOutHistory =
                'Last punch out at ' + localTime.format('YYYY-MM-DD hh:mm A');
            }
          }
        },
        error: (error) => {
          this.punchInOutText = '';
          this.toast.errorMsg(error);
        },
      });
  }

  generateUTCDate() {
    const currentDate = new Date();
    const date = this.commonService.changeDateFormatToUTC(currentDate);
    return date;
  }

  changeBreakInOutText(breakHistory) {
    if (
      breakHistory.every((element) => element.break_in && element.break_out)
    ) {
      this.breakInOutText = 'Break In';
    } else if (
      breakHistory.some((element) => element.break_in && !element.break_out)
    ) {
      this.breakInOutText = 'Break Out';
    } else {
      this.breakInOutText = '';
    }
  }

  onReady(editor: any) {
    this.editorInstance = editor;
  }
}
