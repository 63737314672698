<div class="user-table-view-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 pb-3">
          <div class="row align-items-center">
            <div class="col-sm col-12">
              <h1 class="h2 ls-tight">👋 Users</h1>
            </div>

            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <!-- <page-button
                [config]="{ rank: 'neutral' }"
                [icon]="'bi bi-people-fill'"
                [loading]="false"
                (clicked)="openShareToWebDialog()"
                >Share</page-button
              > -->

                <page-button
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  >Add</page-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="pt-6">
      <div class="container-fluid">
        <div class="vstack gap-4">
          <div class="row gy-2">
            <div class="col-12 col-sm-auto">
              <div class="cmn-search-box cmn-form-field">
                <mat-form-field appearance="outline">
                  <mat-label>Search</mat-label>
                  <input
                    matInput
                    placeholder="Ex. ium"
                    (keyup)="applyFilter($event)"
                    #input
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="col-12 col-sm-auto">
              <div class="cmn-search-box cmn-form-field">
                <mat-form-field appearance="outline">
                  <mat-label>Role</mat-label>
                  <mat-select
                    placeholder="Role"
                    [(value)]="role"
                    (selectionChange)="getUserList($event.value)"
                  >
                    <mat-option
                      *ngFor="let role of roleList"
                      [value]="role.id"
                      >{{ role.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="card table-container">
            <div class="card-header border-bottom d-flex align-items-center">
              <h5 class="me-auto">All users</h5>
              <!-- <div class="dropdown">
                <a
                  class="text-muted"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><i class="bi bi-three-dots-vertical"></i
                ></a>
                <div class="dropdown-menu">
                  <a href="#!" class="dropdown-item">Action </a
                  ><a href="#!" class="dropdown-item">Another action </a
                  ><a href="#!" class="dropdown-item">Something else here</a>
                </div>
              </div> -->
            </div>
            <div class="cmn-table-wrapper">
              <table mat-table [dataSource]="dataSource" class="w-100">
                <!-- Position Column -->

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="avatar avatar-sm bg-warning rounded-circle text-white"
                    >
                      <img
                        [src]="environmentPath + element.imageUrl"
                        onerror="this.src='../../../assets/images/thumbnail/img-profile.jpg'"
                        alt=""
                        *ngIf="element.imageUrl"
                      />
                      <mat-icon *ngIf="!element.imageUrl">person</mat-icon>
                    </span>
                    {{ element.name }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef>Email</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.email }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="role">
                  <th mat-header-cell *matHeaderCellDef>Role</th>
                  <td mat-cell *matCellDef="let element">
                    <span
                      class="badge text-uppercase bg-soft-primary text-primary rounded-pill"
                    >
                      {{ getRoleById(element.role) }}</span
                    >
                  </td>
                </ng-container>
                <ng-container matColumnDef="mobileNo">
                  <th mat-header-cell *matHeaderCellDef>Mobile</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.mobileNo }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="empty">
                  <th mat-header-cell *matHeaderCellDef></th>

                  <td class="text-end" mat-cell *matCellDef="let element">
                    <div class="d-flex">
                      <button
                        mat-icon-button
                        class="cus-round-btn"
                        (click)="openAddEditSideNav(element.id)"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        mat-icon-button
                        class="cus-round-btn"
                        color="warn"
                        (click)="deleteUser(element.id)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell ps-5 pt-5 text-center" colspan="6">
                    <b>No data found</b>
                  </td>
                </tr>
              </table>
            </div>
            <mat-paginator
              [length]="paginatorData?.itemCount"
              [pageSize]="paginatorData?.perPage"
              [pageSizeOptions]="[10, 20, 50]"
              aria-label="Select page of users"
              (page)="pageChange($event)"
              class="mat-paginator-sticky"
            ></mat-paginator>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
<!-- <app-create-new-user></app-create-new-user> -->
