import { LocalStorageService } from './../../../core/services/local-storage.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { Component, OnInit, ViewChild } from '@angular/core';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';
import { projectApi } from 'src/app/core/model/api-url';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus, status } from 'src/app/core/enums/roles.enum';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RoleAccessService } from 'src/app/core/services/role-access.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit {
  projectList = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  displayedColumns: string[] = [
    'name',
    'orgId',
    'status',
    'budget',
    // 'desc',
    'empty',
  ];
  user;
  dataSource = new MatTableDataSource(this.projectList);
  private updateProjectListSubscription: Subscription;
  action;

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastr: ToastService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private roleAccessService: RoleAccessService
  ) {}

  async ngOnInit() {
    this.access();
    await this.getProjectList();
    this.subscribeToUpdateProjectList();
    this.user = this.localStorageService.getUser().username;
  }

  access() {
    const url = this.router.routerState.snapshot.url;
    if (url) {
      this.action = this.roleAccessService.access[url];
      if (!this.action.delete && !this.action.edit) {
        this.displayedColumns.splice(-1);
      }
    }
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav('ProjectComponent', id);
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('ProjectComponent');
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getProjectList();
  }

  async getProjectList() {
    let projectListUrl = projectApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService.getList(projectListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res.data && res.data.data) {
            res.data.data.forEach((el) => {
              el.imageUrl = environment.mainUrl + el.imageUrl;
            });
          }
          this.projectList = res.data.data;
          this.dataSource = new MatTableDataSource(this.projectList);
          // this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paginatorData = res.data.paginator;
        }
      },
      (err) => {
        this.toastr.errorMsg(err);
      }
    );
  }

  deleteProject(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteportfolioUrl = projectApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteportfolioUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastr.successMsg(
                  'Deleted',
                  `Project has been successfully deleted`
                );
                this.getProjectList();
              }
            },
            (err) => {
              this.toastr.errorMsg(err);
            }
          );
      }
    });
  }

  getStatus(id) {
    return status[id];
  }

  private subscribeToUpdateProjectList(): void {
    this.updateProjectListSubscription =
      this.cmnAddEditSidenavService.updateProjectList.subscribe(async (res) => {
        if (res) {
          await this.getProjectList(); // Await the getProjectList method if the event is triggered.
        }
      });
  }

  ngOnDestroy(): void {
    // this.cmnAddEditSidenavService.updateProjectList.unsubscribe();
    if (this.updateProjectListSubscription) {
      this.updateProjectListSubscription.unsubscribe();
    }
  }
}
