<div class="h-100 overflow-auto cmn-add-edit-sidenav">
    <div class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary">
        <h5 class="add-edit-sidenav-title">
            {{ isEdit ? "Update" : "Create a new" }} site credential detail
        </h5>

        <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="add-edit-sidenav-body px-4 pt-4">
        <div class="px-0 container-fluid">
            <form [formGroup]="credsForm" class="row">
                <div class="cmn-form-field col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name">
                        <mat-error *ngIf="credsForm.controls['name'].invalid" class="error-msg text-danger">
                            {{ formErrors["name"]}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Public Key</mat-label>
                        <input matInput formControlName="publicKey" readonly>
                        <button matIconSuffix mat-icon-button (click)="copyToClipboard('publicKey')">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Private Key</mat-label>
                            <input
                              matInput
                              formControlName="privateKey"
                              readonly
                            />
                            <button matIconSuffix mat-icon-button (click)="copyToClipboard('privateKey')">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                    </mat-form-field>
                        
                </div>
                <div class="cmn-form-field col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Website Name</mat-label>
                        <input matInput formControlName="websiteName">
                        <mat-error *ngIf="credsForm.controls['websiteName'].invalid" class="error-msg text-danger">
                            {{ formErrors["websiteName"]}}
                        </mat-error>
                    </mat-form-field>
                </div>   

            </form>
        </div>
    </div>
    <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
        <page-button data-bs-dismiss="add-edit-sidenav" [config]="{ rank: 'neutral' }" [loading]="false"
            (clicked)="closeAddEditSideNav()">Close</page-button>
        <page-button [config]="{ rank: 'primary' }" (clicked)="onSubmit()" [loading]="false">{{ isEdit ? "Update" : "Add" }}
        </page-button>
    </div>
</div>