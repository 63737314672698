import { CmnApiCallService } from '../../../../core/services/cmn-api-call.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShareToWebPopupComponent } from '../../../share-to-web-popup/share-to-web-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { salaryApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { SalaryService } from './../../../../core/services/salary.service';
import { User } from 'src/app/core/model/user';
import { UserListService } from 'src/app/core/services/user-list.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-salary-list',
  templateUrl: './salary-list.component.html',
  styleUrls: ['./salary-list.component.scss'],
})
export class SalaryListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // dataSource: MatTableDataSource<any>;
  destroy$ = new Subject();
  displayedColumns: string[] = [
    'userId',
    'salary',
    'accName',
    'bankAcc',
    'ifsc',
    'upi',
    'desc',
    // 'description',
    'empty',
  ];
  salary = [];
  dataSource = new MatTableDataSource(this.salary);
  updateSalaryList: boolean = false;
  private updateSalaryListSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userList: User[] = [];

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private salaryService: SalaryService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {}

  async ngOnInit() {
    await this.getUserList();
    await this.getSalaryList();
    this.subscribeToUpdateProjectList();
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'SalaryListComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('SalaryListComponent');
    }
  }
  increment(id: number) {
    this.salaryService.haveToIncrementInSalary(true);
    this.cmnAddEditSidenavService.openAddEditSideNav('SalaryListComponent', id);
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getSalaryList();
  }

  async getSalaryList() {
    let salaryUrl = salaryApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService.getList(salaryUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.dataSource = new MatTableDataSource(res.data.data);
            this.paginatorData = res.data.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  deleteSalary(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteSalaryUrl = salaryApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService.addUpdateData(deleteSalaryUrl, model).subscribe(
          (res: ApiResponse) => {
            if (res.status == ApiStatus.SUCCESS) {
              this.toastService.successMsg(
                'Deleted',
                `Salary record has been successfully deleted`
              );
              this.getSalaryList();
            }
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
      }
    });
    // ngAfterViewInit() {
    //   this.cmnAddEditSidenavService.updateSalaryList.subscribe((res) => {
    //     debugger;
    //   });
    // }
  }

  private subscribeToUpdateProjectList(): void {
    this.updateSalaryListSubscription =
      this.cmnAddEditSidenavService.updateSalaryList.subscribe(async (res) => {
        if (res) {
          await this.getSalaryList();
        }
      });
  }

  async getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(this.localStorageService.getUser());
        this.userList.push(...userList);
      });
  }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  ngOnDestroy(): void {
    if (this.updateSalaryListSubscription) {
      this.updateSalaryListSubscription.unsubscribe();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
