<mat-toolbar>
  <button
    mat-icon-button
    class="example-icon me-auto"
    (click)="openSidebar()"
    aria-label="Example icon-button with menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <!-- <form class="form-inline ms-auto me-2 d-none d-md-flex">
    <div class="input-group input-group-inline shadow-none">
      <span class="input-group-text ps-0 pe-3 border-0 bg-white"
        ><i class="bi bi-search"></i> </span
      ><input
        type="text"
        class="form-control form-control-flush border-0 shadow-none"
        placeholder="Search"
        aria-label="Search"
      />
    </div>
  </form> -->
  <div class="navbar-user d-sm-block">
    <div class="hstack gap-3 ms-1">
      <!-- <div class="dropdown">
        <a
          class="nav-link px-3 text-base text-muted text-opacity-70 text-opacity-100-hover"
          id="dropdown-notifications"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          [matMenuTriggerFor]="notification"
        >
          <i class="bi bi-bell-fill"> </i>
        </a>
        <mat-menu #notification class="cmn-mat-menu">
          <div class="px-lg-4 px-3">
            <div class="dropdown-item d-flex align-items-center">
              <h6 class="dropdown-header p-0 m-0 font-semibold">
                Notifications
              </h6>
              <a
                (click)="$event.stopPropagation()"
                class="text-sm font-semibold ms-auto"
                >Clear all</a
              >
            </div>

            <div class="dropdown-item py-3 d-flex">
              <div>
                <div class="avatar bg-tertiary text-white rounded-circle">
                  RF
                </div>
              </div>
              <div class="flex-fill ms-3">
                <div class="text-sm lg-snug w-100 text-wrap">
                  <a class="font-semibold text-heading text-primary-hover">
                    Robert
                  </a>
                  sent a message to
                  <a class="font-semibold text-heading text-primary-hover">
                    Webpixels
                  </a>
                </div>
                <span class="text-muted text-xs">30 mins ago</span>
              </div>
            </div>
            <div class="dropdown-item py-3 d-flex">
              <div>
                <div class="avatar bg-tertiary text-white rounded-circle">
                  RF
                </div>
              </div>
              <div class="flex-fill ms-3">
                <div class="text-sm lg-snug w-100 text-wrap">
                  <a class="font-semibold text-heading text-primary-hover">
                    Robert
                  </a>
                  sent a message to
                  <a class="font-semibold text-heading text-primary-hover">
                    Webpixels
                  </a>
                </div>
                <span class="text-muted text-xs">30 mins ago</span>
              </div>
            </div>
            <div class="dropdown-item py-3 d-flex">
              <div class="avatar">
                <img
                  alt="..."
                  src="../../../assets/images/thumbnail/people-1.jpg"
                  class="rounded-circle"
                />
              </div>
              <div class="flex-fill ms-3">
                <div class="text-sm lg-snug w-100 text-wrap">
                  <a class="font-semibold text-heading text-primary-hover">
                    Robert
                  </a>
                  sent a message to
                  <a class="font-semibold text-heading text-primary-hover">
                    Webpixels
                  </a>
                </div>
                <span class="text-muted text-xs">30 mins ago</span>
              </div>
            </div>
            <div class="dropdown-item py-3 d-flex">
              <div>
                <div class="avatar bg-success text-white rounded-circle">
                  KW
                </div>
              </div>
              <div class="flex-fill ms-3">
                <div class="text-sm lg-snug w-100 text-wrap">
                  <a class="font-semibold text-heading text-primary-hover">
                    Robert
                  </a>
                  sent a message to
                  <a class="font-semibold text-heading text-primary-hover"
                    >Webpixels</a
                  >
                </div>
                <span class="text-muted text-xs">30 mins ago</span>
              </div>
            </div>

            <div class="dropdown-divider"></div>
            <div class="dropdown-item py-2 text-center">
              <a class="font-semibold text-muted text-primary-hover"
                >View all</a
              >
            </div>
          </div>
        </mat-menu>
      </div> -->
      <div class="dropdown">
        <a
          class="d-flex align-items-center"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
        >
          <div>
            <div class="avatar avatar-sm bg-warning rounded-circle text-white">
              <img
                alt="img-profile.jpg"
                src="../../../assets/images/thumbnail/img-profile.jpg"
                class="img-fluid rounded-circle"
              />
            </div>
          </div>
          <div class="d-none d-sm-block ms-3">
            <span class="h6">{{ user }}</span>
          </div>
          <div class="d-none d-md-block ms-md-2">
            <i class="bi bi-chevron-down text-muted text-xs"></i></div
        ></a>
        <div class="dropdown-menu dropdown-menu-end">
          <div class="dropdown-header">
            <span class="d-block text-sm text-muted mb-1">Signed in as</span>
            <span class="d-block text-heading font-semibold">{{ user }}</span>
          </div>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/project"
            ><i class="bi bi-house me-3"></i>Home </a
          ><a class="dropdown-item"
            ><i class="bi bi-pencil me-3"></i>Edit profile</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/settings/general"
            ><i class="bi bi-gear me-3"></i>Settings </a
          ><a class="dropdown-item"><i class="bi bi-image me-3"></i>Media </a
          ><a class="dropdown-item"
            ><i class="bi bi-box-arrow-up me-3"></i>Share</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logOut()"
            ><i class="bi bi-box-arrow-left me-3"></i>Log Out</a
          >
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
