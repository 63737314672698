import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectIdSubject = new Subject<number>();
  projectId = this.projectIdSubject.asObservable();

  setProjectId(projectId: number) {
    this.projectIdSubject.next(projectId);
  }
}
