import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { RoleAccessService } from '../services/role-access.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private roleAccessService: RoleAccessService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentPath = state.url;
    return await this.roleAccessService.fetchRoleAccessData().then((result) => {
      if (result[currentPath] == true) {
        return true;
      } else {
        this.router.navigate(['/task/task']);
        return false;
      }
    });
  }
}
