import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { siteCredsApi, userApi } from 'src/app/core/model/api-url';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { ShareToWebPopupComponent } from 'src/app/pages/share-to-web-popup/share-to-web-popup.component';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-site-creds-list',
  templateUrl: './site-creds-list.component.html',
  styleUrls: ['./site-creds-list.component.scss'],
})
export class SiteCredsListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  destroy$ = new Subject();
  displayedColumns: string[] = [
    'userId',
    'name',
    'publicKey',
    'privateKey',
    'websiteName',
    'empty',
  ];
  apiQuery: ApiQuery = new ApiQuery();
  userList: User[] = [];
  user;
  siteCredsList: [] = [];
  dataSource = new MatTableDataSource(this.siteCredsList);
  paginatorData: Paginator = new Paginator();
  private updateSiteCredsListSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {}

  async ngOnInit() {
    await this.getSiteCredsList();
    await this.getUserList();
    this.subscribeToUpdateSiteCredsList();
    this.user = this.localStorageService.getUser();
  }

  private subscribeToUpdateSiteCredsList(): void {
    this.updateSiteCredsListSubscription =
      this.cmnAddEditSidenavService.updateSiteCredsList.subscribe(
        async (res) => {
          if (res) {
            await this.getSiteCredsList();
          }
        }
      );
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getSiteCredsList();
  }

  async getSiteCredsList() {
    let siteCredsApiUrl = siteCredsApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService
      .getList(siteCredsApiUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.dataSource = new MatTableDataSource(res.data.data);
            this.paginatorData = res.data.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      });
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'SiteCredsListComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'SiteCredsListComponent'
      );
    }
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  deleteSiteCreds(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteSiteCredsApiUrl = siteCredsApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteSiteCredsApiUrl, model)
          .subscribe((res: ApiResponse) => {
            if (res.status == ApiStatus.SUCCESS) {
              this.toastService.successMsg(
                'Deleted',
                'Site Credentials has been successfully deleted'
              );
              this.getSiteCredsList();
              (err) => {
                this.toastService.errorMsg(err);
              };
            }
          });
      }
    });
  }

  async getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(this.localStorageService.getUser());
        this.userList.push(...userList);
      });
  }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  ngOnDestroy(): void {
    if (this.updateSiteCredsListSubscription) {
      this.updateSiteCredsListSubscription.unsubscribe();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
