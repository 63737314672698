import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { AuthGuard } from './core/guard/auth.guard';

const routes: Routes = [
  {
    path: 'authentication',
    loadChildren: () =>
      import('./pages/authentication/authentication.module').then(
        (mod) => mod.AuthenticationModule
      ),
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'project',
        pathMatch: 'full',
      },
      {
        path: 'project',
        loadChildren: () =>
          import('./pages/projects/projects.module').then(
            (mod) => mod.ProjectsModule
          ),
      },
      {
        path: 'task',
        loadChildren: () =>
          import('./pages/tasks/tasks.module').then((mod) => mod.TasksModule),
      },
      {
        path: 'files',
        loadChildren: () =>
          import('./pages/files/files.module').then((mod) => mod.FilesModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./pages/settings/settings.module').then(
            (mod) => mod.SettingsModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'portfolio',
        loadChildren: () =>
          import('./pages/portfolio/portfolio.module').then(
            (mod) => mod.PortfolioModule
          ),
      },
      {
        path: 'user-access',
        loadChildren: () =>
          import('./pages/user-access/user-access.module').then(
            (mod) => mod.UserAccessModule
          ),
      },
      {
        path: 'attendance',
        loadChildren: () =>
          import('./pages/attendance/attendance.module').then(
            (mod) => mod.AttendanceModule
          ),
      },
      {
        path: 'salary',
        loadChildren: () =>
          import('./pages/salary/salary.module').then(
            (mod) => mod.SalaryModule
          ),
      },
      {
        path: 'organization',
        loadChildren: () =>
          import('./pages/organization/organization.module').then(
            (mod) => mod.OrganizationModule
          ),
      },
      {                   // newlu created
        path: 'teams',
        loadChildren: () =>
          import('./pages/teams/teams.module').then(
            (mod) => mod.TeamsModule
          ),
      },
      {                   // newlu created
        path: 'bugs',
        loadChildren: () =>
          import('./pages/bugs/bugs.module').then(
            (mod) => mod.BugsModule
          ),
      },
      {                   // newlu created
        path: 'creator',
        loadChildren: () =>
          import('./pages/creator/creator.module').then(
            (mod) => mod.CreatorModule
          ),
      },
      {
        path: 'client',
        loadChildren: () =>
          import('./pages/client/client.module').then(
            (mod) => mod.ClientModule
          )
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
