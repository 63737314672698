<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>👋 Hi, {{ user }}!</h2>
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <!-- <a class="btn btn-sm btn-neutral border-base" (click)="openShareToWebDialog()">
                                  <span class="pe-2">
                                      <i class="bi bi-people-fill"></i>
                                  </span>
                                  <span> Share</span>
                              </a> -->
                <!-- <page-button
                  [config]="{ rank: 'neutral' }"
                  [icon]="'bi bi-people-fill'"
                  [loading]="false"
                  (clicked)="openShareToWebDialog()"
                  >Share</page-button
                > -->
                <page-button
                  *ngIf="action && action.create"
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  >Add</page-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main class="py-6 bg-surface-secondary">
      <!-- <app-creat-new-project> </app-creat-new-project> -->
      <!-- <app-share-to-web-popup></app-share-to-web-popup> -->
      <div class="container-fluid">
        <!-- <div class="row g-6 mb-6">
          <div class="col-xl-8"></div>
          <div class="col-xl-4">
            <div class="card h-full">
              <div class="card-body">
                <div class="card-title d-flex align-items-center">
                  <h5 class="mb-0">Top Performance</h5>
                  <div class="ms-auto text-end">
                    <a href="javascript:void(0)" class="text-sm font-semibold"
                      >See all</a
                    >
                  </div>
                </div>
                <div class="list-group gap-4">
                  <div
                    class="list-group-item d-flex align-items-center border rounded"
                  >
                    <div class="me-4">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                        />
                      </div>
                    </div>
                    <div class="flex-fill">
                      <a
                        href="javascript:void(0)"
                        class="d-block h6 font-semibold mb-1"
                        >Norman Mohrbacher</a
                      ><span class="d-block text-sm text-muted"
                        >UI Designer</span
                      >
                    </div>
                    <div class="ms-auto text-end">
                      <div class="dropdown">
                        <a
                          class="text-muted"
                          href="javascript:void(0)"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="bi bi-three-dots-vertical"></i
                        ></a>
                        <div class="dropdown-menu">
                          <a href="#!" class="dropdown-item">Action </a
                          ><a href="#!" class="dropdown-item">Another action </a
                          ><a href="#!" class="dropdown-item"
                            >Something else here</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-group-item d-flex align-items-center border rounded"
                  >
                    <div class="me-4">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                        />
                      </div>
                    </div>
                    <div class="flex-fill">
                      <a
                        href="javascript:void(0)"
                        class="d-block h6 font-semibold mb-1"
                        >Leeann Monnet</a
                      ><span class="d-block text-sm text-muted"
                        >Web Developer</span
                      >
                    </div>
                    <div class="ms-auto text-end">
                      <div class="dropdown">
                        <a
                          class="text-muted"
                          href="javascript:void(0)"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="bi bi-three-dots-vertical"></i
                        ></a>
                        <div class="dropdown-menu">
                          <a href="#!" class="dropdown-item">Action </a
                          ><a href="#!" class="dropdown-item">Another action </a
                          ><a href="#!" class="dropdown-item"
                            >Something else here</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-group-item d-flex align-items-center border rounded"
                  >
                    <div class="me-4">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        />
                      </div>
                    </div>
                    <div class="flex-fill">
                      <a
                        href="javascript:void(0)"
                        class="d-block h6 font-semibold mb-1"
                        >Kathe Rahimi</a
                      ><span class="d-block text-sm text-muted"
                        >Marketing Team</span
                      >
                    </div>
                    <div class="ms-auto text-end">
                      <div class="dropdown">
                        <a
                          class="text-muted"
                          href="javascript:void(0)"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="bi bi-three-dots-vertical"></i
                        ></a>
                        <div class="dropdown-menu">
                          <a href="#!" class="dropdown-item">Action </a
                          ><a href="#!" class="dropdown-item">Another action </a
                          ><a href="#!" class="dropdown-item"
                            >Something else here</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="row g-6 mb-6">
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <span
                      class="h6 font-semibold text-muted text-sm d-block mb-2"
                      >Budget</span
                    >
                    <span class="h3 font-bold mb-0">$750.90</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-tertiary text-white text-lg rounded-circle"
                    >
                      <i class="bi bi-credit-card"></i>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-0 text-sm">
                  <span
                    class="badge badge-pill bg-soft-success text-success me-2"
                    ><i class="bi bi-arrow-up me-1"></i>30% </span
                  ><span class="text-nowrap text-xs text-muted"
                    >Since last month</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <span
                      class="h6 font-semibold text-muted text-sm d-block mb-2"
                      >New projects</span
                    >
                    <span class="h3 font-bold mb-0">215</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-primary text-white text-lg rounded-circle"
                    >
                      <i class="bi bi-people"></i>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-0 text-sm">
                  <span
                    class="badge badge-pill bg-soft-success text-success me-2"
                    ><i class="bi bi-arrow-up me-1"></i>23% </span
                  ><span class="text-nowrap text-xs text-muted"
                    >Since last week</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <span
                      class="h6 font-semibold text-muted text-sm d-block mb-2"
                      >Total hours</span
                    >
                    <span class="h3 font-bold mb-0">1.400</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-info text-white text-lg rounded-circle"
                    >
                      <i class="bi bi-clock-history"></i>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-0 text-sm">
                  <span class="badge badge-pill bg-soft-danger text-danger me-2"
                    ><i class="bi bi-arrow-down me-1"></i>-10% </span
                  ><span class="text-nowrap text-xs text-muted"
                    >Since last month</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <span
                      class="h6 font-semibold text-muted text-sm d-block mb-2"
                      >Work load</span
                    >
                    <span class="h3 font-bold mb-0">95%</span>
                  </div>
                  <div class="col-auto">
                    <div
                      class="icon icon-shape bg-warning text-white text-lg rounded-circle"
                    >
                      <i class="bi bi-minecart-loaded"></i>
                    </div>
                  </div>
                </div>
                <div class="mt-2 mb-0 text-sm">
                  <span
                    class="badge badge-pill bg-soft-success text-success me-2"
                    ><i class="bi bi-arrow-up me-1"></i>15% </span
                  ><span class="text-nowrap text-xs text-muted"
                    >Since yestearday</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="card">
          <!-- <div class="card-header border-bottom d-flex align-items-center">
            <h5 class="me-auto">All projects</h5>
            <div class="dropdown">
              <a
                class="text-muted"
                href="javascript:void(0)"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><i class="bi bi-three-dots-vertical"></i
              ></a>
              <div class="dropdown-menu">
                <a href="#!" class="dropdown-item">Action </a
                ><a href="#!" class="dropdown-item">Another action </a
                ><a href="#!" class="dropdown-item">Something else here</a>
              </div>
            </div>
          </div> -->
          <div class="cmn-table-wrapper table-container">
            <table mat-table [dataSource]="dataSource" class="w-100">
              <!-- Position Column -->

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class="m-1 avatar border bg-warning rounded rounded-circle"
                  >
                    <img
                      src="{{ element.imageUrl }}"
                      class="w-75 h-75 rounded rounded-75"
                      onerror="src='../../../assets/images/img/No_image_available.svg'"
                      alt=""
                      *ngIf="element.imageUrl"
                    />
                    <mat-icon *ngIf="!element.imageUrl">person</mat-icon> </span
                  >{{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="orgId">
                <th mat-header-cell *matHeaderCellDef>Organization</th>
                <td mat-cell *matCellDef="let element">{{ element.orgId }}</td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  {{ getStatus(element.status) }}
                </td>
              </ng-container>
              <ng-container matColumnDef="budget">
                <th mat-header-cell *matHeaderCellDef>Budget</th>
                <td mat-cell *matCellDef="let element">{{ element.budget }}</td>
              </ng-container>
              <!-- <ng-container matColumnDef="desc">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">{{ element.desc }}</td>
              </ng-container> -->
              <!-- <ng-container matColumnDef="completion">
                <th mat-header-cell *matHeaderCellDef>COMPLETION</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex align-items-center">
                    <div class="me-2">{{ element.completion }}%</div>
                    <div
                      class="c-progressbar w-100 p-bg-success"
                      style="--h-progress: 0.2rem"
                    >
                      <mat-progress-bar
                        mode="determinate"
                        value="25"
                        class="progress-sm shadow-none"
                      ></mat-progress-bar>
                    </div>
                  </div>
                </td>
              </ng-container> -->
              <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef>Actions</th>

                <td class="text-end" mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <button
                      *ngIf="action && action.edit"
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      *ngIf="action && action.delete"
                      class="cus-round-btn"
                      color="warn"
                      (click)="deleteProject(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            [length]="paginatorData?.itemCount"
            [pageSize]="paginatorData?.perPage"
            [pageSizeOptions]="[10, 20, 50]"
            aria-label="Select page"
            (page)="pageChange($event)"
            >,
          </mat-paginator>
        </div>
      </div>
    </main>
  </div>
</div>
