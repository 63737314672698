import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { RolesEnum } from 'src/app/core/enums/roles.enum';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  object = Object;
  userData: any = [];
  sidebar: any[] = [];
  sidebarItem;
  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    let data: any = this.localStorageService.getUser();
    this.userData = data.roleAccess;
    this.processBackendResponse(data.roleAccess);
  }

  processBackendResponse(response: any[]): void {
    // Group links by apiGrp and generate the sidebar structure
    const groupedLinks = response.reduce((acc, link) => {
      if(link.delete || link.write || link.update || link.read){
        const group = acc.find(group => group.groupName === link.apiGrp);
        if (!group) {
          acc.push({ groupName: link.apiGrp, links: [link] });
        } else {
          group.links.push(link);
        }
      }
      return acc;
    }, []);

    this.sidebar = groupedLinks;
  }

  ngAfterViewInit() {
    if(this.sidebarItem){
      document.getElementById(this.sidebarItem).classList.add('show');
    }
  }

  setItem(link){
    this.localStorageService.setSidebarItem(link);
  }
}
