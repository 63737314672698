<mat-sidenav-container class="main-container-wrapper">
  <mat-sidenav [mode]="sideNaveMode" #sidenav [opened]="opened">
    <app-sidebar></app-sidebar>
  </mat-sidenav>

  <mat-sidenav
    class="add-edit-sidebar"
    [mode]="'over'"
    #addEditSideNav
    [opened]="addEditSideNav$ | async"
    position="end"
    (closed)="closeAddEditSideNav()"
  >
    <ng-container [ngSwitch]="openingComponentSubject?.componentName">
      <app-add-edit-portfolio
        *ngSwitchCase="'PortfolioComponent'"
      ></app-add-edit-portfolio>
      <app-add-edit-users
        *ngSwitchCase="'UsersListComponent'"
      ></app-add-edit-users>
      <app-add-edit-user-access
        *ngSwitchCase="'userAccessComponent'"
      ></app-add-edit-user-access>
      <app-add-edit-project
        *ngSwitchCase="'ProjectComponent'"
      ></app-add-edit-project>
      <app-add-edit-role
        *ngSwitchCase="'RoleListComponent'"
      ></app-add-edit-role>
      <app-add-edit-user-document
        *ngSwitchCase="'UserDocumentListComponent'"
      ></app-add-edit-user-document>
      <app-add-edit-attendance
        *ngSwitchCase="'AttendanceListComponent'"
      ></app-add-edit-attendance>
      <app-add-edit-leave *ngSwitchCase="'LeaveComponent'">
      </app-add-edit-leave>
      <app-add-edit-salary
        *ngSwitchCase="'SalaryListComponent'"
      ></app-add-edit-salary>
      <app-add-edit-salary-dispatched
        *ngSwitchCase="'SalaryDispatchedListComponent'"
      ></app-add-edit-salary-dispatched>
      <app-add-edit-organization
        *ngSwitchCase="'OrganizationListComponent'"
      ></app-add-edit-organization>
      <app-add-edit-client-info
        *ngSwitchCase="'ClientInfoListComponent'"
      ></app-add-edit-client-info>
      <app-add-edit-teams *ngSwitchCase="'TeamsComponent'"></app-add-edit-teams>
      <app-add-edit-post
        *ngSwitchCase="'PostListComponent'"
      ></app-add-edit-post>
      <app-add-edit-site-creds
        *ngSwitchCase="'SiteCredsListComponent'"
      ></app-add-edit-site-creds>
      <app-add-edit-task
        *ngSwitchCase="'TaskListComponent'"
      ></app-add-edit-task>
      <app-add-edit-team-members
        *ngSwitchCase="'TeamMembersListComponent'"
      ></app-add-edit-team-members>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content class="main">
    <div class="header-wrapper" [ngClass]="{ 'header-slider': isHeaderSlide }">
      <app-header (openSidebarEvent)="openSidebar()"></app-header>
    </div>
    <div class="main-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
