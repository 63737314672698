<div class="h-100 overflow-y-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} task detail
    </h5>

    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <main>
      <form [formGroup]="taskForm" class="row">
        <div class="cmn-form-field col-12">
          <mat-form-field>
            <input
              matInput
              formControlName="name"
              type="text"
              class="form-control form-control-flush text-md font-bold"
              placeholder="Enter your task name ..."
            />
            <mat-error
              *ngIf="taskForm.controls['name'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["name"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-6">
          <mat-form-field appearance="outline">
            <mat-label>Task Type</mat-label>
            <mat-select placeholder="Type of Task" formControlName="type">
              <mat-option
                *ngFor="let task of taskTypeList"
                [value]="type[task[1]]"
              >
                <span class="{{ task[0] }}">{{ task[1] }}</span>
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="taskForm.controls['type'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["type"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-6">
          <mat-form-field appearance="outline">
            <mat-label>Parent Task</mat-label>
            <mat-select
              placeholder="Parent Task"
              formControlName="parentTaskId"
            >
              <mat-option
                *ngFor="let parent of parentTaskList"
                [value]="parent.id"
              >
                <span>{{ parent.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Project Manager</mat-label>
            <mat-select
              placeholder="Select Project Manager"
              formControlName="projectManagerId"
            >
              <mat-option
                *ngFor="let projectManager of userList | roleFilter : [1, 2, 4]"
                [value]="projectManager.id"
                >{{ projectManager.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="taskForm.controls['projectManagerId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["projectManagerId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-6">
          <mat-form-field appearance="outline">
            <mat-label>Assignee</mat-label>
            <mat-select
              placeholder="Select your assignee"
              formControlName="assignTo"
            >
              <mat-option
                *ngFor="let assignee of userList | roleFilter : [1, 2, 4]"
                [value]="assignee.id"
                >{{ assignee.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="taskForm.controls['assignTo'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["assignTo"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-6">
          <mat-form-field appearance="outline">
            <mat-label>Reporter</mat-label>
            <mat-select
              placeholder="Select your recipient"
              formControlName="reportTo"
            >
              <mat-option
                *ngFor="let recipient of recipientList"
                [value]="recipient.id"
                >{{ recipient.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="taskForm.controls['reportTo'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["reportTo"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Project</mat-label>
            <mat-select
              placeholder="Select your project"
              formControlName="projectId"
              (selectionChange)="updateStatusList(taskForm.value['projectId'])"
            >
              <mat-option
                *ngFor="let project of projectList"
                [value]="project.id"
                >{{ project.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="taskForm.controls['projectId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["projectId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-6">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select
              placeholder="Current task status"
              formControlName="statusId"
            >
              <mat-option *ngFor="let sts of statusList" [value]="sts[0]">
                <span>{{ sts[1] }}</span>
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="taskForm.controls['statusId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["statusId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-6">
          <mat-form-field appearance="outline">
            <mat-label>Priority</mat-label>
            <mat-select
              placeholder="Current task priority"
              formControlName="priority"
            >
              <mat-option
                *ngFor="let pri of priorityList"
                [value]="priority[pri[1]]"
              >
                <span class="{{ pri[0] }}">{{ pri[1] }}</span>
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="taskForm.controls['priority'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["priority"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-md-4">
          <div class="cmn-date-picker">
            <mat-form-field appearance="outline">
              <mat-label>Due date</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                formControlName="dueDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div class="cmn-form-field col-md-4">
          <div class="cmn-date-picker">
            <mat-form-field appearance="outline">
              <mat-label>Estimate Days</mat-label>
              <mat-select
                placeholder="Estimated"
                formControlName="estimatedDays"
              >
                <mat-option *ngFor="let day of estimatedDays" [value]="day">
                  <span>{{ day }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="cmn-form-field col-md-4">
          <div class="cmn-date-picker">
            <mat-form-field appearance="outline">
              <mat-label>Estimate Hours</mat-label>
              <mat-select
                placeholder="Estimated"
                formControlName="estimatedHours"
              >
                <mat-option *ngFor="let hour of estimatedHours" [value]="hour">
                  <span>{{ hour }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-12 cmn-form-field">
          <ckeditor
            [title]="title"
            [editor]="editor"
            [data]="editorData"
            [config]="editorConfig"
            (ready)="onReady($event)"
            formControlName="desc"
          ></ckeditor>
        </div>

        <div class="col-12 cmn-form-field pt-4">
          <mat-label>
            <h6 class="border-start border-secondary mb-4 ps-4">Attachment</h6>
          </mat-label>
          <div class="file-selector" (click)="attachment.click()">
            <div class="w-100">
              <div
                class="m-auto d-flex h-100 d-flex justify-content-center align-items-center"
              >
                <div class="text-center">
                  <div class="text-2xl text-muted">
                    <i class="bi bi-upload"></i>
                  </div>
                  <div class="d-flex text-sm mt-3">
                    <p class="font-semibold">Upload a file or drag and drop</p>
                  </div>
                  <p class="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                </div>
              </div>
              <input
                type="file"
                #attachment
                hidden
                (change)="onFileSelected($event, 'attachment')"
                accept="image/*"
              />
            </div>
          </div>
          <div class="list-group list-group-flush mt-2">
            <div
              class="list-group-item py-3 d-flex align-items-center"
              *ngFor="let i of attachmentList"
            >
              <div class="flex-fill d-flex align-items-center">
                <img
                  src="{{ i.tag }}"
                  alt="img"
                  class="avatar avatar-md rounded-circle border border-2"
                />
                <span class="d-block ms-4 h6 text-sm font-semibold mb-1">{{
                  i.tag
                }}</span>
              </div>
              <div class="ms-auto dropdown-icon-transparent">
                <button
                  class="border rounded rounded-circle p-0 m-0"
                  (click)="deleteItem(i.id, 'attachment')"
                >
                  <mat-icon class="h-100 w-100 d-flex fs-5">clear</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="cmn-form-field col-12 pt-5 pb-5">
          <div class="">
            <h6 class="border-start border-secondary mb-4 ps-4">Subtasks</h6>
            <div class="vstack gap-3 ps-4">
              <div *ngFor="let i of subTaskList">
                <div class="d-flex">
                  <div
                    class="form-check form-check-linethrough d-flex align-items-center"
                  >
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                    />
                    <a
                      mat-button
                      class="form-check-label font-regular ms-2"
                      (click)="openTask(i.id)"
                      >{{ i.tag }}</a
                    >
                    <button
                      class="border rounded rounded-circle p-0 m-0"
                      (click)="deleteItem(i.id, 'subTask')"
                    >
                      <mat-icon class="h-100 w-100 d-flex fs-5">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-evenly">
              <div class="cmn-form-field mt-4 col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Add subtask</mat-label>
                  <input matInput placeholder="" formControlName="subTask" />
                </mat-form-field>
              </div>
              <div class="col-4 mt-4">
                <page-button
                  [config]="{ rank: 'primary' }"
                  (clicked)="onAddItem(taskForm.value['subTask'], 'subTask')"
                >
                  Add subTask
                </page-button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 cmn-form-field pt-4">
          <mat-label>
            <h6 class="border-start border-secondary mb-4 ps-4">Resources</h6>
          </mat-label>
          <div class="file-selector">
            <div class="w-100" (click)="resource.click()">
              <div
                class="m-auto d-flex h-100 d-flex justify-content-center align-items-center"
              >
                <div class="text-center">
                  <div class="text-2xl text-muted">
                    <i class="bi bi-upload"></i>
                  </div>
                  <div class="d-flex text-sm mt-3">
                    <p class="font-semibold">Upload a file or drag and drop</p>
                  </div>
                  <p class="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                </div>
              </div>
            </div>
            <input
              type="file"
              #resource
              (change)="onFileSelected($event, 'resource')"
              hidden
              accept="image/*"
            />
          </div>

          <div class="list-group list-group-flush mt-2">
            <div
              class="list-group-item py-3 d-flex align-items-center"
              *ngFor="let i of resourcesList"
            >
              <div class="flex-fill d-flex align-items-center">
                <img
                  src="{{ i.tag }}"
                  alt="img"
                  class="avatar avatar-md rounded-circle border border-2"
                />
                <span class="d-block ms-4 h6 text-sm font-semibold mb-1">{{
                  i.tag
                }}</span>
              </div>
              <div class="ms-auto dropdown-icon-transparent pe-4">
                <button
                  class="border rounded rounded-circle p-0 m-0"
                  (click)="deleteItem(i.id, 'resource')"
                >
                  <mat-icon class="h-100 w-100 d-flex fs-5">clear</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-evenly">
          <div class="cmn-form-field col-6">
            <mat-form-field appearance="outline">
              <mat-label>Add Links</mat-label>
              <input matInput placeholder="" formControlName="links" />
            </mat-form-field>
          </div>
          <div class="col-4">
            <page-button
              [config]="{ rank: 'primary' }"
              (clicked)="onAddItem(taskForm.value['links'], 'link')"
            >
              Add Links
            </page-button>
          </div>
        </div>
        <div class="d-flex gap-2 flex-wrap">
          <a
            class="bg-white bg-opacity-20 bg-opacity-100-hover border border-2 rounded px-3 py-1 text-heading fs-6 d-flex justify-content-between"
            *ngFor="let i of links"
            >{{ i.tag }}

            <button
              class="border rounded rounded-circle d-flex align-items-center p-0 ms-1"
              (click)="deleteItem(i.id, 'link')"
            >
              <mat-icon class="fs-5 w-auto h-auto">clear</mat-icon>
            </button>
          </a>
        </div>
      </form>

      <hr class="bg-dark" />

      <div class="container-fluid">
        <div class="row">
          <!-- <div class="col-12">
            <div class="vstack gap-8 mt-4">
              <div class="card">
                <div>
                  <div class="textarea-autosize p-6">
                    <textarea
                      class="form-control border-0 shadow-none p-0"
                      rows="4"
                      placeholder="Enter description"
                      oninput="this.parentNode.dataset.replicatedValue = this.value"
                    ></textarea>
                  </div>
                  <div class="d-flex align-items-center px-6 py-3 border-top">
                    <div class="flex-fill d-flex align-items-center">
                      <h6
                        class="font-semibold text-xs text-muted text-opacity-70"
                      >
                        Markdown formatting
                      </h6>
                    </div>
                    <div class="text-end">
                      <div class="hstack gap-5 align-items-center">
                        <a
                          href="#!"
                          class="text-lg text-muted text-primary-hover"
                          ><i class="bi bi-images"></i> </a
                        ><a
                          href="#!"
                          class="text-lg text-muted text-primary-hover"
                          ><i class="bi bi-emoji-smile"></i> </a
                        ><a
                          href="#!"
                          class="text-lg text-muted text-primary-hover"
                          ><i class="bi bi-paperclip"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ps-6 border-start">
                <h6 class="mb-4">Subtasks</h6>
                <div class="vstack gap-3">
                  <div>
                    <div class="form-check form-check-linethrough">
                      <input
                        class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                        type="checkbox"
                        checked="checked"
                      />
                      <a
                        href="#offcanvas-show-task"
                        class="form-check-label font-regular ms-2"
                        data-bs-toggle="offcanvas"
                        >Keep my mentality healthy by taking walks outside</a
                      >
                    </div>
                  </div>
                  <div>
                    <div class="form-check form-check-linethrough">
                      <input
                        class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                        type="checkbox"
                      />
                      <a
                        href="#offcanvas-show-task"
                        class="form-check-label font-regular ms-2"
                        data-bs-toggle="offcanvas"
                        >Build some new components in Figma</a
                      >
                    </div>
                  </div>
                  <div>
                    <div class="form-check form-check-linethrough">
                      <input
                        class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                        type="checkbox"
                      />
                      <a
                        href="#offcanvas-show-task"
                        class="form-check-label font-regular ms-2"
                        data-bs-toggle="offcanvas"
                        >Figure out how to use Clever from the help center!</a
                      >
                    </div>
                  </div>
                  <div>
                    <div class="form-check form-check-linethrough">
                      <input
                        class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                        type="checkbox"
                      />
                      <a
                        href="#offcanvas-show-task"
                        class="form-check-label font-regular ms-2"
                        data-bs-toggle="offcanvas"
                        >Create wireframes for the new dashboard</a
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="cmn-form-field text-SM font-bold form-control-flush mt-4 mb-3"
                  >
                    <mat-form-field appearance="outline">
                      <mat-label>Add another subtask</mat-label>
                      <input matInput placeholder="" #input />
                    </mat-form-field>
                  </div>
                  <div class="d-flex gap-3">
                    <button
                      type="button"
                      class="btn btn-link p-0 text-muted text-danger-hover text-sm font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-link p-0 link-success text-sm font-semibold"
                    >
                      Save task
                    </button>
                  </div>
                </div>
              </div>
              <div class="mb-6">
                <h6 class="mb-3">Attachments</h6>
                <div class="card">
                  <div class="card-body pb-0">
                    <div
                      class="rounded border-2 border-dashed border-primary-hover position-relative"
                    >
                      <div class="d-flex justify-content-center px-5 py-5">
                        <label
                          for="file_upload"
                          class="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                          ><input
                            id="file_upload"
                            name="file_upload"
                            type="file"
                            class="visually-hidden"
                        /></label>
                        <div class="text-center">
                          <div class="text-2xl text-muted">
                            <i class="bi bi-upload"></i>
                          </div>
                          <div class="d-flex text-sm mt-3">
                            <p class="font-semibold">
                              Upload a file or drag and drop
                            </p>
                          </div>
                          <p class="text-xs text-gray-500">
                            PNG, JPG, GIF up to 3MB
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="list-group list-group-flush mt-2">
                      <div
                        class="list-group-item py-3 d-flex align-items-center"
                      >
                        <div class="flex-fill">
                          <span class="d-block h6 text-sm font-semibold mb-1"
                            >task-img-1.png</span
                          ><span class="d-block text-xs text-muted"
                            >350 kb</span
                          >
                        </div>
                        <div class="ms-auto dropdown-icon-transparent">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="list-group-item py-3 d-flex align-items-center"
                      >
                        <div class="flex-fill">
                          <span class="d-block h6 text-sm font-semibold mb-1"
                            >task-img-1.png</span
                          ><span class="d-block text-xs text-muted"
                            >350 kb</span
                          >
                        </div>
                        <div class="ms-auto">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="list-group-item py-3 d-flex align-items-center"
                      >
                        <div class="flex-fill">
                          <span class="d-block h6 text-sm font-semibold mb-1"
                            >task-img-1.png</span
                          ><span class="d-block text-xs text-muted"
                            >350 kb</span
                          >
                        </div>
                        <div class="ms-auto">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-12">
            <div class="card position-sticky top-24">
              <div class="card-body pb-0">
                <h6 class="mb-4">Task settings</h6>
                <div>
                  <div class="col-12 col-sm-auto">
                    <div class="cmn-search-box cmn-form-field">
                      <mat-form-field appearance="outline">
                        <mat-label>Clever Project</mat-label>
                        <mat-select>
                          <mat-option> Done </mat-option>
                          <mat-option> In Progress </mat-option>
                          <mat-option> Project at risk </mat-option>
                          <mat-option> Not started </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="d-flex gap-3 justify-content-end mt-4">
                    <button type="button" class="btn btn-link p-0 link-primary text-sm font-semibold">
                      Add new project
                    </button>
                  </div>
                  <hr class="mt-4 mb-0" />
                  <div class="list-group list-group-flush">
                    <div class="list-group-item">
                      <div class="d-flex align-items-center">
                        <h6 class="text-sm text-muted font-semibold">Assignee</h6>
                        <div class="ms-auto dropdown-icon-transparent">
                          <button mat-button [matMenuTriggerFor]="Assignee">
                            <i class="bi bi-gear"></i>
                          </button>
                          <div class="cmn-mat-menu">
                            <mat-menu #Assignee="matMenu" class="w-64 cmn-mat-menu menu-first-second-item-bg-remove">
                              <button mat-menu-item>
                                <div (click)="$event.stopPropagation()" class="d-flex align-items-center">
                                  <h6 class="text-sm font-semibold">
                                    Assign up to 10 people
                                  </h6>
                                </div>
                              </button>
                              <button mat-menu-item>
                                <div (click)="$event.stopPropagation()" class="">
                                  <div class="cmn-search-box cmn-form-field py-1">
                                    <mat-form-field appearance="outline">
                                      <mat-label>Search</mat-label>
                                      <input matInput placeholder="Ex. ium" />
                                    </mat-form-field>
                                  </div>
                                </div>
                              </button>
                              <button mat-menu-item>
                                <div class="d-flex align-items-center">
                                  <div class="flex-none">
                                    <img alt="..." class="avatar avatar-xs rounded-circle"
                                      src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                                  </div>
                                  <div class="flex-fill ms-3">
                                    <a class="text-sm text-heading font-semibold mb-0">Robert Fox</a>
                                  </div>
                                </div>
                              </button>
                              <button mat-menu-item>
                                <div class="d-flex align-items-center">
                                  <div class="flex-none">
                                    <img alt="..." class="avatar avatar-xs rounded-circle"
                                      src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                                  </div>
                                  <div class="flex-fill ms-3">
                                    <a class="text-sm text-heading font-semibold mb-0">Darlene Robertson</a>
                                  </div>
                                </div>
                              </button>
                              <button mat-menu-item>
                                <div class="d-flex align-items-center">
                                  <div class="flex-none">
                                    <img alt="..." class="avatar avatar-xs rounded-circle"
                                      src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                                  </div>
                                  <div class="flex-fill ms-3">
                                    <a class="text-sm text-heading font-semibold mb-0">Theresa Webb</a>
                                  </div>
                                </div>
                              </button>
                              <button mat-menu-item>
                                <div class="d-flex align-items-center">
                                  <div class="flex-none">
                                    <img alt="..." class="avatar avatar-xs rounded-circle"
                                      src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                                  </div>
                                  <div class="flex-fill ms-3">
                                    <a class="text-sm text-heading font-semibold mb-0">Kristin Watson</a>
                                  </div>
                                </div>
                              </button>
                            </mat-menu>
                          </div>
                        </div>
                      </div>
                      <div class="vstack gap-3 mt-3">
                        <div class="d-flex align-items-center">
                          <div class="flex-none">
                            <img alt="..." class="avatar w-5 h-5 rounded-circle"
                              src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                          </div>
                          <div class="flex-fill ms-3">
                            <a class="text-sm text-heading font-semibold mb-0">Mark Branson</a>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="flex-none">
                            <img alt="..." class="avatar w-5 h-5 rounded-circle"
                              src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                          </div>
                          <div class="flex-fill ms-3">
                            <a class="text-sm text-heading font-semibold mb-0">Sara Summer</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="d-flex align-items-center">
                        <h6 class="text-sm text-muted font-semibold">Status</h6>
                        <div class="ms-auto dropdown-icon-transparent cmn-mat-menu">
                          <button mat-button [matMenuTriggerFor]="Status">
                            <i class="bi bi-gear"></i>
                          </button>
                          <mat-menu #Status="matMenu" class="w-48 cmn-mat-menu menu-first-item-bg-remove">

                            <button mat-menu-item>
                              <div (click)="$event.stopPropagation()" class="dropdown-header">
                                <h6 class="text-sm font-semibold">
                                  Select an option
                                </h6>
                              </div>
                            </button>

                            <button mat-menu-item>
                              <div class="dropdown-item">
                                <span class="badge badge-sm bg-soft-success text-success">Done</span>
                              </div>
                            </button>
                            <button mat-menu-item>
                              <div class="dropdown-item">
                                <span class="badge badge-sm bg-soft-warning text-warning">In progress</span>
                              </div>
                            </button>
                            <button mat-menu-item>
                              <div class="dropdown-item">
                                <span class="badge badge-sm bg-soft-primary text-primary">Testing</span>
                              </div>
                            </button>
                            <button mat-menu-item>
                              <div class="dropdown-item">
                                <span class="badge badge-sm bg-soft-danger text-danger">Canceled</span>
                              </div>
                            </button>
                          </mat-menu>
                        </div>
                      </div>
                      <div class="mt-3">
                        <span class="badge badge-sm bg-warning text-white">In progress</span>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="d-flex align-items-center">
                        <h6 class="text-sm text-muted font-semibold">Priority</h6>
                        <div class="dropdown ms-auto dropdown-icon-transparent cmn-mat-menu">
                          <button mat-button [matMenuTriggerFor]="Priority">
                            <i class="bi bi-gear"></i>
                          </button>
                          <mat-menu #Priority="matMenu" class="w-48 cmn-mat-menu menu-first-item-bg-remove">

                            <button mat-menu-item>
                              <div (click)="$event.stopPropagation()" class="dropdown-header">
                                <h6 class="text-sm font-semibold">
                                  Select an option
                                </h6>
                              </div>
                            </button>

                            <button mat-menu-item>
                              <div class="dropdown-item">
                                <span class="badge badge-sm bg-soft-danger text-danger"><i
                                    class="bi bi-exclamation-circle-fill me-2"></i>High</span>
                              </div>
                            </button>
                            <button mat-menu-item>
                              <div class="dropdown-item">
                                <span class="badge badge-sm bg-soft-success text-success"><i
                                    class="bi bi-hand-thumbs-up-fill me-2"></i>Low</span>
                              </div>
                            </button>
                            <button mat-menu-item>
                              <div class="dropdown-item">
                                <span class="badge badge-sm bg-soft-success text-success"><i
                                    class="bi bi-hand-thumbs-up-fill me-2"></i>Low</span>
                              </div>
                            </button>
                          </mat-menu>
                        </div>
                      </div>
                      <div class="mt-3">
                        <span class="badge badge-sm bg-soft-success text-success"><i
                            class="bi bi-hand-thumbs-up-fill me-2"></i>Low</span>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div>
                        <h6 class="text-sm text-muted font-semibold">
                          Notifications
                        </h6>
                        <button type="button" class="btn btn-neutral btn-sm w-full mt-3">
                          <i class="bi bi-bell me-2"></i>Subscribe
                        </button>
                        <small class="d-block mt-2 text-muted">You’re not receiving notifications from this
                          thread.</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </main>
  </div>

  <div
    class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary position-sticky"
  >
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
