export enum register {
  create = 'auth/register',
}

export enum forgorPassword {
  create = 'auth/forgot-password',
}

export enum portfolioApi {
  create = 'portfolio/create',
  list = 'portfolio/list',
  byId = 'portfolio/',
  delete = 'portfolio/delete/',
  softDelete = 'portfolio/softDelete/',
  update = 'portfolio/update/',
}

export enum userApi {
  create = 'user/create',
  list = 'user/list',
  byId = 'user/',
  delete = 'user/delete/',
  softDelete = 'user/softDelete/',
  update = 'user/update/',
}

export enum roleApi {
  create = 'role/create',
  list = 'role/list',
  byId = 'role/',
  delete = 'role/delete/',
  softDelete = 'role/softDelete/',
  update = 'role/update/',
}

export enum userDocumentApi {
  create = 'document/create',
  list = 'document/list',
  byId = 'document/',
  delete = 'document/delete/',
  softDelete = 'document/softDelete/',
  update = 'document/update/',
}

export enum rollAccessApi {
  create = 'roleaccess/create',
  list = 'roleaccess/list',
  byId = 'roleaccess/',
  delete = 'roleaccess/delete/',
  softDelete = 'roleaccess/softDelete/',
  update = 'roleaccess/update/',
}

export enum projectApi {
  create = 'project/create',
  list = 'project/list',
  byId = 'project/',
  delete = 'project/delete/',
  softDelete = 'project/softDelete/',
  update = 'project/update/',
}
export enum projectRouteApi {
  create = 'projectroute/create',
  list = 'projectroute/list',
  byId = 'projectroute/',
  delete = 'projectroute/delete/',
  softDelete = 'projectroute/softDelete/',
  update = 'projectroute/update/',
}
export enum organizationApi {
  create = 'organization/create',
  list = 'organization/list',
  byId = 'organization/',
  delete = 'organization/delete/',
  softDelete = 'organization/softDelete/',
  update = 'organization/update/',
}

export enum attendanceApi {
  create = 'attendance/create',
  list = 'attendance/list',
  byId = 'attendance/',
  delete = 'attendance/delete/',
  softDelete = 'attendance/softDelete/',
  update = 'attendance/update/',
}

export enum leaveApi {
  create = 'leave/create',
  list = 'leave/list',
  byId = 'leave/',
  delete = 'leave/delete/',
  softDelete = 'leave/softDelete/',
  update = 'leave/update/',
}

export enum salaryApi {
  create = 'salary/create',
  list = 'salary/list',
  byId = 'salary/',
  delete = 'salary/delete/',
  softDelete = 'salary/softDelete/',
  update = 'salary/update/',
}

export enum salaryDispatchedApi {
  create = 'salarydispatched/create',
  list = 'salarydispatched/list',
  byId = 'salarydispatched/',
  delete = 'salarydispatched/delete/',
  softDelete = 'salarydispatched/softDelete/',
  update = 'salarydispatched/update/',
}

export enum clientInfoApi {
  create = 'clientinfo/create',
  list = 'clientinfo/list',
  byId = 'clientinfo/',
  delete = 'clientinfo/delete/',
  softDelete = 'clientinfo/softDelete/',
  update = 'clientinfo/update/',
}

export enum clientQueryApi {
  create = 'clientquery/create',
  list = 'clientquery/list',
  byId = 'clientquery/',
  delete = 'clientquery/delete/',
  softDelete = 'clientquery/softDelete/',
  update = 'clientquery/update/',
}

//newly Created
export enum teamApi {
  create = 'team/create',
  list = 'team/list',
  byId = 'team/',
  delete = 'team/delete/',
  softDelete = 'team/softDelete/',
  update = 'team/update/',
}

//newly Created
export enum siteCredsApi {
  create = 'siteCreds/create',
  list = 'siteCreds/list',
  byId = 'siteCreds/',
  delete = 'siteCreds/delete/',
  softDelete = 'siteCreds/softDelete/',
  update = 'siteCreds/update/',
}

//newly Created
export enum taskApi {
  create = 'task/create',
  addBulk = 'task/addBulk',
  list = 'task/list',
  byId = 'task/',
  delete = 'task/delete/',
  softDelete = 'task/softDelete/',
  update = 'task/update/',
  count = 'task/count',
}

//newly Created
export enum statusApi {
  create = 'status/create',
  addBulk = 'status/addBulk',
  list = 'status/list',
  byId = 'status/',
  delete = 'status/delete/',
  softDelete = 'status/softDelete/',
  update = 'status/update/',
  updateBulk = 'status/updateBulk/',
  partialUpdate = 'status/partial-update/',
}

//newly Created
export enum teamMemberApi {
  create = 'teammember/create',
  addBulk = 'teammember/addBulk',
  list = 'teammember/list',
  byId = 'teammember/',
  delete = 'teammember/delete/',
  softDelete = 'teammember/softDelete/',
  update = 'teammember/update/',
}
