<div
  class="flex-lg-1 h-screen-header overflow-y-lg-auto task-overview-wrapper bg-surface-secondary"
>
  <header>
    <div class="container-fluid">
      <div class="pt-6 pb-3 border-bottom cmn-header-box">
        <div class="row align-item-center">
          <div class="col-sm col-12">
            <h2>👋 Task Overview</h2>
          </div>
          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <page-button
                [config]="{ rank: 'primary' }"
                [icon]="'bi bi-plus-square-dotted'"
                [loading]="false"
                (clicked)="openAddEditSideNav()"
                >Add</page-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <main class="pb-6">
    <div class="container-fluid vstack gap-6">
      <div class="d-flex flex-column flex-md-row gap-3 justify-content-between">
        <div class="btn-group order-2 order-md-1"></div>
        <div class="d-flex gap-3 order-1 order-md-2"></div>
      </div>
      <div class="card">
        <div class="card-header d-flex align-items-end">
          <!-- <h5 class="mb-0">Work Performance</h5> -->
          <button
            class="btn btn-sm btn-neutral ms-0"
            routerLink="../task-listview"
          >
            Project wise Task status
          </button>
          <button
            class="btn btn-sm btn-neutral p-1 ms-2"
            routerLink="../task-listview-details"
          >
            <i class="bi bi-view-list"></i>
          </button>
          <button class="btn btn-sm btn-neutral p-1 ms-2">
            <i class="bi bi-columns-gap"></i>
          </button>
        </div>
      </div>
      <div class="row g-6">
        <div class="col-xl-4 col-sm-6 col-12">
          <form [formGroup]="FinishTaskForm">
            <div class="cmn-form-field col-12 col-sm-5">
              <mat-form-field appearance="outline">
                <mat-label>Finish Task</mat-label>
                <mat-select
                  placeholder="Finish task status"
                  formControlName="statusId"
                >
                  <mat-option value="all"> All </mat-option>
                  <mat-option *ngFor="let sts of allStatus" [value]="sts[0]">
                    <span>{{ sts[1] }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </form>
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div><h5>Finished Tasks</h5></div>
                <div class="d-flex align-items-center gap-3">
                  <a class="text-muted text-opacity-60 text-opacity-100-hover"
                    ><i class="bi bi-arrow-clockwise"></i>
                  </a>
                </div>
              </div>
              <div class="d-block text-sm mt-1">
                <span> in the </span>
                <a class="link-primary font-semibold">Clever Project</a>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center">
                    <div>
                      <span class="h3 d-block mb-0">{{ finishedTask }}</span>
                    </div>
                    <div class="d-flex align-items-center ms-3 mt-n1">
                      <span
                        class="badge badge-xs badge-pill bg-soft-success text-success"
                        ><i class="bi bi-arrow-up"></i> 20%</span
                      >
                    </div>
                  </div>
                  <span class="d-block text-sm text-muted"
                    >since last month</span
                  >
                </div>
                <div class="col-auto">
                  <div class="w-32"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 col-12">
          <form [formGroup]="onGoingTaskForm">
            <div class="cmn-form-field col-12 col-sm-5">
              <mat-form-field appearance="outline">
                <mat-label>OnGoing Tasks</mat-label>
                <mat-select
                  placeholder="OnGoing task status"
                  formControlName="statusId"
                >
                  <mat-option value="all"> All </mat-option>
                  <mat-option *ngFor="let sts of allStatus" [value]="sts[0]">
                    <span>{{ sts[1] }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </form>
          <div class="card">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div><h5>Ongoing Tasks</h5></div>
                <div class="d-flex align-items-center gap-3">
                  <a class="text-muted text-opacity-60 text-opacity-100-hover"
                    ><i class="bi bi-arrow-clockwise"></i>
                  </a>
                </div>
              </div>
              <div class="d-block text-sm mt-1">
                <span> in the </span>
                <a class="link-tertiary font-semibold">Clever Project</a>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center">
                    <div>
                      <span class="h3 d-block mb-0">{{ onGoingTask }}</span>
                    </div>
                    <div class="d-flex align-items-center ms-3 mt-n1">
                      <span
                        class="badge badge-xs badge-pill bg-soft-success text-success"
                        ><i class="bi bi-arrow-up"></i> 43%</span
                      >
                    </div>
                  </div>
                  <span class="d-block text-sm text-muted"
                    >since last month</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row g-6">
        <div class="col-xl-4">
          <div class="card">
            <div class="card-header">
              <div class="d-flex align-items-center">
                <h5 class="mb-0">Recent Activity</h5>
              </div>
            </div>
            <div
              class="card-body py-0 position-relative scrollable-y"
              style="max-height: 380px"
            >
              <div class="list-group list-group-flush">
                <div
                  class="list-group-item px-0 position-relative hstack flex-wrap"
                >
                  <div class="me-3 text-white">
                    <div class="position-relative d-inline-block text-current">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                        />
                      </div>
                      <span
                        class="position-absolute bottom-2 end-2 transform translate-x-1/2 translate-y-1/2 border-2 border-solid border-current w-3 h-3 bg-success rounded-circle"
                      ></span>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="d-flex align-items-center mb-1">
                      <a class="d-block h6 stretched-link">Robert Fox</a>
                      <span class="text-muted text-xs ms-2">1 hr ago</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <div
                        class="w-3/4 text-sm text-muted line-clamp-1 me-auto"
                      >
                        Some text related to the activity
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="list-group-item px-0 position-relative hstack flex-wrap"
                >
                  <div class="me-3 text-white">
                    <div class="position-relative d-inline-block text-current">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                        />
                      </div>
                      <span
                        class="position-absolute bottom-2 end-2 transform translate-x-1/2 translate-y-1/2 border-2 border-solid border-current w-3 h-3 bg-success rounded-circle"
                      ></span>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="d-flex align-items-center mb-1">
                      <a class="d-block h6 stretched-link">Robert Fox</a>
                      <span class="text-muted text-xs ms-2">1 hr ago</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <div
                        class="w-3/4 text-sm text-muted line-clamp-1 me-auto"
                      >
                        Some text related to the activity
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="list-group-item px-0 position-relative hstack flex-wrap"
                >
                  <div class="me-3 text-white">
                    <div class="position-relative d-inline-block text-current">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        />
                      </div>
                      <span
                        class="position-absolute bottom-2 end-2 transform translate-x-1/2 translate-y-1/2 border-2 border-solid border-current w-3 h-3 bg-success rounded-circle"
                      ></span>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="d-flex align-items-center mb-1">
                      <a class="d-block h6 stretched-link">Robert Fox</a>
                      <span class="text-muted text-xs ms-2">1 hr ago</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <div
                        class="w-3/4 text-sm text-muted line-clamp-1 me-auto"
                      >
                        Some text related to the activity
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="list-group-item px-0 position-relative hstack flex-wrap"
                >
                  <div class="me-3 text-white">
                    <div class="position-relative d-inline-block text-current">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                        />
                      </div>
                      <span
                        class="position-absolute bottom-2 end-2 transform translate-x-1/2 translate-y-1/2 border-2 border-solid border-current w-3 h-3 bg-success rounded-circle"
                      ></span>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="d-flex align-items-center mb-1">
                      <a class="d-block h6 stretched-link">Robert Fox</a>
                      <span class="text-muted text-xs ms-2">1 hr ago</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <div
                        class="w-3/4 text-sm text-muted line-clamp-1 me-auto"
                      >
                        Some text related to the activity
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="list-group-item px-0 position-relative hstack flex-wrap"
                >
                  <div class="me-3 text-white">
                    <div class="position-relative d-inline-block text-current">
                      <div class="avatar rounded-circle">
                        <img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                        />
                      </div>
                      <span
                        class="position-absolute bottom-2 end-2 transform translate-x-1/2 translate-y-1/2 border-2 border-solid border-current w-3 h-3 bg-success rounded-circle"
                      ></span>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="d-flex align-items-center mb-1">
                      <a class="d-block h6 stretched-link">Robert Fox</a>
                      <span class="text-muted text-xs ms-2">1 hr ago</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <div
                        class="w-3/4 text-sm text-muted line-clamp-1 me-auto"
                      >
                        Some text related to the activity
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row g-6">
        <div class="col-xl-8">
          <div class="card">
            <div class="card-header mb-5">
              <div class="d-flex justify-content-between align-items-center">
                <div><h5 class="mb-0">Latest Tasks</h5></div>
                <div class="text-end mx-n1 d-flex align-items-center">
                  <a class="px-2 text-muted"
                    ><i class="bi bi-arrow-clockwise"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-body pt-0 position-relative scrollable-y"
              style="max-height: 380px"
            >
              <div *ngIf="taskName.length > 0">
                <div *ngFor="let task of taskName">
                  <a class="h5 d-inline-block font-semibold mb-2">
                    {{ task.name }}
                  </a>
                  <p class="text-sm text-muted mb-4">
                    {{ task.desc }}
                  </p>
                  <!-- <span class="surtitle text-muted d-block">Progress</span>
                  <div class="d-flex align-items-center">
                    <div style="width: 80%">
                      <div
                        class="c-progressbar p-bg-primary"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a class="h6 text-sm">65%</a>
                    </div>
                  </div> -->
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <!-- <i class="bi bi-files-alt text-muted"></i> -->
                          <a
                            class="text-muted text-primary-hover stretched-link"
                          >
                            <span
                              class="{{ taskTypeColorClassEnum[+task.type] }}"
                              >{{ taskTypeEnum[+task.type] | uppercase }}
                            </span></a
                          >
                        </div>
                        <!-- <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a
                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div> -->
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a class="">{{ task.assignTo }} </a>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div *ngIf="taskName.length === 0">No tasks found.</div>

              <!-- <hr class="my-5" />
              <div>
                <a class="h5 d-inline-block font-semibold mb-2"
                  >Create the new landing sections</a
                >
                <p class="text-sm text-muted mb-4">
                  I need 5 examples of the logo
                </p>
                <span class="surtitle text-muted d-block">Progress</span>
                <div class="d-flex align-items-center">
                  <div style="width: 80%">
                    <div
                      class="c-progressbar p-bg-tertiary"
                      style="--h-progress: 0.5rem"
                    >
                      <mat-progress-bar
                        mode="determinate"
                        value="40"
                        class="progress-sm shadow-none"
                      ></mat-progress-bar>
                    </div>
                  </div>
                  <div class="col px-0 text-end">
                    <a class="h6 text-sm">65%</a>
                  </div>
                </div>
                <div class="d-flex align-items-center mt-3">
                  <div>
                    <div class="hstack gap-4 justify-content-end">
                      <div class="position-relative text-sm">
                        <i class="bi bi-files-alt text-muted"></i>
                        <a class="text-muted text-primary-hover stretched-link"
                          >18</a
                        >
                      </div>
                      <div class="position-relative text-sm">
                        <i class="bi bi-people-fill text-muted"></i>
                        <a class="text-muted text-primary-hover stretched-link"
                          >7</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="ms-auto">
                    <div class="avatar-group">
                      <a
                        class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                        ><img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                        /> </a
                      ><a
                        class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                        ><img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                        /> </a
                      ><a
                        class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                        ><img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-5" />
              <div>
                <a class="h5 d-inline-block font-semibold mb-2"
                  >Design a new icon set</a
                >
                <p class="text-sm text-muted mb-4">
                  I need 5 examples of the logo
                </p>
                <span class="surtitle text-muted d-block">Progress</span>
                <div class="d-flex align-items-center">
                  <div style="width: 80%">
                    <div
                      class="c-progressbar p-bg-success"
                      style="--h-progress: 0.5rem"
                    >
                      <mat-progress-bar
                        mode="determinate"
                        value="40"
                        class="progress-sm shadow-none"
                      ></mat-progress-bar>
                    </div>
                  </div>
                  <div class="col px-0 text-end">
                    <a class="h6 text-sm">65%</a>
                  </div>
                </div>
                <div class="d-flex align-items-center mt-3">
                  <div>
                    <div class="hstack gap-4 justify-content-end">
                      <div class="position-relative text-sm">
                        <i class="bi bi-files-alt text-muted"></i>
                        <a class="text-muted text-primary-hover stretched-link"
                          >18</a
                        >
                      </div>
                      <div class="position-relative text-sm">
                        <i class="bi bi-people-fill text-muted"></i>
                        <a class="text-muted text-primary-hover stretched-link"
                          >7</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="ms-auto">
                    <div class="avatar-group">
                      <a
                        class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                        ><img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                        /> </a
                      ><a
                        class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                        ><img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                        /> </a
                      ><a
                        class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                        ><img
                          alt="..."
                          src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                      /></a>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
