import { LocalStorageService } from './../../core/services/local-storage.service';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiResponse } from './../../core/model/api-response';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/services/toast.service';
import { RoleAccessService } from 'src/app/core/services/role-access.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() openSidebarEvent = new EventEmitter<any>();
  user: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private roleAccessService: RoleAccessService
  ) {}

  ngOnInit(): void {
    this.user = this.localStorageService.getUser().name;
  }

  openSidebar() {
    this.openSidebarEvent.emit();
  }

  logOut() {
    this.authService.logout().subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          localStorage.clear();
          this.roleAccessService.roleAccessDataFlush({});
          this.router.navigate(['/authentication/login']);
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }
}
