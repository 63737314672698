import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostListComponent } from './post/post-list/post-list.component';
import { SiteCredsListComponent } from './site-creds/site-creds-list/site-creds-list.component';
import { RoleGuard } from 'src/app/core/guard/role.guars';

const routes: Routes = [
  { path: 'post', component: PostListComponent, canActivate: [RoleGuard] },
  {
    path: 'site-creds',
    component: SiteCredsListComponent,
    canActivate: [RoleGuard],
  },
  { path: '', redirectTo: 'post', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CreatorRoutingModule {}
