import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ApiStatus, leaves, RolesEnum } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { organizationApi, userApi } from 'src/app/core/model/api-url';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';

@Component({
  selector: 'app-add-edit-organization',
  templateUrl: './add-edit-organization.component.html',
  styleUrls: ['./add-edit-organization.component.scss'],
})
export class AddEditOrganizationComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  userList: User[] = [];
  organizationForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    userId: {
      required: 'User ID is required',
      pattern: 'User ID must be a numeric value',
    },
    name: {
      required: 'Organization Name is required',
    },
  };

  isEdit: boolean = false;
  organizationId: number;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  leaveOptions = [
    { text: 'applied', value: leaves.applied },
    { text: 'accepted', value: leaves.accepted },
    { text: 'rejected', value: leaves.rejected },
  ];
  userId: number;

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.organizationId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createOrganizationForm();
    this.getUserList();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createOrganizationForm() {
    this.organizationForm = this.fb.group({
      userId: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      name: ['', [Validators.required]],
      desc: [''],
    });
  }

  getUserList() {
    let roleUserUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;

            const currentUser = this.localStorageService.getUser();
            if (currentUser.role === RolesEnum.Client) {
              this.userList.push(currentUser);
            }
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onSubmit() {
    this.organizationForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.organizationForm.controls,
      this.validationMessages
    );

    if (this.organizationForm.valid) {
      let organizationApiUrl = this.isEdit
        ? organizationApi.update
        : organizationApi.create;
      let model = this.organizationForm.value;
      // model.userId = +this.userId;
      if (this.isEdit) {
        model['id'] = +this.organizationId;
      }

      this.cmnApiCallService.addUpdateData(organizationApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `Organization created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'Organization has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'OrganizationListComponent'
            );
            this.organizationForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let leaveGetByIdUrl = organizationApi.byId;
    this.cmnApiCallService
      .getById(leaveGetByIdUrl, this.organizationId)
      .subscribe(
        (res: ApiResponse) => {
          if (res.status == ApiStatus.SUCCESS) {
            if (res && res.data) {
              this.organizationForm.patchValue({
                userId: res.data.userId,
                name: res.data.name,
                desc: res.data.desc,
              });
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
