<div
  class="h-screen-header flex-grow-1 d-flex flex-column flex-1 overflow-y-lg-auto task-boardview-wrapper bg-surface-secondary"
>
  <header>
    <div class="container-fluid">
      <div class="border-bottom pt-6 pb-3 cmn-header-box">
        <div class="row align-items-center">
          <!-- <div class="col-sm col-12">
            <h1 class="h2 ls-tight">Launch Clever dashboard theme</h1>
          </div> -->

          <div class="col-12 col-sm">
            <div class="cmn-search-box cmn-form-field">
              <mat-form-field subscriptSizing="dynamic">
                <mat-select
                  class="fs-3 font-bold"
                  placeholder="Project"
                  [(value)]="projectId"
                  (selectionChange)="getStatusByProjectId($event.value)"
                >
                  <mat-option
                    *ngFor="let project of projectList"
                    [value]="project.id"
                    >{{ project.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <a
                href="#modalExport"
                class="btn btn-sm btn-neutral border-base"
                data-bs-toggle="modal"
                ><span class="pe-2"><i class="bi bi-people-fill"></i> </span
                ><span>Share</span>
              </a>
              <a class="btn btn-sm btn-primary" (click)="openAddEditSideNav()"
                ><span class="pe-2"
                  ><i class="bi bi-plus-square-dotted"></i> </span
                ><span>Create</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <main class="flex-grow-1 py-6 overflow-auto">
    <div class="container-fluid h-full">
      <div class="d-flex h-full mx-n3" cdkDropListGroup>
        <div
          class="flex-shrink-0 flex-xl-fill d-flex flex-column min-card-width h-full"
          *ngFor="let ts of taskBoard; let i = index"
        >
          <div class="px-3 mb-6">
            <span class="badge badge-lg badge-dot text-heading font-semibold">
              <i class="bg-secondary"> </i>
              {{ ts[0].name }}</span
            >
          </div>
          <div
            class="flex-1 overflow-y-auto"
            id="{{ ts[0].id }}"
            cdkDropList
            cdkDropListSortingDisabled
            [cdkDropListData]="ts[1]"
            (cdkDropListDropped)="drop($event, i + 1)"
          >
            <div
              class="px-3 mb-3"
              *ngFor="let task of ts[1]"
              cdkDragLockAxis="x"
              cdkDrag
              id="{{ task.id }}"
              [ariaLabel]="[i + 1]"
            >
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div
                      class="d-flex justify-content-between align-item-center w-100"
                    >
                      <span class="fs-7"> T.No: {{ task.id }} </span>
                      <span class="d-block text-muted font-regular fs-8 mb-0">
                        Updated
                        {{ dateAgo(task.updatedAt) }}</span
                      >
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <a
                        (click)="openAddEditSideNav(task.id)"
                        class="h6 font-semibold fs-7 m-0 p-0"
                        >{{ task.name }}</a
                      >
                    </div>

                    <div>
                      <button
                        mat-icon-button
                        class="cus-round-btn"
                        color="warn"
                        (click)="deleteTask(task.id)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="fs-8">
                    {{
                      task.assignTo ? task.assignTo : "Unassigned"
                    }}(TODO:-Replace with name)
                  </div>
                  <div class="d-flex justify-content-between mt-3">
                    <div>
                      <span class="{{ taskTypeColorClassEnum[+task.type] }}"
                        >{{ taskTypeEnum[+task.type] | uppercase }}
                      </span>
                    </div>
                    <div>
                      <span
                        class="{{ taskPriorityColorClassEnum[+task.priority] }}"
                        >{{ taskPriorityEnum[+task.priority] | uppercase }}
                      </span>
                      <span class="fs-7">
                        {{ timeFormate(task.dueDate) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <!-- <main class="flex-grow-1 py-6 bg-surface-secondary overflow-auto">
    <div
      class="offcanvas offcanvas-end w-full w-lg-1/3"
      data-bs-scroll="true"
      data-bs-backdrop="true"
      tabindex="-1"
      id="offcanvasCreate"
      aria-labelledby="offcanvasCreateLabel"
    >
      <div class="offcanvas-header border-bottom py-5 bg-surface-secondary">
        <h5 class="offcanvas-title" id="offcanvasCreateLabel">
          Create a new task
        </h5>
        <button
          type="button"
          class="btn-close text-reset text-xs"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="vstack gap-8">
          <input
            type="text"
            class="form-control form-control-flush text-lg font-bold"
            placeholder="Enter your task name ..."
            value="Click here and start typing 😎"
          />
          <div class="border rounded">
            <div>
              <div class="textarea-autosize">
                <textarea
                  class="form-control border-0 shadow-none p-4"
                  rows="3"
                  placeholder="Enter description"
                  oninput="this.parentNode.dataset.replicatedValue = this.value"
                ></textarea>
              </div>
              <div class="d-flex align-items-center px-6 py-3 border-top">
                <div class="flex-fill d-flex align-items-center">
                  <h6 class="font-semibold text-xs text-muted text-opacity-60">
                    Markdown formatting
                  </h6>
                </div>
                <div class="text-end">
                  <div class="hstack gap-5 align-items-center">
                    <a href="#!" class="text-lg text-muted text-primary-hover"
                      ><i class="bi bi-images"></i> </a
                    ><a href="#!" class="text-lg text-muted text-primary-hover"
                      ><i class="bi bi-emoji-smile"></i> </a
                    ><a href="#!" class="text-lg text-muted text-primary-hover"
                      ><i class="bi bi-paperclip"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 class="mb-4">Subtasks</h6>
            <div class="vstack gap-3">
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                    checked="checked"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Keep my mentality healthy by taking walks outside</a
                  >
                </div>
              </div>
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Build some new components in Figma</a
                  >
                </div>
              </div>
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Figure out how to use Clever from the help center!</a
                  >
                </div>
              </div>
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Create wireframes for the new dashboard</a
                  >
                </div>
              </div>
            </div>
            <div>
              <input
                type="text"
                class="form-control form-control-sm mt-4 mb-3"
                placeholder="Add another subtask"
              />
              <div class="d-flex gap-3">
                <button
                  type="button"
                  class="btn btn-link p-0 text-muted text-danger-hover text-sm font-semibold"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 link-success text-sm font-semibold"
                >
                  Save task
                </button>
              </div>
            </div>
          </div>
          <hr class="m-0" />
          <div>
            <h6 class="mb-3">Attachments</h6>
            <div>
              <div
                class="rounded border-2 border-dashed border-primary-hover position-relative"
              >
                <div class="d-flex justify-content-center px-5 py-5">
                  <label
                    for="file_upload"
                    class="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                    ><input
                      id="file_upload"
                      name="file_upload"
                      type="file"
                      class="visually-hidden"
                  /></label>
                  <div class="text-center">
                    <div class="text-2xl text-muted">
                      <i class="bi bi-upload"></i>
                    </div>
                    <div class="d-flex text-sm mt-3">
                      <p class="font-semibold">
                        Upload a file or drag and drop
                      </p>
                    </div>
                    <p class="text-xs text-gray-500">PNG, JPG, GIF up to 3MB</p>
                  </div>
                </div>
              </div>
              <div class="list-group list-group-flush mt-2">
                <div class="list-group-item py-3 d-flex align-items-center">
                  <div class="flex-fill">
                    <span class="d-block h6 text-sm font-semibold mb-1"
                      >task-img-1.png</span
                    ><span class="d-block text-xs text-muted">350 kb</span>
                  </div>
                  <div class="ms-auto">
                    <div class="dropdown">
                      <a
                        class="text-muted"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ><i class="bi bi-three-dots-vertical"></i
                      ></a>
                      <div class="dropdown-menu">
                        <a href="#!" class="dropdown-item">Action </a
                        ><a href="#!" class="dropdown-item">Another action </a
                        ><a href="#!" class="dropdown-item"
                          >Something else here</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item py-3 d-flex align-items-center">
                  <div class="flex-fill">
                    <span class="d-block h6 text-sm font-semibold mb-1"
                      >task-img-1.png</span
                    ><span class="d-block text-xs text-muted">350 kb</span>
                  </div>
                  <div class="ms-auto">
                    <div class="dropdown">
                      <a
                        class="text-muted"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ><i class="bi bi-three-dots-vertical"></i
                      ></a>
                      <div class="dropdown-menu">
                        <a href="#!" class="dropdown-item">Action </a
                        ><a href="#!" class="dropdown-item">Another action </a
                        ><a href="#!" class="dropdown-item"
                          >Something else here</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item py-3 d-flex align-items-center">
                  <div class="flex-fill">
                    <span class="d-block h6 text-sm font-semibold mb-1"
                      >task-img-1.png</span
                    ><span class="d-block text-xs text-muted">350 kb</span>
                  </div>
                  <div class="ms-auto">
                    <div class="dropdown">
                      <a
                        class="text-muted"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ><i class="bi bi-three-dots-vertical"></i
                      ></a>
                      <div class="dropdown-menu">
                        <a href="#!" class="dropdown-item">Action </a
                        ><a href="#!" class="dropdown-item">Another action </a
                        ><a href="#!" class="dropdown-item"
                          >Something else here</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer py-2 bg-surface-secondary">
        <button
          type="button"
          class="btn btn-sm btn-neutral"
          data-bs-dismiss="offcanvas"
        >
          Close
        </button>
        <button type="button" class="btn btn-sm btn-primary">Save</button>
      </div>
    </div>
    <div class="container-fluid h-full">
      <div class="d-flex h-full mx-n3">
        <div class="flex-shrink-0 flex-xl-fill d-flex flex-column w-80 h-full">
          <div class="px-3 mb-6">
            <span class="badge badge-lg badge-dot text-heading font-semibold"
              ><i class="bg-secondary"></i>New tasks</span
            >
          </div>
          <div class="flex-1 overflow-y-auto">
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Get another day full of work done!</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-success"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">74%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Keep my mentality healthy by taking walks outside</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-warning"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">38%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 flex-xl-fill d-flex flex-column w-80 h-full">
          <div class="px-3 mb-6">
            <span class="badge badge-lg badge-dot text-heading font-semibold"
              ><i class="bg-primary"></i>Do today</span
            >
          </div>
          <div class="flex-1 overflow-y-auto">
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Figure out how to use Clever from the help center! </a
                  ><span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-primary"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">10%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Build some new components in Figma</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-success"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">83%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Create wireframes for the new dashboard</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-tertiary"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">4%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 flex-xl-fill d-flex flex-column w-80 h-full">
          <div class="px-3 mb-6">
            <span class="badge badge-lg badge-dot text-heading font-semibold"
              ><i class="bg-tertiary"></i>In progress</span
            >
          </div>
          <div class="flex-1 overflow-y-auto">
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Figure out how to use Clever from the help center! </a
                  ><span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-primary"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">10%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Build some new components in Figma</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-success"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">83%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Create wireframes for the new dashboard</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-tertiary"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">4%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Get another day full of work done!</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-success"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">74%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Keep my mentality healthy by taking walks outside</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-warning"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">38%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 flex-xl-fill d-flex flex-column w-80 h-full">
          <div class="px-3 mb-6">
            <span class="badge badge-lg badge-dot text-heading font-semibold"
              ><i class="bg-warning"></i>For later</span
            >
          </div>
          <div class="flex-1 overflow-y-auto">
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Figure out how to use Clever from the help center! </a
                  ><span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-primary"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">10%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Build some new components in Figma</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-success"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">83%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Create wireframes for the new dashboard</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-danger"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">4%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Get another day full of work done!</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-success"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">74%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div
                    class="d-flex justify-content-between align-items-center mb-5"
                  >
                    <div>
                      <span class="d-block h6 text-muted font-regular mb-0"
                        >Updated 3hrs ago</span
                      >
                    </div>
                    <div class="d-flex align-items-center gap-3">
                      <a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-arrow-clockwise"></i> </a
                      ><a

                        class="text-muted text-opacity-60 text-opacity-100-hover"
                        ><i class="bi bi-three-dots"></i
                      ></a>
                    </div>
                  </div>
                  <a
                    href="#offcanvasCreate"
                    class="h5 font-semibold"
                    data-bs-toggle="offcanvas"
                    >Keep my mentality healthy by taking walks outside</a
                  >
                  <span
                    class="text-xs text-uppercase text-muted d-block ls-wide font-semibold mt-5 mb-n1"
                    >Progress</span
                  >
                  <div class="d-flex align-items-center">
                    <div style="width: 85%">
                      <div
                        class="c-progressbar p-bg-warning"
                        style="--h-progress: 0.5rem"
                      >
                        <mat-progress-bar
                          mode="determinate"
                          value="40"
                          class="progress-sm shadow-none"
                        ></mat-progress-bar>
                      </div>
                    </div>
                    <div class="col px-0 text-end">
                      <a  class="text-heading text-sm">38%</a>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <div>
                      <div class="hstack gap-4 justify-content-end">
                        <div class="position-relative text-sm">
                          <i class="bi bi-files-alt text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >18</a
                          >
                        </div>
                        <div class="position-relative text-sm">
                          <i class="bi bi-people-fill text-muted"></i>
                          <a

                            class="text-muted text-primary-hover stretched-link"
                            >7</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="ms-auto">
                      <div class="avatar-group">
                        <a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-2.jpg"
                          /> </a
                        ><a

                          class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main> -->
</div>
