<div class="cmn-dialog-box">
  <div mat-dialog-content>
    <div class="modal-content shadow-32">
      <div class="modal-header">
        <div
          class="icon icon-shape rounded-3 bg-soft-primary text-primary text-lg me-4 d-none d-sm-block"
        >
          <i class="bi bi-globe"></i>
        </div>
        <div>
          <h5 class="mb-1">Share to web</h5>
          <small class="d-block text-xs text-muted"
            >Publish and share link with anyone</small
          >
        </div>
        <div class="ms-auto">
          <div class="form-check form-switch me-n2">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              checked="checked"
            />
            <label
              class="form-check-label"
              for="flexSwitchCheckChecked"
            ></label>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center mb-5 flex-wrap">
          <div>
            <p class="text-sm">
              Anyone with this link
              <span class="font-bold text-heading">can view</span>
            </p>
          </div>
          <div class="ms-auto">
            <a class="text-sm font-semibold">Settings</a>
          </div>
        </div>
        <div>
          <div class="input-group input-group-inline">
            <input
              type="email"
              class="form-control"
              placeholder="username"
              value="https://webpixels.io/your-amazing-link"
            />
            <span class="input-group-text"
              ><i class="bi bi-clipboard"></i
            ></span>
          </div>
          <span class="mt-2 valid-feedback">Looks good!</span>
        </div>
      </div>
      <div class="modal-footer">
        <div class="me-auto">
          <a class="text-sm font-semibold"
            ><i class="bi bi-clipboard me-2"></i>Copy link</a
          >
        </div>
        <div mat-dialog-actions>
          <button mat-dialog-close class="btn btn-sm btn-neutral">Close</button>
        </div>
        <button type="button" class="btn btn-sm btn-success">Share file</button>
      </div>
    </div>
  </div>
</div>
