import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageButtonComponent } from './page-button/page-button.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { FormValidationService } from '../core/services/form-validation.service';
import { NumbersOnlyDirective } from '../core/directives/numbers-only.directive';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DataValidationPipe } from '../core/pipes/data-validation.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { RoleFilterPipe } from '../core/pipes/role-filter.pipe';

const COMPONENTS = [
  PageButtonComponent,
  NumbersOnlyDirective,
  DataValidationPipe,
  ConfirmationDialogComponent,
  ImageUploadComponent,
  RoleFilterPipe,
];
const MODULES = [
  CommonModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatCheckboxModule,
  ReactiveFormsModule,
  MatSortModule,
  FormsModule,
  MatIconModule,
  MatButtonModule,
  ToastrModule,
  MatDialogModule,
  CKEditorModule,
  MatTooltipModule,
  MatRadioModule,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ...MODULES,
    ToastrModule.forRoot({
      timeOut: 5000,
      autoDismiss: true,
      resetTimeoutOnDuplicate: true,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  exports: [...COMPONENTS, ...MODULES],
  providers: [FormValidationService, DatePipe],
})
export class SharedModule {}
