import { ApiQuery, Paginator } from './../../../core/model/api-response';
import { LocalStorageService } from './../../../core/services/local-storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiResponse } from 'src/app/core/model/api-response';
import { roleApi, rollAccessApi } from 'src/app/core/model/api-url';
import { userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss'],
})
export class UserAccessComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: any[] = [
    'title',
    'all',
    'read',
    'write',
    'update',
    'delete',
    // 'action',
  ];
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userList = [];
  roleList: any = [];
  selectedUserId: number;
  dataSource = new MatTableDataSource();

  constructor(
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getRoleList();
    // this.getRoleAccessList();
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'userAccessComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('userAccessComponent');
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getUserAccessById(this.selectedUserId);
  }

  getRoleAccessList() {
    let rollAccessListApiUrl = rollAccessApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService
      .getList(rollAccessListApiUrl, this.apiQuery)
      .subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (res && res.data) {
              res.data.data.forEach((el) => {
                if (el.read && el.write && el.update && el.delete) {
                  el['all'] = true;
                } else {
                  el['all'] = false;
                }
              });
              this.dataSource = new MatTableDataSource(res.data.data);
              this.paginatorData = res.data.paginator;
              this.dataSource.sort = this.sort;
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }

  getUserAccessById(roleId) {
    if (roleId) {
      let rollAccessUrl = rollAccessApi.list;
      this.apiQuery.query = {
        isDeleted: false,
        roleId: roleId,
      };
      this.cmnApiCallService
        .getList(rollAccessUrl, this.apiQuery)
        .subscribe((res: ApiResponse) => {
          if (res && res.data) {
            res.data.data.forEach((el) => {
              if (el.read && el.write && el.update && el.delete) {
                el['all'] = true;
              } else {
                el['all'] = false;
              }
            });
            this.dataSource = new MatTableDataSource(res.data.data);
            this.paginatorData = res.data.paginator;
            this.dataSource.sort = this.sort;
          }
        });
    }
  }

  getRoleList() {
    let roleListUrl = roleApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService
      .getList(roleListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.roleList = res.data.data;
            this.selectedUserId = res.data.data[0].id;
            this.getUserAccessById(this.selectedUserId);
          }
        }
      });
  }

  deleteRow(row, i?) {
    console.log(row);
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  toggleRow(row: any): void {
    if (row) {
      if (!row.read || !row.write || !row.update || !row.delete) {
        row.all = false;
      } else if (row.read && row.write && row.update && row.delete) {
        row.all = true;
      }
    }

    let rollAccessApiUrl = rollAccessApi.update;
    let model = {
      read: row.read,
      write: row.write,
      update: row.update,
      delete: row.delete,
      roleId: this.selectedUserId,
      id: row.id,
    };
    this.cmnApiCallService.addUpdateData(rollAccessApiUrl, model).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.toastService.successMsg('Success', 'Role Updated Successfully!');
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  toggleAll(row: any): void {
    row.read = row.all;
    row.write = row.all;
    row.update = row.all;
    row.delete = row.all;
    this.toggleRow(row);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
