import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiResponse } from 'src/app/core/model/api-response';
import { portfolioApi } from 'src/app/core/model/api-url';
import { portFolioModel } from 'src/app/core/model/portfolio-mode';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { PortfolioComponent } from '../portfolio/portfolio.component';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-edit-portfolio',
  templateUrl: './add-edit-portfolio.component.html',
  styleUrls: ['./add-edit-portfolio.component.scss'],
})
export class AddEditPortfolioComponent {
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
  selectedImage: any; // Variable to hold the selected image preview
  portfolioForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    title: {
      required: 'Title is required',
    },
    teamSize: {
      min: 'Minimus teamsize shoul be 1.',
    },
  };
  isEdit: boolean = false;
  porfolioId: number;
  test: File;
  loadingImage: boolean = false;
  public editor = ClassicEditor;
  public editorData = '';
  private editorInstance: any;
  editorConfig = {
    placeholder: 'Add description...',
  };
  openingComponentSubscription: Subscription;
  userUploadedImage: string[] = [];
  public projectDescription = '';
  public projectApproach = '';
  public projectOverview = '';
  public projectResult = '';
  public projectNotes = '';
  private projectDescriptionInstance: any;
  private projectApproachInstance: any;
  private projectOverviewInstance: any;
  private projectResultInstance: any;
  private projectNotesInstance: any;

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private renderer: Renderer2
  ) {
    this.openingComponentSubscription =
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.porfolioId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
  }

  ngOnInit() {
    this.createPortfolioForm();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  // You can also close the add/edit sidenav if needed
  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createPortfolioForm() {
    this.portfolioForm = this.fb.group({
      title: ['', Validators.required],
      desc: [''],
      tech: [''],
      libsFramework: [''],
      devRole: [''],
      duration: [''],
      originCountry: [''],
      teamSize: [null, Validators.min(1)],
      links: [''],
      imageUrls: [''],
      projectShortDesc: [''],
      projectOverview: [''],
      projectResult: [''],
      projectNotes: [''],
      projectApproach: [''],
      clientName: [''],
      color: [''],
    });
  }

  onSubmit() {
    this.portfolioForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.portfolioForm.controls,
      this.validationMessages
    );

    if (this.portfolioForm.valid) {
      let portfolioApiUrl = this.isEdit
        ? portfolioApi.update
        : portfolioApi.create;
      let model: portFolioModel = this.portfolioForm.value;
      debugger;
      model.desc = this.editorInstance.getData();
      model.projectDesc = this.projectDescriptionInstance.getData();
      model.projectApproach = this.projectApproachInstance.getData();
      model.projectOverview = this.projectOverviewInstance.getData();
      model.projectResult = this.projectResultInstance.getData();
      model.projectNotes = this.projectNotesInstance.getData();
      if (this.isEdit) {
        model['id'] = +this.porfolioId;
      }

      this.cmnApiCallService.addUpdateData(portfolioApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `Portfolio created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                res.data[0].title
                  ? `Portfolio "${res.data[0].title}" has been successfully edited`
                  : 'Portfolio has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'PortfolioComponent'
            );
            this.portfolioForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let portfolioGetByIdUrl = portfolioApi.byId;
    this.cmnApiCallService
      .getById(portfolioGetByIdUrl, this.porfolioId)
      .subscribe(
        (res: ApiResponse) => {
          if (res.status == ApiStatus.SUCCESS) {
            if (res && res.data) {
              this.portfolioForm.patchValue({
                title: res.data.title,
                tech: res.data.tech,
                libsFramework: res.data.libsFramework,
                projectShortDesc: res.data.projectShortDesc,
                devRole: res.data.devRole,
                duration: res.data.duration,
                originCountry: res.data.originCountry,
                teamSize: res.data.teamSize,
                links: res.data.links,
                imageUrls: res.data.imageUrls,
                color: res.data.color,
              });
              this.editorData = res.data.desc;
              this.projectDescription = res.data.projectDesc;
              this.projectApproach = res.data.projectApproach;
              this.projectOverview = res.data.projectOverview;
              this.projectResult = res.data.projectResult;
              this.projectNotes = res.data.projectNotes;
              this.selectedImage = environment.mainUrl + res.data.imageUrls;
              if (res.data.imageUrls !== '') {
                this.userUploadedImage = res.data.imageUrls.split(',');
              }
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }

  onFileSelected(event: Event) {
    this.loadingImage = true;
    const fileInput = event.target as HTMLInputElement;
    const selectedFile = fileInput.files?.[0];

    if (selectedFile) {
      const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
      if (allowedFormats.includes(selectedFile.type)) {
        this.cmnApiCallService.uploadImage(selectedFile).subscribe(
          (response: ApiResponse) => {
            if (response.status === ApiStatus.SUCCESS) {
              const path: string = response.data.uploadSuccess[0].path;
              this.portfolioForm.controls['imageUrls'].patchValue(path);
              this.selectedImage = environment.mainUrl + path;
            }
            if (response.status === ApiStatus.FAILURE) {
              this.toastService.uploadFailure(
                response.data.uploadFailed[0].error
              );
            }
            this.loadingImage = false;
          },
          (error) => {
            console.error('Error uploading image', error);
            this.toastService.errorMsg(error);
            this.loadingImage = false;
          }
        );
      } else {
        this.toastService.uploadFailure(
          'Invalid file format. Please upload a jpg, jpeg, or png file.'
        );
        this.loadingImage = false;
      }
    }
  }

  onReady(editor: any) {
    this.editorInstance = editor;
  }

  onReadyProjectDesctiprion(editor: any) {
    this.projectDescriptionInstance = editor;
  }
  onReadyProjectApproach(editor: any) {
    this.projectApproachInstance = editor;
  }
  onReadyProjectOverview(editor: any) {
    this.projectOverviewInstance = editor;
  }
  onReadyProjectResult(editor: any) {
    this.projectResultInstance = editor;
  }
  onReadyProjectNotes(editor: any) {
    this.projectNotesInstance = editor;
  }

  imageEvent(event: any) {
    const result = event.join(',');
    this.portfolioForm.controls['imageUrls'].patchValue(result);
  }
  ngOnDestroy() {
    if (this.openingComponentSubscription) {
      this.openingComponentSubscription.unsubscribe();
    }
  }
}
