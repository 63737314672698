<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>👋 Teams</h2>
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <!-- <page-button [config]="{ rank: 'neutral' }" [icon]="'bi bi-people-fill'" [loading]="false"
                  (clicked)="openShareToWebDialog()">Share</page-button> -->
                <page-button
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  >Add</page-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="container-fluid mt-5">
      <div class="card">
        <main class="bg-surface-secondary">
          <!-- <app-share-to-web-popup></app-share-to-web-popup> -->
          <div class="cmn-table-wrapper table-container">
            <table mat-table matSort [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Team Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <a
                    mat-button
                    class="form-check-label font-regular ms-2"
                    routerLink="{{ 'team-members/' + element.id }}"
                  >
                    {{ element.name | dataValidation }}
                  </a>
                </td>
              </ng-container>
              <ng-container matColumnDef="userId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  User Name
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    {{ getUserNameById(element.userId) | dataValidation }}
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="projectId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Project Name
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ getProjectNameById(element.projectId) | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="desc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Description
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.desc | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="empty">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <div class="d-flex">
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      color="warn"
                      (click)="deleteTeam(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            [length]="paginatorData?.itemCount"
            [pageSize]="paginatorData?.perPage"
            [pageSizeOptions]="[10, 20, 50]"
            aria-label="Select page"
            (page)="pageChange($event)"
            class="mat-paginator-sticky"
            >,
          </mat-paginator>
        </main>
      </div>
    </div>
  </div>
</div>
