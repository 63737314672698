import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreatorRoutingModule } from './creator-routing.module';
import { AddEditPostComponent } from './post/add-edit-post/add-edit-post.component';
import { AddEditSiteCredsComponent } from './site-creds/add-edit-site-creds/add-edit-site-creds.component';
import { PostListComponent } from './post/post-list/post-list.component';
import { SiteCredsListComponent } from './site-creds/site-creds-list/site-creds-list.component';
import { SharedModule } from "../../shared/shared.module";


@NgModule({
    declarations: [
        AddEditPostComponent,
        AddEditSiteCredsComponent,
        PostListComponent,
        SiteCredsListComponent
    ],
    imports: [
        CommonModule,
        CreatorRoutingModule,
        SharedModule
    ],
    exports: [
      AddEditPostComponent,
      AddEditSiteCredsComponent,
    ],
})
export class CreatorModule { }
