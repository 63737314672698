<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">Add new team Members</h5>

    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="px-0 container-fluid">
      <div
        class="cus-checkbox"
        *ngFor="let user of userList | roleFilter : [1, 2, 4]"
      >
        <mat-checkbox
          id="{{ user.id }}"
          color="primary"
          [checked]="isChecked(user.id)"
          (change)="toggle($event)"
          [disabled]="isChecked(user.id)"
          [labelPosition]="'before'"
        >
          <!-- <span class="badge text-uppercase bg-soft-primary text-primary ">
            {{ user.role }}
          </span> -->
          <span
            class="badge text-uppercase bg-soft-primary text-primary fs-14 me-2"
          >
            {{ user.name.slice(0, 1) }}
          </span>
          {{ user.name }}
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >Add
    </page-button>
  </div>
</div>
