import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ApiStatus, RolesEnum } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import { teamMemberApi, userApi } from 'src/app/core/model/api-url';
import { teamMemberModel } from 'src/app/core/model/team-members.model';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-add-edit-team-members',
  templateUrl: './add-edit-team-members.component.html',
  styleUrl: './add-edit-team-members.component.scss',
})
export class AddEditTeamMembersComponent {
  destroy$ = new Subject();
  apiQuery: ApiQuery = new ApiQuery();
  userList;
  currTeamMembers: teamMemberModel[] = [];
  prevTeamMembers: teamMemberModel[] = [];
  private teamId: number;

  constructor(
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private cmnApiCallService: CmnApiCallService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.teamId = +res.id;
        }
      });
    }
  }

  ngOnInit() {
    this.getUserList();
    this.updateCheckBox();
  }

  updateCheckBox() {
    let teamMemberUrl = teamMemberApi.list;
    this.apiQuery.query = {
      isDeleted: false,
      teamId: this.teamId,
    };
    this.cmnApiCallService.getList(teamMemberUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.prevTeamMembers.push(...res.data.data);
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  isChecked(id) {
    return this.prevTeamMembers.find((teamMember) => teamMember.userId === id)
      ? true
      : false;
  }

  getUserList() {
    let userListUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService.getList(userListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;
            const currentUser = this.localStorageService.getUser();
            if (
              currentUser.role === RolesEnum.Developer ||
              currentUser.role === RolesEnum.Admin ||
              currentUser.role === RolesEnum.Creator
            ) {
              this.userList.push(currentUser);
            }
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  toggle($event) {
    if ($event.checked && this.teamId) {
      let teamMember: teamMemberModel = {
        teamId: this.teamId,
        userId: $event.source.id,
      };
      this.currTeamMembers.push(teamMember);
    } else {
      this.currTeamMembers = this.currTeamMembers.filter(
        (teamMemner) => teamMemner.userId !== $event.source.id
      );
    }
  }

  onSubmit() {
    let teamMemberUrl = teamMemberApi.addBulk;
    let model = {
      data: this.currTeamMembers,
    };
    this.cmnApiCallService.addUpdateData(teamMemberUrl, model).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.toastService.successMsg(
            'Success',
            `Team Members added successfully`
          );
          this.cmnAddEditSidenavService.getUpdatedDataList(
            'TeamMembersListComponent'
          );
          this.closeAddEditSideNav();
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
