import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';
import { ActivatedRoute } from '@angular/router';
import { teamMemberApi } from 'src/app/core/model/api-url';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { UserListService } from 'src/app/core/services/user-list.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrl: './team-members.component.scss',
})
export class TeamMembersComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ['userId', 'email', 'mobileNO', 'empty'];
  teamMembers: [] = [];
  dataSource = new MatTableDataSource(this.teamMembers);
  paginatorData: Paginator = new Paginator();
  teamId: number;
  userList;
  private routeSub: Subscription;
  private updateTeamMembersListSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  destroy$ = new Subject();
  constructor(
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private userListService: UserListService,
    private localStorageService: LocalStorageService
  ) {}

  openAddEditSideNav(id?: number) {
    this.cmnAddEditSidenavService.openAddEditSideNav(
      'TeamMembersListComponent',
      this.teamId
    );
    this.getTeamMembersById();
  }

  private subscribeToUpdateTeamList(): void {
    this.updateTeamMembersListSubscription =
      this.cmnAddEditSidenavService.updateTeamMembersList.subscribe(
        async (res) => {
          if (res) {
            this.getTeamMembersById();
          }
        }
      );
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((params) => {
      this.teamId = params['id'];
    });
    this.getUserList();
    this.getTeamMembersById();
    this.subscribeToUpdateTeamList();
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  getTeamMembersById() {
    let teamMemberUrl = teamMemberApi.list;
    this.apiQuery.query = {
      isDeleted: false,
      teamId: +this.teamId,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService.getList(teamMemberUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.dataSource = new MatTableDataSource(res.data.data);
            this.paginatorData = res.data.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  deleteTeam(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteTeamUrl = teamMemberApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService.addUpdateData(deleteTeamUrl, model).subscribe(
          (res: ApiResponse) => {
            if (res.status == ApiStatus.SUCCESS) {
              this.toastService.successMsg(
                'Deleted',
                `Team has been successfully deleted`
              );
              this.getTeamMembersById();
            }
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
      }
    });
  }

  getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(this.localStorageService.getUser());
        this.userList.push(...userList);
      });
  }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, +id, 'name');
  }

  getUserRoleById(id) {
    return this.userListService.getUserDataByInfo(this.userList, +id, 'role');
  }

  getUserEmailById(id) {
    return this.userListService.getUserDataByInfo(this.userList, +id, 'email');
  }

  getUserMobileById(id) {
    return this.userListService.getUserDataByInfo(
      this.userList,
      +id,
      'mobileNo'
    );
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getTeamMembersById();
  }

  ngOnDestroy() {
    if (this.updateTeamMembersListSubscription) {
      this.updateTeamMembersListSubscription.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
