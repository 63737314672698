import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

interface ValidationMessages {
  [key: string]: {
    [errorKey: string]: string;
  };
}

@Injectable()
export class FormValidationService {
  getErrorValue(
    controls: { [key: string]: AbstractControl },
    validationMessages: ValidationMessages
  ): { [key: string]: string } {
    const errorObject: { [key: string]: string } = {};

    for (const controlKey in controls) {
      if (controls[controlKey].errors) {
        const errorObjectKeys = Object.keys(
          controls[controlKey].errors as object
        );
        if (errorObjectKeys.length > 0) {
          const getErrorKey = errorObjectKeys[0];
          if (
            validationMessages[controlKey] &&
            validationMessages[controlKey][getErrorKey]
          ) {
            errorObject[controlKey] =
              validationMessages[controlKey][getErrorKey];
          } else {
            console.log(
              `${controlKey} or ${getErrorKey} is not defined in Validation message`
            );
          }
        }
      }
    }

    return errorObject;
  }
}
