import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { organizationApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { User } from 'src/app/core/model/user';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserListService } from 'src/app/core/services/user-list.service';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // dataSource: MatTableDataSource<any>;
  destroy$ = new Subject();
  displayedColumns: string[] = ['userId', 'name', 'desc', 'empty'];
  organization = [];
  dataSource = new MatTableDataSource(this.organization);
  updateOrganizationList: boolean = false;
  private updateOrganizationListSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userList: User[] = [];

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {}

  async ngOnInit() {
    await this.getUserList();
    await this.getOrganizationList();
    this.subscribeToUpdateProjectList();
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'OrganizationListComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'OrganizationListComponent'
      );
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getOrganizationList();
  }

  async getOrganizationList() {
    let organizationUrl = organizationApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService.getList(organizationUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.dataSource = new MatTableDataSource(res.data.data);
            this.paginatorData = res.data.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  deleteOrganization(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteOrganizationUrl = organizationApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteOrganizationUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastService.successMsg(
                  'Deleted',
                  `Organization has been successfully deleted`
                );
                this.getOrganizationList();
              }
            },
            (err) => {
              this.toastService.errorMsg(err);
            }
          );
      }
    });
    // ngAfterViewInit() {
    //   this.cmnAddEditSidenavService.updateOrganizationList.subscribe((res) => {
    //     debugger;
    //   });
    // }
  }

  private subscribeToUpdateProjectList(): void {
    this.updateOrganizationListSubscription =
      this.cmnAddEditSidenavService.updateOrganizationList.subscribe(
        async (res) => {
          if (res) {
            await this.getOrganizationList();
          }
        }
      );
  }

  async getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(this.localStorageService.getUser());
        this.userList.push(...userList);
      });
  }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  ngOnDestroy(): void {
    if (this.updateOrganizationListSubscription) {
      this.updateOrganizationListSubscription.unsubscribe();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
