import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { clientQueryApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { ShareToWebPopupComponent } from 'src/app/pages/share-to-web-popup/share-to-web-popup.component';
import { User } from 'src/app/core/model/user';
import { UserListService } from 'src/app/core/services/user-list.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-query-list',
  templateUrl: './client-query-list.component.html',
  styleUrls: ['./client-query-list.component.scss'],
})
export class ClientQueryListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // dataSource: MatTableDataSource<any>;
  destroy$ = new Subject();
  displayedColumns: string[] = [
    'type',
    'services',
    'name',
    'email',
    'location',
    'phone',
    'desc',
    'file',
  ];
  clientQueryList = [];
  dataSource = new MatTableDataSource(this.clientQueryList);
  updateClientQueryList: boolean = false;
  private updateClientQueryListSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userList: User[] = [];
  link = environment.baseUrl;

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {}

  async ngOnInit() {
    await this.getUserList();
    await this.getClientQueryList();
    this.subscribeToUpdateClientQueryList();
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'ClientQueryListComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'ClientQueryListComponent'
      );
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getClientQueryList();
  }

  async getClientQueryList() {
    let clientQueryListUrl = clientQueryApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService.getList(clientQueryListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.dataSource = new MatTableDataSource(res.data.data);
            this.paginatorData = res.data.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  deleteClientQuery(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteClientQueryUrl = clientQueryApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteClientQueryUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastService.successMsg(
                  'Deleted',
                  `ClientQuery record has been successfully deleted`
                );
                this.getClientQueryList();
              }
            },
            (err) => {
              this.toastService.errorMsg(err);
            }
          );
      }
    });
    // ngAfterViewInit() {
    //   this.cmnAddEditSidenavService.updateClientQueryList.subscribe((res) => {
    //     debugger;
    //   });
    // }
  }

  private subscribeToUpdateClientQueryList(): void {
    this.updateClientQueryListSubscription =
      this.cmnAddEditSidenavService.updateClientInfoList.subscribe(
        async (res) => {
          if (res) {
            await this.getClientQueryList();
          }
        }
      );
  }

  getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(this.localStorageService.getUser());
        this.userList.push(...userList);
      });
  }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  ngOnDestroy(): void {
    if (this.updateClientQueryListSubscription) {
      this.updateClientQueryListSubscription.unsubscribe();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
