import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Subject, takeUntil } from 'rxjs';
import {
  ApiStatus,
  TaskPriority,
  TaskType,
  gender,
  status,
} from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import {
  projectApi,
  statusApi,
  taskApi,
  userApi,
} from 'src/app/core/model/api-url';
import { Tags } from 'src/app/core/model/tags';
import { taskModel } from 'src/app/core/model/task.model';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-edit-task',
  templateUrl: './add-edit-task.component.html',
  styleUrls: ['./add-edit-task.component.scss'],
})
export class AddEditTaskComponent implements OnInit {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();

  user;
  taskId: number;
  projectId: number;
  projectManagerId: number;
  userList: User[] = [];
  currentDate: Date = new Date();
  estimatedDays = [0, 1, 2, 3, 4, 5];
  estimatedHours = [0, 1, 2, 3, 4, 5, 6, 7];
  linkId = 0;
  links: Tags[] = [];
  subTaskId = 0;
  subTaskList: Tags[] = [];
  attachmentId = 0;
  attachmentList: Tags[] = [];
  resourceId = 0;
  resourcesList: Tags[] = [];
  projectList = [];
  recipientList = [];
  parentTaskList = [];
  type = TaskType;
  taskTypeList = [
    ['badge badge-sm bg-soft-primary text-primary', 'story'],
    ['badge badge-sm bg-success text-white', 'ticket'],
    ['badge badge-sm bg-soft-success text-success', 'subticket'],
    ['badge badge-sm bg-soft-warning text-warning', 'bug'],
  ];
  statusId;
  statusList = [];
  priority = TaskPriority;
  priorityList = [
    ['badge badge-sm bg-success text-white', 'lowest'],
    ['badge badge-sm bg-soft-success text-success', 'low'],
    ['badge badge-sm bg-soft-warning text-warning', 'medium'],
    ['badge badge-sm bg-soft-danger text-danger', 'high'],
    ['badge badge-sm bg-danger text-white', 'highest'],
    ['badge badge-sm bg-secondary text-dark', 'NA'],
    ['badge badge-sm bg-dark text-white', 'blocker'],
  ];
  isEdit: boolean = false;
  loadingImage: boolean = false;
  apiQuery: ApiQuery = new ApiQuery();
  taskForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    type: {
      required: 'Task type is required',
    },
    name: {
      required: 'Task name is required',
    },
    assignTo: {
      required: 'Asignee is required',
    },
    reportTo: {
      required: 'Recipient is required',
    },
    projectId: {
      required: 'Project is required',
    },
    priority: {
      required: 'Priority is required',
    },
    projectManagerId: {
      required: 'Project Manager is required',
    },
  };
  public editor = ClassicEditor;
  public title = 'Description';
  public editorData = '';
  private editorInstance: any;
  editorConfig = {
    placeholder: 'Add description...',
    height: '500px',
  };

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private formValidationService: FormValidationService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res && res.id) {
            this.isEdit = true;
            this.taskId = +res.id;
            if (this.isEdit) {
              this.patchVal();
              this.linkId = 0;
              this.links = [];

              this.subTaskId = 0;
              this.subTaskList = [];

              this.attachmentId = 0;
              this.attachmentList = [];

              this.resourceId = 0;
              this.resourcesList = [];
            }
          } else {
            this.isEdit = false;
          }
        });
    }
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      if (this.isEdit) {
        this.closeAddEditSideNav();
      }
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent', id);
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent');
    }
  }

  patchVal() {
    let taskByIdUrl = taskApi.byId;
    this.cmnApiCallService.getById(taskByIdUrl, this.taskId).subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.taskForm.patchValue({
              type: +res.data.type,
              name: res.data.name,
              desc: res.data.desc,
              assignTo: res.data.assignTo,
              reportTo: res.data.reportTo,
              projectId: res.data.projectId,
              statusId: +res.data.statusId,
              priority: +res.data.priority,
              attachment: res.data.attachment,
              startDate: res.data.startDate,
              dueDate: res.data.dueDate,
              parentTaskId: res.data.parentTaskId,
              childTaskId: res.data.childTaskId,
              releaseDate: res.data.releaseDate,
              projectManagerId: res.data.projectManagerId,
              estimatedTime: res.data.estimatedTime,
              completionDate: res.data.completionDate,
              resource: res.data.resource,
              links: res.data.links,
            });
            this.getTaskList(res.data.id);
            this.stringToArray(
              this.taskForm.value['attachment'],
              this.attachmentList,
              this.attachmentId
            );
            this.stringToArray(
              this.taskForm.value['resource'],
              this.resourcesList,
              this.resourceId
            );
            this.stringToArray(
              this.taskForm.value['links'],
              this.links,
              this.linkId
            );
            this.updateStatusList(this.taskForm.value['projectId']);
            this.estimatedNumFormate(this.taskForm.value['estimatedTime']);
            this.editorData = res.data.desc;
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  ngOnInit(): void {
    this.createTaskForm();
    this.getUserList();
    this.getProjectList();
    if (!this.isEdit) {
      this.getTaskList();
    }
  }

  createTaskForm() {
    this.taskForm = this.fb.group({
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      desc: [''],
      assignTo: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      reportTo: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      projectId: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      statusId: [0],
      priority: ['', Validators.required],
      attachment: [''],
      startDate: [''],
      dueDate: [null],
      parentTaskId: [0],
      childTaskId: [0],
      releaseDate: [null],
      projectManagerId: [
        '',
        [Validators.required, Validators.pattern(/^[0-9]+$/)],
      ],
      estimatedTime: [''],
      estimatedDays: [0],
      estimatedHours: [0],
      completionDate: [null],
      resource: [''],
      links: [''],
      subTask: [''],
    });
  }

  getUserList() {
    let roleUserUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;
            this.recipientList.push(this.localStorageService.getUser());
            this.recipientList = [...this.recipientList, ...this.userList];
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  getProjectList() {
    let projectListUrl = projectApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService.getList(projectListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.projectList = res.data.data;
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  getTaskList(taskId?) {
    let taskListUrl = taskApi.list;
    this.apiQuery.query = taskId
      ? {
          isDeleted: false,
          parentTaskId: taskId,
        }
      : {
          isDeleted: false,
        };

    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService.getList(taskListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (taskId) {
            let taskList = res.data.data;
            taskList.map((task) => {
              let id = task.id;
              let tag = task.name;
              this.subTaskList.push({ id, tag });
            });
          } else {
            this.parentTaskList = res.data.data;
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  updateStatusList(projectId) {
    let statusListUrl = statusApi.list;
    this.apiQuery.query = {
      isDeleted: false,
      projectId: projectId,
    };

    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService
      .getList(statusListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          let projectStatus = res.data.data;
          let sts = [];
          projectStatus.map((status) => {
            sts.push([status.id, status.name]);
          });
          this.statusList = sts;
          if (this.statusList.length) {
            this.statusId = +this.statusList[0][0];
          }
        }
      });
  }

  onAddItem(tag, type) {
    let id: number;

    if (type == 'link') {
      id = this.linkId++;
      this.links.push({ id, tag });
    } else if (type == 'subTask') {
      if (this.isEdit) {
        this.addEditSubTask(this.taskId, tag);
      } else {
        id = this.subTaskId++;
        this.subTaskList.push({ id, tag });
      }
    } else if (type == 'attachment') {
      id = this.attachmentId++;
      tag = environment.mainUrl + tag;
      this.attachmentList.push({ id, tag });
    } else if (type == 'resource') {
      id = this.resourceId++;
      tag = environment.mainUrl + tag;
      this.resourcesList.push({ id, tag });
    }
  }

  deleteItem(id: number, type) {
    if (type == 'link') {
      this.links = this.links.filter((link) => link.id !== id);
    } else if (type == 'subTask') {
      if (this.isEdit) {
        this.deleteTask(id);
      } else {
        this.subTaskList = this.subTaskList.filter((task) => task.id !== id);
      }
    } else if (type == 'attachment') {
      this.attachmentList = this.attachmentList.filter(
        (attachment) => attachment.id !== id
      );
    } else if (type == 'resource') {
      this.resourcesList = this.resourcesList.filter(
        (resource) => resource.id !== id
      );
    }
  }

  deleteTask(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteTaskUrl = taskApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService.addUpdateData(deleteTaskUrl, model).subscribe(
          (res: ApiResponse) => {
            if (res.status == ApiStatus.SUCCESS) {
              this.subTaskList = this.subTaskList.filter(
                (subTask) => subTask.id !== id
              );
              this.toastService.successMsg(
                'Deleted',
                `Task has been successfully deleted`
              );
            }
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
      }
    });
  }

  onSubmit() {
    this.patchAndRemoveValues();
    this.taskForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.taskForm.controls,
      this.validationMessages
    );

    if (this.taskForm.valid) {
      let taskApiUrl = this.isEdit ? taskApi.update : taskApi.create;
      let model = this.taskForm.value;

      if (this.isEdit) {
        model['id'] = +this.taskId;
      }

      this.cmnApiCallService.addUpdateData(taskApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              if (this.subTaskList.length) {
                this.addEditSubTask(res.data.id);
              }
              this.toastService.successMsg(
                'Success',
                `Task created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'Task has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'TaskListComponent'
            );
            this.taskForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchAndRemoveValues() {
    this.projectId = this.taskForm.value['projectId'];
    this.projectManagerId = this.taskForm.value['projectManagerId'];
    this.taskForm.controls['resource'].patchValue(
      this.arrayToString(this.resourcesList)
    );
    this.taskForm.controls['links'].patchValue(this.arrayToString(this.links));
    this.taskForm.controls['startDate'].patchValue(this.currentDate);
    this.taskForm.controls['attachment'].patchValue(
      this.arrayToString(this.attachmentList)
    );
    this.taskForm.controls['childTaskId'].patchValue(0);
    this.taskForm.controls['estimatedTime'].patchValue(
      this.estimatedStringFormate()
    );
    this.taskForm.removeControl('subTask');
  }

  estimatedStringFormate() {
    return (
      this.taskForm.value['estimatedDays'] +
      '-' +
      this.taskForm.value['estimatedHours']
    );
  }

  estimatedNumFormate(time) {
    console.log(time);
    if (time) {
      let arr = time.split('-');
      this.taskForm.controls['estimatedDays'].patchValue(+arr[0]);
      this.taskForm.controls['estimatedHours'].patchValue(+arr[1]);
    }
  }

  addEditSubTask(parentTaskId, tag?) {
    let taskApiUrl;
    let model;

    if (!this.isEdit) {
      taskApiUrl = taskApi.addBulk;
      model = {
        data: this.addEditBulkTask(parentTaskId),
      };
    } else {
      taskApiUrl = taskApi.create;
      model = {
        type: 3,
        name: tag,
        projectId: this.taskForm.value['projectId'],
        projectManagerId: this.taskForm.value['projectManagerId'],
        statusId: this.statusId,
        startDate: this.currentDate.toISOString(),
        parentTaskId: parentTaskId,
      };
    }

    this.cmnApiCallService.addUpdateData(taskApiUrl, model).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (this.isEdit) {
            let id = res.data.id;
            this.subTaskList.push({ id, tag });
          }
        }
        return 0;
      },
      (err) => {
        return err;
      }
    );
  }

  addEditBulkTask(parentTaskId) {
    let type: string = '3';
    let data: taskModel[] = [];
    let projectId = this.projectId;
    let projectManagerId = this.projectManagerId;
    let statusId = this.statusId;
    let startDate = this.currentDate.toISOString();
    this.subTaskList.map((Tags) => {
      let name = Tags.tag;
      data.push({
        type,
        projectId,
        name,
        startDate,
        parentTaskId,
        projectManagerId,
        statusId,
      });
    });
    return data;
  }

  onReady(editor: any) {
    this.editorInstance = editor;
  }

  onFileSelected(event: Event, type) {
    this.loadingImage = true;
    const fileInput = event.target as HTMLInputElement;
    const selectedFile = fileInput.files?.[0];

    if (selectedFile) {
      this.cmnApiCallService.uploadImage(selectedFile).subscribe(
        (response: ApiResponse) => {
          if (response.status === ApiStatus.SUCCESS) {
            const path: string = response.data.uploadSuccess[0].path;
            // this.taskForm.controls['attachment'].patchValue(path);
            this.onAddItem(path, type);
          }
          if (response.status === ApiStatus.FAILURE) {
            this.toastService.uploadFailure(
              response.data.uploadFailed[0].error
            );
          }
          this.loadingImage = false;
        },
        (error) => {
          console.error('Error uploading image', error);
          this.toastService.errorMsg(error);
          this.loadingImage = false;
        }
      );
    }
  }

  arrayToString(list) {
    let str = [];
    list.map((obj) => {
      str.push(obj.tag);
    });
    return str.join();
  }

  stringToArray(str, list, id) {
    let arr = [];
    if (str) {
      arr = str.split(',');
      arr.map((tag) => {
        list.push({ id, tag });
        id++;
      });
    }
  }

  openTask(id) {
    if (this.isEdit) {
      this.openAddEditSideNav(id);
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
