import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiResponse } from 'src/app/core/model/api-response';
import { attendanceApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ToastService } from 'src/app/core/services/toast.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-add-edit-attendance',
  templateUrl: './add-edit-attendance.component.html',
  styleUrls: ['./add-edit-attendance.component.scss'],
})
export class AddEditAttendanceComponent {
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
  selectedImage: any; // Variable to hold the selected image preview
  attendanceForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    title: {
      required: 'Title is required',
    },
    teamSize: {
      min: 'Minimus teamsize shoul be 1.',
    },
  };
  isEdit: boolean = false;
  attendanceId: number;
  public editor = ClassicEditor;
  public editorData = '';
  private editorInstance: any;
  editorConfig = {
    placeholder: 'Add description...',
  };

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.attendanceId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createattendanceForm();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  // You can also close the add/edit sidenav if needed
  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createattendanceForm() {
    this.attendanceForm = this.fb.group({
      userId: [],
      start: [''],
      end: [''],
      description: [''],
    });
  }

  onSubmit() {
    this.attendanceForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.attendanceForm.controls,
      this.validationMessages
    );

    if (this.attendanceForm.valid) {
      let attendanceApiUrl = this.isEdit
        ? attendanceApi.update
        : attendanceApi.create;
      let model = this.attendanceForm.value;
      model.description = this.editorInstance.getData();

      if (this.isEdit) {
        model['id'] = +this.attendanceId;
      }

      this.cmnApiCallService.addUpdateData(attendanceApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `attendance created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                res.data[0].title
                  ? `attendance "${res.data[0].title}" has been successfully edited`
                  : 'attendance has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'AttendanceListComponent'
            );
            this.attendanceForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let attendanceGetByIdUrl = attendanceApi.byId;
    this.cmnApiCallService
      .getById(attendanceGetByIdUrl, this.attendanceId)
      .subscribe((res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.attendanceForm.patchValue({
              userId: res.data.userId,
              description: res.data.description,
              start: res.data.start,
              end: res.data.end,
            });
          }
          this.editorData = res.data.description;
        }
      });
  }

  onReady(editor: any) {
    this.editorInstance = editor;
  }

  getVal() {
    console.log(this.attendanceForm.controls['end'].value);
  }
}
