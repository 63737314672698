import { CmnApiCallService } from '../../../../core/services/cmn-api-call.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShareToWebPopupComponent } from '../../../share-to-web-popup/share-to-web-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { salaryDispatchedApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { User } from 'src/app/core/model/user';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserListService } from 'src/app/core/services/user-list.service';

@Component({
  selector: 'app-salary-dispatched-list',
  templateUrl: './salary-dispatched-list.component.html',
  styleUrls: ['./salary-dispatched-list.component.scss'],
})
export class SalaryDispatchedListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // dataSource: MatTableDataSource<any>;
  destroy$ = new Subject();
  displayedColumns: string[] = [
    'userId',
    'salaryId',
    'amount',
    'leaves',
    'totalAmount',
    // 'description',
    'empty',
  ];
  salaryDispatched = [];
  dataSource = new MatTableDataSource(this.salaryDispatched);
  updateSalaryDispatchedList: boolean = false;
  private updateSalaryDispatchedListSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userList: User[] = [];

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {}

  async ngOnInit() {
    await this.getUserList();
    await this.getSalaryDispatchedList();
    this.subscribeToUpdateProjectList();
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'SalaryDispatchedListComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'SalaryDispatchedListComponent'
      );
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getSalaryDispatchedList();
  }

  async getSalaryDispatchedList() {
    let salaryDispatchedUrl = salaryDispatchedApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10;
    this.cmnApiCallService
      .getList(salaryDispatchedUrl, this.apiQuery)
      .subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (res && res.data) {
              this.dataSource = new MatTableDataSource(res.data.data);
              this.paginatorData = res.data.paginator;
              this.dataSource.sort = this.sort;
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  deleteDispatchedSalary(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteDispatchedSalaryUrl = salaryDispatchedApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteDispatchedSalaryUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastService.successMsg(
                  'Deleted',
                  `Salary Dispatched record has been successfully deleted`
                );
                this.getSalaryDispatchedList();
              }
            },
            (err) => {
              this.toastService.errorMsg(err);
            }
          );
      }
    });
    // ngAfterViewInit() {
    //   this.cmnAddEditSidenavService.updateSalaryDispatchedList.subscribe((res) => {
    //     debugger;
    //   });
    // }
  }

  private subscribeToUpdateProjectList(): void {
    this.updateSalaryDispatchedListSubscription =
      this.cmnAddEditSidenavService.updateSalaryDispatchedList.subscribe(
        async (res) => {
          if (res) {
            await this.getSalaryDispatchedList();
          }
        }
      );
  }

  async getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(this.localStorageService.getUser());
        this.userList.push(...userList);
      });
  }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  ngOnDestroy(): void {
    if (this.updateSalaryDispatchedListSubscription) {
      this.updateSalaryDispatchedListSubscription.unsubscribe();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
