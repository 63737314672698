import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import {
  ApiStatus,
  TaskType,
  TaskTypeColorClass,
} from 'src/app/core/enums/roles.enum';
import { statusApi, taskApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { Subject, takeUntil } from 'rxjs';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { ShareToWebPopupComponent } from 'src/app/pages/share-to-web-popup/share-to-web-popup.component';
import { User } from 'src/app/core/model/user';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss'],
})
export class TaskOverviewComponent implements OnInit {
  // dataSource: MatTableDataSource<any>;
  destroy$ = new Subject();
  statusListApiQuery: ApiQuery = new ApiQuery();
  userList: User[] = [];
  lastTaskApiQuery: ApiQuery = new ApiQuery();
  taskName: any[] = [];
  taskAssignee: any[] = [];
  taskTypeEnum = TaskType;
  taskTypeColorClassEnum = TaskTypeColorClass;

  FinishTaskForm: FormGroup = this.fb.group({
    statusId: ['all'],
  });
  onGoingTaskForm: FormGroup = this.fb.group({
    statusId: ['all'],
  });
  allStatus: any[] = [];
  finishedTask = 0;
  onGoingTask = 0;

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.getSelectedProjectStatusList();
    this.getFinishStatusCount();
    this.getOnGoingStatusCount();
    this.handleStatusSelectionChange();
    this.getTaskList();
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent', id);
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent');
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }
  // async getClientInfoList() {
  //   let clientInfoListUrl = clientInfoApi.list;
  //   this.apiQuery.query = {
  //     isDeleted: false,
  //   };
  //   this.cmnApiCallService.getList(clientInfoListUrl, this.apiQuery).subscribe(
  //     (res: ApiResponse) => {
  //       if (res.status === ApiStatus.SUCCESS) {
  //         if (res && res.data) {
  //           this.dataSource = new MatTableDataSource(res.data.data);
  //           this.paginatorData = res.data.paginator;
  //           this.dataSource.sort = this.sort;
  //         }
  //       }
  //     },
  //     (err) => {
  //       this.toastService.errorMsg(err);
  //     }
  //   );
  // }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  // deleteClientInfo(id) {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent);

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       let deleteClientInfoUrl = clientInfoApi.softDelete;
  //       let model = {
  //         isDeleted: true,
  //       };
  //       model['id'] = +id;

  //       this.cmnApiCallService
  //         .addUpdateData(deleteClientInfoUrl, model)
  //         .subscribe(
  //           (res: ApiResponse) => {
  //             if (res.status == ApiStatus.SUCCESS) {
  //               this.toastService.successMsg(
  //                 'Deleted',
  //                 `ClientInfo record has been successfully deleted`
  //               );
  //               this.getClientInfoList();
  //             }
  //           },
  //           (err) => {
  //             this.toastService.errorMsg(err);
  //           }
  //         );
  //     }
  //   });
  //   // ngAfterViewInit() {
  //   //   this.cmnAddEditSidenavService.updateClientInfoList.subscribe((res) => {
  //   //     debugger;
  //   //   });
  //   // }
  // }

  // private subscribeToUpdateClientInfoList(): void {
  //   this.updateTaskListSubscription =
  //     this.cmnAddEditSidenavService.updateClientInfoList.subscribe(
  //       async (res) => {
  //         if (res) {
  //           await this.getClientInfoList();
  //         }
  //       }
  //     );
  // }

  // async getUserList() {
  //   this.userListService
  //     .getUserList()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((userList) => {
  //       this.userList = [];
  //       this.userList.push(this.localStorageService.getUser());
  //       this.userList.push(...userList);
  //     });
  // }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  getSelectedProjectStatusList() {
    let statusListUrl = statusApi.list;
    this.statusListApiQuery.query = {
      isDeleted: false,
      projectId: 1,
    };
    this.statusListApiQuery.options.page = 1;
    this.statusListApiQuery.options.paginate = 1000;

    this.cmnApiCallService
      .getList(statusListUrl, this.statusListApiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          let projectStatus = res.data.data;
          let sts = [];
          projectStatus.map((status) => {
            sts.push([status.id, status.name]);
          });
          this.allStatus = sts;
        }
      });
  }

  getFinishStatusCount() {
    let projectStatusListUrl = taskApi.count;
    const { statusId } = this.FinishTaskForm.value;
    const query = {
      where: {
        isActive: true,
        projectId: 1,
        statusId: statusId !== 'all' ? statusId : undefined,
      },
    };
    this.cmnApiCallService
      .getList(projectStatusListUrl, query)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS && res.data && res.data.count) {
          this.finishedTask = res.data.count;
        } else {
          this.finishedTask = 0;
        }
      });
  }
  getOnGoingStatusCount() {
    let projectStatusListUrl = taskApi.count;
    const { statusId } = this.onGoingTaskForm.value;
    const query = {
      where: {
        isActive: true,
        projectId: 1,
        statusId: statusId !== 'all' ? statusId : undefined,
      },
    };
    this.cmnApiCallService
      .getList(projectStatusListUrl, query)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS && res.data && res.data.count) {
          this.onGoingTask = res.data.count;
        } else {
          this.onGoingTask = 0;
        }
      });
  }
  handleStatusSelectionChange() {
    this.FinishTaskForm.get('statusId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (this.allStatus.length) {
          setTimeout(() => {
            this.getFinishStatusCount();
          }, 100);
        }
      });
    this.onGoingTaskForm
      .get('statusId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (this.allStatus.length) {
          setTimeout(() => {
            this.getOnGoingStatusCount();
          }, 100);
        }
      });
  }
  getTaskList() {
    let taskListUrl = taskApi.list;
    this.lastTaskApiQuery.query = {
      isDeleted: false,
      projectId: 3,
    };
    this.lastTaskApiQuery.options.page = 1;
    this.lastTaskApiQuery.options.paginate = 5;
    this.lastTaskApiQuery.options.sort = { createdAt: -1 };
    this.cmnApiCallService
      .getList(taskListUrl, this.lastTaskApiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          this.taskName = res.data.data;
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
