import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../model/user';

@Pipe({
  name: 'roleFilter',
})
export class RoleFilterPipe implements PipeTransform {
  transform(users: User[], roleIds: number[]): User[] {
    if (!users || !roleIds || roleIds.length === 0) {
      return users;
    }
    return users.filter((user) => roleIds.includes(+user.role));
  }
}
