<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>👋 Creator - posts</h2>
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <!-- <a class="btn btn-sm btn-neutral border-base" (click)="openShareToWebDialog()">
                                        <span class="pe-2">
                                            <i class="bi bi-people-fill"></i>
                                        </span>
                                        <span> Share</span>
                                    </a> -->
                <!-- <page-button
                    [config]="{ rank: 'neutral' }"
                    [icon]="'bi bi-people-fill'"
                    [loading]="false"
                    (clicked)="openShareToWebDialog()"
                    >Share</page-button
                  > -->
                <page-button
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  >Add</page-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</div>
