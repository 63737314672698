import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { ProjectGridviewComponent } from './project-gridview/project-gridview.component';
import { ProjectTableViewComponent } from './project-table-view/project-table-view.component';
import { CreatNewProjectComponent } from './creat-new-project/creat-new-project.component';
import { CreateProjectFormComponent } from './create-project-form/create-project-form.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
// import { ShareToWebPopupComponent } from './share-to-web-popup/share-to-web-popup.component';
import { AddEditProjectComponent } from './add-edit-project/add-edit-project.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    ProjectOverviewComponent,
    ProjectGridviewComponent,
    CreatNewProjectComponent,
    ProjectTableViewComponent,
    CreateProjectFormComponent,
    ProjectDetailsComponent,
    AddEditProjectComponent,
    // ShareToWebPopupComponent,
  ],
  imports: [CommonModule, ProjectsRoutingModule, SharedModule, CdkDropList, CdkDrag],
  exports: [AddEditProjectComponent],
})
export class ProjectsModule {}
