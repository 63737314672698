import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ApiStatus, TaskPriority } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import {
  projectApi,
  statusApi,
  taskApi,
  userApi,
} from 'src/app/core/model/api-url';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { CommonService } from 'src/app/core/services/common.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-task-listview',
  templateUrl: './task-listview.component.html',
  styleUrls: ['./task-listview.component.scss'],
})
export class TaskListviewComponent implements OnInit {
  destroy$ = new Subject();
  private updateTaskListSubscription: Subscription;

  projectList = [];
  userList: User[] = [];
  projectStatus = [];
  projectTask = [];
  taskList = [];
  projectId;

  apiQuery: ApiQuery = new ApiQuery();

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private cmnService: CommonService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private projectService: ProjectService,
    private userListService: UserListService
  ) {}

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent', id);
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent');
    }
  }

  ngOnInit(): void {
    this.getProjectList();
    this.getUserList();
    this.subscribeToUpdateTaskList();
  }

  getProjectList() {
    let projectListUrl = projectApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService.getList(projectListUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.projectList = res.data.data;
          if (this.projectList.length) {
            this.projectId = this.projectList[0].id;
            this.getStatusByProjectId(this.projectId);
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(this.localStorageService.getUser());
        this.userList.push(...userList);
      });
  }

  subscribeToUpdateTaskList() {
    this.updateTaskListSubscription =
      this.cmnAddEditSidenavService.updateTaskList.subscribe(async (res) => {
        if (res) {
          await this.getTaskByProjectId(); // Await the getTaskList method if the event is triggered.
        }
      });
  }

  getStatusByProjectId(projectId, id?) {
    this.projectId = +projectId;
    this.projectService.setProjectId(+projectId);
    let statusListUrl = statusApi.list;
    this.apiQuery.query = {
      isDeleted: false,
      projectId: projectId,
    };
    if (id) {
      this.apiQuery.query['id'] = id;
    }

    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService
      .getList(statusListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.projectStatus = res.data.data;
          this.projectStatus.sort((a, b) => a.priority - b.priority);
          this.getTaskByProjectId();
        }
      });
  }

  getTaskByProjectId() {
    let taskListUrl = taskApi.list;

    this.apiQuery.query = {
      isDeleted: false,
      projectId: this.projectId,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService
      .getList(taskListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.projectTask = res.data.data;
          this.mapStatusToTask();
        }
      });
  }

  mapStatusToTask() {
    let taskList = [];
    this.projectStatus.map((status) => {
      let tsk = [];
      this.projectTask.map((task) => {
        if (task.statusId == status.id) {
          tsk.push(task);
        }
      });
      taskList.push([status, tsk]);
    });
    this.taskList = taskList;
  }

  deleteTask(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteTaskUrl = taskApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService.addUpdateData(deleteTaskUrl, model).subscribe(
          (res: ApiResponse) => {
            if (res.status == ApiStatus.SUCCESS) {
              this.toastService.successMsg(
                'Deleted',
                `Task has been successfully deleted`
              );
              this.getTaskByProjectId();
            }
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
      }
    });
  }

  getImgUrl(id) {
    let imageUrl;
    this.userList.map((user) => {
      if (user.id == id) {
        imageUrl = user.imageUrl;
      }
    });
    return imageUrl;
  }

  timeFormate(date) {
    return this.cmnService.convertToCustomFormat(date);
  }

  getPriority(pri) {
    return TaskPriority[+pri];
  }

  ngOnDestroy(): void {
    if (this.updateTaskListSubscription) {
      this.updateTaskListSubscription.unsubscribe();
    }
    this.destroy$.complete();
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }
}
