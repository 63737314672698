import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
  ApiStatus,
  TaskPriority,
  TaskPriorityColorClass,
  TaskType,
  TaskTypeColorClass,
} from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { taskApi, statusApi, projectApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { User } from 'src/app/core/model/user';

@Component({
  selector: 'app-task-listview-details',
  templateUrl: './task-listview-details.component.html',
  styleUrls: ['./task-listview-details.component.scss'],
})
export class TaskListviewDetailsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // dataSource: MatTableDataSource<any>;
  destroy$ = new Subject();
  displayedColumns: string[] = [
    'projectId',
    'status',
    'taskType',
    'task-name-summary',
    'priority',
    'assignee',
    'project-manager',
    'creator',
    'dueDate',
    'created',
    'empty',
  ];
  clientInfoList = [];
  dataSource = new MatTableDataSource(this.clientInfoList);
  updateClientInfoList: boolean = false;
  private updateTaskListSubscription: Subscription;
  projectlistApiQuery: ApiQuery = new ApiQuery();
  statuslistApiQuery: ApiQuery = new ApiQuery();
  tasklistApiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userList: User[] = [];
  projectTask = [];
  filterTaskForm: FormGroup = this.fb.group({
    projectId: [],
    statusId: [{ value: 'all', disabled: true }],
  });
  statusList = [];
  projectList = [];
  taskPriorityEnum = TaskPriority;
  taskPriorityColorClassEnum = TaskPriorityColorClass;
  taskTypeEnum = TaskType;
  taskTypeColorClassEnum = TaskTypeColorClass;
  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    this.handleProjectSelectionChange();
    this.handleFormChange();
    this.getProjectList();
    this.subscribeToUpdateTaskList();
  }
  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent', id);
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('TaskListComponent');
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.tasklistApiQuery.options.page = data.pageIndex + 1;
    this.tasklistApiQuery.options.paginate = data.pageSize;
    this.getAllTask();
  }

  // async getClientInfoList() {
  //   let clientInfoListUrl = clientInfoApi.list;
  //   this.apiQuery.query = {
  //     isDeleted: false,
  //   };
  //   this.cmnApiCallService.getList(clientInfoListUrl, this.apiQuery).subscribe(
  //     (res: ApiResponse) => {
  //       if (res.status === ApiStatus.SUCCESS) {
  //         if (res && res.data) {
  //           this.dataSource = new MatTableDataSource(res.data.data);
  //           this.paginatorData = res.data.paginator;
  //           this.dataSource.sort = this.sort;
  //         }
  //       }
  //     },
  //     (err) => {
  //       this.toastService.errorMsg(err);
  //     }
  //   );
  // }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  // deleteClientInfo(id) {
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent);

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {
  //       let deleteClientInfoUrl = clientInfoApi.softDelete;
  //       let model = {
  //         isDeleted: true,
  //       };
  //       model['id'] = +id;

  //       this.cmnApiCallService
  //         .addUpdateData(deleteClientInfoUrl, model)
  //         .subscribe(
  //           (res: ApiResponse) => {
  //             if (res.status == ApiStatus.SUCCESS) {
  //               this.toastService.successMsg(
  //                 'Deleted',
  //                 `ClientInfo record has been successfully deleted`
  //               );
  //               this.getClientInfoList();
  //             }
  //           },
  //           (err) => {
  //             this.toastService.errorMsg(err);
  //           }
  //         );
  //     }
  //   });
  //   // ngAfterViewInit() {
  //   //   this.cmnAddEditSidenavService.updateClientInfoList.subscribe((res) => {
  //   //     debugger;
  //   //   });
  //   // }
  // }

  // private subscribeToUpdateClientInfoList(): void {
  //   this.updateTaskListSubscription =
  //     this.cmnAddEditSidenavService.updateClientInfoList.subscribe(
  //       async (res) => {
  //         if (res) {
  //           await this.getClientInfoList();
  //         }
  //       }
  //     );
  // }

  // async getUserList() {
  //   this.userListService
  //     .getUserList()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((userList) => {
  //       this.userList = [];
  //       this.userList.push(this.localStorageService.getUser());
  //       this.userList.push(...userList);
  //     });
  // }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  getAllTask() {
    let taskListUrl = taskApi.list;
    const { statusId, projectId } = this.filterTaskForm.value;
    this.tasklistApiQuery.query = {
      isDeleted: false,
      statusId: statusId !== 'all' && statusId !== null ? statusId : undefined,
      projectId: projectId ? projectId : undefined,
    };
    this.tasklistApiQuery.options.page = 1;
    this.tasklistApiQuery.options.paginate = 50;

    this.cmnApiCallService
      .getList(taskListUrl, this.tasklistApiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.projectTask = res.data.data;
          this.dataSource = new MatTableDataSource(res.data.data);
          this.paginatorData = res.data.paginator;
          this.dataSource.sort = this.sort;
        }
      });
  }
  getProjectStatusList() {
    if (this.projectList.length) {
      let statusListUrl = statusApi.list;
      this.statuslistApiQuery.query = {
        isDeleted: false,
        projectId: this.filterTaskForm.value.projectId
          ? this.filterTaskForm.value.projectId
          : undefined,
      };
      this.statuslistApiQuery.options.page = 1;
      this.statuslistApiQuery.options.paginate = 1000;

      this.cmnApiCallService
        .getList(statusListUrl, this.statuslistApiQuery)
        .subscribe((res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            let projectStatus = res.data.data;
            let sts = [];
            projectStatus.map((status) => {
              sts.push([status.id, status.name]);
            });
            this.statusList = sts;
          }
        });
    }
  }

  getProjectList() {
    let projectListUrl = projectApi.list;
    this.projectlistApiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService
      .getList(projectListUrl, this.projectlistApiQuery)
      .subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            this.projectList = res.data.data;
            if (this.projectList.length) {
              this.filterTaskForm.patchValue({
                projectId: +this.projectList[0].id,
              });
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }

  handleProjectSelectionChange() {
    this.filterTaskForm
      .get('projectId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((projectId) => {
        if (projectId) {
          setTimeout(() => {
            this.filterTaskForm.get('statusId').enable();
            this.getProjectStatusList();
          });
        }
      });
  }

  handleFormChange() {
    this.filterTaskForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        const { projectId, statusId } = response;
        if (projectId) {
          this.getAllTask();
        }
      });
  }

  subscribeToUpdateTaskList() {
    this.updateTaskListSubscription =
      this.cmnAddEditSidenavService.updateTaskList.subscribe(async (res) => {
        if (res) {
          this.getAllTask();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.updateTaskListSubscription) {
      this.updateTaskListSubscription.unsubscribe();
    }
    this.destroy$.complete();
  }
}
