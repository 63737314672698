import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiStatus, status } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import { projectApi } from 'src/app/core/model/api-url';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-gridview',
  templateUrl: './project-gridview.component.html',
  styleUrls: ['./project-gridview.component.scss']
})
export class ProjectGridviewComponent implements OnInit {

  projectList = [];
  list = [];
  apiQuery: ApiQuery = new ApiQuery();

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private cmnService: CommonService,
  ) { }

  ngOnInit(): void {
    this.getProjectList();
  }

  getProjectList() {
    let projectApiUrl = projectApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    }

    this.cmnApiCallService.getList(projectApiUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if(res.status === ApiStatus.SUCCESS){
          this.list = res.data.data;
          this.projectList = this.list;
          // this.projectList = res.data.data;
          console.log(this.projectList);
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    )
  }
  
  deleteProject(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteportfolioUrl = projectApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteportfolioUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastService.successMsg(
                  'Deleted',
                  `Project has been successfully deleted`
                );
                this.getProjectList();
              }
            },
            (err) => {
              this.toastService.errorMsg(err);
            }
          );
      }
    });
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.projectList = this.list.filter(project => project.name.includes(filterValue));
  }

  getImage(url){
    return environment.mainUrl + url;
  }

  getStatus(statusId){
    return status[statusId];
  }
  
  timeFormate(date) {
    return this.cmnService.convertToCustomFormat(date);
  }

}
