import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import { roleApi, userApi, userDocumentApi } from 'src/app/core/model/api-url';
import { roleModel } from 'src/app/core/model/role.model';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-edit-user-document',
  templateUrl: './add-edit-user-document.component.html',
  styleUrls: ['./add-edit-user-document.component.scss'],
})
export class AddEditUserDocumentComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  roleDocumentForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    userId: {
      required: 'User is required',
    },
    docUrl: {
      required: 'Document is required',
    },
    documentNo: {
      required: 'Document No is required',
    },
    type: {
      required: 'Document type is required',
    },
  };
  isEdit: boolean = false;
  roleDocId: number;
  test: File;
  loadingImage: boolean = false; // Add this variable to control loader visibility
  apiQuery: ApiQuery = new ApiQuery();
  userList: User[] = [];
  selectedImage: string;

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.roleDocId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createRoleForm();
    if (this.isEdit) {
      this.patchVal();
    }
    this.getUserList();
  }

  // You can also close the add/edit sidenav if needed
  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createRoleForm() {
    this.roleDocumentForm = this.fb.group({
      userId: [null, Validators.required],
      docUrl: ['', Validators.required],
      documentNo: ['', Validators.required],
      type: [null, [Validators.required]],
    });
  }

  onSubmit() {
    this.roleDocumentForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.roleDocumentForm.controls,
      this.validationMessages
    );

    if (this.roleDocumentForm.valid) {
      let userDocumentApiUrl = this.isEdit
        ? userDocumentApi.update
        : userDocumentApi.create;
      let model = this.roleDocumentForm.value;

      if (this.isEdit) {
        model['id'] = +this.roleDocId;
      }

      this.cmnApiCallService.addUpdateData(userDocumentApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `User Document created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                res.data[0].title
                  ? `User Document "${res.data[0].name}" has been successfully edited`
                  : 'User Document has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'UserDocumentListComponent'
            );
            this.roleDocumentForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  getUserList() {
    let roleUserUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;
            this.userList.push(this.localStorageService.getUser());
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  patchVal() {
    let roleGetByIdUrl = userDocumentApi.byId;
    this.cmnApiCallService.getById(roleGetByIdUrl, this.roleDocId).subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.roleDocumentForm.patchValue({
              userId: res.data.userId,
              docUrl: res.data.docUrl,
              documentNo: res.data.documentNo,
              type: res.data.type,
            });
            this.selectedImage = environment.mainUrl + res.data.docUrl;
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onFileSelected(event: Event) {
    this.loadingImage = true;
    const fileInput = event.target as HTMLInputElement;
    const selectedFile = fileInput.files?.[0];

    if (selectedFile) {
      this.cmnApiCallService.uploadImage(selectedFile).subscribe(
        (response: ApiResponse) => {
          if (response.status === ApiStatus.SUCCESS) {
            const path: string = response.data.uploadSuccess[0].path;
            this.roleDocumentForm.controls['docUrl'].patchValue(path);
            this.selectedImage = environment.mainUrl + path;
          }
          if (response.status === ApiStatus.FAILURE) {
            this.toastService.uploadFailure(
              response.data.uploadFailed[0].error
            );
          }
          this.loadingImage = false;
        },
        (error) => {
          console.error('Error uploading image', error);
          this.toastService.errorMsg(error);
          this.loadingImage = false;
        }
      );
    }
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
