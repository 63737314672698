import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { rollAccessApi } from '../model/api-url';
import { ApiQuery, ApiResponse, Paginator } from '../model/api-response';
import { ApiStatus } from '../enums/roles.enum';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RoleAccessService {
  private roleAccessData: { [key: string]: boolean };
  private apiQuery: ApiQuery = new ApiQuery();
  private roleId = 2;
  private CRUDAccess: {
    [key: string]: {
      view: boolean;
      create: boolean;
      edit: boolean;
      delete: boolean;
    };
  };

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private localStorageService: LocalStorageService
  ) {
    this.roleId = this.localStorageService?.getUser().role
      ? this.localStorageService?.getUser().role
      : 2;
  }

  async fetchRoleAccessData(): Promise<{ [key: string]: boolean }> {
    return new Promise((resove, reject) => {
      if (this.roleAccessData && Object.keys(this.roleAccessData).length > 0) {
        return resove(this.roleAccessData);
      } else {
        this.roleId;
        this.apiQuery.query['roleId'] = +this.roleId;
        this.apiQuery.options.page = 1;
        this.apiQuery.options.paginate = 2000;
        this.http
          .post(environment.baseUrl + rollAccessApi.list, this.apiQuery)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status === ApiStatus.SUCCESS) {
                if (res && res.data) {
                  const { groupedData, permissionData } =
                    this.groupByUrlAndAggregate(res.data.data);
                  this.roleAccessData = { ...groupedData };
                  this.CRUDAccess = { ...permissionData };
                  resove(this.roleAccessData);
                }
              }
            },
            (err) => {
              this.toastService.errorMsg(err);
              resove({});
            }
          );
      }
    });
  }

  groupByUrlAndAggregate(data) {
    const groupedData = {};
    const permissionData = {};
    data.forEach((item) => {
      const { url, read, write, delete: canDelete, update } = item;
      if (!groupedData[url]) {
        groupedData[url] = false;
      }
      if (read || canDelete || update || write) {
        groupedData[url] = true;
      }
      if (!permissionData[url]) {
        permissionData[url] = {
          view: read,
          create: write,
          edit: update,
          delete: canDelete,
        };
      }
    });

    return { groupedData, permissionData };
  }

  set roleAccessDataFlush(value) {
    this.roleAccessData = {};
    this.CRUDAccess = {};
  }

  get rolAccessValue() {
    return this.roleAccessData;
  }

  get access() {
    return this.CRUDAccess;
  }
}
