import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]',
})
export class NumbersOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const value = inputElement.value;

    // Remove non-numeric characters using a regular expression
    const numericValue = value.replace(/[^0-9]/g, '');

    // Set the input field value to the numeric value
    inputElement.value = numericValue;
  }
}
