<div
  class="flex-lg-1 h-screen-header overflow-y-lg-auto task-listview-wrapper bg-surface-secondary"
>
  <header>
    <div class="container-fluid">
      <div class="border-bottom pt-6 pb-3 cmn-header-box">
        <div class="row align-items-center">
          <div class="col-12 col-sm">
            <div class="cmn-search-box cmn-form-field">
              <mat-form-field subscriptSizing="dynamic">
                <mat-select
                  class="fs-3 font-bold"
                  placeholder="Project"
                  [(value)]="projectId"
                  (selectionChange)="getStatusByProjectId($event.value)"
                >
                  <mat-option
                    *ngFor="let project of projectList"
                    [value]="project.id"
                    >{{ project.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <a
                href="#modalExport"
                class="btn btn-sm btn-neutral border-base"
                data-bs-toggle="modal"
                ><span class="pe-2"><i class="bi bi-people-fill"></i> </span
                ><span>Share</span> </a
              ><a
                href="#offcanvasCreate"
                class="btn btn-sm btn-primary"
                data-bs-toggle="offcanvas"
                ><span class="pe-2"
                  ><i class="bi bi-plus-square-dotted"></i> </span
                ><span>Create</span></a
              >
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </header>

  <main class="py-6">
    <div class="container-fluid vstack gap-3">
      <div class="card">
        <div class="px-4 px-md-8">
          <div *ngFor="let ts of taskList">
            <!-- <div class="px-3 mb-6">
            <span class="badge badge-lg badge-dot text-heading font-semibold"><i class="bg-secondary"></i>
              {{ts[0].name}}</span>
            </div> -->

            <div class="d-flex align-items-center py-5 border-bottom">
              <h5 class="font-semibold">
                <span class="me-1">📊</span> <span>{{ ts[0].name }}</span>
              </h5>
              <!-- <div class="hstack gap-3 ms-auto">	
                <a  class="p-1 text-muted"	
                  ><i class="bi bi-calendar-event"></i> </a	
                ><a  class="p-1 text-muted"	
                  ><i class="bi bi-three-dots"></i	
                ></a>	
              </div> -->
            </div>

            <!-- <div class="flex-1 overflow-y-auto">
              <div class="px-3 mb-3" *ngFor="let task of ts[1]">
                <div class="card">
                  <div class="card-body">
                    <a
                      href=""
                      class="h5 font-semibold fs-5"
                      data-bs-toggle="offcanvas"
                      >{{task.name}}</a
                    >
                    <br>
                    <div [innerHTML]="task.desc"></div>
                    <div class="d-flex justify-content-between mt-3">
                      <div>
                        <a
    
                            class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                            ><img
                              alt="..."
                              src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                            /> </a
                          >
                      </div>
                      <div>
                        {{timeFormate(task.dueDate)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="list-group list-group-flush">
              <div class="list-group-item py-3" *ngFor="let task of ts[1]">
                <div class="row g-3 align-items-center">
                  <div class="col-sm-6 col-12 d-flex">
                    <div class="form-check form-check-linethrough">
                      <div class="d-flex align-items-center">
                        <!-- <input	            
                          class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"	                            {{task.id}}
                          type="checkbox"	                     
                          id="check-new-tasks-1"	
                        /> -->
                        <h6>
                          {{ task.id }}
                        </h6>
                        <a
                          mat-button
                          class="form-check-label font-regular ms-2"
                          (click)="openAddEditSideNav(task.id)"
                          >{{ task.name }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-auto col-12 ms-sm-auto">
                    <div class="hstack gap-5 justify-content-end flex-wrap">
                      <div>
                        <span class="badge bg-soft-warning text-warning">{{
                          getPriority(task.priority)
                        }}</span>
                      </div>
                      <!-- <div class="position-relative text-sm">
                        <i class="bi bi-check-circle-fill text-success"></i>
                        <span class="d-inline-block w-8 text-muted text-end"
                          >74%</span
                        >
                      </div> -->
                      <div class="position-relative text-sm d-flex">
                        <!-- <mat-icon class="fs-5">watch_later</mat-icon> -->
                        <span>
                          {{ timeFormate(task.dueDate) }}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <a
                            class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"
                          >
                            <!-- src = {{getImgUrl(task.assignTo)}} | assignee image src -->
                            <img
                              alt="..."
                              src="../../../../assets/images/avatar-images/avatar-img-1.jpg"
                            />
                          </a>
                        </div>
                      </div>
                      <div>
                        <button
                          mat-icon-button
                          class="cus-round-btn"
                          color="warn"
                          (click)="deleteTask(task.id)"
                        >
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <main class="py-6 bg-surface-secondary">
    <!-- <div
      class="offcanvas offcanvas-end w-full w-lg-1/3"
      data-bs-scroll="true"
      data-bs-backdrop="true"
      tabindex="-1"
      id="offcanvasCreate"
      aria-labelledby="offcanvasCreateLabel"
    >
      <div class="offcanvas-header border-bottom py-5 bg-surface-secondary">
        <h5 class="offcanvas-title" id="offcanvasCreateLabel">
          Create a new task
        </h5>
        <button
          type="button"
          class="btn-close text-reset text-xs"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="vstack gap-8">
          <input
            type="text"
            class="form-control form-control-flush text-lg font-bold"
            placeholder="Enter your task name ..."
            value="Click here and start typing 😎"
          />
          <div class="border rounded">
            <div>
              <div class="textarea-autosize">
                <textarea
                  class="form-control border-0 shadow-none p-4"
                  rows="3"
                  placeholder="Enter description"
                  oninput="this.parentNode.dataset.replicatedValue = this.value"
                ></textarea>
              </div>
              <div class="d-flex align-items-center px-6 py-3 border-top">
                <div class="flex-fill d-flex align-items-center">
                  <h6 class="font-semibold text-xs text-muted text-opacity-60">
                    Markdown formatting
                  </h6>
                </div>
                <div class="text-end">
                  <div class="hstack gap-5 align-items-center">
                    <a href="#!" class="text-lg text-muted text-primary-hover"
                      ><i class="bi bi-images"></i> </a
                    ><a href="#!" class="text-lg text-muted text-primary-hover"
                      ><i class="bi bi-emoji-smile"></i> </a
                    ><a href="#!" class="text-lg text-muted text-primary-hover"
                      ><i class="bi bi-paperclip"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6 class="mb-4">Subtasks</h6>
            <div class="vstack gap-3">
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                    checked="checked"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Keep my mentality healthy by taking walks outside</a
                  >
                </div>
              </div>
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Build some new components in Figma</a
                  >
                </div>
              </div>
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Figure out how to use Clever from the help center!</a
                  >
                </div>
              </div>
              <div>
                <div class="form-check form-check-linethrough">
                  <input
                    class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                    type="checkbox"
                  />
                  <a
                    href="#offcanvas-show-task"
                    class="form-check-label font-regular ms-2"
                    data-bs-toggle="offcanvas"
                    >Create wireframes for the new dashboard</a
                  >
                </div>
              </div>
            </div>
            <div>
              <input
                type="text"
                class="form-control form-control-sm mt-4 mb-3"
                placeholder="Add another subtask"
              />
              <div class="d-flex gap-3">
                <button
                  type="button"
                  class="btn btn-link p-0 text-muted text-danger-hover text-sm font-semibold"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-link p-0 link-success text-sm font-semibold"
                >
                  Save task
                </button>
              </div>
            </div>
          </div>
          <hr class="m-0" />
          <div>
            <h6 class="mb-3">Attachments</h6>
            <div>
              <div
                class="rounded border-2 border-dashed border-primary-hover position-relative"
              >
                <div class="d-flex justify-content-center px-5 py-5">
                  <label
                    for="file_upload"
                    class="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                    ><input
                      id="file_upload"
                      name="file_upload"
                      type="file"
                      class="visually-hidden"
                  /></label>
                  <div class="text-center">
                    <div class="text-2xl text-muted">
                      <i class="bi bi-upload"></i>
                    </div>
                    <div class="d-flex text-sm mt-3">
                      <p class="font-semibold">
                        Upload a file or drag and drop
                      </p>
                    </div>
                    <p class="text-xs text-gray-500">PNG, JPG, GIF up to 3MB</p>
                  </div>
                </div>
              </div>
              <div class="list-group list-group-flush mt-2">
                <div class="list-group-item py-3 d-flex align-items-center">
                  <div class="flex-fill">
                    <span class="d-block h6 text-sm font-semibold mb-1"
                      >task-img-1.png</span
                    ><span class="d-block text-xs text-muted">350 kb</span>
                  </div>
                  <div class="ms-auto">
                    <div class="dropdown">
                      <a
                        class="text-muted"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ><i class="bi bi-three-dots-vertical"></i
                      ></a>
                      <div class="dropdown-menu">
                        <a href="#!" class="dropdown-item">Action </a
                        ><a href="#!" class="dropdown-item">Another action </a
                        ><a href="#!" class="dropdown-item"
                          >Something else here</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item py-3 d-flex align-items-center">
                  <div class="flex-fill">
                    <span class="d-block h6 text-sm font-semibold mb-1"
                      >task-img-1.png</span
                    ><span class="d-block text-xs text-muted">350 kb</span>
                  </div>
                  <div class="ms-auto">
                    <div class="dropdown">
                      <a
                        class="text-muted"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ><i class="bi bi-three-dots-vertical"></i
                      ></a>
                      <div class="dropdown-menu">
                        <a href="#!" class="dropdown-item">Action </a
                        ><a href="#!" class="dropdown-item">Another action </a
                        ><a href="#!" class="dropdown-item"
                          >Something else here</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item py-3 d-flex align-items-center">
                  <div class="flex-fill">
                    <span class="d-block h6 text-sm font-semibold mb-1"
                      >task-img-1.png</span
                    ><span class="d-block text-xs text-muted">350 kb</span>
                  </div>
                  <div class="ms-auto">
                    <div class="dropdown">
                      <a
                        class="text-muted"

                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        ><i class="bi bi-three-dots-vertical"></i
                      ></a>
                      <div class="dropdown-menu">
                        <a href="#!" class="dropdown-item">Action </a
                        ><a href="#!" class="dropdown-item">Another action </a
                        ><a href="#!" class="dropdown-item"
                          >Something else here</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer py-2 bg-surface-secondary">
        <button
          type="button"
          class="btn btn-sm btn-neutral"
          data-bs-dismiss="offcanvas"
        >
          Close
        </button>
        <button type="button" class="btn btn-sm btn-primary">Save</button>
      </div>
    </div> -->

    <!-- 
      <div class="card">
        <div class="px-4 px-md-8">
          <div class="d-flex align-items-center py-5 border-bottom">
            <h5 class="font-semibold">
              <span class="me-1">📬</span> <span>New tasks</span>
            </h5>
            <div class="hstack gap-3 ms-auto">
              <a  class="p-1 text-muted"
                ><i class="bi bi-calendar-event"></i> </a
              ><a  class="p-1 text-muted"
                ><i class="bi bi-three-dots"></i
              ></a>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-new-tasks-1"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Get another day full of work done!</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div>
                      <span class="badge bg-soft-warning text-warning"
                        >Important</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-success"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >74%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-new-tasks-2"
                      checked="checked"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Keep my mentality healthy by taking walks outside</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-warning"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >38%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center py-5 border-bottom">
            <h5 class="font-semibold">
              <span class="me-1">✨</span> <span>Do today</span>
            </h5>
            <div class="hstack gap-3 ms-auto">
              <a  class="p-1 text-muted"
                ><i class="bi bi-calendar-event"></i> </a
              ><a  class="p-1 text-muted"
                ><i class="bi bi-three-dots"></i
              ></a>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-do-today-1"
                      checked="checked"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Figure out how to use Clever from the help center!</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-primary"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >10%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-do-today-2"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Build some new components in Figma</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div>
                      <span class="badge bg-soft-success text-success"
                        >New product</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-success"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >83%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-do-today-3"
                      checked="checked"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Create wireframes for the new dashboard</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-danger"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >4%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center py-5 border-bottom">
            <h5 class="font-semibold">
              <span class="me-1">✍</span> <span>In progress</span>
            </h5>
            <div class="hstack gap-3 ms-auto">
              <a  class="p-1 text-muted"
                ><i class="bi bi-calendar-event"></i> </a
              ><a  class="p-1 text-muted"
                ><i class="bi bi-three-dots"></i
              ></a>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-in-progress-1"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Figure out how to use Clever from the help center!</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-primary"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >10%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-in-progress-2"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Build some new components in Figma</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div>
                      <span class="badge bg-soft-danger text-danger"
                        >Delayed</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-success"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >83%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-in-progress-3"
                      checked="checked"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Create wireframes for the new dashboard</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-danger"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >4%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-in-progress-4"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Get another day full of work done!</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-success"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >74%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-in-progress-5"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Keep my mentality healthy by taking walks outside</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-warning"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >38%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center py-5 border-bottom">
            <h5 class="font-semibold">
              <span class="me-1">✍</span> <span>For later</span>
            </h5>
            <div class="hstack gap-3 ms-auto">
              <a  class="p-1 text-muted"
                ><i class="bi bi-calendar-event"></i> </a
              ><a  class="p-1 text-muted"
                ><i class="bi bi-three-dots"></i
              ></a>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-for-later-1"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Figure out how to use Clever from the help center!</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-primary"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >10%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-for-later-2"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Build some new components in Figma</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div>
                      <span class="badge bg-soft-danger text-danger"
                        >Delayed</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-success"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >83%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-for-later-3"
                      checked="checked"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Create wireframes for the new dashboard</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-danger"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >4%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-for-later-4"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Get another day full of work done!</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-success"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >74%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group-item py-3">
              <div class="row g-3 align-items-center">
                <div class="col-sm-6 col-12 d-flex">
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      id="check-for-later-5"
                    />
                    <a
                      href="#offcanvasCreate"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Keep my mentality healthy by taking walks outside</a
                    >
                  </div>
                </div>
                <div class="col-sm-auto col-12 ms-sm-auto">
                  <div class="hstack gap-5 justify-content-end flex-wrap">
                    <div><span class="badge bg-soft- text-"></span></div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-check-circle-fill text-warning"></i>
                      <span class="d-inline-block w-8 text-muted text-end"
                        >38%</span
                      >
                    </div>
                    <div class="position-relative text-sm">
                      <i class="bi bi-files-alt text-muted me-1"></i>
                      <a

                        class="text-muted text-primary-hover stretched-link"
                        >18</a
                      >
                    </div>
                    <div
                      class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                    >
                      <span
                        class="avatar avatar-xs rounded-circle bg-primary text-white"
                        >JM</span
                      >
                      <div class="ps-2 pe-4">
                        <a

                          class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                          >+3</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </main>
</div>
