import { CmnApiCallService } from '../../../../core/services/cmn-api-call.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ApiStatus, leaves, RolesEnum } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { salaryDispatchedApi, userApi } from 'src/app/core/model/api-url';
import { User } from 'src/app/core/model/user';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserListService } from 'src/app/core/services/user-list.service';

@Component({
  selector: 'app-add-edit-salary-dispatched',
  templateUrl: './add-edit-salary-dispatched.component.html',
  styleUrls: ['./add-edit-salary-dispatched.component.scss'],
})
export class AddEditSalaryDispatchedComponent {
  destroy$ = new Subject();
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  userList: User[] = [];
  salaryDispatchedForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    userId: {
      required: 'User Name is required',
      pattern: 'User Name must be a numeric value',
    },
    salaryDispatchedId: {
      required: 'Salary ID is required',
    },
    amount: {
      required: 'Amount is required',
    },
    leaves: {
      required: 'Leaves is required',
    },
    totalAmount: {
      required: 'Total Amount is required',
    },
  };

  isEdit: boolean = false;
  salaryDispatchedId: number;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  leaveOptions = [
    { text: 'applied', value: leaves.applied },
    { text: 'accepted', value: leaves.accepted },
    { text: 'rejected', value: leaves.rejected },
  ];
  userId: number;

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.salaryDispatchedId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createSalaryForm();
    this.getUserList();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createSalaryForm() {
    this.salaryDispatchedForm = this.fb.group({
      userId: ['', [Validators.required]],
      salaryDispatchedId: [null],
      amount: [null, [Validators.required]],
      leaves: [null, [Validators.required]],
      totalAmount: ['', [Validators.required]],
    });
  }

  getUserList() {
    let roleUserUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;
    this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.userList = res.data.data;
            const currentUser = this.localStorageService.getUser();
            if (
              currentUser.role === RolesEnum.Developer ||
              currentUser.role === RolesEnum.Admin ||
              currentUser.role === RolesEnum.Creator
            ) {
              this.userList.push(currentUser);
            }
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onSubmit() {
    this.salaryDispatchedForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.salaryDispatchedForm.controls,
      this.validationMessages
    );

    if (this.salaryDispatchedForm.valid) {
      let salaryDispatchedApiUrl = this.isEdit
        ? salaryDispatchedApi.update
        : salaryDispatchedApi.create;
      let model = this.salaryDispatchedForm.value;
      if (!this.isEdit) {
        delete model.salaryDispatchedId;
      }
      // model.userId = +this.userId;
      if (this.isEdit) {
        model['id'] = +this.salaryDispatchedId;
      }

      this.cmnApiCallService
        .addUpdateData(salaryDispatchedApiUrl, model)
        .subscribe(
          (res: ApiResponse) => {
            if (res.status === ApiStatus.SUCCESS) {
              if (!this.isEdit) {
                this.toastService.successMsg(
                  'Success',
                  `Salary Dispatched created successfully`
                );
              } else {
                this.toastService.successMsg(
                  'Updated',
                  'Salary Dispatched has been successfully edited'
                );
              }
              this.cmnAddEditSidenavService.getUpdatedDataList(
                'SalaryDispatchedListComponent'
              );
              this.salaryDispatchedForm.reset();
              this.closeAddEditSideNav();
            }
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
    }
  }

  patchVal() {
    let salaryGetByIdUrl = salaryDispatchedApi.byId;
    this.cmnApiCallService
      .getById(salaryGetByIdUrl, this.salaryDispatchedId)
      .subscribe(
        (res: ApiResponse) => {
          if (res.status == ApiStatus.SUCCESS) {
            if (res && res.data) {
              this.salaryDispatchedForm.patchValue({
                userId: res.data.userId,
                salaryDispatchedId: res.data.salaryDispatchedId,
                amount: res.data.amount,
                leaves: res.data.leaves,
                totalAmount: res.data.totalAmount,
              });
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
