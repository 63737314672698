<div class="project-overview-wrapper">
  <div
    class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
  >
    <header>
      <div class="container-fluid">
        <div class="border-bottom pt-6 cmn-header-box pb-3">
          <div class="row align-item-center">
            <div class="col-sm col-12">
              <h2>👋 Portfolio</h2>
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                <!-- <a class="btn btn-sm btn-neutral border-base" (click)="openShareToWebDialog()">
                                    <span class="pe-2">
                                        <i class="bi bi-people-fill"></i>
                                    </span>
                                    <span> Share</span>
                                </a> -->
                <!-- <page-button
                  [config]="{ rank: 'neutral' }"
                  [icon]="'bi bi-people-fill'"
                  [loading]="false"
                  (clicked)="openShareToWebDialog()"
                  >Share</page-button
                > -->
                <page-button
                  [config]="{ rank: 'primary' }"
                  [icon]="'bi bi-plus-square-dotted'"
                  [loading]="false"
                  (clicked)="openAddEditSideNav()"
                  *ngIf="portfolio['write']"
                  >Add</page-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div
      class="offcanvas offcanvas-end w-full w-lg-1/3"
      data-bs-scroll="true"
      data-bs-backdrop="true"
      tabindex="-1"
      id="offcanvasCreate"
      aria-labelledby="offcanvasCreateLabel"
    >
      <app-add-edit-portfolio
        (getPortfolioList)="getPortFolioList()"
      ></app-add-edit-portfolio>
    </div>
    <div class="container-fluid mt-5">
      <div class="card">
        <div class="card-header border-bottom d-flex align-items-center">
          <h5 class="me-auto">Portfolio List</h5>
          <!-- <div class="dropdown">
            <a
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              class="text-muted"
              ><i class="bi bi-three-dots-vertical"></i
            ></a>
            <div class="dropdown-menu">
              <a href="#!" class="dropdown-item">Action </a
              ><a href="#!" class="dropdown-item">Another action </a
              ><a href="#!" class="dropdown-item">Something else here</a>
            </div>
          </div> -->
        </div>
        <main class="bg-surface-secondary">
          <!-- <app-share-to-web-popup></app-share-to-web-popup> -->
          <div class="cmn-table-wrapper table-container">
            <table mat-table matSort [dataSource]="dataSource" class="w-100">
              <ng-container matColumnDef="image">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class="avatar avatar-sm bg-warning rounded-circle text-white"
                  >
                    <img
                      src="{{ element.imageUrls }}"
                      onerror="this.src='../../../assets/images/thumbnail/img-profile.jpg'"
                      *ngIf="element.imageUrls"
                    />
                    <mat-icon *ngIf="!element.imageUrls">person</mat-icon>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="title">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <!-- <span
                    class="avatar avatar-sm bg-warning rounded-circle text-white me-2"
                  >
                    <img class="img-fluid cus-image" [src]="element.image" />
                  </span> -->
                  <span>
                    {{ element.title | dataValidation }}
                  </span>
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="description">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Description
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.description | dataValidation }}
                </td>
              </ng-container> -->
              <ng-container matColumnDef="technology">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Technology
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.technology | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="libraryFrameworks">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Library/Frameworks
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.libraryFrameworks | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.role | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="duration">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Duration
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.duration | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="country">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Country
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.country | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="teamsize">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Team Size
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.teamsize | dataValidation }}
                </td>
              </ng-container>
              <ng-container matColumnDef="links">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Links</th>
                <td mat-cell *matCellDef="let element">
                  <div class="d-flex flex-column">
                    <a [href]="element.links" target="_blank">{{
                      element.links | dataValidation
                    }}</a>
                  </div>
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="image">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Image</th>
                <td mat-cell *matCellDef="let element">
                  <div style="max-width: 60px">
                    <img class="img-fluid" [src]="element.image" alt="" />
                  </div>
                </td>
              </ng-container> -->
              <ng-container matColumnDef="empty">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td class="text-end" mat-cell *matCellDef="let element">
                  <!-- <a
                    (click)="openAddEditSideNav(element.id)"
                    class="btn btn-sm btn-neutral"
                    >Edit</a
                  > -->
                  <div class="d-flex">
                    <!-- <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="openAddEditSideNav(element.id)"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                      (click)="deletePortfolio(element.id)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button> -->
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      (click)="openAddEditSideNav(element.id)"
                      *ngIf="portfolio['update']"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      class="cus-round-btn"
                      color="warn"
                      (click)="deletePortfolio(element.id)"
                      *ngIf="portfolio['delete']"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-paginator
            [length]="paginatorData?.itemCount"
            [pageSize]="paginatorData?.perPage"
            [pageSizeOptions]="[10, 20, 50]"
            aria-label="Select page"
            (page)="pageChange($event)"
            class="mat-paginator-sticky"
            >,
          </mat-paginator>
        </main>
      </div>
    </div>
  </div>
</div>
