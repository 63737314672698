import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TasksRoutingModule } from './tasks-routing.module';
import { TaskOverviewComponent } from './task-overview/task-overview.component';
import { TaskListviewComponent } from './task-listview/task-listview.component';
import { TaskListviewDetailsComponent } from './task-listview-details/task-listview-details.component';
import { TaskBoardviewComponent } from './task-boardview/task-boardview.component';
import { AddEditTaskComponent } from './add-edit-task/add-edit-task.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    TaskOverviewComponent,
    TaskListviewComponent,
    TaskListviewDetailsComponent,
    TaskBoardviewComponent,
    AddEditTaskComponent,
  ],
  imports: [
    CommonModule,
    TasksRoutingModule,
    MatProgressBarModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SharedModule,
    DragDropModule,
  ],
  exports: [AddEditTaskComponent],
})
export class TasksModule {}
