<!-- <div class="offcanvas-header border-bottom py-5 bg-surface-secondary">
  <h5 class="offcanvas-title" id="offcanvasCreateLabel">
    Create a new user access
  </h5>
  <button
    type="button"
    class="btn-close text-reset text-xs"
    data-bs-dismiss="offcanvas"
    aria-label="Close"
  ></button>
</div>
<div class="offcanvas-body">
  <div class="vstack gap-8">
    <input
      type="text"
      class="form-control form-control-flush text-lg font-bold"
      placeholder="Enter your task name ..."
      value="Click here and start typing 😎"
    />
    <div class="border rounded">
      <div>
        <div class="textarea-autosize">
          <textarea
            class="form-control border-0 shadow-none p-4"
            rows="3"
            placeholder="Enter description"
            oninput="this.parentNode.dataset.replicatedValue = this.value"
          ></textarea>
        </div>
        <div class="d-flex align-items-center px-6 py-3 border-top">
          <div class="flex-fill d-flex align-items-center">
            <h6 class="font-semibold text-xs text-muted text-opacity-60">
              Markdown formatting
            </h6>
          </div>
          <div class="text-end">
            <div class="hstack gap-5 align-items-center">
              <a href="#!" class="text-lg text-muted text-primary-hover"
                ><i class="bi bi-images"></i> </a
              ><a href="#!" class="text-lg text-muted text-primary-hover"
                ><i class="bi bi-emoji-smile"></i> </a
              ><a href="#!" class="text-lg text-muted text-primary-hover"
                ><i class="bi bi-paperclip"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h6 class="mb-4">Subtasks</h6>
      <div class="vstack gap-3">
        <div>
          <div class="form-check form-check-linethrough">
            <input
              class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
              type="checkbox"
              checked="checked"
            />
            <a
              href="#offcanvas-show-task"
              class="form-check-label font-regular ms-2"
              data-bs-toggle="offcanvas"
              >Keep my mentality healthy by taking walks outside</a
            >
          </div>
        </div>
        <div>
          <div class="form-check form-check-linethrough">
            <input
              class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
              type="checkbox"
            />
            <a
              href="#offcanvas-show-task"
              class="form-check-label font-regular ms-2"
              data-bs-toggle="offcanvas"
              >Build some new components in Figma</a
            >
          </div>
        </div>
        <div>
          <div class="form-check form-check-linethrough">
            <input
              class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
              type="checkbox"
            />
            <a
              href="#offcanvas-show-task"
              class="form-check-label font-regular ms-2"
              data-bs-toggle="offcanvas"
              >Figure out how to use Clever from the help center!</a
            >
          </div>
        </div>
        <div>
          <div class="form-check form-check-linethrough">
            <input
              class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
              type="checkbox"
            />
            <a
              href="#offcanvas-show-task"
              class="form-check-label font-regular ms-2"
              data-bs-toggle="offcanvas"
              >Create wireframes for the new dashboard</a
            >
          </div>
        </div>
      </div>
      <div>
        <input
          type="text"
          class="form-control form-control-sm mt-4 mb-3"
          placeholder="Add another subtask"
        />
        <div class="d-flex gap-3">
          <button
            type="button"
            class="btn btn-link p-0 text-muted text-danger-hover text-sm font-semibold"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-link p-0 link-success text-sm font-semibold"
          >
            Save task
          </button>
        </div>
      </div>
    </div>
    <hr class="m-0" />
    <div>
      <h6 class="mb-3">Attachments</h6>
      <div>
        <div
          class="rounded border-2 border-dashed border-primary-hover position-relative"
        >
          <div class="d-flex justify-content-center px-5 py-5">
            <label
              for="file_upload"
              class="position-absolute w-full h-full top-0 start-0 cursor-pointer"
              ><input
                id="file_upload"
                name="file_upload"
                type="file"
                class="visually-hidden"
            /></label>
            <div class="text-center">
              <div class="text-2xl text-muted">
                <i class="bi bi-upload"></i>
              </div>
              <div class="d-flex text-sm mt-3">
                <p class="font-semibold">Upload a file or drag and drop</p>
              </div>
              <p class="text-xs text-gray-500">PNG, JPG, GIF up to 3MB</p>
            </div>
          </div>
        </div>
        <div class="list-group list-group-flush mt-2">
          <div class="list-group-item py-3 d-flex align-items-center">
            <div class="flex-fill">
              <span class="d-block h6 text-sm font-semibold mb-1"
                >task-img-1.png</span
              ><span class="d-block text-xs text-muted">350 kb</span>
            </div>
            <div class="ms-auto">
              <div class="dropdown">
                <a
                  class="text-muted"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><i class="bi bi-three-dots-vertical"></i
                ></a>
                <div class="dropdown-menu">
                  <a href="#!" class="dropdown-item">Action </a
                  ><a href="#!" class="dropdown-item">Another action </a
                  ><a href="#!" class="dropdown-item">Something else here</a>
                </div>
              </div>
            </div>
          </div>
          <div class="list-group-item py-3 d-flex align-items-center">
            <div class="flex-fill">
              <span class="d-block h6 text-sm font-semibold mb-1"
                >task-img-1.png</span
              ><span class="d-block text-xs text-muted">350 kb</span>
            </div>
            <div class="ms-auto">
              <div class="dropdown">
                <a
                  class="text-muted"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><i class="bi bi-three-dots-vertical"></i
                ></a>
                <div class="dropdown-menu">
                  <a href="#!" class="dropdown-item">Action </a
                  ><a href="#!" class="dropdown-item">Another action </a
                  ><a href="#!" class="dropdown-item">Something else here</a>
                </div>
              </div>
            </div>
          </div>
          <div class="list-group-item py-3 d-flex align-items-center">
            <div class="flex-fill">
              <span class="d-block h6 text-sm font-semibold mb-1"
                >task-img-1.png</span
              ><span class="d-block text-xs text-muted">350 kb</span>
            </div>
            <div class="ms-auto">
              <div class="dropdown">
                <a
                  class="text-muted"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><i class="bi bi-three-dots-vertical"></i
                ></a>
                <div class="dropdown-menu">
                  <a href="#!" class="dropdown-item">Action </a
                  ><a href="#!" class="dropdown-item">Another action </a
                  ><a href="#!" class="dropdown-item">Something else here</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer py-2 bg-surface-secondary">
  <button
    type="button"
    class="btn btn-sm btn-neutral"
    data-bs-dismiss="offcanvas"
  >
    Close
  </button>
  <button type="button" class="btn btn-sm btn-primary">Save</button>
</div> -->

<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} portfolio
    </h5>
    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="container-fluid px-0">
      <form [formGroup]="portfolioForm" class="row">
        <div class="col-12 mx-auto mb-4">
          <app-image-upload
            (uploadedImageEvent)="imageEvent($event)"
            [isMulti]="true"
            [showPreview]="true"
            [uploadedImage]="userUploadedImage"
          ></app-image-upload>
        </div>
        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" required />
            <mat-error
              *ngIf="portfolioForm.controls['title'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["title"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Technology</mat-label>
            <input matInput formControlName="tech" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Libs/Framework</mat-label>
            <input matInput formControlName="libsFramework" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Project Short Description</mat-label>
            <input matInput formControlName="projectShortDesc" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Development Role</mat-label>
            <input matInput formControlName="devRole" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Duration</mat-label>
            <input matInput formControlName="duration" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Origin Country</mat-label>
            <input matInput formControlName="originCountry" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Team Size</mat-label>
            <input matInput type="number" formControlName="teamSize" />
            <mat-error
              *ngIf="portfolioForm.controls['teamSize'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["teamSize"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Links</mat-label>
            <input matInput formControlName="links" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Color</mat-label>
            <input matInput type="color" formControlName="color" />
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-12">
          <span class="form-label d-block mb-2">Role Description</span>
          <!-- <mat-form-field class="w-100" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="desc" matInput></textarea>
          </mat-form-field> -->
          <ckeditor
            id="roleDescription"
            [editor]="editor"
            [data]="editorData"
            [config]="editorConfig"
            (ready)="onReady($event)"
          ></ckeditor>
        </div>
        <div class="cmn-form-field col-12 mt-4">
          <span class="form-label d-block mb-2">Project Description</span>
          <ckeditor
            id="projectDescription"
            [editor]="editor"
            [data]="projectDescription"
            (ready)="onReadyProjectDesctiprion($event)"
            [config]="editorConfig"
          ></ckeditor>
        </div>
        <div class="cmn-form-field col-12 mt-4">
          <span class="form-label d-block mb-2">Project Approach</span>
          <ckeditor
            id="projectApproach"
            [editor]="editor"
            [data]="projectApproach"
            (ready)="onReadyProjectApproach($event)"
            [config]="editorConfig"
          ></ckeditor>
        </div>
        <div class="cmn-form-field col-12 mt-4">
          <span class="form-label d-block mb-2">Project Overview</span>
          <ckeditor
            id="projectApproach"
            [editor]="editor"
            [data]="projectOverview"
            (ready)="onReadyProjectOverview($event)"
            [config]="editorConfig"
          ></ckeditor>
        </div>
        <div class="cmn-form-field col-12 mt-4">
          <span class="form-label d-block mb-2">Project Result</span>
          <ckeditor
            id="projectApproach"
            [editor]="editor"
            [data]="projectResult"
            (ready)="onReadyProjectResult($event)"
            [config]="editorConfig"
          ></ckeditor>
        </div>
        <div class="cmn-form-field col-12 mt-4">
          <span class="form-label d-block mb-2">Project Notes</span>
          <ckeditor
            id="projectApproach"
            [editor]="editor"
            [data]="projectNotes"
            (ready)="onReadyProjectNotes($event)"
            [config]="editorConfig"
          ></ckeditor>
        </div>
        <!-- <div class="cmn-form-field col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>Image URLs</mat-label>
              <input type="select" matInput formControlName="imageUrls" />
            </mat-form-field>
          </div> -->
      </form>
    </div>
  </div>

  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
