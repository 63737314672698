import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiResponse } from 'src/app/core/model/api-response';
import { roleApi } from 'src/app/core/model/api-url';
import { roleModel } from 'src/app/core/model/role.model';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss'],
})
export class AddEditRoleComponent {
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  roleForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    name: {
      required: 'Name is required',
    },
    weight: {
      required: 'weight is required',
      min: 'Weight should be 1',
    },
    code: {
      required: 'Code is required',
    },
  };
  isEdit: boolean = false;
  roleId: number;
  test: File;
  loadingImage: boolean = false; // Add this variable to control loader visibility

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.roleId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createRoleForm();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  // You can also close the add/edit sidenav if needed
  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createRoleForm() {
    this.roleForm = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      weight: [null, [Validators.required, Validators.min(1)]],
    });
  }

  onSubmit() {
    this.roleForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.roleForm.controls,
      this.validationMessages
    );

    if (this.roleForm.valid) {
      let roleApiUrl = this.isEdit ? roleApi.update : roleApi.create;
      let model: roleModel = this.roleForm.value;

      if (this.isEdit) {
        model['id'] = +this.roleId;
      }

      this.cmnApiCallService.addUpdateData(roleApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `Role created successfully`
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                res.data[0].title
                  ? `Role "${res.data[0].name}" has been successfully edited`
                  : 'Role has been successfully edited'
              );
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'RoleListComponent'
            );
            this.roleForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let roleGetByIdUrl = roleApi.byId;
    this.cmnApiCallService.getById(roleGetByIdUrl, this.roleId).subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.roleForm.patchValue({
              name: res.data.name,
              weight: res.data.weight,
              code: res.data.code,
            });
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }
}
