import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeaveComponent } from './leave/leave.component';
import { InOutComponent } from './in-out/in-out.component';
import { AttendanceListComponent } from './attendance-list/attendance-list.component';
import { RoleGuard } from 'src/app/core/guard/role.guars';

const routes: Routes = [
  {
    path: 'overview',
    component: AttendanceListComponent,
    canActivate: [RoleGuard],
  },
  { path: 'leave', component: LeaveComponent, canActivate: [RoleGuard] },
  { path: 'in-out', component: InOutComponent, canActivate: [RoleGuard] },
  { path: '', redirectTo: 'daily', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AttendanceRoutingModule {}
