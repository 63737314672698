<div class="project-table-view-wrapper">
  <header class="page-cmn-header">
    <div class="container-fluid">
      <div class="border-bottom pt-6 pb-3">
        <div class="row align-items-center">
          <div class="col-sm col-12">
            <h1 class="h2 ls-tight">
              <span class="d-inline-block me-3">😎</span>My Cool Projects
            </h1>
          </div>
          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <a
                href="#modalExport"
                class="btn btn-sm btn-neutral border-base"
                data-bs-toggle="modal"
              >
                <span class="pe-2">
                  <i class="bi bi-people-fill"></i>
                </span>
                <span> Share </span>
              </a>
              <a
                href="#offcanvasCreate"
                class="btn btn-sm btn-primary"
                data-bs-toggle="offcanvas"
              >
                <span class="pe-2">
                  <i class="bi bi-plus-square-dotted"></i>
                </span>
                <span>Create</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="container-fluid py-6 vstack gap-4">
    <div class="row gy-2">
      <div class="col-12 col-sm-auto">
        <div class="cmn-search-box cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Ex. ium"
              #input
            />
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-sm-auto">
        <div class="cmn-search-box cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select>
              <mat-option> Done </mat-option>
              <mat-option> In Progress </mat-option>
              <mat-option> Project at risk </mat-option>
              <mat-option> Not started </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header border-bottom d-flex align-items-center">
        <h5 class="me-auto">All projects</h5>
        <div class="dropdown">
          <a
            class="text-muted"
            href="javascript:void(0)"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ><i class="bi bi-three-dots-vertical"></i
          ></a>
          <div class="dropdown-menu">
            <a href="#!" class="dropdown-item">Action </a
            ><a href="#!" class="dropdown-item">Another action </a
            ><a href="#!" class="dropdown-item">Something else here</a>
          </div>
        </div>
      </div>
      <div class="cmn-table-wrapper">
        <table mat-table [dataSource]="dataSource" class="w-100">
          <!-- Position Column -->

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              <span
                class="avatar avatar-sm bg-warning rounded-circle text-white"
              >
                <img src="../../../../assets/images/thumbnail/people-1.jpg" />
              </span>
              {{ element.name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>DUE DATE</th>
            <td mat-cell *matCellDef="let element">{{ element.date }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>STATUS</th>
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
          </ng-container>
          <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef>TEAM</th>
            <td mat-cell *matCellDef="let element">{{ element.team }}</td>
          </ng-container>
          <ng-container matColumnDef="completion">
            <th mat-header-cell *matHeaderCellDef>COMPLETION</th>
            <td mat-cell *matCellDef="let element">
              {{ element.completion }}
            </td>
          </ng-container>
          <ng-container matColumnDef="empty">
            <th mat-header-cell *matHeaderCellDef></th>

            <td class="text-end" mat-cell *matCellDef="let element">
              <div class="d-flex">
                <button
                  type="button"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                  (click)="openAddEditSideNav(element.id)"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                  (click)="deleteProject(element.id)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 25, 100]"
          aria-label="Select page of users"
        ></mat-paginator>
      </div>
    </div>
  </div>
</div>
<app-creat-new-project> </app-creat-new-project>
