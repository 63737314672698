import { LocalStorageService } from './../../../core/services/local-storage.service';
import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { ApiStatus, leaves } from 'src/app/core/enums/roles.enum';
import { leaveApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { Subject, Subscription, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { User } from 'src/app/core/model/user';
import { UserListService } from 'src/app/core/services/user-list.service';

@Component({
  selector: 'app-leave',
  templateUrl: './leave.component.html',
  styleUrls: ['./leave.component.scss'],
})
export class LeaveComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // dataSource: MatTableDataSource<any>;
  destroy$ = new Subject();
  displayedColumns: string[] = [
    'userId',
    'status',
    'startDate',
    'endDate',
    'desc',
    'empty',
  ];
  LeaveList = [];
  LeaveStatusEnum = leaves;
  dataSourceApplied = new MatTableDataSource(this.LeaveList);
  dataSourceRequest = new MatTableDataSource(this.LeaveList);
  updateLeave: boolean = false;
  private updateLeaveSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  userList: User[] = [];
  loggedInUser = this.localStorageService.getUser();

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private userListService: UserListService
  ) {}

  async ngOnInit() {
    await this.getUserList();
    await this.getLeave();
    this.subscribeToUpdateProjectList();
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav('LeaveComponent', id);
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav('LeaveComponent');
    }
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getLeave();
  }

  getStatusColorClass(status): string {
    switch (status) {
      case '0':
        return 'status-yellow';
      case '1':
        return 'status-green';
      case '2':
        return 'status-red';
      default:
        return '';
    }
  }

  async getLeave() {
    let LeaveUrl = leaveApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.apiQuery.options.paginate = 10000;
    this.cmnApiCallService.getList(LeaveUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          let requests = [];
          let applied = [];
          res.data.data.forEach((element) => {
            if (
              this.loggedInUser.id == element.approverId &&
              element.status == leaves.applied
            ) {
              requests.push(element);
            } else if (this.loggedInUser.id == element.userId) {
              applied.push(element);
            }
          });
          this.dataSourceApplied = new MatTableDataSource(applied);
          this.dataSourceRequest = new MatTableDataSource(requests);
          this.paginatorData = res.data.paginator;
          this.dataSourceApplied.sort = this.sort;
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  getUserList() {
    this.userListService
      .getUserList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userList) => {
        this.userList = [];
        this.userList.push(...userList);
      });
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  deleteLeave(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteLeaveUrl = leaveApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService.addUpdateData(deleteLeaveUrl, model).subscribe(
          (res: ApiResponse) => {
            if (res.status == ApiStatus.SUCCESS) {
              this.toastService.successMsg(
                'Deleted',
                `Leave has been successfully deleted`
              );
              this.getLeave();
            }
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
      }
    });
  }

  formatCustomDate(date) {
    if (date) {
      const formattedDate = moment(date).format('DD/MM/YYYY');
      return formattedDate;
    } else {
      return null;
    }
  }

  subscribeToUpdateProjectList(): void {
    this.updateLeaveSubscription =
      this.cmnAddEditSidenavService.updateLeaveList.subscribe(async (res) => {
        if (res) {
          await this.getLeave();
        }
      });
  }

  getUserNameById(id) {
    return this.userListService.getUserDataByInfo(this.userList, id, 'name');
  }

  getStatusFromEnum(statusValue: number): string {
    switch (+statusValue) {
      case leaves.applied:
        return 'applied';
      case leaves.accepted:
        return 'accepted';
      case leaves.rejected:
        return 'rejected';
      default:
        return 'Unknown';
    }
  }

  changeStatus(status, element) {
    element.status = status;
    let data = {
      id: element.id,
      status: status,
    };
    this.cmnApiCallService.addUpdateData(leaveApi.update, data).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.getLeave();
          this.toastService.successMsg(
            'Updated',
            'Leave has been successfully edited'
          );
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.updateLeaveSubscription) {
      this.updateLeaveSubscription.unsubscribe();
    }
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
