import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private datePipe: DatePipe) { }

  changeDateFormatToUTC(date: any) {
    // Parse the input date
    const parsedDate = moment(date);

    // Check if the parsed date is valid
    if (parsedDate.isValid()) {
      // Convert to UTC and format in the desired format
      return parsedDate.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else {
      // Handle invalid dates or return an error message
      return 'Invalid Date';
    }
  }

  convertToCustomFormat(date): string {
    // Convert ISO string to JavaScript Date object
    const dateObj: Date = new Date(date);

    // Use DatePipe to format the date
    return this.datePipe.transform(dateObj, 'd MMM');
  }

}


