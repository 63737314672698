<div class="h-100 overflow-auto cmn-add-edit-sidenav">
    <div
      class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
    >
      <h5 class="add-edit-sidenav-title">
        {{ isEdit ? "Update" : "Create a new" }} team detail
      </h5>
  
      <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="add-edit-sidenav-body px-4 pt-4">
      <div class="px-0 container-fluid">
        <form [formGroup]="teamForm" class="row">
          <div class="cmn-form-field col-12">
            <mat-form-field appearance="outline">
              <mat-label>User Name</mat-label>
              <mat-select placeholder="Select your user" formControlName="userId">
                <mat-option *ngFor="let user of userList" [value]="user.id">{{
                  user.name
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="teamForm.controls['userId'].invalid"
                class="error-msg text-danger"
              >
                {{ formErrors["userId"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="cmn-form-field col-12">
            <mat-form-field appearance="outline">
              <mat-label>Project Name</mat-label>
              <mat-select placeholder="Select your project" formControlName="projectId">
                <mat-option *ngFor="let project of projectList" [value]="project.id">{{
                  project.name
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="teamForm.controls['projectId'].invalid"
                class="error-msg text-danger"
              >
                {{ formErrors["projectId"] }}
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="cmn-form-field col-lg-6 col-12">
            <mat-form-field appearance="outline">
              <mat-label>Teams Name</mat-label>
              <input matInput formControlName="name" />
              <mat-error
                *ngIf="teamForm.controls['name'].invalid"
                class="error-msg text-danger"
              >
                {{ formErrors["name"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 cmn-form-field">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Description</mat-label>
              <textarea formControlName="desc" rows="3" matInput></textarea>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
    <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
      <page-button
        data-bs-dismiss="add-edit-sidenav"
        [config]="{ rank: 'neutral' }"
        [loading]="false"
        (clicked)="closeAddEditSideNav()"
        >Close</page-button
      >
      <page-button
        [config]="{ rank: 'primary' }"
        [loading]="false"
        (clicked)="onSubmit()"
        >{{ isEdit ? "Update" : "Add" }}
      </page-button>
    </div>
  </div>
  