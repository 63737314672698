<div class="project-overview-wrapper">
    <div
      class="bg-surface-secondary flex-lg-1 h-screen-header overflow-y-lg-auto"
    >
      <header>
        <div class="container-fluid">
          <div class="border-bottom pt-6 cmn-header-box pb-3">
            <div class="row align-item-center">
              <div class="col-sm col-12">
                <h2>👋 User Access</h2>
              </div>
              <!-- <div class="col-sm-auto col-12 mt-4 mt-sm-0">
                <div class="hstack gap-2 justify-content-sm-end">
                  <a class="btn btn-sm btn-neutral border-base" >
                                    <span class="pe-2">
                                        <i class="bi bi-people-fill"></i>
                                    </span>
                                    <span> Share</span>
                                </a>
                  <page-button
                    [config]="{ rank: 'primary' }"
                    [icon]="'bi bi-plus-square-dotted'"
                    [loading]="false"
                    (clicked)="openAddEditSideNav()"
                    >Add</page-button
                  >
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </header>
      <!-- <div
        class="offcanvas offcanvas-end w-full w-lg-1/3"
        data-bs-scroll="true"
        data-bs-backdrop="true"
        tabindex="-1"
        id="offcanvasCreate"
        aria-labelledby="offcanvasCreateLabel"
      >
        <div class="offcanvas-header border-bottom py-5 bg-surface-secondary">
          <h5 class="offcanvas-title" id="offcanvasCreateLabel">
            Create a new user access
          </h5>
          <button
            type="button"
            class="btn-close text-reset text-xs"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="vstack gap-8">
            <input
              type="text"
              class="form-control form-control-flush text-lg font-bold"
              placeholder="Enter your task name ..."
              value="Click here and start typing 😎"
            />
            <div class="border rounded">
              <div>
                <div class="textarea-autosize">
                  <textarea
                    class="form-control border-0 shadow-none p-4"
                    rows="3"
                    placeholder="Enter description"
                    oninput="this.parentNode.dataset.replicatedValue = this.value"
                  ></textarea>
                </div>
                <div class="d-flex align-items-center px-6 py-3 border-top">
                  <div class="flex-fill d-flex align-items-center">
                    <h6 class="font-semibold text-xs text-muted text-opacity-60">
                      Markdown formatting
                    </h6>
                  </div>
                  <div class="text-end">
                    <div class="hstack gap-5 align-items-center">
                      <a href="#!" class="text-lg text-muted text-primary-hover"
                        ><i class="bi bi-images"></i> </a
                      ><a href="#!" class="text-lg text-muted text-primary-hover"
                        ><i class="bi bi-emoji-smile"></i> </a
                      ><a href="#!" class="text-lg text-muted text-primary-hover"
                        ><i class="bi bi-paperclip"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-4">Subtasks</h6>
              <div class="vstack gap-3">
                <div>
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                      checked="checked"
                    />
                    <a
                      href="#offcanvas-show-task"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Keep my mentality healthy by taking walks outside</a
                    >
                  </div>
                </div>
                <div>
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                    />
                    <a
                      href="#offcanvas-show-task"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Build some new components in Figma</a
                    >
                  </div>
                </div>
                <div>
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                    />
                    <a
                      href="#offcanvas-show-task"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Figure out how to use Clever from the help center!</a
                    >
                  </div>
                </div>
                <div>
                  <div class="form-check form-check-linethrough">
                    <input
                      class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                      type="checkbox"
                    />
                    <a
                      href="#offcanvas-show-task"
                      class="form-check-label font-regular ms-2"
                      data-bs-toggle="offcanvas"
                      >Create wireframes for the new dashboard</a
                    >
                  </div>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  class="form-control form-control-sm mt-4 mb-3"
                  placeholder="Add another subtask"
                />
                <div class="d-flex gap-3">
                  <button
                    type="button"
                    class="btn btn-link p-0 text-muted text-danger-hover text-sm font-semibold"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-link p-0 link-success text-sm font-semibold"
                  >
                    Save task
                  </button>
                </div>
              </div>
            </div>
            <hr class="m-0" />
            <div>
              <h6 class="mb-3">Attachments</h6>
              <div>
                <div
                  class="rounded border-2 border-dashed border-primary-hover position-relative"
                >
                  <div class="d-flex justify-content-center px-5 py-5">
                    <label
                      for="file_upload"
                      class="position-absolute w-full h-full top-0 start-0 cursor-pointer"
                      ><input
                        id="file_upload"
                        name="file_upload"
                        type="file"
                        class="visually-hidden"
                    /></label>
                    <div class="text-center">
                      <div class="text-2xl text-muted">
                        <i class="bi bi-upload"></i>
                      </div>
                      <div class="d-flex text-sm mt-3">
                        <p class="font-semibold">
                          Upload a file or drag and drop
                        </p>
                      </div>
                      <p class="text-xs text-gray-500">PNG, JPG, GIF up to 3MB</p>
                    </div>
                  </div>
                </div>
                <div class="list-group list-group-flush mt-2">
                  <div class="list-group-item py-3 d-flex align-items-center">
                    <div class="flex-fill">
                      <span class="d-block h6 text-sm font-semibold mb-1"
                        >task-img-1.png</span
                      ><span class="d-block text-xs text-muted">350 kb</span>
                    </div>
                    <div class="ms-auto">
                      <div class="dropdown">
                        <a
                          class="text-muted"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="bi bi-three-dots-vertical"></i
                        ></a>
                        <div class="dropdown-menu">
                          <a href="#!" class="dropdown-item">Action </a
                          ><a href="#!" class="dropdown-item">Another action </a
                          ><a href="#!" class="dropdown-item"
                            >Something else here</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item py-3 d-flex align-items-center">
                    <div class="flex-fill">
                      <span class="d-block h6 text-sm font-semibold mb-1"
                        >task-img-1.png</span
                      ><span class="d-block text-xs text-muted">350 kb</span>
                    </div>
                    <div class="ms-auto">
                      <div class="dropdown">
                        <a
                          class="text-muted"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="bi bi-three-dots-vertical"></i
                        ></a>
                        <div class="dropdown-menu">
                          <a href="#!" class="dropdown-item">Action </a
                          ><a href="#!" class="dropdown-item">Another action </a
                          ><a href="#!" class="dropdown-item"
                            >Something else here</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item py-3 d-flex align-items-center">
                    <div class="flex-fill">
                      <span class="d-block h6 text-sm font-semibold mb-1"
                        >task-img-1.png</span
                      ><span class="d-block text-xs text-muted">350 kb</span>
                    </div>
                    <div class="ms-auto">
                      <div class="dropdown">
                        <a
                          class="text-muted"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="bi bi-three-dots-vertical"></i
                        ></a>
                        <div class="dropdown-menu">
                          <a href="#!" class="dropdown-item">Action </a
                          ><a href="#!" class="dropdown-item">Another action </a
                          ><a href="#!" class="dropdown-item"
                            >Something else here</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer py-2 bg-surface-secondary">
          <button
            type="button"
            class="btn btn-sm btn-neutral"
            data-bs-dismiss="offcanvas"
          >
            Close
          </button>
          <button type="button" class="btn btn-sm btn-primary">Save</button>
        </div>
      </div> -->
      <div class="container-fluid mt-5">
        <div class="card">
          <div class="card-header border-bottom d-flex align-items-center">
            <h5 class="me-3">Role List</h5>
            <div class="cmn-form-field user-list-dropdown mb-1">
              <mat-form-field appearance="outline">
                <mat-label>Roles</mat-label>
                <mat-select
                  [(value)]="selectedUserId"
                  (valueChange)="getUserAccessById(selectedUserId)"
                  placeholder="Select your role"
                >
                  <mat-option *ngFor="let role of roleList" [value]="role.id">{{
                    role.name
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <main class="bg-surface-secondary">
            <!-- <app-share-to-web-popup></app-share-to-web-popup> -->
            <div class="cmn-table-wrapper table-container">
              <table mat-table [dataSource]="dataSource" matSort>
                <!-- All Checkbox Column -->
                <ng-container matColumnDef="all">
                  <th mat-header-cell *matHeaderCellDef>
                    <div class="cus-checkbox">All</div>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="cus-checkbox">
                      <mat-checkbox
                        color="primary"
                        [checked]="
                          row.read && row.write && row.update && row.delete
                        "
                        [(ngModel)]="row.all"
                        (change)="toggleAll(row)"
                      ></mat-checkbox>
                    </div>
                  </td>
                </ng-container>
  
                <!-- Title Column -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell mat-sort-header *matHeaderCellDef
                    >Title</th
                  >
                  <td mat-cell *matCellDef="let row">{{
                    row.api | dataValidation
                  }}</td>
                </ng-container>
  
                <!-- Admin Checkbox Column -->
                <ng-container matColumnDef="read">
                  <th mat-header-cell *matHeaderCellDef>Read</th>
                  <td mat-cell *matCellDef="let row">
                    <div class="cus-checkbox">
                      <mat-checkbox
                        color="primary"
                        (change)="toggleRow(row)"
                        [(ngModel)]="row.read"
                      ></mat-checkbox>
                    </div>
                  </td>
                </ng-container>
  
                <!-- Developer Checkbox Column -->
                <ng-container matColumnDef="write">
                  <th mat-header-cell *matHeaderCellDef>write</th>
                  <td mat-cell *matCellDef="let row">
                    <div class="cus-checkbox">
                      <mat-checkbox
                        color="primary"
                        (change)="toggleRow(row)"
                        [(ngModel)]="row.write"
                      ></mat-checkbox>
                    </div>
                  </td>
                </ng-container>
  
                <!-- Client Checkbox Column -->
                <ng-container matColumnDef="update">
                  <th mat-header-cell *matHeaderCellDef>Update</th>
                  <td mat-cell *matCellDef="let row">
                    <div class="cus-checkbox">
                      <mat-checkbox
                        color="primary"
                        (change)="toggleRow(row)"
                        [(ngModel)]="row.update"
                      ></mat-checkbox>
                    </div>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef>Delete</th>
                  <td mat-cell *matCellDef="let row">
                    <div class="cus-checkbox">
                      <mat-checkbox
                        color="primary"
                        (change)="toggleRow(row)"
                        [(ngModel)]="row.delete"
                      ></mat-checkbox>
                    </div>
                  </td>
                </ng-container>
  
                <!-- Action Column (Delete Button) -->
                <!-- <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let row">
                    <button
                      *ngIf="row.title"
                      type="button"
                      class="btn btn-sm btn-square btn-neutral text-danger-hover ms-1"
                      (click)="deleteRow(row)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container> -->
  
                <tr mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></tr>
                <tr mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
  
            </div>
            <mat-paginator
              [length]="paginatorData?.itemCount"
              [pageSize]="paginatorData?.perPage"
              [pageSizeOptions]="[10, 20, 50]"
              aria-label="Select page"
              (page)="pageChange($event)"
              class="mat-paginator-sticky"
              >,
            </mat-paginator>
          </main>
        </div>
      </div>
    </div>
  </div>
  