<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      <ng-container *ngIf="!haveToIncrementInSalary">
        {{ isEdit ? "Update" : "Create a new" }} salary detail
      </ng-container>
      <ng-container *ngIf="haveToIncrementInSalary">
        Increment salary detail
      </ng-container>
    </h5>

    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="px-0 container-fluid">
      <form [formGroup]="salaryForm" class="row">
        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>User Name</mat-label>
            <mat-select placeholder="Select your user" formControlName="userId">
              <mat-option
                *ngFor="let user of userList | roleFilter : [1, 2, 4]"
                [value]="user.id"
                >{{ user.name }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="salaryForm.controls['userId'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["userId"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Salary</mat-label>
            <input type="number" matInput formControlName="salary" />
            <mat-error
              *ngIf="salaryForm.controls['salary'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["salary"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Account Holder Name</mat-label>
            <input matInput formControlName="accName" />
            <mat-error
              *ngIf="salaryForm.controls['accName'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["accName"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Bank Account No</mat-label>
            <input type="number" matInput formControlName="bankAcc" />
            <mat-error
              *ngIf="salaryForm.controls['bankAcc'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["bankAcc"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>Bank Name</mat-label>
            <input type="text" matInput formControlName="bankName" />
            <mat-error
              *ngIf="salaryForm.controls['bankName'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["bankName"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>IFSC Code</mat-label>
            <input matInput formControlName="ifsc" />
            <mat-error
              *ngIf="salaryForm.controls['ifsc'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["ifsc"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="cmn-form-field col-lg-6 col-12">
          <mat-form-field appearance="outline">
            <mat-label>UPI Id</mat-label>
            <input matInput formControlName="upi" />
            <mat-error
              *ngIf="salaryForm.controls['upi'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["upi"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="cmn-form-field col-lg-6 col-12"
          [ngClass]="{ 'd-none': haveToIncrementInSalary == false }"
        >
          <!-- <mat-form-field appearance="outline">
            <mat-label>Increment Date</mat-label>
            <input matInput type="number"    formControlName="incrementDate" />
            <mat-error
              *ngIf="salaryForm.controls['incrementDate'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["incrementDate"] }}
            </mat-error>
          </mat-form-field> -->
          <div class="cmn-date-picker">
            <mat-form-field appearance="outline">
              <mat-label>Increment Date</mat-label>
              <input
                matInput
                [matDatepicker]="picker2"
                formControlName="incrementDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <mat-error
                *ngIf="salaryForm.controls['incrementDate'].invalid"
                class="error-msg text-danger"
              >
                {{ formErrors["incrementDate"] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div
          class="cmn-form-field col-lg-6 col-12"
          [ngClass]="{ 'd-none': haveToIncrementInSalary == false }"
        >
          <mat-form-field appearance="outline">
            <mat-label>Increment Amount</mat-label>
            <input matInput type="number" formControlName="incrementAmount" />
            <mat-error
              *ngIf="salaryForm.controls['incrementAmount'].invalid"
              class="error-msg text-danger"
            >
              {{ formErrors["incrementAmount"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 cmn-form-field">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="desc" rows="3" matInput></textarea>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
    >
      <ng-container *ngIf="!haveToIncrementInSalary">
        {{ isEdit ? "Update" : "Add" }}
      </ng-container>
      <ng-container *ngIf="haveToIncrementInSalary"> Add </ng-container>
    </page-button>
  </div>
</div>
