import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}
  // private routeList(role) {
  //   switch (role) {
  //     case 'Admin': {
  //       return [
  //         'pay',
  //         'setting',
  //         'dashboard',
  //         'profile',
  //         'employee',
  //         'clients',
  //         'violations',
  //         'services',
  //         'properties',
  //         'pay',
  //         'pay/add',
  //         'address',
  //         'lane-striping/:id',
  //         'lane-striping',
  //         'power-sweeping/:id',
  //         'boot/:id',
  //         'boot/add',
  //         'boot',
  //         'asset',
  //         'settings',
  //         'profile',
  //         'scheduleMaster',
  //         'schedule',
  //         'payment',
  //         'pressure-washing/add',
  //         'power-sweeping/list',
  //         'lane-striping/list',
  //         'pressure-washing/list',
  //         'earning',
  //         'payment/add',
  //         'todo-list',
  //         'boot-cash',
  //       ];
  //     }
  //     case 'User': {
  //       return [
  //         'user-dashboard',
  //         'earning',
  //         'boot',
  //         'profile',
  //         'pay_log',
  //         'pressure-washing',
  //         'pressure-washing/add',
  //         'lane-striping',
  //         'power-sweeping',
  //         'schedule',
  //         'pay',
  //         'pay/add',
  //       ];
  //     }
  //     case 'Client': {
  //       return [
  //         'client-dashboard',
  //         'profile',
  //         'account_payment_information',
  //         'services',
  //         'properties',
  //         'schedule',
  //         'pay',
  //         'pay/add',
  //       ];
  //     }
  //   }
  // }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.localStorageService.isLoggedIn()) {
      const user = this.localStorageService.getUser();

      // const routList = this.routeList(RolesEnum[user.role]);
      // const currentRout = routList.find(
      //   (element) => element == state.url.substr(1)
      // );
      this.authService.requestedUrl = this.router.url;
      // if (state.url.substr(1) !== currentRout) {
      //   this.router.navigate([RolesDashboardEnum[user.role]]);
      // }
      return true;
    }
    this.authService.requestedUrl = state.url;
    this.router.navigate(['/authentication/login']);
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
}
