import { CmnApiCallService } from './../../../core/services/cmn-api-call.service';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import {
  ApiQuery,
  ApiResponse,
  Paginator,
} from 'src/app/core/model/api-response';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  organizationApi,
  projectApi,
  statusApi,
} from 'src/app/core/model/api-url';
import { projectModel } from 'src/app/core/model/project.model';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Tags } from 'src/app/core/model/tags';
import { statusModel } from 'src/app/core/model/status.model';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-project',
  templateUrl: './add-edit-project.component.html',
  styleUrls: ['./add-edit-project.component.scss'],
})
export class AddEditProjectComponent {
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();
  @ViewChild('fileInput') fileInputRef!: ElementRef<HTMLInputElement>;
  selectedImage: any; // Variable to hold the selected image preview
  OrganizationId: any;
  OrganizationList = [];
  projectForm: FormGroup;
  formErrors: any = {};
  validationMessages = {
    orgId: {
      required: 'Organization is required',
    },
    name: {
      required: 'Name is required',
    },
    desc: {
      required: 'Description is required',
    },
    budget: {
      required: 'Budget required',
      min: 'Minimum budget should be 1',
    },
  };

  isEdit: boolean = false;
  projectId: number;
  loadingImage: boolean = false;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  public editor = ClassicEditor;
  public editorData = '';
  private editorInstance: any;
  editorConfig = {
    placeholder: 'Add description...',
  };
  ProjectStatusList = [
    { id: 0, name: 'Initiated' },
    { id: 1, name: 'Confirmed' },
    { id: 2, name: 'Inprogress' },
    { id: 3, name: 'Testing' },
    { id: 4, name: 'Deployed' },
    { id: 5, name: 'Done' },
  ];

  projectStatusId = 0;
  projectStatus: Tags[] = [];

  ProjectypeList = [
    { id: 0, name: 'Fixed' },
    { id: 1, name: 'Hourly' },
    { id: 2, name: 'Milestone' },
  ];

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.projectId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createProjectForm();
    this.getOrganizationById();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.projectStatus,
      event.previousIndex,
      event.currentIndex
    );
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  createProjectForm() {
    this.projectForm = this.fb.group({
      orgId: [null, Validators.required],
      name: ['', Validators.required],
      imageUrl: [''],
      budget: [0],
      desc: [''],
      startDate: [null],
      dueDate: [null],
      type: [0],
      status: [0],
      view: ['0'],
      projectStatus: [],
    });
  }

  onSubmit() {
    this.projectForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.projectForm.controls,
      this.validationMessages
    );

    if (this.projectForm.valid) {
      let projectApiUrl = this.isEdit ? projectApi.update : projectApi.create;
      const {
        orgId,
        name,
        imageUrl,
        budget,
        desc,
        startDate,
        dueDate,
        type,
        status,
        view,
      } = this.projectForm.value;
      let model: projectModel = {
        orgId: orgId,
        name: name,
        imageUrl: imageUrl,
        budget: budget,
        desc: desc,
        startDate: startDate,
        dueDate: dueDate,
        type: type,
        status: status,
        view: +view,
      };

      model.desc = this.editorInstance.getData();
      if (this.isEdit) {
        model['id'] = +this.projectId;
      }

      this.cmnApiCallService.addUpdateData(projectApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            if (!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                `Project created successfully`
              );

              if (this.projectStatus.length) {
                this.addEditStatus(res.data.id);
              }
            } else {
              this.toastService.successMsg(
                'Updated',
                res.data[0].title
                  ? `Project "${res.data[0].title}" has been successfully edited`
                  : 'Project has been successfully edited'
              );
              this.addEditStatus(res.data[0].id);
            }
            this.cmnAddEditSidenavService.getUpdatedDataList(
              'ProjectComponent'
            );
            this.projectForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  addEditStatus(projectId) {
    let statusApiUrl = this.isEdit ? statusApi.update : statusApi.addBulk;
    let data = this.addEditBulkStatus(projectId);
    let model = {
      data: data,
    };

    if (this.isEdit) {
      data.map((model) => {
        this.cmnApiCallService.addUpdateData(statusApiUrl, model).subscribe(
          (res: ApiResponse) => {
            if (res.status === ApiStatus.SUCCESS) {
              return;
            }
            this.toastService.errorMsg(res.message);
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
      });
    } else {
      this.cmnApiCallService.addUpdateData(statusApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if (res.status === ApiStatus.SUCCESS) {
            return;
          }
          this.toastService.errorMsg(res.message);
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  addEditBulkStatus(projectId: number) {
    let priority: number = 0;
    let name: string;
    let id: number;
    let data: statusModel[] = [];
    this.projectStatus.map((Tags) => {
      id = Tags.id;
      name = Tags.tag;
      if (this.isEdit) {
        data.push({ id, projectId, name, priority });
      } else {
        data.push({ projectId, name, priority });
      }
      priority++;
    });
    return data;
  }

  getOrganizationById() {
    let rollAccessUrl = organizationApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService
      .getList(rollAccessUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          this.OrganizationList = res.data.data;
        }
      });
  }

  getStatusById(projectId) {
    let statusListUrl = statusApi.list;
    this.apiQuery.query = {
      isDeleted: false,
      projectId: projectId,
    };

    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService
      .getList(statusListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          let projectStatus = res.data.data;
          projectStatus.sort((a, b) => a.priority - b.priority);
          projectStatus.map((status) => {
            let id = status.id;
            let tag = status.name;
            this.projectStatus.push({ id, tag });
          });
        }
      });
  }

  onAddItem(tag) {
    if (this.isEdit) {
      this.addSingleStatus(tag);
    } else {
      let id = this.projectStatusId++;
      this.projectStatus.push({ id, tag });
    }
    this.projectForm.controls['projectStatus'].reset();
  }

  addSingleStatus(tag) {
    let statusApiUrl = statusApi.create;
    let model = {
      projectId: this.projectId,
      name: tag,
      priority: this.projectStatus.length,
    };

    this.cmnApiCallService.addUpdateData(statusApiUrl, model).subscribe(
      (res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          let id = res.data.id;
          this.projectStatus.push({ id, tag });
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  deleteItem(id) {
    if (this.isEdit) {
      this.deleteStatus(id);
    } else {
      this.projectStatus = this.projectStatus.filter(
        (project) => project.id !== id
      );
    }
  }

  deleteStatus(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteTaskUrl = statusApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService.addUpdateData(deleteTaskUrl, model).subscribe(
          (res: ApiResponse) => {
            if (res.status == ApiStatus.SUCCESS) {
              this.toastService.successMsg(
                'Deleted',
                `Task has been successfully deleted`
              );
              this.projectStatus = this.projectStatus.filter(
                (status) => status.id !== id
              );
            }
          },
          (err) => {
            this.toastService.errorMsg(err);
          }
        );
      }
    });
  }

  patchVal() {
    let projectGetByIdUrl = projectApi.byId;
    this.cmnApiCallService.getById(projectGetByIdUrl, this.projectId).subscribe(
      (res: ApiResponse) => {
        if (res.status == ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.projectForm.patchValue({
              title: res.data.title,
              desc: res.data.desc,
              orgId: res.data.orgId,
              imageUrl: res.data.imageUrl,
              budget: res.data.budget,
              name: res.data.name,
              startDate: res.data.startDate,
              dueDate: res.data.dueDate,
              type: +res.data.type,
              status: +res.data.status,
              view: res.data.view.toString(),
            });
            this.selectedImage = environment.mainUrl + res.data.imageUrl;
            this.editorData = res.data.desc;
            this.getStatusById(this.projectId);
          }
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    );
  }

  onFileSelected(event: Event) {
    this.loadingImage = true;
    const fileInput = event.target as HTMLInputElement;
    const selectedFile = fileInput.files?.[0];

    if (selectedFile) {
      this.cmnApiCallService.uploadImage(selectedFile).subscribe(
        (response: ApiResponse) => {
          if (response.status === ApiStatus.SUCCESS) {
            const path: string = response.data.uploadSuccess[0].path;
            this.projectForm.controls['imageUrl'].patchValue(path);
            this.selectedImage = environment.mainUrl + path;
          }
          if (response.status === ApiStatus.FAILURE) {
            this.toastService.uploadFailure(
              response.data.uploadFailed[0].error
            );
          }
          this.loadingImage = false;
        },
        (error) => {
          this.toastService.errorMsg(error);
          this.loadingImage = false;
        }
      );
    }
  }

  onReady(editor: any) {
    this.editorInstance = editor;
  }
}
