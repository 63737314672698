import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiResponse } from 'src/app/core/model/api-response';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss',
})
export class ImageUploadComponent {
  @Output() uploadedImageEvent = new EventEmitter<any>();
  @Input() isMulti = false;
  @Input() showPreview = false;
  @Input() selectedImage = '';
  loadingImage = false;
  @Input() uploadedImage = [];
  @Input() maxFileSize = 3;
  enviromentUrl = environment.mainUrl;
  @ViewChild('fileInput') fileInput: any;

  constructor(
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService
  ) {}

  onFileSelected1(event: any) {
    this.loadingImage = true;
    const formData = new FormData();
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        formData.append(
          'files',
          event.target.files[i],
          event.target.files[i].name
        );
      }
    }
    if (event.target.files.length > 0 && formData) {
      this.cmnApiCallService.imageUpload(formData).subscribe(
        (response: ApiResponse) => {
          debugger;
          if (response.status === ApiStatus.SUCCESS) {
            response.data.uploadSuccess.forEach((element) => {
              this.uploadedImage.push(element.path);
            });
            this.uploadedImageEvent.emit(this.uploadedImage);
            this.fileInput.nativeElement.value = null; 
          }
          if (response.status === ApiStatus.FAILURE) {
            this.toastService.uploadFailure(
              response.data.uploadFailed[0].error
            );
          }
          this.loadingImage = false;
        },
        (error) => {
          this.toastService.errorMsg(error);
          this.loadingImage = false;
        }
      );
    }
  }

  onFileSelected(event: any) {
    this.loadingImage = true;
    const formData = new FormData();
    let filesSkipped = false;

    if (event.target.files) {
      const fileZie = this.maxFileSize * 1024 * 1024;
      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size <= fileZie) {
          formData.append(
            'files',
            event.target.files[i],
            event.target.files[i].name
          );
        } else {
          filesSkipped = true;
        }
      }
    }


    if (formData.has('files')) {
      this.cmnApiCallService.imageUpload(formData).subscribe(
        (response: ApiResponse) => {
          if (response.status === ApiStatus.SUCCESS) {
            response.data.uploadSuccess.forEach((element) => {
              this.uploadedImage.push(element.path);
            });
            this.uploadedImageEvent.emit(this.uploadedImage);
          }
          if (response.status === ApiStatus.FAILURE) {
            this.toastService.uploadFailure(
              response.data.uploadFailed[0].error
            );
          }
          this.loadingImage = false;
        },
        (error) => {
          this.toastService.errorMsg(error);
          this.loadingImage = false;
        }
      );
    } else {
      if (filesSkipped) {
        const message = `File is larger than ${this.maxFileSize} MB was not uploaded`;
        this.toastService.uploadFailure(message);
        this.fileInput.nativeElement.value = null; 
      }
    }
  }
  removeImage(i: number) {
    if (this.uploadedImage.length == 1) {
      this.uploadedImage = [];
    } else {
      this.uploadedImage = this.uploadedImage.splice(i, 1);
    }
    this.uploadedImageEvent.emit(this.uploadedImage);
  }
}
