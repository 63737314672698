<div class="project-gridview-wrapper">
  <header class="page-cmn-header">
    <div class="container-fluid">
      <div class="border-bottom pt-6 pb-3 cmn-header-box">
        <div class="row align-items-center">
          <div class="col-sm col-12">
            <h1 class="h2 ls-tight">My Cool Projects</h1>
          </div>
          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <a href="#modalExport" class="btn btn-sm btn-neutral border-base" data-bs-toggle="modal">
                <span class="pe-2">
                  <i class="bi bi-people-fill"></i>
                </span>
                <span> Share </span>
              </a>
              <a href="#offcanvasCreate" class="btn btn-sm btn-primary" data-bs-toggle="offcanvas">
                <span class="pe-2">
                  <i class="bi bi-plus-square-dotted"></i>
                </span>
                <span>Create</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="p-4">
    <div class="row gy-2">
      <div class="col-12 col-sm-auto">
        <div class="cmn-search-box cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Ex. ium" (keyup)="applyFilter($event)"/>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-sm-auto">
        <div class="cmn-search-box cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select>
              <mat-option value="done"> Done </mat-option>
              <mat-option value="In Progress"> In Progress </mat-option>
              <mat-option value="Project at risk"> Project at risk </mat-option>
              <mat-option value=" Not started"> Not started </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    
    <div class="d-flex flex-wrap gap-4 justify-content-between">
      <div class="card col-xl-3 col-lg-5 col-12" *ngFor = "let project of projectList">
        <div class="card-header border-0">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span class="h6 text-sm font-semibold">{{getStatus(project.status)}}</span>
            </div>
            <div>
              <button mat-icon-button class="cus-round-btn" color="warn" 
              (click)="deleteProject(project.id)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body text-center">
          <figure class="mx-auto">
            <div class="w-48 mx-auto border border-1">
              <img alt="..." class="w-full" [src]="getImage(project.imageUrl)" />
            </div>
          </figure>
          <a href="javascript:void(0)" class="h5 d-block my-6">{{project.name}}</a>
          <div class="my-4">
            <div class="avatar-group">
              <a href="javascript:void(0)"
                class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
              </a>
              <a href="javascript:void(0)"
                class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
              </a>
              <a href="javascript:void(0)"
                class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"><img
                  alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-8 px-4">
            <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
              <i class="bi bi-clipboard-data"> </i>
            </a>
            <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
              <i class="bi bi-people"> </i>
            </a>
            <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
              <i class="bi bi-trash"> </i>
            </a>
          </div>
          <hr class="my-7" />
          <div class="row justify-content-between align-items-center">
            <div class="col-6">
              <span class="d-block h6 text-heading mb-0">${{project.budget}}</span>
              <span class="d-block text-sm text-muted">Budget</span>
            </div>
            <div class="col-6">
              <span class="d-block h6 text-heading mb-0">{{timeFormate(project.dueDate)}}</span>
              <span class="d-block text-sm text-muted">Due Date</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid py-6 vstack gap-4">
    <div class="row gy-2">
      <div class="col-12 col-sm-auto">
        <div class="cmn-search-box cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Ex. ium" />
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-sm-auto">
        <div class="cmn-search-box cmn-form-field">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select>
              <mat-option value="done"> Done </mat-option>
              <mat-option value="In Progress"> In Progress </mat-option>
              <mat-option value="Project at risk"> Project at risk </mat-option>
              <mat-option value=" Not started"> Not started </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="vstack gap-4">
      <div class="row g-4">
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold"><span class="text-warning me-2">●</span> In progress</span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"> </i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/airbnb.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">Website Redesign</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card"><img
                      alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-people"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-trash"> </i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">$9.800</span>
                  <span class="d-block text-sm text-muted">In budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold"><span class="text-success me-2">●</span> Done</span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"> </i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/amazon.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">E-commerce App</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"></i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-people"></i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-trash"> </i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">$7.800</span>
                  <span class="d-block text-sm text-muted">In budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold">
                    <span class="text-danger me-2">●</span> Project at
                    risk</span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"> </i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/bootstrap.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">Learning Platform</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"></i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-people"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-trash"> </i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-danger mb-0">$800,00</span>
                  <span class="d-block text-sm text-muted">Over budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold">
                    <span class="text-warning me-2">●</span>
                    In progress
                  </span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"> </i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/dribbble.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">Design Portfolio</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" /> </a><a
                    href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-people"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-trash"> </i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">$800,00</span>
                  <span class="d-block text-sm text-muted">Over budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold">
                    <span class="text-warning me-2">●</span>
                    In progress
                  </span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"> </i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/spotify.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">Our team's playlist</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" /> </a><a
                    href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" /> </a><a
                    href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-people"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-trash"> </i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-danger mb-0">$500,00</span>
                  <span class="d-block text-sm text-muted">Over budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold">
                    <span class="text-secondary me-2"> ● </span>
                    Not started
                  </span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"> </i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/uber.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">Uber native app</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" /> </a><a
                    href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-people"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-trash"> </i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">$1800,00</span>
                  <span class="d-block text-sm text-muted">In budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold"><span class="text-danger me-2">●</span> Project at
                    risk</span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"> </i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/codepen.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">Code Examples for Devs</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-people"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover"><i class="bi bi-trash"></i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-danger mb-0">$800,00</span>
                  <span class="d-block text-sm text-muted">Over budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-sm-6">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="h6 text-sm font-semibold"><span class="text-danger me-2">●</span> Project at
                    risk</span>
                </div>
                <div class="text-end mx-n2 d-flex align-items-center">
                  <a href="javascript:void(0)" class="px-2 text-muted">
                    <i class="bi bi-arrow-clockwise"></i>
                  </a>
                  <div class="dropdown">
                    <a class="text-muted" href="javascript:void(0)" role="button" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots"> </i>
                    </a>
                    <div class="dropdown-menu">
                      <a href="#!" class="dropdown-item">Action </a>
                      <a href="#!" class="dropdown-item">Another action </a>
                      <a href="#!" class="dropdown-item">Something else here</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body text-center">
              <figure class="mx-auto">
                <div class="w-12 mx-auto">
                  <img alt="..." class="w-full" src="../../../../assets/images/thumbnail/uber.svg" />
                </div>
              </figure>
              <a href="javascript:void(0)" class="h5 d-block my-6">Community Channel</a>
              <div class="my-4">
                <div class="avatar-group">
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-1.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-2.jpg" />
                  </a>
                  <a href="javascript:void(0)"
                    class="avatar avatar-xs rounded-circle text-white border border-1 border-solid border-card">
                    <img alt="..." src="../../../../assets/images/avatar-images/avatar-img-3.jpg" />
                  </a>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-8 px-4">
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-clipboard-data"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover"><i class="bi bi-people"> </i>
                </a>
                <a href="javascript:void(0)" class="px-2 text-muted text-primary-hover">
                  <i class="bi bi-trash"> </i>
                </a>
              </div>
              <hr class="my-7" />
              <div class="row justify-content-between align-items-center">
                <div class="col-6">
                  <span class="d-block h6 text-danger mb-0">$800,00</span>
                  <span class="d-block text-sm text-muted">Over budget</span>
                </div>
                <div class="col-6">
                  <span class="d-block h6 text-heading mb-0">7</span>
                  <span class="d-block text-sm text-muted">Days Delay</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12">
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"
            class="cmn-pagination"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <app-creat-new-project> </app-creat-new-project>
</div>