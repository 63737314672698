import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { AddMemberPopupComponent } from '../add-member-popup/add-member-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss'],
})
export class UserPermissionsComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'date',
    'position',
    'workedhours',
    'empty',
  ];
  ELEMENT_DATA: any[] = [
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Robert Fox',
      date: 'darlene@example.com',
      position: 'Admin',
      team: 'John,Anup',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Helium',
      date: 'darlene@example.com',
      position: 'Manager',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Lithium',
      date: 'darlene@example.com',
      position: 'Publisher',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Beryllium',
      date: 'darlene@example.com',
      position: 'Web Developers',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Boron',
      date: 'darlene@example.com',
      position: 'Editor',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Carbon',
      date: 'darlene@example.com',
      position: 'Web Developers',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Nitrogen',
      date: 'darlene@example.com',
      position: 'Web Developers',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Oxygen',
      date: 'darlene@example.com',
      position: 'Web Developers',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Fluorine',
      date: 'darlene@example.com',
      position: 'Web Developers',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
    {
      logo: 'https://clever-dashboard.webpixels.work/img/social/airbnb.svg',
      name: 'Neon',
      date: 'darlene@example.com',
      position: 'Web Developers',
      performance: [47, 94, 24, 18, 26, 65, 31, 47, 10, 44, 45],
      workedhours: '25',
    },
  ];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  constructor(public dialog: MatDialog) {}
  openDialog(): void {
    this.dialog.open(AddMemberPopupComponent, {
      width: '400px',
    });
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  ngOnInit(): void {}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
