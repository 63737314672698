<div class="user-profile-wrapper">
  <div class="flex-lg-1 h-screen-header overflow-y-lg-auto">
    <header class="bg-surface-secondary">
      <div
        class="bg-cover"
        style="
          height: 300px;
          background-image: url(.././../../../assets/images/img/hero-bg-image.jpg);
          background-position: center center;
        "
      ></div>
      <div class="container-fluid max-w-screen-xl">
        <div class="row g-0">
          <div class="col-auto">
            <a

              class="avatar w-40 h-40 border border-body border-4 rounded-circle shadow mt-n16"
            >
              <img
                alt="..."
                src=".././../../../assets/images/thumbnail/img-profile.jpg"
                class="rounded-circle"
              />
            </a>
          </div>
          <div class="col ps-4 pt-4">
            <h6 class="text-xs text-uppercase text-muted mb-1">UI Designer</h6>
            <h1 class="h2">Tahlia Mooney</h1>
            <div class="d-flex gap-2 flex-wrap mt-3 d-none d-sm-block">
              <a

                class="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                >UI/UX</a
              >
              <a

                class="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                >Mobile Apps</a
              >
              <a

                class="bg-white bg-opacity-50 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                >UI Research</a
              >
            </div>
          </div>
          <div
            class="col-12 col-md-auto mt-2 mt-md-0 mb-md-3 hstack gap-2 mt-4 mt-sm-0"
          >
            <a  class="btn btn-sm btn-square btn-neutral"
              ><i class="bi bi-envelope"></i> </a
            ><a  class="btn btn-sm btn-square btn-neutral"
              ><i class="bi bi-bell-fill"></i> </a
            ><a

              class="btn btn-sm btn-primary d-block d-md-inline-block ms-auto ms-md-0"
              >Follow</a
            >
          </div>
        </div>
        <ul class="nav nav-tabs overflow-x ms-1 mt-4">
          <li class="nav-item">
            <a href="#!" class="nav-link active font-bold">My profile</a>
          </li>
          <li class="nav-item"><a href="#!" class="nav-link">Settings</a></li>
          <li class="nav-item">
            <a href="#!" class="nav-link">Integrations</a>
          </li>
          <li class="nav-item"><a href="#!" class="nav-link">Security</a></li>
        </ul>
      </div>
    </header>
    <main class="py-6 bg-surface-secondary">
      <div class="container-fluid max-w-screen-xl">
        <div class="row g-6">
          <div class="col-xl-8">
            <div class="vstack gap-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="mb-3">Biography</h5>
                  <p class="text-sm lh-relaxed mb-4">
                    Personal profiles are the perfect way for you to grab their
                    attention and persuade recruiters to continue reading your
                    CV because you’re telling them from the off exactly why they
                    should hire you. Of course, you’ll need to know how to write
                    an effective statement first, but we’ll get on to that in a
                    bit.
                  </p>
                  <p class="text-sm lh-loose mb-4">
                    When we strive to become better than we are everything
                    around us becomes better too. This is a wider card with
                    supporting text below. Personal profiles are the perfect way
                    for you to grab their attention and persuade recruiters to
                    continue reading your CV because you’re telling them from
                    the off exactly why they should hire you. Of course, you’ll
                    need to know how to write an effective statement first, but
                    we’ll get on to that in a bit.
                  </p>
                  <a  class="link-primary font-semibold text-sm"
                    >Read more</a
                  >
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="mb-6">Recent Activity</h5>
                  <div
                    class="list-group list-group-flush list-group-borderless ms-4"
                  >
                    <div class="list-group-item px-2 py-0">
                      <div class="border-start">
                        <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                          <div class="flex-none me-3">
                            <div
                              class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                            >
                              <i class="bi bi-file-earmark-pdf-fill"></i>
                            </div>
                          </div>
                          <div>
                            <small class="d-block mb-1 text-muted"
                              >2 hrs ago</small
                            >
                            <div>
                              You've uploaded
                              <span class="font-bold">documentation.pdf</span>
                              to the
                              <span class="font-bold">Webpixels</span> project
                            </div>
                            <div class="d-flex gap-2 mt-2">
                              <div
                                class="position-relative bg-light border border-dashed border-warning-hover rounded-pill"
                              >
                                <div
                                  class="py-2 px-3 d-flex align-items-center"
                                >
                                  <div class="me-2">
                                    <i class="bi bi-image text-warning"></i>
                                  </div>
                                  <div class="flex-fill">
                                    <a

                                      class="d-block font-semibold text-xs text-muted text-warning-hover stretched-link"
                                      >documentation.pdf</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item px-2 py-0">
                      <div class="border-start">
                        <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                          <div class="flex-none me-3">
                            <div
                              class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                            >
                              <i class="bi bi-file-earmark-pdf-fill"></i>
                            </div>
                          </div>
                          <div class="flex-fill">
                            <small class="d-block mb-1 text-muted"
                              >4 hrs ago</small
                            >
                            <div class="text-sm">
                              <span class="text-heading text-sm font-bold"
                                >Mike</span
                              >
                              added a new comment to your task:
                            </div>
                            <div class="mt-2">
                              <p class="text-sm text-muted">
                                On the other hand, we denounce with righteous
                                indignation and dislike men who are so beguiled
                                and demoralized by the charms of pleasure of the
                                moment, so blinded by desire, that they cannot
                                foresee.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item px-2 py-0">
                      <div class="border-start">
                        <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                          <div class="flex-none me-3">
                            <div
                              class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                            >
                              <i class="bi bi-file-earmark-pdf-fill"></i>
                            </div>
                          </div>
                          <div class="flex-fill">
                            <small class="d-block mb-1 text-muted"
                              >4 hrs ago</small
                            >
                            <div class="text-sm">
                              Heather added
                              <span class="font-bold">4 images</span> to the
                              <span class="font-bold">Team</span> album:
                            </div>
                            <div class="mt-2 d-flex gap-2 scrollable-x">
                              <div class="col-auto">
                                <img
                                  alt="..."
                                  src=".././../../../assets/images/img/media-img-3.jpg"
                                  class="rounded w-auto h-16"
                                />
                              </div>
                              <div class="col-auto">
                                <img
                                  alt="files"
                                  src=".././../../../assets/images/img/media-img-4.jpg"
                                  class="rounded w-auto h-16"
                                />
                              </div>
                              <div class="col-auto">
                                <img
                                  alt="files"
                                  src=".././../../../assets/images/img/media-img-5.jpg"
                                  class="rounded w-auto h-16"
                                />
                              </div>
                              <div class="col-auto">
                                <img
                                  alt="files"
                                  src=".././../../../assets/images/img/media-img-6.jpg"
                                  class="rounded w-auto h-16"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item px-2 py-0">
                      <div class="border-start">
                        <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                          <div class="flex-none me-3">
                            <div
                              class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                            >
                              <i class="bi bi-file-earmark-pdf-fill"></i>
                            </div>
                          </div>
                          <div class="flex-fill">
                            <small class="d-block mb-1 text-muted"
                              >4 hrs ago</small
                            >
                            <div class="text-sm">
                              Heather added
                              <span class="font-bold">Anna</span> to the
                              <span class="font-bold">Clever</span> team.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item px-2 py-0">
                      <div class="border-start">
                        <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                          <div class="flex-none me-3">
                            <div
                              class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                            >
                              <i class="bi bi-file-earmark-pdf-fill"></i>
                            </div>
                          </div>
                          <div class="flex-fill">
                            <small class="d-block mb-1 text-muted"
                              >4 hrs ago</small
                            >
                            <div class="text-sm">
                              Heather created the tags
                              <div class="d-inline-block mx-1">
                                <a

                                  class="badge rounded-pill bg-danger bg-opacity-20 bg-opacity-100-hover text-danger text-white-hover"
                                  >Bugs</a
                                >
                                <a

                                  class="badge rounded-pill bg-success bg-opacity-20 bg-opacity-100-hover text-success text-white-hover"
                                  >Showcase</a
                                >
                              </div>
                              for the
                              <span class="font-bold">Clever</span> project
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group-item px-2 py-0">
                      <div>
                        <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                          <div class="flex-none me-3">
                            <div
                              class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                            >
                              <i class="bi bi-file-earmark-pdf-fill"></i>
                            </div>
                          </div>
                          <div class="flex-fill">
                            <small class="d-block mb-1 text-muted"
                              >2 hrs ago</small
                            >
                            <div>
                              You've uploaded
                              <span class="font-bold">documentation.pdf</span>
                              to the
                              <span class="font-bold">Webpixels</span> project
                            </div>
                            <div
                              class="mt-2 scrollable-x overflow-hidden gap-2 v-stack d-flex flex-wrap"
                            >
                              <div
                                class="d-inline-block position-relative bg-light border border-dashed border-tertiary-hover rounded-pill"
                              >
                                <div
                                  class="py-2 px-3 d-flex align-items-center"
                                >
                                  <div class="me-2">
                                    <i class="bi bi-image text-tertiary"></i>
                                  </div>
                                  <div class="flex-fill">
                                    <a

                                      class="d-block font-semibold text-xs text-muted text-tertiary-hover stretched-link"
                                      >design.fig</a
                                    >
                                  </div>
                                </div>
                              </div>
                              <div
                                class="d-inline-block position-relative bg-light border border-dashed border-warning-hover rounded-pill"
                              >
                                <div
                                  class="py-2 px-3 d-flex align-items-center"
                                >
                                  <div class="me-2">
                                    <i class="bi bi-image text-warning"></i>
                                  </div>
                                  <div class="flex-fill">
                                    <a

                                      class="d-block font-semibold text-xs text-muted text-warning-hover stretched-link"
                                      >readme.md</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h5 class="mb-4">My Files</h5>
                <div class="row g-5">
                  <div class="col-lg-4 col-sm-6">
                    <div class="card shadow-4-hover">
                      <div class="p-2">
                        <a  class="d-block"
                          ><img
                            class="img-fluid rounded-2"
                            alt="..."
                            src="../../../../assets/images/img/files-1.jpg"
                        /></a>
                      </div>
                      <div class="px-4 pt-2 pb-4">
                        <h6 class="font-semibold mb-2">Website Redesign</h6>
                        <div class="d-flex align-items-center">
                          <div class="text-xs text-muted">
                            <i class="bi bi-lock me-1"></i>Private
                          </div>
                          <div class="text-xs text-muted ms-auto">
                            3 hrs ago
                          </div>
                        </div>
                        <span class="d-block"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="card shadow-4-hover">
                      <div class="p-2">
                        <a  class="d-block"
                          ><img
                            class="img-fluid rounded-2"
                            alt="..."
                            src="../../../../assets/images/img/files-2.jpg"
                        /></a>
                      </div>
                      <div class="px-4 pt-2 pb-4">
                        <h6 class="font-semibold mb-2">Website Redesign</h6>
                        <div class="d-flex align-items-center">
                          <div class="text-xs text-muted">
                            <i class="bi bi-lock me-1"></i>Private
                          </div>
                          <div class="text-xs text-muted ms-auto">
                            3 hrs ago
                          </div>
                        </div>
                        <span class="d-block"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="card shadow-4-hover">
                      <div class="p-2">
                        <a  class="d-block"
                          ><img
                            class="img-fluid rounded-2"
                            alt="..."
                            src="../../../../assets/images/img/files-3.jpg"
                        /></a>
                      </div>
                      <div class="px-4 pt-2 pb-4">
                        <h6 class="font-semibold mb-2">Website Redesign</h6>
                        <div class="d-flex align-items-center">
                          <div class="text-xs text-muted">
                            <i class="bi bi-lock me-1"></i>Private
                          </div>
                          <div class="text-xs text-muted ms-auto">
                            3 hrs ago
                          </div>
                        </div>
                        <span class="d-block"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="card shadow-4-hover">
                      <div class="p-2">
                        <a  class="d-block"
                          ><img
                            class="img-fluid rounded-2"
                            alt="..."
                            src="../../../../assets/images/img/files-4.jpg"
                        /></a>
                      </div>
                      <div class="px-4 pt-2 pb-4">
                        <h6 class="font-semibold mb-2">Website Redesign</h6>
                        <div class="d-flex align-items-center">
                          <div class="text-xs text-muted">
                            <i class="bi bi-lock me-1"></i>Private
                          </div>
                          <div class="text-xs text-muted ms-auto">
                            3 hrs ago
                          </div>
                        </div>
                        <span class="d-block"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="card shadow-4-hover">
                      <div class="p-2">
                        <a  class="d-block"
                          ><img
                            class="img-fluid rounded-2"
                            alt="..."
                            src="../../../../assets/images/img/files-5.jpg"
                        /></a>
                      </div>
                      <div class="px-4 pt-2 pb-4">
                        <h6 class="font-semibold mb-2">Website Redesign</h6>
                        <div class="d-flex align-items-center">
                          <div class="text-xs text-muted">
                            <i class="bi bi-lock me-1"></i>Private
                          </div>
                          <div class="text-xs text-muted ms-auto">
                            3 hrs ago
                          </div>
                        </div>
                        <span class="d-block"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <div class="card shadow-4-hover">
                      <div class="p-2">
                        <a  class="d-block"
                          ><img
                            class="img-fluid rounded-2"
                            alt="..."
                            src="../../../../assets/images/img/files-6.jpg"
                        /></a>
                      </div>
                      <div class="px-4 pt-2 pb-4">
                        <h6 class="font-semibold mb-2">Website Redesign</h6>
                        <div class="d-flex align-items-center">
                          <div class="text-xs text-muted">
                            <i class="bi bi-lock me-1"></i>Private
                          </div>
                          <div class="text-xs text-muted ms-auto">
                            3 hrs ago
                          </div>
                        </div>
                        <span class="d-block"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="vstack gap-6">
              <div class="card">
                <div class="card-body">
                  <h5 class="mb-4">Location</h5>
                  <div class="d-flex align-items-center">
                    <i class="bi bi-geo-alt-fill me-2 text-muted"></i>
                    <a  class="text-sm text-heading text-primary-hover"
                      >San Francisco, California</a
                    >
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="mb-4">Social</h5>
                  <div class="vstack gap-4">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-globe me-2 text-muted"></i>
                      <a

                        class="text-sm text-heading text-primary-hover"
                        >mywebsite.com</a
                      >
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-journal-album me-2 text-muted"></i>
                      <a

                        class="text-sm text-heading text-primary-hover"
                        >clever.mywebsite.com</a
                      >
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-github me-2 text-muted"></i>
                      <a

                        class="text-sm text-heading text-primary-hover"
                        >GitHub</a
                      >
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-dribbble me-2 text-muted"></i>
                      <a

                        class="text-sm text-heading text-primary-hover"
                        >Dribbble</a
                      >
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="bi bi-twitter me-2 text-muted"></i>
                      <a

                        class="text-sm text-heading text-primary-hover"
                        >Twitter</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="mb-4">Mutual connections</h5>
                  <div class="vstack gap-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <img
                          alt="..."
                          class="avatar rounded-circle"
                          src="../../../../assets/images/thumbnail/people-1.jpg"
                        />
                      </div>
                      <div class="flex-1">
                        <a

                          class="d-block font-semibold text-sm text-heading text-primary-hover"
                          >Robert Fox</a
                        >
                        <div class="text-xs text-muted line-clamp-1">
                          Web Developer
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <a  class="btn btn-sm px-3 py-1 btn-neutral"
                          >Follow</a
                        >
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <img
                          alt="..."
                          class="avatar rounded-circle"
                          src="../../../../assets/images/thumbnail/people-1.jpg"
                        />
                      </div>
                      <div class="flex-1">
                        <a

                          class="d-block font-semibold text-sm text-heading text-primary-hover"
                          >Darlene Robertson</a
                        >
                        <div class="text-xs text-muted line-clamp-1">
                          Web Developer
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <a  class="btn btn-sm px-3 py-1 btn-neutral"
                          >Follow</a
                        >
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <img
                          alt="..."
                          class="avatar rounded-circle"
                          src="../../../../assets/images/thumbnail/people-1.jpg"
                        />
                      </div>
                      <div class="flex-1">
                        <a

                          class="d-block font-semibold text-sm text-heading text-primary-hover"
                          >Theresa Webb</a
                        >
                        <div class="text-xs text-muted line-clamp-1">
                          Web Developer
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <a  class="btn btn-sm px-3 py-1 btn-neutral"
                          >Follow</a
                        >
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <img
                          alt="..."
                          class="avatar rounded-circle"
                          src="../../../../assets/images/thumbnail/people-1.jpg"
                        />
                      </div>
                      <div class="flex-1">
                        <a

                          class="d-block font-semibold text-sm text-heading text-primary-hover"
                          >Kristin Watson</a
                        >
                        <div class="text-xs text-muted line-clamp-1">
                          Web Developer
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <a  class="btn btn-sm px-3 py-1 btn-neutral"
                          >Follow</a
                        >
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <img
                          alt="..."
                          class="avatar rounded-circle"
                          src="../../../../assets/images/thumbnail/people-1.jpg"
                        />
                      </div>
                      <div class="flex-1">
                        <a

                          class="d-block font-semibold text-sm text-heading text-primary-hover"
                          >Cody Fisher</a
                        >
                        <div class="text-xs text-muted line-clamp-1">
                          Web Developer
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <a  class="btn btn-sm px-3 py-1 btn-neutral"
                          >Follow</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
