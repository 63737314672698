import { ApiStatus } from '../enums/roles.enum';

export class ApiResponse {
  data: PagedData | any;
  message: string;
  status: ApiStatus;
}

export class PagedData {
  data: any;
  paginator: Paginator;
}

export class Paginator {
  currentPage: number;
  itemCount: number;
  pageCount: number;
  perPage: number;
}

export class ApiQuery {
  query: any = {};
  options: ApiQueryOptions = new ApiQueryOptions();
  isCountOnly: boolean = false;
}

export class ApiQueryOptions {
  sort: any;
  select: any[] = null;
  page: number = 1;
  paginate: number = 6;   
}
