import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { userApi } from '../model/api-url';
import { ApiQuery, ApiResponse } from '../model/api-response';
import { CmnApiCallService } from './cmn-api-call.service';
import { ToastService } from './toast.service';
import { ApiStatus } from '../enums/roles.enum';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserListService {
  private apiQuery: ApiQuery = new ApiQuery();
  private userList = new BehaviorSubject<[]>([]);
  private userList$ = this.userList.asObservable();

  constructor(
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService,
  ) { }

  setUserList() {
    let usersApiUrl = userApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    }
    this.apiQuery.options.page = 1;
    this.apiQuery.options.paginate = 1000;

    this.cmnApiCallService.getList(usersApiUrl, this.apiQuery).subscribe(
      (res: ApiResponse) => {
        if(res.status === ApiStatus.SUCCESS){
          this.userList.next(res.data.data);
        }
      },
      (err) => {
        this.toastService.errorMsg(err);
      }
    )
  }

  getUserList(): Observable<[]>{
    return this.userList$;
  }

  getUserDataByInfo(userList: User[], data, info){
    const user: any = userList.find((a: any) => a.id == data);
    return user ? user[info] : undefined;
  }

  emptyUserList() {
    return this.userList.complete();
  }

  setList(data) {
    this.userList.next(data);
  }
}
