import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalaryRoutingModule } from './salary-routing.module';
import { SalaryListComponent } from './salary/salary-list/salary-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddEditSalaryComponent } from './salary/add-edit-salary/add-edit-salary.component';
import { AddEditSalaryDispatchedComponent } from './salary-dispatched/add-edit-salary-dispatched/add-edit-salary-dispatched.component';
import { SalaryDispatchedListComponent } from './salary-dispatched/salary-dispatched-list/salary-dispatched-list.component';

@NgModule({
  declarations: [
    SalaryListComponent,
    AddEditSalaryComponent,
    AddEditSalaryDispatchedComponent,
    SalaryDispatchedListComponent,
  ],
  imports: [CommonModule, SalaryRoutingModule, SharedModule],
  exports: [AddEditSalaryComponent, AddEditSalaryDispatchedComponent],
})
export class SalaryModule {}
