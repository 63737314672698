import { LocalStorageService } from './local-storage.service';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CmnApiCallService {
  constructor(
    private http: HttpClient,
    private LocalStorageService: LocalStorageService
  ) { }

  getList(url, model = {}) {
    return this.http.post(environment.baseUrl + url, model);
  }

  getById(url, id) {
    return this.http.get(environment.baseUrl + url + id);
  }

  addUpdateData(url, model: any = {}, userType?) {
    let user = this.LocalStorageService.getUser();
    // model.userType = user.userType;
    if (model.id) {
      return this.http.put(environment.baseUrl + url + model.id, model);
    } else {
      return this.http.post(environment.baseUrl + url, model);
    }
  }

  deleteData(url, id) {
    return this.http.delete(environment.baseUrl + url + id, {});
  }

  softDeleteData(url, id) {
    return this.http.put(environment.baseUrl + url + id, {});
  }

  uploadImage(image: File) {
    const formData = new FormData();
    formData.append('files', image);
    return this.http.post(`${environment.baseUrl}upload`, formData);
  }

  imageUpload(formData: FormData){
    return this.http.post(`${environment.baseUrl}upload`, formData);
  }
}
