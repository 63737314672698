import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TeamsRoutingModule } from './teams-routing.module';
import { TeamsComponent } from './teams/teams.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddEditTeamsComponent } from './add-edit-teams/add-edit-teams.component';
import { TeamMembersComponent } from './team-members/team-members.component';
import { AddEditTeamMembersComponent } from './add-edit-team-members/add-edit-team-members.component';

@NgModule({
  declarations: [TeamsComponent, AddEditTeamsComponent, TeamMembersComponent, AddEditTeamMembersComponent],
  imports: [CommonModule, TeamsRoutingModule, SharedModule],
  exports: [AddEditTeamsComponent, AddEditTeamMembersComponent],
})
export class TeamsModule {}
