// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://api-tmpl.nexotips.com/platform',
  // mainUrl: 'https://api-tmpl.nexotips.com/platform',
  // baseUrl: 'http://192.168.1.18:5000/admin/',
  // mainUrl: 'http://192.168.1.18:5000/',
  // baseUrl: 'http://localhost:5000/admin/',
  // mainUrl: 'http://localhost:5000/',
  baseUrl: 'https://nexdevapi.nexotips.com/admin/',
  mainUrl: 'https://nexdevapi.nexotips.com/',
  dataKey: 'oWklamwens',
};
// baseUrl: 'http://192.168.1.12:5000/admin/',
// mainUrl: 'http://192.168.1.12:5000/',
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
