import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TeamsComponent } from './teams/teams.component';
import { TeamMembersComponent } from './team-members/team-members.component';
import { RoleGuard } from 'src/app/core/guard/role.guars';

const routes: Routes = [
  { path: '', component: TeamsComponent, canActivate: [RoleGuard] },
  {
    path: 'team-members/:id',
    component: TeamMembersComponent,
    canActivate: [RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamsRoutingModule {}
