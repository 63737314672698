import { Component } from '@angular/core';

@Component({
  selector: 'app-add-edit-user-access',
  templateUrl: './add-edit-user-access.component.html',
  styleUrls: ['./add-edit-user-access.component.scss']
})
export class AddEditUserAccessComponent {

}
