import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
export interface DialogData {
  shareLink: string;
}
@Component({
  selector: 'app-share-to-web-popup',
  templateUrl: './share-to-web-popup.component.html',
  styleUrls: ['./share-to-web-popup.component.scss'],
})
export class ShareToWebPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}
