<div class="flex-lg-1 h-screen-header overflow-y-lg-auto">
  <header>
    <div class="container-fluid">
      <div class="border-bottom py-6">
        <div class="row align-items-center">
          <div class="col-sm col-12">
            <h1 class="h2 ls-tight">Roles and Permissions</h1>
          </div>
          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <!-- <a
                class="btn btn-sm btn-neutral border-base"
                (click)="openShareToWebDialog()"
                ><span class="pe-2"><i class="bi bi-people-fill"></i> </span
                ><span>Share</span> </a
              > -->
              <a
                href="#offcanvasCreate"
                class="btn btn-sm btn-primary"
                data-bs-toggle="offcanvas"
                ><span class="pe-2"
                  ><i class="bi bi-plus-square-dotted"></i> </span
                ><span>Create</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <main class="py-6 bg-surface-secondary">
    <app-new-user-role></app-new-user-role>
    <!-- <app-add-member-popup></app-add-member-popup> -->

    <div class="container-fluid">
      <div class="vstack gap-6">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <h5 class="me-auto">Manage Roles</h5>
              <div class="dropdown">
                <a
                  class="text-muted"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><i class="bi bi-three-dots-vertical"></i
                ></a>
                <div class="dropdown-menu">
                  <a href="#!" class="dropdown-item">Action </a
                  ><a href="#!" class="dropdown-item">Another action </a
                  ><a href="#!" class="dropdown-item">Something else here</a>
                </div>
              </div>
            </div>
            <p class="text-sm text-muted mt-1">
              Manage your account's roles and give your admins only the required
              permissions.
            </p>
          </div>
          <div class="card-body">
            <div class="row g-4">
              <div class="col-xl-3 col-sm-6">
                <div
                  class="card shadow-none border border-primary-hover"
                  (click)="openDialog()"
                >
                  <div class="card-body">
                    <div class="d-flex align-items-center flex-wrap">
                      <div class="me-auto">
                        <span
                          class="text-sm text-muted text-primary-hover d-block font-semibold"
                          >5 Members</span
                        >
                      </div>
                      <div class="avatar-group">
                        <span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                        /></span>
                      </div>
                      <a
                        class="avatar avatar-sm border-2 border-card rounded-circle bg-secondary text-muted text-xs"
                        >+3</a
                      >
                    </div>
                    <h6 class="h4 font-semibold mt-5 mb-2">Admin</h6>
                    <a
                      href="javascript:void(0)"
                      class="d-inline-block text-sm font-semibold stretched-link"
                      data-bs-toggle="modal"
                      >Edit members -&gt;</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div
                  class="card shadow-none border border-primary-hover"
                  (click)="openDialog()"
                >
                  <div class="card-body">
                    <div class="d-flex align-items-center flex-wrap">
                      <div class="me-auto">
                        <span
                          class="text-sm text-muted text-primary-hover d-block font-semibold"
                          >15 Members</span
                        >
                      </div>
                      <div class="avatar-group">
                        <span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                        /></span>
                      </div>
                      <a
                        class="avatar avatar-sm border-2 border-card rounded-circle bg-secondary text-muted text-xs"
                        >+3</a
                      >
                    </div>
                    <h6 class="h4 font-semibold mt-5 mb-2">Editor</h6>
                    <a
                      href="javascript:void(0)"
                      class="d-inline-block text-sm font-semibold stretched-link"
                      data-bs-toggle="modal"
                      >Edit members -&gt;</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div
                  class="card shadow-none border border-primary-hover"
                  (click)="openDialog()"
                >
                  <div class="card-body">
                    <div class="d-flex align-items-center flex-wrap">
                      <div class="me-auto">
                        <span
                          class="text-sm text-muted text-primary-hover d-block font-semibold"
                          >3 Members</span
                        >
                      </div>
                      <div class="avatar-group">
                        <span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                        /></span>
                      </div>
                      <a
                        class="avatar avatar-sm border-2 border-card rounded-circle bg-secondary text-muted text-xs"
                        >+3</a
                      >
                    </div>
                    <h6 class="h4 font-semibold mt-5 mb-2">Publisher</h6>
                    <a
                      href="javascript:void(0)"
                      class="d-inline-block text-sm font-semibold stretched-link"
                      data-bs-toggle="modal"
                      >Edit members -&gt;</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6">
                <div
                  class="card shadow-none border border-primary-hover"
                  (click)="openDialog()"
                >
                  <div class="card-body">
                    <div class="d-flex align-items-center flex-wrap">
                      <div class="me-auto">
                        <span
                          class="text-sm text-muted text-primary-hover d-block font-semibold"
                          >2 Members</span
                        >
                      </div>
                      <div class="avatar-group">
                        <span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                          /> </span
                        ><span
                          class="avatar avatar-sm rounded-circle text-white border border-1 border-solid border-card"
                          ><img
                            alt="..."
                            src="../../../../assets/images/thumbnail/people-1.jpg"
                        /></span>
                      </div>
                      <a
                        class="avatar avatar-sm border-2 border-card rounded-circle bg-secondary text-muted text-xs"
                        >+3</a
                      >
                    </div>
                    <h6 class="h4 font-semibold mt-5 mb-2">Manager</h6>
                    <a
                      href="javascript:void(0)"
                      class="d-inline-block text-sm font-semibold stretched-link"
                      data-bs-toggle="modal"
                      >Edit members -&gt;</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h5 class="me-auto">Admin Users</h5>
            <div class="dropdown">
              <a
                class="text-muted"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><i class="bi bi-three-dots-vertical"></i
              ></a>
              <div class="dropdown-menu">
                <a href="#!" class="dropdown-item">Action </a
                ><a href="#!" class="dropdown-item">Another action </a
                ><a href="#!" class="dropdown-item">Something else here</a>
              </div>
            </div>
          </div>
          <div
            class="px-4 py-4 border-top border-bottom d-flex flex-column flex-sm-row gap-3"
          >
            <div class="scrollable-x">
              <div class="btn-group" style="min-width: 700px">
                <a
                  href="javascript:void(0)"
                  class="btn btn-sm btn-neutral text-primary"
                  aria-current="page"
                  >View all</a
                >
                <a href="javascript:void(0)" class="btn btn-sm btn-neutral"
                  >Admin <span class="text-muted text-xs">(5)</span></a
                >
                <a href="javascript:void(0)" class="btn btn-sm btn-neutral"
                  >Publisher <span class="text-muted text-xs">(3)</span></a
                >
                <a href="javascript:void(0)" class="btn btn-sm btn-neutral"
                  >Manager <span class="text-muted text-xs">(2)</span></a
                >
              </div>
            </div>
            <div class="ms-auto hstack gap-2 flex-wrap">
              <div class="cmn-search-box cmn-form-field">
                <mat-form-field appearance="outline">
                  <mat-label>Search</mat-label>
                  <input
                    matInput
                    placeholder="Ex. ium"
                    #input
                    (keyup)="applyFilter($event)"
                  />
                </mat-form-field>
              </div>
              <div>
                <div class="cmn-search-box cmn-form-field">
                  <mat-form-field appearance="outline">
                    <mat-label>Position</mat-label>
                    <mat-select>
                      <mat-option>Developer</mat-option>
                      <mat-option>Designer</mat-option>
                      <mat-option>Intern</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="cmn-table-wrapper">
            <table mat-table [dataSource]="dataSource" class="w-100">
              <!-- Position Column -->

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <img [src]="element.logo" [alt]="element.logo" />
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>EMAIL</th>
                <td mat-cell *matCellDef="let element">{{ element.date }}</td>
              </ng-container>
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef>Role</th>
                <td mat-cell *matCellDef="let element">
                  <span
                    class="badge text-uppercase bg-soft-primary text-primary rounded-pill"
                  >
                    {{ element.position }}</span
                  >
                </td>
              </ng-container>
              <ng-container matColumnDef="workedhours">
                <th mat-header-cell *matHeaderCellDef>ACCESS</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.workedhours }}
                </td>
              </ng-container>

              <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef></th>

                <td class="text-end" mat-cell *matCellDef="let element">
                  <button
                    type="button"
                    class="btn btn-sm btn-square btn-neutral text-danger-hover ms-1"
                  >
                    <i class="bi bi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-square btn-neutral text-danger-hover ms-1"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">
                  No data matching the filter "{{ input.value }}"
                </td>
              </tr>
            </table>
            <mat-paginator
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page of users"
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
