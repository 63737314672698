<div class="bg-surface-secondary project-details-wrapper">
  <div class="d-xl-flex">
    <div class="min-w-0 flex-xl-fill">
      <header>
        <div class="container-fluid">
          <div class="pt-6 border-bottom pb-3 cmn-header-box ">
            <div class="row align-items-center">
              <div class="col-sm-12 col-12">
                <h1 class="h2 ls-tight">Launch Clever dashboard theme</h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main class="py-6">
        <div class="container-fluid">
          <div class="vstack gap-6">
            <div class="card">
              <div class="card-body">
                <h4 class="mb-3">Mission</h4>
                <p class="text-sm">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable you need to be sure there isn't
                  anything hidden in the middle of text.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h4 class="mb-3">Team</h4>
                <div class="row g-6 mt-n3">
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="position-relative d-flex align-items-center">
                      <div class="me-4">
                        <div
                          class="avatar rounded-circle border-2 border-dashed"
                        >
                          <i class="bi bi-plus-lg"></i>
                        </div>
                      </div>
                      <div class="flex-fill">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 text-muted text-primary-hover font-semibold stretched-link"
                          >Add member</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <div class="avatar rounded-circle">
                          <img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="flex-fill text-limit">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 font-semibold"
                          >Robert Fox</a
                        ><span class="d-block text-xs text-muted"
                          >Web Developer</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <div class="avatar rounded-circle">
                          <img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="flex-fill text-limit">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 font-semibold"
                          >Darlene Robertson</a
                        ><span class="d-block text-xs text-muted"
                          >Web Developer</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <div class="avatar rounded-circle">
                          <img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="flex-fill text-limit">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 font-semibold"
                          >Theresa Webb</a
                        ><span class="d-block text-xs text-muted"
                          >Web Developer</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <div class="avatar rounded-circle">
                          <img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="flex-fill text-limit">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 font-semibold"
                          >Kristin Watson</a
                        ><span class="d-block text-xs text-muted"
                          >Web Developer</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <div class="avatar rounded-circle">
                          <img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="flex-fill text-limit">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 font-semibold"
                          >Cody Fisher</a
                        ><span class="d-block text-xs text-muted"
                          >Web Developer</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <div class="avatar rounded-circle">
                          <img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="flex-fill text-limit">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 font-semibold"
                          >Robert Fox</a
                        ><span class="d-block text-xs text-muted"
                          >Web Developer</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-3 col-md-4 col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="me-3">
                        <div class="avatar rounded-circle">
                          <img
                            alt="..."
                            src="../../../../assets/images/avatar-images/avatar-img-3.jpg"
                          />
                        </div>
                      </div>
                      <div class="flex-fill text-limit">
                        <a
                          href="javascript:void(0)"
                          class="d-block h6 font-semibold"
                          >Darlene Robertson</a
                        ><span class="d-block text-xs text-muted"
                          >Web Developer</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">Files</h4>
                <div class="row g-3">
                  <div class="col-xxl-4 col-lg-6">
                    <div class="position-relative border border-dashed rounded">
                      <div class="p-3 d-flex align-items-center">
                        <div class="me-4">
                          <div
                            class="icon icon-shape text-xl bg-orange-500 text-white"
                          >
                            <i class="bi bi-card-image"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <a
                            href="javascript:void(0)"
                            class="d-block h6 text-sm font-semibold mb-1"
                            >Images</a
                          >
                          <span class="d-block text-xs">2560 files</span>
                        </div>
                        <div class="ms-4 pe-2 text-end">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              href="javascript:void(0)"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-4 col-lg-6">
                    <div class="position-relative border border-dashed rounded">
                      <div class="p-3 d-flex align-items-center">
                        <div class="me-4">
                          <div
                            class="icon icon-shape text-xl bg-blue-500 text-white"
                          >
                            <i class="bi bi-file-earmark-font"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <a
                            href="javascript:void(0)"
                            class="d-block h6 text-sm font-semibold mb-1"
                            >Documents</a
                          >
                          <span class="d-block text-xs">9800 files</span>
                        </div>
                        <div class="ms-4 pe-2 text-end">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              href="javascript:void(0)"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-4 col-lg-6">
                    <div class="position-relative border border-dashed rounded">
                      <div class="p-3 d-flex align-items-center">
                        <div class="me-4">
                          <div
                            class="icon icon-shape text-xl bg-green-500 text-white"
                          >
                            <i class="bi bi-file-earmark-excel"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <a
                            href="javascript:void(0)"
                            class="d-block h6 text-sm font-semibold mb-1"
                            >Sheets</a
                          >
                          <span class="d-block text-xs">120 files</span>
                        </div>
                        <div class="ms-4 pe-2 text-end">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              href="javascript:void(0)"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-4 col-lg-6">
                    <div class="position-relative border border-dashed rounded">
                      <div class="p-3 d-flex align-items-center">
                        <div class="me-4">
                          <div
                            class="icon icon-shape text-xl bg-purple-500 text-white"
                          >
                            <i class="bi bi-camera-video"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <a
                            href="javascript:void(0)"
                            class="d-block h6 text-sm font-semibold mb-1"
                            >Videos</a
                          >
                          <span class="d-block text-xs">400 files</span>
                        </div>
                        <div class="ms-4 pe-2 text-end">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              href="javascript:void(0)"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-4 col-lg-6">
                    <div class="position-relative border border-dashed rounded">
                      <div class="p-3 d-flex align-items-center">
                        <div class="me-4">
                          <div
                            class="icon icon-shape text-xl bg-yellow-500 text-white"
                          >
                            <i class="bi bi-file-arrow-down"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <a
                            href="javascript:void(0)"
                            class="d-block h6 text-sm font-semibold mb-1"
                            >Downloads</a
                          >
                          <span class="d-block text-xs">400 files</span>
                        </div>
                        <div class="ms-4 pe-2 text-end">
                          <div class="dropdown">
                            <a
                              class="text-muted"
                              href="javascript:void(0)"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              ><i class="bi bi-three-dots-vertical"></i
                            ></a>
                            <div class="dropdown-menu">
                              <a href="#!" class="dropdown-item">Action </a
                              ><a href="#!" class="dropdown-item"
                                >Another action </a
                              ><a href="#!" class="dropdown-item"
                                >Something else here</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="mb-6">Recent Activity</h5>
                <div
                  class="list-group list-group-flush list-group-borderless ms-4"
                >
                  <div class="list-group-item px-2 py-0">
                    <div class="border-start">
                      <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                        <div class="flex-none me-3">
                          <div
                            class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                          >
                            <i class="bi bi-file-earmark-pdf-fill"></i>
                          </div>
                        </div>
                        <div>
                          <small class="d-block mb-1 text-muted"
                            >2 hrs ago</small
                          >
                          <div>
                            You've uploaded
                            <span class="font-bold">documentation.pdf</span> to
                            the <span class="font-bold">Webpixels</span> project
                          </div>
                          <div class="d-flex gap-2 mt-2">
                            <div
                              class="position-relative bg-light border border-dashed border-warning-hover rounded-pill"
                            >
                              <div class="py-2 px-3 d-flex align-items-center">
                                <div class="me-2">
                                  <i class="bi bi-image text-warning"></i>
                                </div>
                                <div class="flex-fill">
                                  <a
                                    href="javascript:void(0)"
                                    class="d-block font-semibold text-xs text-muted text-warning-hover stretched-link"
                                    >documentation.pdf</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item px-2 py-0">
                    <div class="border-start">
                      <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                        <div class="flex-none me-3">
                          <div
                            class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                          >
                            <i class="bi bi-file-earmark-pdf-fill"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <small class="d-block mb-1 text-muted"
                            >4 hrs ago</small
                          >
                          <div class="text-sm">
                            <span class="text-heading text-sm font-bold"
                              >Mike</span
                            >
                            added a new comment to your task:
                          </div>
                          <div class="mt-2">
                            <p class="text-sm text-muted">
                              On the other hand, we denounce with righteous
                              indignation and dislike men who are so beguiled
                              and demoralized by the charms of pleasure of the
                              moment, so blinded by desire, that they cannot
                              foresee.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item px-2 py-0">
                    <div class="border-start">
                      <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                        <div class="flex-none me-3">
                          <div
                            class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                          >
                            <i class="bi bi-file-earmark-pdf-fill"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <small class="d-block mb-1 text-muted"
                            >4 hrs ago</small
                          >
                          <div class="text-sm">
                            Heather added
                            <span class="font-bold">4 images</span> to the
                            <span class="font-bold">Team</span> album:
                          </div>
                          <div class="mt-2 d-flex gap-2 scrollable-x">
                            <div class="col-auto">
                              <img
                                alt=""
                                src="../../../../assets/images/Details-page-images/activity-img-1.jpg"
                                class="rounded w-auto h-16"
                              />
                            </div>
                            <div class="col-auto">
                              <img
                                alt=""
                                src="../../../../assets/images/Details-page-images/activity-img-2.jpg"
                                class="rounded w-auto h-16"
                              />
                            </div>
                            <div class="col-auto">
                              <img
                                alt=""
                                src="../../../../assets/images/Details-page-images/activity-img-3.jpg"
                                class="rounded w-auto h-16"
                              />
                            </div>
                            <div class="col-auto">
                              <img
                                alt=""
                                src="../../../../assets/images/Details-page-images/activity-img-4.jpg"
                                class="rounded w-auto h-16"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item px-2 py-0">
                    <div class="border-start">
                      <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                        <div class="flex-none me-3">
                          <div
                            class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                          >
                            <i class="bi bi-file-earmark-pdf-fill"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <small class="d-block mb-1 text-muted"
                            >4 hrs ago</small
                          >
                          <div class="text-sm">
                            Heather added <span class="font-bold">Anna</span> to
                            the <span class="font-bold">Clever</span> team.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item px-2 py-0">
                    <div class="border-start">
                      <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                        <div class="flex-none me-3">
                          <div
                            class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                          >
                            <i class="bi bi-file-earmark-pdf-fill"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <small class="d-block mb-1 text-muted"
                            >4 hrs ago</small
                          >
                          <div class="text-sm">
                            Heather created the tags
                            <div class="d-inline-block mx-1">
                              <a
                                href="javascript:void(0)"
                                class="badge rounded-pill bg-danger bg-opacity-20 bg-opacity-100-hover text-danger text-white-hover"
                                >Bugs</a
                              >
                              <a
                                href="javascript:void(0)"
                                class="badge rounded-pill bg-success bg-opacity-20 bg-opacity-100-hover text-success text-white-hover"
                                >Showcase</a
                              >
                            </div>
                            for the
                            <span class="font-bold">Clever</span> project
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item px-2 py-0">
                    <div>
                      <div class="d-flex ms-sm-n6 ms-n4 pb-6">
                        <div class="flex-none me-3">
                          <div
                            class="icon icon-shape text-base w-sm-12 h-sm-12 w-8 h-8 bg-soft-primary text-primary rounded-circle"
                          >
                            <i class="bi bi-file-earmark-pdf-fill"></i>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <small class="d-block mb-1 text-muted"
                            >2 hrs ago</small
                          >
                          <div>
                            You've uploaded
                            <span class="font-bold">documentation.pdf</span> to
                            the <span class="font-bold">Webpixels</span> project
                          </div>
                          <div
                            class="mt-2 scrollable-x overflow-hidden gap-2 v-stack d-flex flex-wrap"
                          >
                            <div
                              class="d-inline-block position-relative bg-light border border-dashed border-tertiary-hover rounded-pill"
                            >
                              <div class="py-2 px-3 d-flex align-items-center">
                                <div class="me-2">
                                  <i class="bi bi-image text-tertiary"></i>
                                </div>
                                <div class="flex-fill">
                                  <a
                                    href="javascript:void(0)"
                                    class="d-block font-semibold text-xs text-muted text-tertiary-hover stretched-link"
                                    >design.fig</a
                                  >
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-inline-block position-relative bg-light border border-dashed border-warning-hover rounded-pill"
                            >
                              <div class="py-2 px-3 d-flex align-items-center">
                                <div class="me-2">
                                  <i class="bi bi-image text-warning"></i>
                                </div>
                                <div class="flex-fill">
                                  <a
                                    href="javascript:void(0)"
                                    class="d-block font-semibold text-xs text-muted text-warning-hover stretched-link"
                                    >readme.md</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="px-4 px-md-8">
                <div class="d-flex align-items-center py-5 border-bottom">
                  <h5 class="font-semibold">
                    <span class="me-1">📬</span> <span>New tasks</span>
                  </h5>
                  <div class="hstack gap-3 ms-auto">
                    <a href="javascript:void(0)" class="p-1 text-muted"
                      ><i class="bi bi-calendar-event"></i> </a
                    ><a href="javascript:void(0)" class="p-1 text-muted"
                      ><i class="bi bi-three-dots"></i
                    ></a>
                  </div>
                </div>
                <div class="list-group list-group-flush">
                  <div class="list-group-item py-3">
                    <div class="row g-3 align-items-center">
                      <div class="col-sm-6 col-12 d-flex">
                        <div class="form-check form-check-linethrough">
                          <input
                            class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                            type="checkbox"
                            id="check-new-tasks-1"
                          />
                          <a
                            href="#offcanvasCreate"
                            class="form-check-label font-regular ms-2"
                            data-bs-toggle="offcanvas"
                            >Get another day full of work done!</a
                          >
                        </div>
                      </div>
                      <div class="col-sm-auto col-12 ms-sm-auto">
                        <div class="hstack gap-5 justify-content-end flex-wrap">
                          <div>
                            <span class="badge bg-soft-warning text-warning"
                              >Important</span
                            >
                          </div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-check-circle-fill text-success"></i>
                            <span class="d-inline-block w-8 text-muted text-end"
                              >74%</span
                            >
                          </div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-files-alt text-muted me-1"></i>
                            <a
                              href="javascript:void(0)"
                              class="text-muted text-primary-hover stretched-link"
                              >18</a
                            >
                          </div>
                          <div
                            class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                          >
                            <span
                              class="avatar avatar-xs rounded-circle bg-primary text-white"
                              >JM</span
                            >
                            <div class="ps-2 pe-4">
                              <a
                                href="javascript:void(0)"
                                class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                                >+3</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item py-3">
                    <div class="row g-3 align-items-center">
                      <div class="col-sm-6 col-12 d-flex">
                        <div class="form-check form-check-linethrough">
                          <input
                            class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                            type="checkbox"
                            id="check-new-tasks-2"
                            checked="checked"
                          />
                          <a
                            href="#offcanvasCreate"
                            class="form-check-label font-regular ms-2"
                            data-bs-toggle="offcanvas"
                            >Keep my mentality healthy by taking walks
                            outside</a
                          >
                        </div>
                      </div>
                      <div class="col-sm-auto col-12 ms-sm-auto">
                        <div class="hstack gap-5 justify-content-end flex-wrap">
                          <div><span class="badge bg-soft- text-"></span></div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-check-circle-fill text-warning"></i>
                            <span class="d-inline-block w-8 text-muted text-end"
                              >38%</span
                            >
                          </div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-files-alt text-muted me-1"></i>
                            <a
                              href="javascript:void(0)"
                              class="text-muted text-primary-hover stretched-link"
                              >18</a
                            >
                          </div>
                          <div
                            class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                          >
                            <span
                              class="avatar avatar-xs rounded-circle bg-primary text-white"
                              >JM</span
                            >
                            <div class="ps-2 pe-4">
                              <a
                                href="javascript:void(0)"
                                class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                                >+3</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center py-5 border-bottom">
                  <h5 class="font-semibold">
                    <span class="me-1">✨</span> <span>Do today</span>
                  </h5>
                  <div class="hstack gap-3 ms-auto">
                    <a href="javascript:void(0)" class="p-1 text-muted"
                      ><i class="bi bi-calendar-event"></i> </a
                    ><a href="javascript:void(0)" class="p-1 text-muted"
                      ><i class="bi bi-three-dots"></i
                    ></a>
                  </div>
                </div>
                <div class="list-group list-group-flush">
                  <div class="list-group-item py-3">
                    <div class="row g-3 align-items-center">
                      <div class="col-sm-6 col-12 d-flex">
                        <div class="form-check form-check-linethrough">
                          <input
                            class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                            type="checkbox"
                            id="check-do-today-1"
                            checked="checked"
                          />
                          <a
                            href="#offcanvasCreate"
                            class="form-check-label font-regular ms-2"
                            data-bs-toggle="offcanvas"
                            >Figure out how to use Clever from the help
                            center!</a
                          >
                        </div>
                      </div>
                      <div class="col-sm-auto col-12 ms-sm-auto">
                        <div class="hstack gap-5 justify-content-end flex-wrap">
                          <div><span class="badge bg-soft- text-"></span></div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-check-circle-fill text-primary"></i>
                            <span class="d-inline-block w-8 text-muted text-end"
                              >10%</span
                            >
                          </div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-files-alt text-muted me-1"></i>
                            <a
                              href="javascript:void(0)"
                              class="text-muted text-primary-hover stretched-link"
                              >18</a
                            >
                          </div>
                          <div
                            class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                          >
                            <span
                              class="avatar avatar-xs rounded-circle bg-primary text-white"
                              >JM</span
                            >
                            <div class="ps-2 pe-4">
                              <a
                                href="javascript:void(0)"
                                class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                                >+3</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item py-3">
                    <div class="row g-3 align-items-center">
                      <div class="col-sm-6 col-12 d-flex">
                        <div class="form-check form-check-linethrough">
                          <input
                            class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                            type="checkbox"
                            id="check-do-today-2"
                          />
                          <a
                            href="#offcanvasCreate"
                            class="form-check-label font-regular ms-2"
                            data-bs-toggle="offcanvas"
                            >Build some new components in Figma</a
                          >
                        </div>
                      </div>
                      <div class="col-sm-auto col-12 ms-sm-auto">
                        <div class="hstack gap-5 justify-content-end flex-wrap">
                          <div>
                            <span class="badge bg-soft-success text-success"
                              >New product</span
                            >
                          </div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-check-circle-fill text-success"></i>
                            <span class="d-inline-block w-8 text-muted text-end"
                              >83%</span
                            >
                          </div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-files-alt text-muted me-1"></i>
                            <a
                              href="javascript:void(0)"
                              class="text-muted text-primary-hover stretched-link"
                              >18</a
                            >
                          </div>
                          <div
                            class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                          >
                            <span
                              class="avatar avatar-xs rounded-circle bg-primary text-white"
                              >JM</span
                            >
                            <div class="ps-2 pe-4">
                              <a
                                href="javascript:void(0)"
                                class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                                >+3</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item py-3">
                    <div class="row g-3 align-items-center">
                      <div class="col-sm-6 col-12 d-flex">
                        <div class="form-check form-check-linethrough">
                          <input
                            class="form-check-input w-5 h-5 mt-0 rounded-circle border-dashed flex-none"
                            type="checkbox"
                            id="check-do-today-3"
                            checked="checked"
                          />
                          <a
                            href="#offcanvasCreate"
                            class="form-check-label font-regular ms-2"
                            data-bs-toggle="offcanvas"
                            >Create wireframes for the new dashboard</a
                          >
                        </div>
                      </div>
                      <div class="col-sm-auto col-12 ms-sm-auto">
                        <div class="hstack gap-5 justify-content-end flex-wrap">
                          <div><span class="badge bg-soft- text-"></span></div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-check-circle-fill text-danger"></i>
                            <span class="d-inline-block w-8 text-muted text-end"
                              >4%</span
                            >
                          </div>
                          <div class="position-relative text-sm">
                            <i class="bi bi-files-alt text-muted me-1"></i>
                            <a
                              href="javascript:void(0)"
                              class="text-muted text-primary-hover stretched-link"
                              >18</a
                            >
                          </div>
                          <div
                            class="position-relative d-flex rounded-pill align-items-center d-inline-flex bg-secondary bg-secondary-hover bg-opacity-20"
                          >
                            <span
                              class="avatar avatar-xs rounded-circle bg-primary text-white"
                              >JM</span
                            >
                            <div class="ps-2 pe-4">
                              <a
                                href="javascript:void(0)"
                                class="mb-0 text-sm text-muted text-primary-hover stretched-link"
                                >+3</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="px-xl-0 px-6 pb-xl-0 pb-6">
      <div
        class="flex-xl-none w-xl-96 border-start-xl card-xl card bg-surface-primary h-xl-calc position-sticky cus-top-xl-18"
        style="--x-h-xl: 3.4rem"
      >
        <aside class="p-3 p-sm-8 h-full overflow-y-xl-auto">
          <div class="vstack gap-6">
            <div>
              <div class="d-flex align-items-center mb-3">
                <h5 class="me-auto">Project Details</h5>
                <a  class="link-primary font-bold text-sm"
                  ><i class="bi bi-clipboard-check me-2"></i>Copy link</a
                >
              </div>
              <div class="mb-5">
                <img
                  alt="..."
                  class="rounded"
                  src="../../../../assets/images/Details-page-images/side-image-1.jpg"
                />
              </div>
              <h6 class="text-muted font-semibold mb-3">Description</h6>
              <p class="text-sm mb-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <h6 class="text-muted font-semibold mb-3">Tags</h6>
              <div class="d-flex gap-2 flex-wrap">
                <a

                  class="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                  >Project</a
                >
                <a

                  class="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                  >SpaceX</a
                >
                <a

                  class="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                  >Launch</a
                >
                <a

                  class="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                  >Falcon</a
                >
                <a

                  class="bg-white bg-opacity-20 bg-opacity-100-hover border rounded px-3 py-1 font-semibold text-heading text-xs"
                  >Multiplanetary</a
                >
              </div>
            </div>
            <div>
              <h6 class="text-muted font-semibold mb-5">Timeline</h6>
              <div class="list-group list-group-flush list-group-borderless">
                <div class="list-group-item px-2 py-0">
                  <div class="border-start">
                    <div class="d-flex ms-n2 pb-6">
                      <div class="flex-none me-3">
                        <div
                          class="icon icon-shape text-sm w-4 h-4 bg-success rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <a
                          href="#!"
                          class="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                          >Prepare for launch</a
                        >
                        <div>
                          <small class="text-xs text-muted">1 week ago</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item px-2 py-0">
                  <div class="border-start">
                    <div class="d-flex ms-n2 pb-6">
                      <div class="flex-none me-3">
                        <div
                          class="icon icon-shape text-sm w-4 h-4 bg-danger rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <a
                          href="#!"
                          class="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                          >Make the final tests</a
                        >
                        <div>
                          <small class="text-xs text-muted">1 week ago</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item px-2 py-0">
                  <div class="border-start">
                    <div class="d-flex ms-n2 pb-6">
                      <div class="flex-none me-3">
                        <div
                          class="icon icon-shape text-sm w-4 h-4 bg-success rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <a
                          href="#!"
                          class="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                          >Prepare the documentation</a
                        >
                        <div>
                          <small class="text-xs text-muted">1 week ago</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item px-2 py-0">
                  <div class="border-start">
                    <div class="d-flex ms-n2 pb-6">
                      <div class="flex-none me-3">
                        <div
                          class="icon icon-shape text-sm w-4 h-4 bg-warning rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <a
                          href="#!"
                          class="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                          >Build the new website</a
                        >
                        <div>
                          <small class="text-xs text-muted">1 week ago</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item px-2 py-0">
                  <div class="border-start">
                    <div class="d-flex ms-n2 pb-6">
                      <div class="flex-none me-3">
                        <div
                          class="icon icon-shape text-sm w-4 h-4 bg-success rounded-circle"
                        ></div>
                      </div>
                      <div>
                        <a
                          href="#!"
                          class="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                          >Create the design system</a
                        >
                        <div>
                          <small class="text-xs text-muted">1 week ago</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item px-2 py-0">
                  <div>
                    <div class="d-flex ms-n2 pb-6">
                      <div class="flex-none me-3">
                        <i
                          class="bi bi-calendar2-plus-fill text-base text-muted"
                        ></i>
                      </div>
                      <div>
                        <a
                          href="#!"
                          class="h6 font-semibold lh-none text-heading text-primary-hover d-inline-block mb-1"
                          >The project was created</a
                        >
                        <div>
                          <small class="text-xs text-muted">1 week ago</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </div>
</div>
