import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationRoutingModule } from './organization-routing.module';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { AddEditOrganizationComponent } from './add-edit-organization/add-edit-organization.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [OrganizationListComponent, AddEditOrganizationComponent],
  imports: [CommonModule, OrganizationRoutingModule, SharedModule],
  exports: [AddEditOrganizationComponent],
})
export class OrganizationModule {}
