<div class="h-100 overflow-auto cmn-add-edit-sidenav">
  <div
    class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary"
  >
    <h5 class="add-edit-sidenav-title">
      {{ isEdit ? "Update" : "Create a new" }} Attendance
    </h5>
    <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="add-edit-sidenav-body px-4 pt-4">
    <div class="container-fluid px-0">
      <form [formGroup]="attendanceForm" class="row">
        <!-- <div class="cmn-form-field col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>User Name</mat-label>
            <input [disabled]="true" matInput formControlName="userId" />
          </mat-form-field>
        </div> -->

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Punch-In Date</mat-label>
            <input
              matInput
              [ngxMatDatetimePicker]="punchInDate"
              placeholder="Choose a date"
              formControlName="start"
            />
            <ngx-mat-datepicker-toggle
              matSuffix
              [for]="punchInDate"
            ></ngx-mat-datepicker-toggle>
            <ngx-mat-datetime-picker #punchInDate>
              <ngx-mat-datepicker-actions>
                <button mat-button ngxMatDatepickerCancel>Cancel</button>
                <button mat-raised-button color="primary" ngxMatDatepickerApply>
                  Apply
                </button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>

        <div class="cmn-form-field col-12">
          <mat-form-field appearance="outline">
            <mat-label>Punch-Out Date</mat-label>

            <input
              matInput
              [ngxMatDatetimePicker]="PunchOutDate"
              placeholder="Choose a date"
              formControlName="end"
            />
            <ngx-mat-datepicker-toggle
              matSuffix
              [for]="PunchOutDate"
            ></ngx-mat-datepicker-toggle>
            <ngx-mat-datetime-picker matIconSuffix #PunchOutDate>
              <ngx-mat-datepicker-actions>
                <button mat-button ngxMatDatepickerCancel>Cancel</button>
                <button mat-raised-button color="primary" ngxMatDatepickerApply>
                  Apply
                </button>
              </ngx-mat-datepicker-actions>
            </ngx-mat-datetime-picker>
          </mat-form-field>
        </div>
        <button (click)="getVal()">click</button>

        <div class="cmn-form-field col-lg-12">
          <ckeditor
            [editor]="editor"
            [data]="editorData"
            [config]="editorConfig"
            (ready)="onReady($event)"
          ></ckeditor>
        </div>
      </form>
    </div>
  </div>

  <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
    <page-button
      data-bs-dismiss="add-edit-sidenav"
      [config]="{ rank: 'neutral' }"
      [loading]="false"
      (clicked)="closeAddEditSideNav()"
      >Close</page-button
    >
    <page-button
      [config]="{ rank: 'primary' }"
      [loading]="false"
      (clicked)="onSubmit()"
      >{{ isEdit ? "Update" : "Add" }}
    </page-button>
  </div>
</div>
