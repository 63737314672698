import { ToastService } from 'src/app/core/services/toast.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, pipe, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, finalize, retry } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';

import { Router } from '@angular/router';
import { ApiStatus } from '../enums/roles.enum';
import { SpinnerOverlayService } from 'src/app/shared/spinner/services/spinner-overlay.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  req: any;
  next: any;
  constructor(
    private toastService: ToastService,
    private localStorageService: LocalStorageService,
    private spinnerOverlayService: SpinnerOverlayService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = req.headers;
    let data: any = this.localStorageService.getUser();
    if (data) {
      if (data.token && !req.url.includes('networkmerchants')) {
        headers = headers.set('Authorization', 'Bearer ' + data.token);
      }
    }
    const clonedRequest = req.clone({ headers });

    let showSpinner = true;
    if (showSpinner) {
      const spinnerSubscription: Subscription =
        this.spinnerOverlayService.spinner$.subscribe();
      return next
        .handle(clonedRequest)
        .pipe(
          retry(1),
          catchError((error) => {
            if (error.error.status == ApiStatus.UNAUTHORIZED) {
              this.localStorageService.logout();
              this.router.navigate(['/authentication/login']);
            }
            if (error instanceof HttpErrorResponse) {
              this.toastService.errorMsg(error);
            }
            return of(error.error);
          })
        )
        .pipe(finalize(() => spinnerSubscription.unsubscribe()));
    } else {
      let spinnerSubscription: Subscription = new Subscription();
      return next
        .handle(clonedRequest)
        .pipe(
          retry(1),
          catchError((error) => {
            if (error.error.status == ApiStatus.UNAUTHORIZED) {
              this.localStorageService.logout();
              this.router.navigate(['/authentication/login']);
            }
            if (error instanceof HttpErrorResponse) {
              this.toastService.errorMsg(error);
            }
            return of(error.error);
          })
        )
        .pipe(finalize(() => spinnerSubscription.unsubscribe()));
    }
  }
}
