<div class="h-100 overflow-auto cmn-add-edit-sidenav">
    <div class="add-edit-sidenav-header border-bottom px-4 py-3 bg-surface-secondary">
        <h5 class="add-edit-sidenav-title">
            {{ isEdit ? "Update" : "Create a new" }} post detail
        </h5>

        <button mat-icon-button class="close-icon" (click)="closeAddEditSideNav()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="add-edit-sidenav-body px-4 pt-4">
        <div class="px-0 container-fluid">
            <form [formGroup]="postForm" class="row">
                <div class="cmn-form-field col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Title</mat-label>
                        <input matInput formControlName="title">
                        <mat-error *ngIf="postForm.controls['title'].invalid" class="error-msg text-danger">
                            {{ formErrors["title"]}}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 cmn-form-field">
                    
                    <ckeditor [title]="title" [editor]="editor" [data]="editorData" [config]="editorConfig" (ready)="onReady($event)" formControlName="desc"></ckeditor>
                </div>

                <div class="col-12 cmn-form-field pt-5">
                        <div class="file-selector" [ngClass]="{ 'border-none': selectedImage }">
                            <div class="w-100" (click)="fileInput.click()">
                                <div class="m-auto d-flex h-100 d-flex justify-content-center align-items-center"
                                    *ngIf="!selectedImage">
                                    <div class="text-center">
                                        <div class="text-2xl text-muted">
                                            <i class="bi bi-upload"></i>
                                        </div>
                                        
                                        <mat-label><b>Feattured Image</b></mat-label>
                                        <div class="d-flex text-sm mt-3">
                                            <p class="font-semibold">Upload a file or drag and drop</p>
                                        </div>
                                        <p class="text-xs text-gray-500">PNG, JPG, GIF up to 5MB</p>
                                    </div>
                                </div>
                                <div class="preview-container w-full h-full rounded-[12px]" *ngIf="selectedImage">
                                    <img [src]="selectedImage" alt="Selected Image" class="preview-image" />
                                    <mat-icon style="color: black; z-index: 3"
                                        class="position-absolute w-[26px] h-[26px]">add_circle_outline</mat-icon>
                                </div>
                            </div>

                            <input type="file" #fileInput hidden (change)="onFileSelected($event)" accept="image/*"
                                />
                        </div>
                </div>
                <div class="cmn-form-field col-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Image Url</mat-label>
                        <input matInput formControlName="imgUrl" type="url">
                    </mat-form-field>
                </div>

                <div class="cmn-form-field col-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Tags</mat-label>
                        <input matInput formControlName="tags">
                        <mat-error *ngIf="postForm.controls['tags'].invalid" class="error-msg text-danger">
                            {{ formErrors["tags"]}}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cmn-form-field col-3">
                    <page-button [config]="{ rank: 'primary' }" [loading]="false" (clicked)="onAddTag(postForm.value['tags'])">
                        Add more
                    </page-button>
                </div>
                    <div class="d-flex gap-2 flex-wrap">
                        <a
                        class="bg-white bg-opacity-20 bg-opacity-100-hover border border-2 rounded px-3 py-1 text-heading fs-6 d-flex justify-content-between"
                        *ngFor="let i of tags">{{i.tag}}
                        
                            <button class="border rounded rounded-circle d-flex align-items-center p-0 ms-1" (click)="deleteTag(i.id)">
                                <mat-icon class="fs-5 w-auto h-auto">clear</mat-icon>
                            </button>
                        </a>
                    </div>   


            </form>
        </div>
    </div>
    <div class="add-edit-sidenav-footer px-4 py-3 bg-surface-secondary">
        <page-button data-bs-dismiss="add-edit-sidenav" [config]="{ rank: 'neutral' }" [loading]="false"
            (clicked)="closeAddEditSideNav()">Close</page-button>
        <page-button [config]="{ rank: 'primary' }" (clicked)="onSubmit()" [loading]="false">{{ isEdit ? "Update" : "Add" }}
        </page-button>
    </div>
</div>