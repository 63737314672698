import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import { Tags } from 'src/app/core/model/tags';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-edit-post',
  templateUrl: './add-edit-post.component.html',
  styleUrls: ['./add-edit-post.component.scss'],
})
export class AddEditPostComponent {
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();

  selectedImage: any; // Variable to hold the selected image preview
  loadingImage: boolean = false;

  postForm: FormGroup;
  formErrors: any = {};
  isEdit: boolean = false;
  apiQuery: ApiQuery = new ApiQuery();
  tags: Tags[] = [];
  tagId: number = 0;

  public editor = ClassicEditor;
  public title = 'Description';
  public editorData = '';
  private editorInstance: any;
  editorConfig = {
    placeholder: 'Add description...',
    height: '500px',
  };

  postId: number;
  validationMessages = {
    title: {
      required: 'User ID is required',
    },
    desc: {
      required: 'Description is required',
    },
    imgUrl: {
      required: 'Image is required',
    },
    tags: {
      required: 'Tags is required',
    },
  };

  constructor(
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService
  ) {
    if (this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if (res && res.id) {
          this.isEdit = true;
          this.postId = +res.id;
        } else {
          this.isEdit = false;
        }
      });
    }
  }

  ngOnInit() {
    this.createTeamForm();
  }

  createTeamForm() {
    this.postForm = this.fb.group({
      title: ['', [Validators.required]],
      desc: ['', [Validators.required]],
      imgUrl: [''],
      tags: [],
    });
  }

  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  onFileSelected(event: Event) {
    this.loadingImage = true;
    const fileInput = event.target as HTMLInputElement;
    const selectedFile = fileInput.files?.[0];

    if (selectedFile) {
      this.cmnApiCallService.uploadImage(selectedFile).subscribe(
        (response: ApiResponse) => {
          if (response.status === ApiStatus.SUCCESS) {
            const path: string = response.data.uploadSuccess[0].path;
            this.postForm.controls['imgUrl'].patchValue(path);
            this.selectedImage = environment.mainUrl + path;
          }
          if (response.status === ApiStatus.FAILURE) {
            this.toastService.uploadFailure(
              response.data.uploadFailed[0].error
            );
          }
          this.loadingImage = false;
        },
        (error) => {
          console.error('Error uploading image', error);
          this.toastService.errorMsg(error);
          this.loadingImage = false;
        }
      );
    }
  }

  onAddTag(tag: string) {
    let id: number = this.tagId++;
    this.tags.push({ id, tag });
    this.postForm.controls['tags'].reset();
  }

  deleteTag(id: number) {
    this.tags = this.tags.filter((tag) => tag.id !== id);
  }

  onSubmit() {
    this.postForm.value['tags'] = this.tags;
    this.postForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.postForm.controls,
      this.validationMessages
    );
    if (this.postForm.valid) {
      console.log(this.postForm.value);
    }
  }

  onReady(editor: any) {
    this.editorInstance = editor;
  }
}
