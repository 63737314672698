import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UsersComponent } from './users/users.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { CreateNewUserComponent } from './create-new-user/create-new-user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NewUserRoleComponent } from './new-user-role/new-user-role.component';
import { AddMemberPopupComponent } from './add-member-popup/add-member-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RoleListComponent } from './role/role-list/role-list.component';
import { AddEditRoleComponent } from './role/add-edit-role/add-edit-role.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserDocumentListComponent } from './user-documents/user-document-list/user-document-list.component';
import { AddEditUserDocumentComponent } from './user-documents/add-edit-user-document/add-edit-user-document.component';
import { UserAccessComponent } from './user-access/user-access.component';
import { AddEditUsersComponent } from './add-edit-users/add-edit-users.component';

@NgModule({
  declarations: [
    UserProfileComponent,
    UsersComponent,
    UserPermissionsComponent,
    CreateNewUserComponent,
    NewUserRoleComponent,
    AddMemberPopupComponent,
    RoleListComponent,
    AddEditRoleComponent,
    UserDocumentListComponent,
    AddEditUserDocumentComponent,
    UserAccessComponent,
    AddEditUsersComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDialogModule,
    SharedModule,
  ],
  exports: [AddEditRoleComponent, AddEditUserDocumentComponent, AddEditUsersComponent],
})
export class UserModule {}
