export enum RolesEnum {
  Admin = 1,
  Developer = 2,
  Client = 3,
  Creator = 4,
}

export enum ApiStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  RECORD_NOT_FOUND = 'RECORD_NOT_FOUND',
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum leaves {
  'applied' = 0,
  'accepted' = 1,
  'rejected' = 2,
}

export enum gender {
  Male = 0,
  Female = 1,
}

export enum status {
  '⚪ Initiated' = 0,
  '🔵 Confirmed' = 1,
  '🟠 In_Progress' = 2,
  '🟡 Testing' = 3,
  '🟣 Deployed' = 4,
  '🟢 Done' = 5,
}

export enum TaskType {
  story = 1,
  ticket = 2,
  subticket = 3,
  bug = 4,
}

export enum TaskPriority {
  medium = 0,
  highest = 1,
  high = 2,
  low = 3,
  lowest = 4,
  blocker = 5,
  NA = 6,
}

export enum TaskTypeColorClass {
  'badge badge-sm bg-soft-primary text-primary' = 1,
  'badge badge-sm bg-success text-white' = 2,
  'badge badge-sm bg-soft-success text-success' = 3,
  'badge badge-sm bg-soft-warning text-warning' = 4,
}

export enum TaskPriorityColorClass {
  'badge badge-sm bg-soft-warning text-warning' = 0,
  'badge badge-sm bg-danger text-white' = 1,
  'badge badge-sm bg-soft-danger text-danger' = 2,
  'badge badge-sm bg-soft-success text-success' = 3,
  'badge badge-sm bg-success text-white' = 4,
  'badge badge-sm bg-dark text-white' = 5,
  'badge badge-sm bg-secondary text-dark' = 6,
}
