import { CreateProjectFormComponent } from './create-project-form/create-project-form.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectGridviewComponent } from './project-gridview/project-gridview.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { ProjectTableViewComponent } from './project-table-view/project-table-view.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { RoleGuard } from 'src/app/core/guard/role.guars';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
  {
    path: 'overview',
    component: ProjectOverviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'project',
    component: ProjectOverviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'project-gridview',
    component: ProjectGridviewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'project-tableview',
    component: ProjectTableViewComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'create-project-form',
    component: CreateProjectFormComponent,
    canActivate: [RoleGuard],
  },
  {
    path: 'project-details',
    component: ProjectDetailsComponent,
    canActivate: [RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule {}
