import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Clipboard} from '@angular/cdk/clipboard';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse } from 'src/app/core/model/api-response';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { environment } from 'src/environments/environment';
import { siteCredsApi } from 'src/app/core/model/api-url';

@Component({
  selector: 'app-add-edit-site-creds',
  templateUrl: './add-edit-site-creds.component.html',
  styleUrls: ['./add-edit-site-creds.component.scss']
})
export class AddEditSiteCredsComponent {
  addEditSideNav$ = this.cmnAddEditSidenavService.getAddEditSideNav();

  selectedImage: any; // Variable to hold the selected image preview
  loadingImage: boolean = false;

  userId: number;
  siteCredsId: number;
  group: FormGroup;
  credsForm: FormGroup;
  formErrors: any = {};
  isEdit: boolean = false;
  apiQuery: ApiQuery = new ApiQuery();
  tags: string[] = [];
  disabled: boolean = true;

  public editor = ClassicEditor;
  public editorData = '';
  private editorInstance: any;
  editorConfig = {
    placeholder: 'Add description...',
  };

  validationMessages = {
    name: {
      required: 'name is required',
    },
    websiteName: {
      required: 'website is required',
    },
  };

  constructor (
    private fb: FormBuilder,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private localStorageService: LocalStorageService,
    private clipboard: Clipboard,
    private formValidationService: FormValidationService,
    private cmnApiCallService: CmnApiCallService,
    private toastService: ToastService
  ) {
    if(this.cmnAddEditSidenavService.openingComponentSubject) {
      this.cmnAddEditSidenavService.openingComponentSubject.subscribe((res) => {
        if(res && res.id) {
          this.isEdit = true;
          this.siteCredsId = +res.id;
        } else {
          this.isEdit = false;
        }
      })
    }
  }
  
  ngOnInit() {
    this.userId = this.localStorageService.getUser().id;
    this.createSiteCredsForm();
    if (this.isEdit) {
      this.patchVal();
    }
  }

  createSiteCredsForm() {
    this.credsForm = this.fb.group({
      name: ['', [Validators.required]],
      publicKey: ['832b96f9441ac4cd168df7d17e8a7b23e53c7ceb', [Validators.required]], // SHA1 Hash	
      privateKey: ['abef9be1e28b9daf936da3657c1a57d59c3843e6', [Validators.required]], // SHA1 Hash
      websiteName: [],
      userId:this.userId,
    });
  }

  copyToClipboard(content: string){
    this.clipboard.copy(this.credsForm.value[content]);
    this.toastService.infoMsg('', content + ' Copied');
  }
  
  closeAddEditSideNav() {
    this.cmnAddEditSidenavService.closeAddEditSideNav();
  }

  onSubmit(){
    this.credsForm.markAllAsTouched();
    this.formErrors = this.formValidationService.getErrorValue(
      this.credsForm.controls,
      this.validationMessages
    );
    if(this.credsForm.valid){
      let siteCredsApiUrl = this.isEdit 
      ? siteCredsApi.update
      : siteCredsApi.create;
      let model = this.credsForm.value;
  
      if(this.isEdit) {
        model['id'] = +this.siteCredsId;
      }
  
      this.cmnApiCallService.addUpdateData(siteCredsApiUrl, model).subscribe(
        (res: ApiResponse) => {
          if(res.status === ApiStatus.SUCCESS) {
            if(!this.isEdit) {
              this.toastService.successMsg(
                'Success',
                'site Credentials created successfully'
              );
            } else {
              this.toastService.successMsg(
                'Updated',
                'site Creadentials successfully edited'
              )
            }

            this.cmnAddEditSidenavService.getUpdatedDataList(
              'SiteCredsListComponent'
            );
            this.credsForm.reset();
            this.closeAddEditSideNav();
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
    }
  }

  patchVal() {
    let leaveGetByIdUrl = siteCredsApi.byId;
    this.cmnApiCallService
      .getById(leaveGetByIdUrl, this.siteCredsId)
      .subscribe(
        (res: ApiResponse) => {
          if (res.status == ApiStatus.SUCCESS) {
            if (res && res.data) {
              this.credsForm.patchValue({
                name: res.data.name,
                publicKey: res.data.publicKey,
                privateKey: res.data.privateKey,
                websiteName: res.data.websiteName,
              });
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }
}
