import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ApiStatus } from 'src/app/core/enums/roles.enum';
import { ApiQuery, ApiResponse, Paginator } from 'src/app/core/model/api-response';
import { roleApi, userApi } from 'src/app/core/model/api-url';
import { CmnAddEditSidenavService } from 'src/app/core/services/cmn-add-edit-sidenav.service';
import { CmnApiCallService } from 'src/app/core/services/cmn-api-call.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ShareToWebPopupComponent } from '../../share-to-web-popup/share-to-web-popup.component';
import { User } from 'src/app/core/model/user';
import { UserListService } from 'src/app/core/services/user-list.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = [
    'name',
    'email',
    'mobileNo',
    'role',
    'empty',
  ];
  role: number = -1;
  userList: [] = [];
  roleList = [];
  dataSource = new MatTableDataSource(this.userList);
  private updateUsersListSubscription: Subscription;
  apiQuery: ApiQuery = new ApiQuery();
  paginatorData: Paginator = new Paginator();
  environmentPath = environment.mainUrl;
  defaultFilterPredicate?: (data: any, filter: string) => boolean;

  constructor(
    public dialog: MatDialog,
    private cmnApiCallService: CmnApiCallService,
    private cmnAddEditSidenavService: CmnAddEditSidenavService,
    private toastService: ToastService,
    private userListService: UserListService,
  ) {
    this.apiQuery.options.paginate = 10;
  }

  ngOnInit() : void {
    this.getRoleList();
    this.defaultFilterPredicate = this.dataSource.filterPredicate;
    this.subscribeToUpdateUsersList();
  }

  getRoleById(roleId){
    let role = this.roleList.find((role) => role.id == roleId);
    return role.code;
  }

  private subscribeToUpdateUsersList(): void {
    this.updateUsersListSubscription =
      this.cmnAddEditSidenavService.updateUsersList.subscribe(
        async (res) => {
          if (res) {
            this.getUserList(this.role);
          }
        }
      );
  }

  getUserList(role: number) {
    this.role = role;
    let roleUserUrl = userApi.list;
    if(role && role != -1){
      this.apiQuery.query = {
        isDeleted: false,
        role: role.toString(),
      }
      }else {
        this.apiQuery.query = {
          isDeleted: false,
        }
      }
      this.apiQuery.options.paginate = 10;
      this.cmnApiCallService.getList(roleUserUrl, this.apiQuery).subscribe(
        (res: ApiResponse) => {
          if(res.status === ApiStatus.SUCCESS) {
            if (res && res.data) {
              this.dataSource = new MatTableDataSource(res.data.data);
              this.userList = res.data.data;
              this.userListService.setList(this.userList);
              this.paginatorData = res.data.paginator;
              this.dataSource.sort = this.sort;
            }
          }
        },
        (err) => {
          this.toastService.errorMsg(err);
        }
      );
  }
  getRoleList() {
    let roleListUrl = roleApi.list;
    this.apiQuery.query = {
      isDeleted: false,
    };
    this.cmnApiCallService
      .getList(roleListUrl, this.apiQuery)
      .subscribe((res: ApiResponse) => {
        if (res.status === ApiStatus.SUCCESS) {
          if (res && res.data) {
            this.roleList.push({
            name: "All",
            id: -1,
            addedBy: null,
            code: "ALL",
            createdAt: "2024-05-14T11:19:53.000Z",
            isActive: true,
            isDeleted: false,
            updatedAt: "2024-05-14T11:19:53.000Z",
            updatedBy: null,
            weight: 1});
            this.roleList = [ ...this.roleList, ...res.data.data];
            this.getUserList(this.role);
          }
        }
      });
  }

  openShareToWebDialog(): void {
    this.dialog.open(ShareToWebPopupComponent, {
      width: '400px',
    });
  }

  openAddEditSideNav(id?: number) {
    if (typeof id != 'undefined') {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'UsersListComponent',
        id
      );
    } else {
      this.cmnAddEditSidenavService.openAddEditSideNav(
        'UsersListComponent'
      );
    }
    if(id){
      this.getUserList(this.role);
    }
  }

  deleteUser(id) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let deleteTeamUrl = userApi.softDelete;
        let model = {
          isDeleted: true,
        };
        model['id'] = +id;

        this.cmnApiCallService
          .addUpdateData(deleteTeamUrl, model)
          .subscribe(
            (res: ApiResponse) => {
              if (res.status == ApiStatus.SUCCESS) {
                this.toastService.successMsg(
                  'Deleted',
                  `User has been successfully deleted`
                );
                this.getUserList(this.role);
              }
            },
            (err) => {
              this.toastService.errorMsg(err);
            }
          );
      }
    });
  }

  applyFilter(event: Event) {
    this.dataSource.filterPredicate = this.defaultFilterPredicate;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  pageChange(data) {
    this.apiQuery.options.page = data.pageIndex + 1;
    this.apiQuery.options.paginate = data.pageSize;
    this.getUserList(this.role);
  }

  ngOnDestroy(){
    if(this.updateUsersListSubscription){
      this.updateUsersListSubscription.unsubscribe();
    }
  }
}
