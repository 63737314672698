import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalaryService {
  isIncrement: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  haveToIncrementInSalary(value: boolean) {
    this.isIncrement.next(value);
  }
}
