<button
  #btn
  class="button-wrapper btn btn-sm"
  [disabled]="disabled"
  (click)="onClick()"
  [type]="type"
  [ngClass]="{
    loading: loading,
    secondary: config?.rank === 'secondary',
    neutral: config?.rank === 'neutral',
    primary: config?.rank === 'primary',
    'secondary-grey': config?.rank === 'secondary-grey',
    ternary: config?.rank === 'ternary',
    orange: config?.rank === 'orange',
    'has-left-arrow': config?.arrow === 'left',
    'has-right-arrow': config?.arrow === 'right'
  }"
>
  <!-- LEFT ARROW -->
  <svg
    class="arrow arrow-left"
    width="23px"
    height="9px"
    viewBox="0 0 23 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    *ngIf="isLeft(config?.arrow)"
  >
    <path d="M0.5,4.5 L22.5,4.5" stroke="#212121"></path>
    <polyline
      stroke="#212121"
      fill="transparent"
      transform="translate(4.510408, 4.545942) scale(-1, 1) rotate(45.000000) translate(-4.510408, -4.545942) "
      points="1.51040764 1.54594155 7.51040764 1.54594155 7.51040764 7.54594155"
    ></polyline>
  </svg>

  <!-- LABEL -->
  <div class="button-label ellipsis">
    <i *ngIf="icon" [class]="icon" class="me-2"> </i>
    <span #ref><ng-content></ng-content></span>
    <span *ngIf="($any(ref).nativeElement?.childNodes?.length || 0) === 0">{{
      config?.label
    }}</span>
  </div>

  <!-- RIGHT ARROW -->
  <svg
    class="arrow arrow-right"
    width="23px"
    height="9px"
    viewBox="0 0 23 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    *ngIf="!loading && isRight(config?.arrow)"
  >
    <path d="M0.5,4.5 L22.5,4.5" stroke="#212121"></path>
    <polyline
      stroke="#212121"
      fill="transparent"
      transform="translate(18.510408, 4.545942) rotate(45.000000) translate(-18.510408, -4.545942)"
      points="15.5104076 1.54594155 21.5104076 1.54594155 21.5104076 7.54594155"
    ></polyline>
  </svg>

  <!-- SPINNER -->
  <div
    class="spinner"
    [ngClass]="{
      loading: loading,
      'animated-expand': config?.arrow !== 'right'
    }"
  >
    <i class="bi bi-arrow-repeat"></i>
  </div>

  <!-- RIPPLE -->
  <div class="mat-ripple-receiver" matRipple *ngIf="!disabled"></div>
</button>
